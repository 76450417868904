import { Button } from "antd";
import React from "react";
import ReactPlayer from "react-player";

export default function InputData({ data }) {
  return (
    <div className="imgs-assetsp-bcs">
      {data.map((d, di) => {
        return d.indexOf(".png?") !== -1 ||
          d.indexOf(".jpeg?") !== -1 ||
          d.indexOf(".jpg?") !== -1 ||
          d.indexOf(".webp?") !== -1 ? (
          <img key={di} src={d} alt="" />
        ) : d.indexOf(".mp4?") !== -1 ||
          d.indexOf(".webm?") !== -1 ||
          d.indexOf(".mpg?") !== -1 ||
          d.indexOf(".mpeg?") !== -1 ? (
          <div className="fl-c-mid221">
            <ReactPlayer
              url={d}
              key={di}
              className=""
              controls
              //   playing
              // ref={playerRef}
              // onProgress={(e) => setPlayedSeconds(parseInt(e.playedSeconds))}
            />
          </div>
        ) : (
          <a href={d} key={di}>
            <Button>Display file</Button>
          </a>
        );
      })}
    </div>
  );
}
