import { Button, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import ConceptTable from "./components/ConceptTable";
import "./concepts.css";
import { FaPlus } from "react-icons/fa";
import AddConcept from "./components/AddConcept";
import Icons from "../../components/Icons";
const abc = [
  {
    name: "string",
    data_t: ["a", "b"],
    data_v: [Icons.logo],
    status: "pending",
    index_progress: 25,
    last_indexed: new Date(),
    user_id: "123",
    created_by: new Date(),
    favorite: true,
    index_id: "abc123",
  },
];

export default function Concepts() {
  const { concept, setConcept } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);

  useEffect(() => {
    if (concept === undefined) {
      getConcept();
    }
  }, [callApi]);

  const getConcept = () => {
    setConcept(abc);
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    // fetch(`https://int.display.video/api/channel?page=1&limit=15`, requestOptions)
    fetch(`https://int.display.video/api/concept?page=1&limit=15`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  };

  return (
    <div>
      <div>
        <AddConcept />
      </div>
      {concept ? <ConceptTable /> : null}
    </div>
  );
}
