import {
  Input,
  message,
  Image,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
} from "antd";
import { EditOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdEdit, MdPlusOne } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import SearchContext from "../../Context/Search/SearchContext";
import Pages from "./components/Pages";
import Uploader from "./components/Uploader";
const { Option } = Select;
export default function List() {
  const { allEmp, user } = useContext(SearchContext);

  const [Emp, setEmp] = useState("");
  const [list, setlist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [loadeingAI, setLoadeingAI] = useState(true);
  const [addUrl, setaddUrl] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [ploading, setPloading] = useState(false);
  const [dAdd, setdAdd] = useState();
  const [editImage, setEditImage] = useState();
  const [categroy, setCategory] = useState("");
  const [section, setsection] = useState("");
  const [search, setsearch] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editedUrl, setEditedUrl] = useState("");

  useEffect(() => {
    if (list === undefined) {
      setLoadeing(true);
      getlist();
    }
  }, [pageNo, categroy, section, search]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/list?page=${pageNo}&limit=15&sort_by=${-1}&section=${section}&category=${categroy}&search=${search}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setlist(data.listList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };
  const Savelist = (row) => {
    row.created_by = user.user_id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/list`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Row added in first page");
          const all = [...list];
          all.unshift(data.data);

          setlist(all);
        } else {
          message.error(data.msgError);
        }
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/list/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = list.filter((t) => t._id !== id);
    setlist(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/list/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = list.map((m) => (m._id === row._id ? row : m));
    setlist(allRow);
  };

  const AIProduct = (id) => {
    const obj = {};
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/list/product/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        AIMovie(id);
      });
  };

  const AIMovie = (id) => {
    const obj = {};
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/list/movie/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        AIPeople(id);
      });
  };
  const AIPeople = (id) => {
    const obj = {};
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/list/people/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        AIPrompt(id);
      });
  };
  const AIPrompt = (id) => {
    const obj = {};
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/list/description/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeingAI(false);
        if (data && data.isSuccess) {
          const allRow = list.map((m) => (m._id === id ? data.listData : m));
          setlist(allRow);
        } else {
          // message.error("SOmething went wrong");
        }
      });
  };

  const PreviewImage = () => {
    const obj = { query: addUrl.create };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/create/preview/image`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPloading(false);
        if (data.isSuccess) {
          setaddUrl({ ...addUrl, URL: data.data?.data });
        } else {
        }
      });
  };

  const columns = [
    {
      title: "Url",
      dataIndex: "url",
      key: "url",
      render: (text, row) => {
        return (
          <>
            <Image width={200} src={text} alt="" />
            <BButton
              type="link"
              icon={<MdEdit />}
              onClick={() => {
                setEditImage(row); // Initialize editedUrl with current text
              }}
            />
          </>
        );
      },
    },
    {
      title: "Web Link",
      dataIndex: "web_link",
      key: "web_link",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="web link"
          onChange={(e) => {
            changeField("web_link", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ web_link: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "value",
      dataIndex: "value",
      key: "value",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="value"
          onChange={(e) => {
            changeField("value", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ value: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="name"
          onChange={(e) => {
            changeField("name", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ name: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "category",
      dataIndex: "category",
      key: "category",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="category"
          onChange={(e) => {
            changeField("category", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ category: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "section",
      dataIndex: "section",
      key: "section",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="section"
          onChange={(e) => {
            changeField("section", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ section: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Connect Type",
      dataIndex: "connect_type",
      key: "connect_type",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Type"
          onChange={(e) => {
            changeField("connect_type", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ connect_type: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="status"
          onChange={(e) => {
            changeField("status", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ status: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "description",
      dataIndex: "description",
      key: "description",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="description"
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "cost",
      dataIndex: "cost",
      key: "cost",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="cost"
          onChange={(e) => {
            changeField("cost", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ cost: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "creator",
      dataIndex: "creator",
      key: "creator",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="creator"
          onChange={(e) => {
            changeField("creator", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ cost: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "compute",
      dataIndex: "compute",
      key: "compute",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="compute"
          onChange={(e) => {
            changeField("compute", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ compute: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "guide",
      dataIndex: "guide",
      key: "guide",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="guide"
          onChange={(e) => {
            changeField("guide", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ guide: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "config",
      dataIndex: "config",
      key: "config",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="config"
          onChange={(e) => {
            changeField("config", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ config: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "modelFlow",
      dataIndex: "model_flow",
      key: "05",
      width: 150,

      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="blue"
          onTagChange={(v) => {
            changeField("model_flow", v, row);
            saveChanges({ model_flow: v }, row._id, true);
          }}
        />
      ),
    },

    {
      title: "Regions",
      dataIndex: "region_options",
      key: "05",
      width: 150,

      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="blue"
          onTagChange={(v) => {
            changeField("region_options", v, row);
            saveChanges({ region_options: v }, row._id, true);
          }}
        />
      ),
    },

    {
      title: "Computing",
      dataIndex: "compute_options",
      key: "05",
      width: 150,

      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="blue"
          onTagChange={(v) => {
            changeField("compute_options", v, row);
            saveChanges({ compute_options: v }, row._id, true);
          }}
        />
      ),
    },

    {
      title: "Models",
      dataIndex: "model_options",
      key: "05",
      width: 150,

      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="blue"
          onTagChange={(v) => {
            changeField("model_options", v, row);
            saveChanges({ model_options: v }, row._id, true);
          }}
        />
      ),
    },

    // {
    //   title: "products",
    //   dataIndex: "products",
    //   key: "products",
    //   render: (text, row) =>
    //     text && text.length ? <GreenTick height="20px" width="20px" /> : "",
    // },
    {
      title: "AI",
      dataIndex: "",
      key: "03",
      width: 100,
      render: (text, row) => (
        <div className="buton-list-cs">
          {loadeingAI === row._id ? (
            <Spin />
          ) : (
            <BButton
              onClick={() => {
                setLoadeingAI(row._id);
                AIProduct(row._id);
              }}
              type="primary"
              size="small"
            >
              AI
            </BButton>
          )}
          <BButton
            style={{ margin: "10px" }}
            type="primary"
            size="small"
            onClick={() => setaddUrl(row)}
          >
            Create
          </BButton>
          <Pages
            saveChanges={(obj, id, isSave) => saveChanges(obj, id, isSave)}
            row={row}
          />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="table-top-nav">
        <Button
          size="sm"
          onClick={() => setdAdd({ section: "", value: "", category: "" })}
        >
          <BsPlus />
          Add
        </Button>
        <div>
          <Input
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
              setlist();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Search Value "
          />
          <Input
            value={section}
            onChange={(e) => {
              setsection(e.target.value);
              setlist();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="section "
          />
          <Input
            value={categroy}
            onChange={(e) => {
              setCategory(e.target.value);
              setlist();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Category "
          />
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {list ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setlist();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={list}
        />
      ) : null}
      <Modal
        visible={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          dAdd.url ? (
            <div className="bx-form-brand no-radius-input">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b style={{ marginRight: "10px" }}> Image:</b>
                    </td>
                    <td>
                      <div className="img-dadd-c">
                        <img src={dAdd.url} alt="image" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b style={{ marginRight: "10px" }}> Value:</b>
                    </td>
                    <td>
                      <Input
                        className="bx-fld-10t"
                        placeholder="Value"
                        value={dAdd.value}
                        onChange={(e) =>
                          setdAdd({ ...dAdd, value: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b style={{ marginRight: "10px" }}> Category:</b>
                    </td>
                    <td>
                      <Input
                        className="bx-fld-10t"
                        placeholder="category"
                        value={dAdd.category}
                        onChange={(e) =>
                          setdAdd({ ...dAdd, category: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b style={{ marginRight: "10px" }}> Section:</b>
                    </td>
                    <td>
                      <Input
                        className="bx-fld-10t"
                        placeholder="Section"
                        value={dAdd.section}
                        onChange={(e) =>
                          setdAdd({ ...dAdd, section: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flex-end">
                <Button
                  onClick={() => {
                    Savelist(dAdd);
                    setdAdd();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <Uploader setdAdd={setdAdd} dAdd={dAdd} />
          )
        ) : null}
      </Modal>
      <Modal
        visible={editImage !== undefined}
        onCancel={() => setEditImage()}
        className="no-f-no-h brand-modal"
      >
        {editImage ? (
          <div>
            <div className="img-dadd-c new-img-edit">
              <img
                style={{ margin: "0 auto" }}
                src={editImage.url}
                alt="image"
              />
            </div>
            {editImage.reuploaded ? (
              <div className="save-btn-cancel">
                <Button
                  onClick={() => {
                    setEditImage({ ...editImage, reuploaded: undefined });
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Change
                </Button>
                <Button
                  onClick={() => {
                    changeField("url", editImage.url, {
                      ...editImage,
                      reuploaded: undefined,
                    });
                    saveChanges({ url: editImage.url }, editImage._id, true);
                    setEditImage();
                  }}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div>
                <h5>Upload new Image</h5>
                <Uploader
                  setdAdd={setEditImage}
                  dAdd={editImage}
                  changeField={changeField}
                  saveChanges={saveChanges}
                />
              </div>
            )}
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete List</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={addUrl !== undefined}
        onCancel={() => setaddUrl()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {addUrl ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Create:</b>
                  </td>
                  <td style={{ display: "flex" }}>
                    <Input
                      className="bx-fld-10t"
                      placeholder="file_type"
                      value={addUrl.create}
                      onChange={(e) =>
                        setaddUrl({ ...addUrl, create: e.target.value })
                      }
                    />
                    {addUrl.create && addUrl.create.length ? (
                      ploading ? (
                        <Spin />
                      ) : (
                        <BButton
                          size="small"
                          type="primary"
                          onClick={() => {
                            PreviewImage();
                            setPloading(true);
                          }}
                        >
                          Generate
                        </BButton>
                      )
                    ) : null}
                  </td>
                </tr>
                <td>
                  <b style={{ marginRight: "10px" }}> Generated:</b>
                </td>
                <td>
                  {addUrl?.URL ? (
                    <img src={addUrl?.URL} alt="" className="imag-moda-add" />
                  ) : null}
                </td>
              </tbody>
            </table>
            <div className="flex-end">
              {addUrl?.URL ? (
                <Button
                  onClick={() => {
                    changeField("url", addUrl?.URL, addUrl);
                    saveChanges({ url: addUrl?.URL }, addUrl._id, true);
                    setaddUrl();
                  }}
                >
                  Save
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
