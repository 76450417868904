import { Card, Col, Progress, Row, Slider, Spin, Tag } from "antd";
import {
  SettingFilled,
  StarOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import React, { useContext, useState } from "react";
import ReactPlayer from "react-player";
import FileDrawer from "./FileDrawer";
import { Link, useNavigate } from "react-router-dom";
import { FaTable, FaInfo } from "react-icons/fa";
import { BiCard } from "react-icons/bi";
import { TimeCorrection } from "../../../components/GlobalFunctions";
import InfoVideo from "./InfoVideo";
import SearchContext from "../../../Context/Search/SearchContext";
import moment from "moment";
export default function FileCards() {
  const navigate = useNavigate();
  const { videoTasks, setVideoTask } = useContext(SearchContext);

  const [selectedCard, setSelectedCard] = useState();
  const [selectedCard2, setSelectedCard2] = useState();

  return (
    <div>
      <Row className="vt-vid-c">
        {videoTasks.videos &&
          videoTasks.videos.map((vd, vdi) => {
            return (
              <Col
                key={vdi}
                xs={{
                  span: 24,
                }}
                md={{ span: 12 }}
                lg={{
                  span: 6,
                }}
                className="vid-col-c"
              >
                <Card className="card-bx-fl">
                  <div className="fl-c-top">
                    <StarOutlined />
                    <FaInfo onClick={() => setSelectedCard2(vd)} />
                    <SettingFilled onClick={() => setSelectedCard(vd)} />
                  </div>
                  <div
                    onClick={() => {
                      setVideoTask({ ...videoTasks, activeVideo: vd });
                      navigate(`/video/${vd._id}`);
                    }}
                    className="fl-c-mid"
                  >
                    <ReactPlayer
                      url={vd.file_url}
                      className=""
                      controls={false}
                    />
                    <b>{vd.video_name}</b>
                  </div>
                  <div className="fl-c-botom">
                    <b>
                      {TimeCorrection(Math.floor(Number(vd.video_duration)))}
                    </b>

                    <span className="crd-bx-cntr">
                      <b>{vd.model_id}</b>
                      {vd.status === "pending" ? <span>⌛</span> : null}
                      {vd.status === "progress" ? <Spin /> : null}
                      {vd.status === "done" ? <span> ✔️</span> : null}
                    </span>

                    <Link to={`/files/${vd._id}`}>
                      <PlayCircleOutlined />
                    </Link>
                  </div>
                  <div>
                    <div className="bx-x-ss">
                      <b> Created At:</b>
                      {moment(vd.createdAt).format("MMMM Do YYYY h:mm:ss")}
                    </div>
                    <div className="bx-x-ss">
                      <b> Updated At:</b>
                      {moment(vd.updatedAt).format("MMMM Do YYYY h:mm:ss")}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
      </Row>

      <FileDrawer
        setSelectedCard={() => setSelectedCard()}
        card={selectedCard}
      />
      {selectedCard2 ? (
        <InfoVideo
          setSelectedCard={() => setSelectedCard2()}
          card={selectedCard2}
        />
      ) : null}
    </div>
  );
}
