import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  Typography,
  Tooltip,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import SearchContext from "../../Context/Search/SearchContext";
import moment from "moment";
import TaskIdAssets from "./components/TaskIdAssets";
const { Paragraph } = Typography;

export default function ChatLog() {
  const { user } = useContext(SearchContext);

  const [Org, setOrg] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [callApi, setCallApi] = useState(1);
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [loadingS, setLoadingS] = useState(false);

  useEffect(() => {
    getOrg();
  }, [pageNo, callApi, userId]);

  const getOrg = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/chat_log?page=${pageNo}&limit=15&sort_by=${-1}&user_id=${userId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setOrg(data.chatlogList);
          setTotalPages(data.totalPages);
          if (pageNo === 1) {
            setTimeout(() => {
              setCallApi(callApi + 1);
            }, 8000);
          }
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const deleteInvite = (id) => {
    const filtereted = Org.filter((t) => t._id !== id);
    setOrg(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/organization/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      render: (text, row) => (
        <span>{moment(text).format("MMM Do YY h:mm:ss")}</span>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 90,
      render: (text, row) => <div className="description-height">{text}</div>,
    },
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      width: 150,
      render: (text, row) => (
        <div style={{ maxWidth: "400px" }}>
          <Tooltip title={text}>
            <Paragraph
              ellipsis={{
                rows: 5,
                // expandable: true,
                symbol: "see more",
              }}
              className="desc-p-crdjjkl"
            >
              {text}
            </Paragraph>
          </Tooltip>
        </div>
      ),
    },

    {
      title: "Bus Name",
      dataIndex: "business_name",
      key: "business_name",
      width: 150,
      render: (text, row) => <div className="description-height">{text}</div>,
    },
    {
      title: "Gate 1",
      dataIndex: "goal",
      key: "goal",
      width: 150,
      render: (text, row) => (
        <div>
          {text &&
            text.map((t, ti) => {
              return (
                <Tag color="black" key={ti}>
                  {t}
                </Tag>
              );
            })}
        </div>
      ),
    },

     {
      title: "Gate 2",
      dataIndex: "secondary_goal",
      key: "secondary_goal",
      width: 150,
      render: (text, row) => (
        <div>
          {text &&
            text.map((t, ti) => {
              return (
                <Tag color="black" key={ti}>
                  {t}
                </Tag>
              );
            })}
        </div>
      ),
    },
    {
      title: "Assets",
      dataIndex: "Assets",
      key: "Assets",
      width: 20,
      render: (text, row) => <TaskIdAssets row={row} />,
    },

    {
      title: "User id",
      dataIndex: "user_id",
      key: "user_id",
      width: 120,
      render: (text, row) => text,
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  const createTask = () => {
    const obj = {
      query: search,
      user_id: "lab",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/fx`, requestOptions)
      .then((response) => {
        if (response.status === 429) {
          setSearch("");
          setLoadingS(false);

          throw new Error("Too Many Requests (429)");
        }
        return response.json();
      })
      .then((data) => {
        setSearch("");
        setLoadingS(false);
        if (data.data) {
          message.success("Processing");
        } else {
          console.log("API call succeeded, but data is missing");
          message.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
        if (error.message !== "Too Many Requests (429)") {
          message.error("Something went wrong. Please try again later.");
        }
      });
  };
  return (
    <div>
      {loadeing ? <Spin /> : null}
      <div className="space-between">
        {" "}
        <div style={{ display: "flex" }} className="create-input-fvc">
          <div className={`create-input-fvc2 `}>
            <Input.TextArea
              autoSize={{ maxRows: 4, minRows: 1 }}
              style={{
                width: 300,
              }}
              type="text"
              placeholder="Generate"
              onChange={(e) => {
                if (e.target.value.length < 1000) {
                  setSearch(e.target.value);
                }
              }}
              value={search}
              required=""
              className={`form-control border-0  px-4 grey-input main-search-cine`}
              // onKeyPress={handleKeyPress}
            />
            {search.trim().length ? (
              loadingS ? (
                <div className="loader-c-sd">
                  <Spin
                    style={{
                      fontSize: 20,
                      marginLeft: "10px",
                    }}
                    size="small"
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    if (!loadingS) {
                      createTask(search);
                      setLoadingS(true);
                    }
                  }}
                  style={{ padding: "0px", width: "auto" }}
                  className={` start-card-bx `}
                >
                  <BButton>Create</BButton>
                </div>
              )
            ) : null}
          </div>
        </div>
        <div>
          <Tag
            color={userId === "" ? "black" : "default"}
            onClick={() => setUserId("")}
          >
            All User
          </Tag>
          <Tag
            color={userId === "lab" ? "black" : "default"}
            onClick={() => setUserId("lab")}
          >
            Lab
          </Tag>
        </div>
      </div>
      {Org ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setOrg();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={Org}
        />
      ) : null}

      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Org</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
