import React, { useContext, useEffect, useState, useRef } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Button,
  InputNumber,
  message,
  Switch,
  Popconfirm,
  Modal,
  Input,
  notification,
  Select,
} from "antd";
import ReactPlayer from "react-player";
import "./screenshoot.css";
import { FaArrowLeft, FaArrowRight, FaPlay, FaTrash } from "react-icons/fa";
import { TimeCorrection } from "../../components/GlobalFunctions";
import LoadingText from "../../components/LoadingText";
import NotFound from "../../components/NotFound";

const { Option } = Select;
export default function Screenshoot() {
  const { videoTasks, setVideoTask, videoConcept, setVideoConcept } =
    useContext(SearchContext);
  const videoRef = useRef();
  const [callApi, setCallApi] = useState(0);
  const [callApi2, setCallApi2] = useState(0);
  const [notSave, setNotSave] = useState(false);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [searchLabel, setSearchLabel] = useState("");
  const [active, setActive] = useState(0);
  const [timer, setTimer] = useState(31);
  const [active_id3, setActiveId] = useState();
  const [displayName, setDisplayName] = useState();
  const [int_description, setInt_description] = useState("");

  useEffect(() => {
    if (videoTasks.screenshot === undefined) {
      getVideoTask();
      setActive(0);
      resettALL();
      setActiveId(window.localStorage.getItem("active_id3"));
    }
  }, [callApi]);

  const resettALL = () => {
    setNotSave(false);
    setViewThumbnails();
    setSearchLabel("");
  };

  useEffect(() => {
    if (callApi2 !== 0) {
      getConcept();
    }
  }, [callApi2]);

  const getConcept = () => {
    const vt = videoTasks.screenshot.slice(active, active + 1)[0];

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/concept?user_id=${vt.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.conceptList) {
          const list = data.conceptList;
          list.unshift({ name: "Nothing", saved_images: [] });
          setVideoConcept({
            ...videoConcept,
            concept3: list,
            // selectedConcepts: [],
          });
        }
        console.log(data, "concept");
      });
  };

  const getVideoTask = (actId) => {
    let isId = actId ? actId.id : window.localStorage.getItem("active_id3");
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      isId
        ? `https://int.display.video/api/video_segment?_id=${isId}`
        : `https://int.display.video/api/video_segment?page=1&limit=15&model_i=pending`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          if (isId) {
            checkIdActive(data.segmentList, isId);
          }
          setVideoTask({
            ...videoTasks,
            screenshot: data.segmentList,
          });

          if (data.segmentList && data.segmentList.length) {
            setCallApi2(callApi2 + 1);
          }
        }
        console.log(data);
      });
  };

  const checkIdActive = (videos, id) => {
    const vd = videos.filter((f) => f._id === id);
    if (vd.length) {
      setVideoTask({
        ...videoTasks,
        screenshot: videos,
      });
    } else {
      localStorage.removeItem("active_id3");
      setActiveId();
      getVideoTask({ id: undefined });
    }
    if (videos.length) {
      setCallApi2(callApi2 + 1);
    }
  };

  const startBtnClicled = (name) => {
    setDisplayName();
    const video = videoTasks.screenshot.slice(active, active + 1)[0];
    if (video.search_status === "progress") {
      message.warning("Someone Already  Doing");
    } else {
      video.search_status = "progress";
      const all = videoTasks.screenshot.map((v) =>
        v._id === video._id ? video : v
      );
      setVideoTask({ ...videoTasks, screenshot: all });
      setActiveId(video._id);
      updateFiled({ model_i: "progress" }, video._id);
      window.localStorage.setItem("active_id3", video._id);
    }
    // noti30S();
  };

  const updateFiled = (obj, id, isDone) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (isDone !== undefined) {
            localStorage.removeItem("active_id3");
            window.location.reload();
          }
          message.success("Updated");
        } else {
          message.error("something went wrong");
        }
      });
  };

  const saveScreenshot = () => {
    const videoTime = parseInt(
      videoRef.current.player.player.player.currentTime
    );
    const vt = videoTasks.screenshot.slice(active, active + 1)[0];

    const obj = {
      internal_index: 1,
      video_name: "screenshot",
      video_type: "concept_image",
      concepts: videoConcept.selectedConcepts2,
      int_description: int_description,
      description: ".",
      file_url: vt.file_url,
      status: "automated",
      startTime: TimeCorrection(videoTime),
      tags: "test",
      headclip_id: vt._id,
      user_id: vt.user_id,
    };
    setVideoConcept({
      ...videoConcept,
      selectedConcepts2: [],
    });
    setInt_description("");
    const requestOptions = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/screenshot`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Added");
        } else {
          message.warning("something went wrong");
        }
      });
  };

  const saveConcept = (tag) => {
    const { selectedConcepts2 } = videoConcept;
    const filter = selectedConcepts2.filter((t) => t === tag);
    let removed;
    let added;
    if (filter.length) {
      removed = selectedConcepts2.filter((t) => t !== tag);
    } else {
      added = [...selectedConcepts2, tag];
    }

    setVideoConcept({
      ...videoConcept,
      selectedConcepts2: removed ? removed : added,
    });
    setNotSave(true);
  };

  return (
    <div>
      <Row>
        {videoTasks.screenshot && videoTasks.screenshot.length ? (
          <Col md={{ span: 12 }} lg={{ span: 12 }}>
            <div className="r-l-divc">
              <div>
                {active === 0 ? null : (
                  <FaArrowLeft
                    onClick={() => {
                      resettALL();
                      setActive(active - 1);
                      setVideoConcept({
                        ...videoConcept,
                        concept3: undefined,
                      });
                      setCallApi2(callApi2 + 1);
                    }}
                  />
                )}
              </div>
              <div>
                {active + 1 === videoTasks.screenshot.length ? null : (
                  <FaArrowRight
                    onClick={() => {
                      resettALL();
                      setActive(active + 1);
                      setVideoConcept({
                        ...videoConcept,
                        concept3: undefined,
                      });
                      setCallApi2(callApi2 + 1);
                    }}
                  />
                )}
              </div>
            </div>
            {videoTasks.screenshot
              .slice(active, active + 1)

              .map((vt, vti) => {
                return (
                  <div key={vti} className="vt-vid-c">
                    <ReactPlayer
                      onPlay={(e) => console.log(e)}
                      controls
                      url={vt.file_url}
                      ref={videoRef}
                    />
                    <div className="bx-sp-tn">
                      <a href={vt.file_url} download>
                        Dwonload
                      </a>

                      {active_id3 === vt._id ? (
                        <>
                          <Popconfirm
                            title="Make Sure Your Complete this Video?"
                            onConfirm={() => {
                              updateFiled(
                                {
                                  model_i: "done",
                                },
                                vt._id,
                                "done"
                              );
                              setActiveId();
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button size="small" className="donebtns-c">
                              Done
                            </Button>
                          </Popconfirm>
                          {notSave ? (
                            <Button type="primary" onClick={saveScreenshot}>
                              Save Screen
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        <Button
                          onClick={() => setDisplayName("")}
                          className="plat-btn-strt"
                          size="small"
                          type="primary"
                        >
                          Start <FaPlay />
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
          </Col>
        ) : null}
        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="flex-betweenbc">
            <Input
              placeholder="Search Label"
              onChange={(e) => setSearchLabel(e.target.value)}
            />
            <Select
              onChange={(v) => setInt_description(v)}
              value={int_description}
            >
              <Option disabled value="">
                Select View
              </Option>
              <Option value="camera_view">Camera View</Option>
              <Option value="scene_change">Scene Change</Option>
            </Select>
          </div>
          {int_description !== "" ? (
            <div className="con-vd-c">
              {videoConcept.concept3 &&
                videoConcept.concept3
                  .filter((f) =>
                    f.name.toLowerCase().includes(searchLabel.toLowerCase())
                  )
                  .map((vt, vti) => {
                    return (
                      <div key={vti} className="select-cn-cc">
                        <div>
                          {vt.saved_images && vt.saved_images.length ? (
                            <img
                              alt=""
                              className="img-thum-con"
                              src={vt.saved_images[0]}
                              onClick={() => setViewThumbnails(vt)}
                            />
                          ) : null}
                        </div>
                        <div
                          key={vti}
                          onClick={() => saveConcept(vt.name)}
                          className={`name-bx-field-t3 ${
                            videoConcept.selectedConcepts2 &&
                            videoConcept.selectedConcepts2.filter(
                              (t) => t === vt.name
                            ).length
                              ? " active-icn-f"
                              : ""
                          }`}
                        >
                          {vt.name}
                        </div>
                      </div>
                    );
                  })}
            </div>
          ) : (
            <div>
              {videoTasks.screenshot ? (
                <div>
                  {videoTasks.screenshot.length === 0 ? <NotFound /> : null}
                </div>
              ) : (
                <div style={{ marginTop: "100px" }}>
                  {" "}
                  <LoadingText />
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>

      <Modal
        className="no-h-bo-b-modal "
        visible={displayName !== undefined}
        onCancel={() => setDisplayName()}
      >
        <h4>Your Name Code</h4>
        <Input
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder="Name"
        />
        <Button
          className="btnc-s"
          type="primary"
          onClick={() => startBtnClicled(displayName)}
        >
          Save and Start
        </Button>
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
