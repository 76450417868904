import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Popconfirm,
  Spin,
  Input,
  TimePicker,
} from "antd";
import { CloseOutlined, TagFilled } from "@ant-design/icons";
import ReactPlayer from "react-player";
import moment from "moment";
export default function ShortsImages() {
  const { shorts, setshorts, user } = useContext(SearchContext);
  const playerRef = React.useRef();
  const [loading, setLoading] = useState();
  const [loadingS, setLoadingS] = useState();
  const [SegmentNames, setSegmentNames] = useState();
  const [lod, setLod] = useState(0);
  const [startTime, setstartTime] = useState("00:00:00");
  const [endTime, setendTime] = useState("00:00:00");

  useEffect(() => {
    setLoadingS(false);
  }, [lod]);

  const updateFiled = (obj, id, type) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (type === "indexed") {
            updateTimeSheet(shorts.activeVideo);
          }
        } else {
          message.error("something went wrong");
        }
      });
  };
  const updateTimeSheet = (video) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/timesheet?model_id=${user.user_id}&sheet_duration=progress&sheet_type=shorts&video_id=${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data &&
          data.isSuccess &&
          data.timesheetList &&
          data.timesheetList.length
        ) {
          updateTID(data.timesheetList[0]._id);
        }
      });
  };

  const updateTID = (id) => {
    const obj = {
      sheet_duration: "indexed",
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/timesheet/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          message.success(`Whole Video Complete `);
          const all = shorts[shorts.activeTab].map((r) =>
            r._id === shorts.activeVideo._id
              ? {
                  ...shorts.activeVideo,
                  shorts_ai: "indexed",
                }
              : r
          );

          setLoading(false);
          setshorts({
            ...shorts,
            activeVideo: undefined,
            [shorts.activeTab]: all,
            images: undefined,
            pageNo: 1,
            pageNo4: 1,
          });
          message.success(`Whole Video Complete `);
        } else {
          setLoading(false);
          message.error("something went wrong");
        }
      });
  };

  const createShort = () => {
    if (startTime === "00:00:00" || endTime === "00:00:00") {
      message.error("Time cannot be 00:00:00");
      setLod(lod + 1);
    } else {
      const obj = { startTime, endTime, video_id: shorts.activeVideo._id };
      const requestOptions = {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
        },
        body: JSON.stringify(obj),
      };
      fetch(`https://int.display.video/api/video_segment/create`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setLoadingS(false);
          if (data.isSuccess) {
            message.success("Created");
          } else {
            message.error("something went wrong");
          }
        });
    }
  };

  return (
    <div>
      <div>
        <Row>
          <Col md={{ span: 24 }}>
            <div className="sel-s-c">
              <h5>
                {shorts.activeVideo.video_name}{" "}
                <CloseOutlined
                  className="eye-icn-c close-icn"
                  onClick={() =>
                    setshorts({
                      ...shorts,
                      activeVideo: undefined,
                      images: undefined,
                      pageNo: 1,
                      pageNo4: 1,
                    })
                  }
                />
              </h5>
              {loading ? (
                <Spin />
              ) : (
                <Popconfirm
                  title={
                    <div>
                      <>All Importent Shorts Created?</>
                    </div>
                  }
                  onConfirm={() => {
                    setLoading(true);
                    updateFiled(
                      { model_s: "indexed" },
                      shorts.activeVideo._id,
                      "indexed"
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">Press Button to Complete Video</Button>
                </Popconfirm>
              )}
            </div>

            <div>
              {shorts.images && shorts.images.length === 0 ? (
                <h1>No Images Can Press Done</h1>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 14 }} lg={{ span: 14 }}>
            <div className="vt-vid-c">
              <ReactPlayer
                controls
                url={shorts.activeVideo.file_url}
                ref={playerRef}
                playing
              />
              <div className="sav-ch-btn">
                <a href={shorts.activeVideo.file_url} download>
                  Dwonload
                </a>
              </div>
            </div>
          </Col>
          <Col md={{ span: 9, offset: 1 }} lg={{ span: 9, offset: 1 }}>
            <table className="my-t-adjust-c">
              <tbody>
                <tr>
                  <td>Start Time: </td>
                  <td>
                    <TimePicker
                      onChange={(a, b) => setstartTime(b)}
                      defaultValue={moment(startTime, "HH:mm:ss")}
                      size="large"
                    />
                  </td>
                </tr>
                <tr>
                  <td>End Time: </td>
                  <td>
                    <TimePicker
                      onChange={(a, b) => setendTime(b)}
                      defaultValue={moment(endTime, "HH:mm:ss")}
                      size="large"
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="save-btn-dv">
                    {loadingS ? (
                      <Spin />
                    ) : (
                      <Button
                        onClick={() => {
                          createShort();
                          setLoadingS(true);
                        }}
                        type="primary"
                      >
                        {" "}
                        Create Short
                      </Button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  );
}
