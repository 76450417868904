import { Col, Row } from "antd";
import React from "react";
import Icons from "../../components/Icons";
import { Container } from "react-bootstrap";
import SignupForm from "./components/SignupForm";
import "./signup.css";
export default function SignUp() {
  return (
    <div>
      <Row>
        <Col lg={{ span: 12 }} md={{ span: 12 }}>
          <div className="left-lg-c">
            <div className="left-lg-c2">
              <img src={Icons.Logo} alt="" />
              <h4>Visual Intelligence, Anytime, Anywhere</h4>
              <p>
              Video is the new text. Fussy helps you craft better stories by cutting down hours of organizing and editing, so you can create more content and interact with your audience in new ways.
              </p>
            </div>
          </div>
        </Col>

        <Col lg={{ span: 12 }} md={{ span: 12 }}>
          <div className="log-f-r-c">
            <SignupForm />
          </div>
        </Col>
      </Row>
    </div>
  );
}
