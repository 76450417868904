import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Popconfirm,
  Spin,
  Input,
} from "antd";
import { CloseOutlined, TagFilled } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;
export default function DescriptionImages() {
  const { description, setdescription, user } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (description.images === undefined) {
      getVideoTask();
    }
  }, [callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${description.pageNo}&limit=40&video_type=concept_image&headclip_id=${description.activeVideo._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setdescription({
            ...description,
            images: data.segmentList,
            totalPages: data.totalPages,
          });
        }
      });
  };

  const updateTag = (val, con, type) => {
    let tags = con.tags ? con.tags : [];
    if (type === "remove") {
      tags = tags.filter((f) => f !== "d_mark");
    } else {
      tags.push("d_mark");
    }

    con.tags = tags;
    const images = description.images.map((l) => (l._id === con._id ? con : l));
    setdescription({
      ...description,
      images,
    });
    updateFiled({ tags }, con._id);
  };

  const updateDesc = (val, con) => {
    con.description = val;
    const images = description.images.map((l) => (l._id === con._id ? con : l));
    setdescription({
      ...description,
      images,
    });
  };
  const updateFiled = (obj, id, type) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (type === "indexed") {
            updateTimeSheet(description.activeVideo);
          }
        } else {
          message.error("something went wrong");
        }
      });
  };
  const updateTimeSheet = (video) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/timesheet?model_id=${user.user_id}&sheet_duration=progress&sheet_type=description&video_id=${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data &&
          data.isSuccess &&
          data.timesheetList &&
          data.timesheetList.length
        ) {
          updateTID(data.timesheetList[0]._id);
        }
      });
  };

  const updateTID = (id) => {
    const obj = {
      sheet_duration: "indexed",
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/timesheet/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          const all = description[description.activeTab].map((r) =>
            r._id === description.activeVideo._id
              ? {
                  ...description.activeVideo,
                  search_status: "indexed",
                }
              : r
          );

          setLoading(false);
          setdescription({
            ...description,
            activeVideo: undefined,
            [description.activeTab]: all,
            images: undefined,
            pageNo: 1,
            pageNo4: 1,
          });
          message.success(`Whole Video Complete `);
        } else {
          setLoading(false);
          message.error("something went wrong");
        }
      });
  };

  return (
    <div>
      <div>
        <Row>
          <Col md={{ span: 24 }}>
            <div className="sel-s-c">
              <h5>
                {description.activeVideo.video_name}{" "}
                <CloseOutlined
                  className="eye-icn-c close-icn"
                  onClick={() =>
                    setdescription({
                      ...description,
                      activeVideo: undefined,
                      images: undefined,
                      pageNo: 1,
                      pageNo4: 1,
                    })
                  }
                />
              </h5>
              {loading ? (
                <Spin />
              ) : (
                <Popconfirm
                  title={
                    <div>
                      <h6>Complete all Images?</h6>
                      <h6>Complete all pages?</h6>
                    </div>
                  }
                  onConfirm={() => {
                    setLoading(true);
                    updateFiled(
                      { search_status: "indexed" },
                      description.activeVideo._id,
                      "indexed"
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">Press Button to Complete Video</Button>
                </Popconfirm>
              )}
            </div>

            <div>
              {description.images && description.images.length === 0 ? (
                <h1>No Images Can Press Done</h1>
              ) : null}
              <Row>
                {description.images &&
                  description.images.map((lb, lbi) => {
                    return (
                      <Col key={lbi} md={{ span: 6 }}>
                        <div className="lbl-c-col">
                          <Card>
                            <div>
                              {(lb.tags &&
                                lb.tags.filter((f) => f === "d_mark").length) ||
                              (lb.description &&
                                lb.description.length) ? null : (
                                <Button
                                  type="primary"
                                  size="small"
                                  onClick={(v) => updateTag(v, lb)}
                                >
                                  description
                                </Button>
                              )}
                            </div>
                            <img
                              onClick={() => setViewFile(lb.file_url)}
                              className="lbl-mn-img"
                              src={lb.file_url}
                              alt="img"
                            />
                            <div className="bx-2-date-tm">
                              <b>
                                Start:
                                <i>{lb.startTime}</i>
                              </b>
                            </div>
                            <div className="lbl-s-e-c">
                              {(lb.tags &&
                                lb.tags.filter((f) => f === "d_mark").length) ||
                              (lb.description && lb.description.length) ? (
                                <TextArea
                                  placeholder="Add Description"
                                  autoSize={{
                                    minRows: 2,
                                    maxRows: 3,
                                  }}
                                  onChange={(e) =>
                                    updateDesc(e.target.value, lb)
                                  }
                                  value={lb.description}
                                  onBlur={() =>
                                    updateFiled(
                                      { description: lb.description },
                                      lb._id
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </Card>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
              <div className="page-vid-c">
                {description.totalPages ? (
                  <Pagination
                    pageSize={40}
                    current={description.pageNo}
                    size="small"
                    total={description.totalPages * 40}
                    onChange={(v) => {
                      setdescription({
                        ...description,
                        images: undefined,
                        pageNo: v,
                      });
                      setCallApi(callApi + 1);
                    }}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          <img src={viewFile} alt="" />;
        </div>
      </Modal>
    </div>
  );
}
