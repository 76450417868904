import {
  Button,
  Col,
  List,
  message,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { FaTrash } from "react-icons/fa";
import SearchContext from "../../../Context/Search/SearchContext";

export default function MambaFilterTable() {
  const { mambaFilter2, setMambaFilter2 } = useContext(SearchContext);
  const [edited, setEdited] = useState();
  const [openShop, setOpenShop] = useState();
  const [enableUpdate, setEnableUpdate] = useState(false);

  const editData = (key, value, row) => {
    setEdited(true);
    row[key] = value;
    const changedRows = mambaFilter2.data.map((m) =>
      m._id === row._id ? row : m
    );
    setMambaFilter2({ ...mambaFilter2, data: changedRows });
  };

  const editDatabase = (row) => {
    if (edited) {
      const requestOptions = {
        method: "PUT",
        body: JSON.stringify(row),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
        },
      };

      fetch(`https://api.fussy.ai/internal/mamba/${row._id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isUpdated) {
            message.success("Updated");
          } else {
            message.error("something went wrong");
          }
          setEdited(false);
          console.log(data, "guide/test");
        });
    }
  };

  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };

  const timeToseconds = (time) => {
    let hours = time.slice(0, 2);
    hours = hours * 3600;
    let minuts = time.slice(3, 5);
    minuts = minuts * 60;
    let seconds = time.slice(6);
    seconds = Number(seconds) + Number(minuts) + Number(hours);
    return seconds;
  };

  const changeTimestamp = (row, value) => {
    timeToseconds(value.trim());
    // editData("headline", e.target.value, tm)
  };

  const columns = [
    {
      title: "TS",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text, row) => (
        <a
          target="blank"
          href={
            row.platform.toLowerCase() === "spotify"
              ? row.link + `?t=${row.timestamp}`
              : `` + row.platform.toLowerCase() === "youtube"
              ? row.link + `&t=${row.timestamp}s`
              : `` + row.platform.toLowerCase() !== "spotify" &&
                row.platform.toLowerCase() !== "youtube"
              ? row.link
              : ``
          }
          className="start-n-l-b"
        >
          <b className="tim-tim-c">{TimeCorrection(text)}</b>
        </a>
      ),
    },

    {
      title: "pipeline",
      dataIndex: "pipeline",
      key: "pipeline",
      render: (text, row) => (
        <select
          style={{ width: "100px" }}
          value={text}
          onChange={(e) => editData("pipeline", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          className={`form-select form-control ${
            text === "quene" ? " red-color" : ""
          }
          ${text === "pending" ? " orange-color" : ""}
          ${text === "removal" ? " orange-color" : ""}
          ${text === "processing" ? " orange-color" : ""}
          ${text === "review" ? " orange-color" : ""}
          ${text === "complete" ? " green-color" : ""}
          ${text === "indexed" ? " green-color" : ""}`}
          aria-label="Default select example"
        >
          <option style={{ color: "orange" }} value="processing">
            Processing
          </option>

          <option style={{ color: "orange" }} value="review">
            Review
          </option>
        </select>
      ),
    },
    {
      title: "Shop",
      dataIndex: "",
      key: "sp",
      render: (text, row) => (
        <Button type="primary" size="small" onClick={() => setOpenShop(row)}>
          Shop
        </Button>
      ),
    },
  ];

  const removeShop = (i, all) => {
    all.splice(i, 1);
    setEdited(true);
    setOpenShop({ ...openShop, shop: all });
    setEnableUpdate(true);
  };

  const changeShop = (value, key, index, all) => {
    all[index][key] = value;
    setOpenShop({ ...openShop, shop: all });
    setEnableUpdate(true);
  };

  const updateForQA = (row) => {
    console.log(row);
    setEnableUpdate(false);
    editDatabase(row, true);
    setOpenShop();
  };

  return (
    <div>
      <Table
        className="channle-table timestamp-table"
        columns={columns}
        dataSource={mambaFilter2.data}
      />
      <Modal
        className="no-h-bo-b-modal top-20h-m"
        visible={openShop !== undefined}
        onCancel={() => setOpenShop()}
        width={700}
      >
        {/* <h5>Questions & Answers</h5> */}
        {openShop && openShop.shop && openShop.shop.length ? (
          <div>
            <div className="q-open-qa">
              {openShop.shop.map((sp, spi) => {
                return (
                  <div className="qa-map-c" key={spi}>
                    <div className="trash-qa-s">
                      <FaTrash onClick={() => removeShop(spi, openShop.shop)} />
                    </div>
                    <textarea
                      onChange={(e) => {
                        changeShop(e.target.value, "url", spi, openShop.shop);
                        setEdited(true);
                      }}
                      value={sp.url}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Add url"
                    />
                    <textarea
                      onChange={(e) => {
                        changeShop(e.target.value, "image", spi, openShop.shop);
                        setEdited(true);
                      }}
                      value={sp.image}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Image"
                    />
                    <textarea
                      onChange={(e) => {
                        changeShop(e.target.value, "name", spi, openShop.shop);
                        setEdited(true);
                      }}
                      value={sp.name}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Add name"
                    />
                    <input
                      onChange={(e) => {
                        changeShop(e.target.value, "store", spi, openShop.shop);
                        setEdited(true);
                      }}
                      value={sp.store}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Add store"
                    />
                  </div>
                );
              })}
            </div>
            <div className="add-ip0c">
              <Button
                onClick={() =>
                  setOpenShop({
                    ...openShop,
                    shop: [
                      ...openShop.shop,
                      { url: "", image: "", name: "", store: "" },
                    ],
                  })
                }
                className=""
              >
                Add
              </Button>
              {enableUpdate ? (
                <div>
                  <Button
                    onClick={() => {
                      updateForQA(openShop);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenShop();
                      setEnableUpdate(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <h4>No Shop Found</h4>
            <div className="add-ip0c">
              <Button
                onClick={() =>
                  setOpenShop({
                    ...openShop,
                    shop: [{ url: "", image: "", name: "", store: "" }],
                  })
                }
                className=""
              >
                Add
              </Button>
              {enableUpdate ? (
                <div>
                  <Button
                    onClick={() => {
                      updateForQA(setOpenShop);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenShop();
                      setEnableUpdate(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
