import { Button, Modal, Spin, Upload, message, notification } from "antd";
import React, { useContext, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";

import { LoadingOutlined } from "@ant-design/icons";
import SearchContext from "../../../Context/Search/SearchContext";

let all = [];

export default function UploadAttachments({ setdAdd, dAdd }) {
  const { action, setAction, user } = useContext(SearchContext);
  const [imageUrl, setImageUrl] = useState(null);

  const [UploadedFile, setUplaoded] = useState([]);
  const [show, setShow] = useState();
  const [upLoading, setUpLoading] = useState(false);
  // formData.append("file", UploadedFile[0].originFileObj);

  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
      // console.log(.url);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const saveFile = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    // formData.append("assistant_id", pActive ? pActive._id : "");

    formData.append("purpose", "block");

    formData.append("media_type", "image");
    formData.append("user_id", user ? user.user_id : "map");

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => {
        if (response.status === 400) {
          return response.json(); // You may also want to parse the error response
        }
        return response.json();
      })
      .then((data) => {
        setUpLoading(false);
        if (data && data.isSuccess) {
          setdAdd({
            ...dAdd,
            url: data.data.file_url,
            reuploaded: dAdd && dAdd._id ? true : undefined,
          });
        } else {
          message.error("something went worng ");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 32,
        color: "white",
      }}
      spin
    />
  );
  const renderFileName = () => {
    if (UploadedFile.length > 0) {
      const file = UploadedFile[0];
      return (
        <>
          <div className="vd-nm-pr img-prev-ibc">
            <img style={{ width: "100px" }} src={imageUrl} alt={"false"} />
          </div>
        </>
      );
    } else {
      return <span style={{ color: "black" }}>Drag or click to upload</span>;
    }
  };
  return (
    <div>
      {/* <Button onClick={() => setShow(true)}>+</Button> */}
      {show && UploadedFile.length ? null : (
        <div className="icons-click-upload">
          <Dragger
            showUploadList={false}
            maxCount={1}
            // beforeUpload={handleBeforeUpload}
            {...props}
          >
            {renderFileName()}
          </Dragger>
        </div>
      )}
      {UploadedFile.length ? (
        upLoading ? (
          <Spin />
        ) : (
          <div className="save-btn-cancel">
            <Button
              onClick={() => {
                setShow();
                setUplaoded([]);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                for (let i = 0; i < UploadedFile.length; i++) {
                  setUpLoading(true);
                  saveFile(UploadedFile[i], i, UploadedFile.length, show);
                }
              }}
            >
              Save
            </Button>
          </div>
        )
      ) : null}
    </div>
  );
}
