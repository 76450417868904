import { AutoComplete, Button, Form, Input, Select } from "antd";
import React, { useContext, useState } from "react";
import LoaderDots from "../../../components/LoaderDots";
import SearchContext from "../../../Context/Search/SearchContext";
import playIconblue from "../../../images/play-buttonb.png";
import MambaFilterTable from "./MambaFilterTable";
const { Option } = Select;
export default function MambaFilter() {
  const { mambaFilter2, setMambaFilter2 } = useContext(SearchContext);
  const [search, setSearch] = useState("");

  const [loader, setLoader] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(false);

  const getMambaFilter = (txt) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(
      `https://api.fussy.ai/internal/mamba/filter?page=${mambaFilter2.page}&limit=${mambaFilter2.limit}&link=${mambaFilter2.link}&channel=${mambaFilter2.channel}&date=${mambaFilter2.date}&pipeline=${mambaFilter2.pipeline}&category=${mambaFilter2.category}&platform=${mambaFilter2.platform}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);

        console.log(data, "mamba/filter");
        if (data && data.isSuccess) {
          setMambaFilter2({
            ...mambaFilter2,
            data: data.mambaList,
            notFound: false,
          });
        } else {
          setMambaFilter2({ ...mambaFilter2, data: undefined, notFound: true });
        }
      });
  };
  return (
    <div>
      <div className="filter-bx-main">
        <Input
          value={mambaFilter2.link}
          onChange={(e) =>
            setMambaFilter2({ ...mambaFilter2, link: e.target.value })
          }
          placeholder="Link"
        />
        <Input
          value={mambaFilter2.channel}
          onChange={(e) =>
            setMambaFilter2({ ...mambaFilter2, channel: e.target.value })
          }
          placeholder="Channel"
        />

        <Input
          value={mambaFilter2.category}
          onChange={(e) =>
            setMambaFilter2({ ...mambaFilter2, category: e.target.value })
          }
          placeholder="category"
        />
        <Input
          value={mambaFilter2.platform}
          onChange={(e) =>
            setMambaFilter2({ ...mambaFilter2, platform: e.target.value })
          }
          placeholder="platform"
        />
        <Select
          placeholder="pipeline"
          value={mambaFilter2.pipeline}
          onChange={(val) =>
            setMambaFilter2({ ...mambaFilter2, pipeline: val })
          }
        >
          <Option disabled value="">
            Pipeline
          </Option>

          <Option value="processing">Processing</Option>

          <Option value="review">Review </Option>
        </Select>

        {loader ? (
          <span className={`loader-input-c `}>
            <LoaderDots />
          </span>
        ) : (
          <Button
            onClick={() => {
              getMambaFilter(search);
              setMambaFilter2({ ...mambaFilter2, data: undefined });
              setLoader(true);
            }}
            type="primary"
          >
            Get
          </Button>
        )}
      </div>
      {mambaFilter2.data ? <MambaFilterTable /> : null}
    </div>
  );
}
