import { Button, Collapse, Input, Modal } from "antd";
import React, { useState } from "react";

export default function Pages({ row, saveChanges }) {
  const [show, setShow] = useState();
  const changeValue = (r, key, i, val) => {
    r[key][i] = val;
    setShow(r);
  };
  return (
    <div>
      <Button onClick={() => setShow(row)} type="primary" size="small">
        Pages
      </Button>
      <Modal
        width={600}
        visible={show !== undefined}
        onCancel={() => {
          setShow();
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        <h5 className="create-btn-h">Pages </h5>
        {show ? (
          <div className="bx-form-brand spacing-input-c">
            <Collapse defaultActiveKey={"main_text"} accordion>
              <Collapse.Panel header="Main Text" key="main_text">
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_text", 0, e.target.value)
                  }
                  value={show.main_text ? show.main_text[0] : ""}
                  placeholder=""
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_text", 1, e.target.value)
                  }
                  value={show.main_text ? show.main_text[1] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_text", 2, e.target.value)
                  }
                  value={show.main_text ? show.main_text[2] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_text", 3, e.target.value)
                  }
                  value={show.main_text ? show.main_text[3] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_text", 4, e.target.value)
                  }
                  value={show.main_text ? show.main_text[4] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_text", 5, e.target.value)
                  }
                  value={show.main_text ? show.main_text[5] : ""}
                />
              </Collapse.Panel>
              <Collapse.Panel header="Main Image" key="main_image">
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_image", 0, e.target.value)
                  }
                  value={show.main_image ? show.main_image[0] : ""}
                  placeholder=""
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_image", 1, e.target.value)
                  }
                  value={show.main_image ? show.main_image[1] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_image", 2, e.target.value)
                  }
                  value={show.main_image ? show.main_image[2] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_image", 3, e.target.value)
                  }
                  value={show.main_image ? show.main_image[3] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_image", 4, e.target.value)
                  }
                  value={show.main_image ? show.main_image[4] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_image", 5, e.target.value)
                  }
                  value={show.main_image ? show.main_image[5] : ""}
                />
              </Collapse.Panel>
              <Collapse.Panel header="Main Title" key="main_title">
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_title", 0, e.target.value)
                  }
                  value={show.main_title ? show.main_title[0] : ""}
                  placeholder=""
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_title", 1, e.target.value)
                  }
                  value={show.main_title ? show.main_title[1] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_title", 2, e.target.value)
                  }
                  value={show.main_title ? show.main_title[2] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_title", 3, e.target.value)
                  }
                  value={show.main_title ? show.main_title[3] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_title", 4, e.target.value)
                  }
                  value={show.main_title ? show.main_title[4] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_title", 5, e.target.value)
                  }
                  value={show.main_title ? show.main_title[5] : ""}
                />
              </Collapse.Panel>

              <Collapse.Panel header="Main Link" key="main_link">
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_link", 0, e.target.value)
                  }
                  value={show.main_link ? show.main_link[0] : ""}
                  placeholder=""
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_link", 1, e.target.value)
                  }
                  value={show.main_link ? show.main_link[1] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_link", 2, e.target.value)
                  }
                  value={show.main_link ? show.main_link[2] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_link", 3, e.target.value)
                  }
                  value={show.main_link ? show.main_link[3] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_link", 4, e.target.value)
                  }
                  value={show.main_link ? show.main_link[4] : ""}
                />
                <Input
                  onChange={(e) =>
                    changeValue(show, "main_link", 5, e.target.value)
                  }
                  value={show.main_link ? show.main_link[5] : ""}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        ) : null}
        <div style={{ marginTop: "10px" }} className="flex-end">
          <Button
            onClick={() => {
              saveChanges(
                {
                  main_text: show.main_text,
                  main_image: show.main_image,
                  main_title: show.main_title,
                  main_link: show.main_link,
                },
                show._id,
                true
              );
              setShow()
            }}
            type="primary"
          >
            Update
          </Button>
        </div>
      </Modal>
    </div>
  );
}
