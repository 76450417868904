import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Spin,
  Input,
} from "antd";
import { CloseOutlined, TagFilled } from "@ant-design/icons";
import { IoIosClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AddInsight from "../../../components/AddInsight";
const { Option } = Select;
export default function Object() {
  const navigate = useNavigate();
  const { object, setobject, user, review, setreview } =
    useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [mLoad, setMLoad] = useState(false);
  const [loading, setLoading] = useState();
  const [duplicate, setDuplicate] = useState();
  const [general, setgeneral] = useState();
  const [callGeneral, setcallGeneral] = useState("");
  const [moments, setMomets] = useState([]);

  useEffect(() => {
    if (object.images === undefined) {
      getVideoTask();
    }
  }, [callApi]);

  useEffect(() => {
    getgeneral();
  }, [callGeneral]);

  const getgeneral = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/insight?page=1&limit=15&search=${callGeneral}&category=visual`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setgeneral(data.insightList);
        }
      });
  };

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${object.pageNo}&limit=40&video_type=concept_image&headclip_id=${object.activeVideo._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setobject({
            ...object,
            images: data.segmentList,
            totalPages: data.totalPages,
          });
        }
      });
  };

  const saveDuplicate = () => {
    const obj = {
      startTime: duplicate.startTime,
      endTime: duplicate.end,
      label: "Vegetation",
    };
    setDuplicate();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `${process.env.REACT_APP_API}/video_segment/duplicate_label/${object.activeVideo._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Added");
          getVideoTask();
        } else {
          message.error("Changes Not Saved");
        }
      });
  };

  const updateConcept = (val, con) => {
    console.log(val, con);
    let concepts = con.concepts;
    const falseLables = object.activeVideo.concept_index
      .filter((f) => f.indexed === false)
      .slice(0, 5);
    for (let i = 0; i < falseLables.length; i++) {
      concepts = concepts.filter((f) => f !== falseLables[i].concept_name);
    }
    for (let i = 0; i < val.length; i++) {
      concepts = concepts.filter((f) => f !== val[i]);
      concepts.push(val[i]);
    }
    con.concepts = concepts;
    const images = object.images.map((l) => (l._id === con._id ? con : l));
    setobject({
      ...object,
      images,
    });
  };

  const changeLabels = (lbls, row) => {
    const labels = row.labels;
    if (lbls) {
      for (let i = 0; i < lbls.length; i++) {
        labels.push({
          Confidence: 0,
          Instances: [],
          Name: lbls[i],
          Parents: [],
        });
      }
    }
    changeField("labels", labels, row);
    changeField("sLabels", undefined, row);
    updateFiled({ labels: row.labels }, row._id);
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = object.images.map((m) => (m._id === row._id ? row : m));
    setobject({
      ...object,
      images: allRow,
    });
  };

  const removeLbl = (remove, row) => {
    const lables = row.labels.filter((f) => f.Name !== remove);
    row.labels = lables;
    const allRow = object.images.map((m) => (m._id === row._id ? row : m));
    setobject({
      ...object,
      images: allRow,
    });
    updateFiled({ labels: row.labels }, row._id);
  };

  const updateFiled = (obj, id, type) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Addedd");
          if (type === "indexed") {
            updateTimeSheet(object.activeVideo);
            updateFunction3(object.activeVideo);
          }
        } else {
          message.error("something went wrong");
        }
      });
  };
  const updateTimeSheet = (video) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/timesheet?model_id=${user.user_id}&sheet_duration=progress&video_id=${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data &&
          data.isSuccess &&
          data.timesheetList &&
          data.timesheetList.length
        ) {
          updateTID(data.timesheetList[0]._id);
        }
      });
  };

  const updateVd = (obj, id) => {
    setMomets([]);
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setMLoad(false);
        if (data && data.isSuccess) {
          message.success("added");
          setobject({
            ...object,
            activeVideo: data.segmentData,
          });
        } else {
          message.error("something went wrong");
        }
      });
  };

  const updateTID = (id) => {
    const obj = {
      sheet_duration: "indexed",
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/timesheet/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          navigate("/object");
          setLoading(false);
          setobject({
            ...object,
            activeVideo: undefined,
            images: undefined,
            pageNo: 1,
            pageNo4: 1,
          });
          setreview({
            ...review,
            doneVideo2: undefined,
            images2: undefined,
            pageNo: 1,
            activeKey: "Speaker",
          });
          message.success(`Whole Video Complete `);
          window.location.reload();
        } else {
          setLoading(false);
          message.error("something went wrong");
        }
      });
  };
  const updateFunction3 = (video) => {
    const obj = {
      headclip_id: video._id,
      user_id: video.user_id,
    };
    const requestOptions = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/function3`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const getImages = (id) => {
    const obj = {
      headclip_id: id,
      ratio: "0.01",
    };
    const requestOptions = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/video_segment/image_downloader/headclip`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setTimeout(() => window.location.reload(), 10000);
        }
      });
  };

  return (
    <div>
      <div>
        {object.images ? (
          <Row>
            <Col md={{ span: 18 }} lg={{ span: 18 }}>
              <div className="space-between">
                <div className="sel-s-c">
                  <h5>
                    {object.activeVideo.video_name}{" "}
                    <CloseOutlined
                      className="eye-icn-c close-icn"
                      onClick={() =>
                        setobject({
                          ...object,
                          activeVideo: undefined,
                          pageNo: 1,
                          pageNo4: 1,
                        })
                      }
                    />
                  </h5>
                </div>

                {loading ? (
                  <Spin />
                ) : object.activeVideo.frame_status !== "indexed" ? (
                  <div>
                    {object.images && object.images.length === 0 ? (
                      <Button
                        onClick={() => {
                          getImages(object.activeVideo._id);
                          setLoading(true);
                        }}
                        type="primary"
                      >
                        Get Images
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => {
                        updateFiled(
                          { frame_status: "indexed" },
                          object.activeVideo._id,
                          "indexed"
                        );
                        setLoading(true);
                      }}
                      type="primary"
                    >
                      Press Button to Complete Video
                    </Button>
                  </div>
                ) : null}
              </div>
              <div>
                {object.images && object.images.length === 0 ? (
                  <h1>No Images Can Press Done</h1>
                ) : null}
                <Row>
                  {object.images &&
                    object.images.map((lb, lbi) => {
                      return (
                        <Col key={lbi} md={{ span: 8 }}>
                          <div className="lbl-c-col">
                            <Card>
                              <LazyLoadImage
                                effect="blur"
                                onClick={() => setViewFile(lb.file_url)}
                                className="lbl-mn-img"
                                src={lb.file_url}
                                alt="img"
                              />

                              <div className="lbl-s-e-c">
                                {lb.labels ? (
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    value={lb.sLabels ? lb.sLabels : []}
                                    onChange={(v) => {
                                      changeField("sLabels", v, lb);
                                    }}
                                    onBlur={(v) => {
                                      changeLabels(lb.sLabels, lb);
                                    }}
                                    onSearch={(e) => setcallGeneral(e)}
                                    placeholder="Select object"
                                    mode="multiple"
                                  >
                                    {general &&
                                      general.map((lb, lbi) => {
                                        return (
                                          <Option value={lb.value} key={lbi}>
                                            <div className="lbl-drp-c">
                                              {lb.value}{" "}
                                            </div>
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                ) : null}
                                <div>
                                  {lb.labels.map((t, ti) => {
                                    return (
                                      <Tag key={ti}>
                                        {t.Name}{" "}
                                        <IoIosClose
                                          onClick={() => removeLbl(t.Name, lb)}
                                          className="hover"
                                        />
                                      </Tag>
                                    );
                                  })}
                                </div>
                                {duplicate &&
                                duplicate._id === lb._id ? null : (
                                  <div className="dup-lbl-bx-c">
                                    {lb.labels && lb.labels.length ? (
                                      <Button
                                        onClick={() => setDuplicate(lb)}
                                        size="small"
                                        type="primary"
                                      >
                                        Duplicate
                                      </Button>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                              <div className="play-btn-vdc">
                                <div className="start-end-bx-lbl">
                                  <b>{lb.startTime}</b>
                                  <b>{lb.endTime}</b>
                                </div>
                              </div>
                              {duplicate && duplicate._id === lb._id ? (
                                <div className="box-dup-main0-c">
                                  <b>Add duplicate end time</b>
                                  <div className="bx-2-date-tm">
                                    <b>
                                      Start:
                                      <i>
                                        <Input
                                          disabled
                                          value={duplicate.startTime}
                                          className=" time-input-img"
                                        />
                                      </i>
                                    </b>
                                    <b>
                                      End:
                                      <i>
                                        <Input
                                          value={
                                            duplicate.end
                                              ? duplicate.end
                                              : "00:00:00"
                                          }
                                          onChange={(e) => {
                                            setDuplicate({
                                              ...duplicate,
                                              end: e.target.value.trim(),
                                            });
                                          }}
                                          className=" time-input-img"
                                        />
                                      </i>
                                    </b>
                                  </div>
                                  <div className="save-dup-bt">
                                    {duplicate.end &&
                                    duplicate.end.length === 8 ? (
                                      <Button
                                        onClick={saveDuplicate}
                                        type="primary"
                                        size="small"
                                      >
                                        Save Duplicate
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <div className="page-vid-c">
                  {object.totalPages ? (
                    <Pagination
                      pageSize={40}
                      current={object.pageNo}
                      size="small"
                      total={object.totalPages * 40}
                      onChange={(v) => {
                        setobject({ ...object, images: undefined, pageNo: v });
                        setCallApi(callApi + 1);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </Col>
            <Col md={{ span: 6 }} lg={{ span: 6 }}>
              <Affix offsetTop={10}>
                <div className="p-10">
                  <div className="import-things-bfc">
                    <h6>Important Things</h6>
                    <div>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        value={moments}
                        onChange={(v) => {
                          setMomets(v);
                        }}
                        onBlur={(v) => {
                          updateVd(
                            {
                              moments: moments.concat(
                                object.activeVideo.moments
                                  ? object.activeVideo.moments
                                  : []
                              ),
                            },
                            object.activeVideo._id
                          );
                        }}
                        onSearch={(e) => setcallGeneral(e)}
                        placeholder="Select main object"
                        mode="multiple"
                      >
                        {general &&
                          general
                            .filter((f) =>
                              object.activeVideo.moments.filter(
                                (fm) => fm === f.value
                              ).length
                                ? false
                                : true
                            )
                            .map((lb, lbi) => {
                              return (
                                <Option value={lb.value} key={lbi}>
                                  <div className="lbl-drp-c">{lb.value} </div>
                                </Option>
                              );
                            })}
                      </Select>
                      <div>
                        {object.activeVideo.moments &&
                          object.activeVideo.moments.map((m, mi) => {
                            return mLoad === m ? (
                              <Spin size="small" />
                            ) : (
                              <Tag className="brand-tag" key={mi}>
                                {m}{" "}
                                <IoIosClose
                                  onClick={() => {
                                    updateVd(
                                      {
                                        moments:
                                          object.activeVideo.moments.filter(
                                            (f) => f !== m
                                          ),
                                      },
                                      object.activeVideo._id
                                    );
                                    setMLoad(m);
                                  }}
                                  className="hover"
                                />
                              </Tag>
                            );
                          })}
                      </div>
                      <div className="add-if-no-ins">
                        <AddInsight />
                      </div>
                    </div>
                  </div>
                </div>
              </Affix>
            </Col>
          </Row>
        ) : (
          <Spin />
        )}
      </div>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <LazyLoadImage key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          <LazyLoadImage src={viewFile} alt="" />;
        </div>
      </Modal>
    </div>
  );
}
