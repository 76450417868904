import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

export default function VideoView({ setDisplayVideo, data }) {
  const [video, setVideo] = useState();

  useEffect(() => {
    getVideoTask();
  }, []);

  const getVideoTask = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/video_segment?_id=${data.minutes_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setVideo(data.segmentList[0]);
        }
      });
  };
  return (
    <Modal
      width={600}
      visible={video !== undefined}
      onCancel={() => {
        setVideo();
        setDisplayVideo();
      }}
      className="no-f-no-h top-20-x close-icon-modal"
    >
      {video ? (
        <div className="fl-c-mid221">
          <ReactPlayer
            url={video.file_url}
            className=""
            controls
            playing
            // ref={playerRef}
            // onProgress={(e) => setPlayedSeconds(parseInt(e.playedSeconds))}
          />
        </div>
      ) : null}
    </Modal>
  );
}
