import { Table, Spin, Tag, message, Button, Modal, Input } from "antd";
import { Button as ButtonB } from "react-bootstrap";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { TimeCorrection } from "../../../components/GlobalFunctions";
import SearchContext from "../../../Context/Search/SearchContext";
import AddVideo from "./AddVideo";
import { BsJournalBookmarkFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";

export default function DisplayVideo() {
  const navigate = useNavigate();
  const { videos, setVideos } = useContext(SearchContext);

  const [dDelete, displayDelete] = useState();
  const [loadeing, setLoadeing] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [isCh, setIsCh] = useState(false);
  const [callAPI, setCallAPI] = useState(1);
  const [C, setC] = useState("");

  useEffect(() => {
    if (videos.dataList === undefined) {
      setLoadeing(true);
      getSearchVideos();
    }
  }, [callAPI]);

  const getSearchVideos = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/videos/upload?page=${
        videos.pageNo
      }&limit=15&sort_by=${-1}&video_type=original&channel=${C.trim()}&status=pending`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.dataList) {
          setVideos({
            ...videos,
            dataList: data.dataList,
            totalPages: data.totalPages,
          });
        }
        setLoadeing(false);
        console.log(data);
      });
  };
  const columns = [
    {
      title: "",
      dataIndex: "platform",
      key: "platform",
      render: (text, row) => (
        <span>
          {text === "youtube" ? (
            <FaYoutube className="youtube-icon-c" />
          ) : text === "tiktok" ? (
            <FaTiktok className="tiktok-icon-c" />
          ) : text === "twitter" ? (
            <FaTwitter className="twitter-icon-c" />
          ) : (
            text
          )}
        </span>
      ),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      render: (text, row) => (
        <span>
          <TextArea
            autoSize={{
              minRows: 1,
              maxRows: 3,
            }}
            value={text}
            onChange={(e) => {
              changeField("channel", e.target.value, row);
              setIsChange(true);
            }}
            className="hidden-table-input"
            onBlur={() => saveChanges({ channel: text }, row._id)}
          />
        </span>
      ),
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, row) => (
        <span className="date-crw-c">
          {" "}
          {moment(text).format("MMM Do YYYY")}
        </span>
      ),
    },
    {
      title: "Upload Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => (
        <span className="date-crw-c">
          {" "}
          {moment(text).format("MMM Do YYYY")}
        </span>
      ),
    },
    {
      title: "URL",
      dataIndex: "web_url",
      key: "web_url",
      render: (text, row) => (
        <span>
          <TextArea
            autoSize={{
              minRows: 1,
              maxRows: 3,
            }}
            value={text}
            onChange={(e) => {
              changeField("web_url", e.target.value, row);
              setIsChange(true);
            }}
            className="hidden-table-input"
            onBlur={() => saveChanges({ web_url: text }, row._id)}
          />
        </span>
      ),
    },
    {
      title: "Video Name",
      dataIndex: "video_name",
      key: "video_name",
      width: "200px",
      render: (text, row) => (
        <span>
          {" "}
          <TextArea
            autoSize={{
              minRows: 1,
              maxRows: 3,
            }}
            value={text}
            onChange={(e) => {
              changeField("video_name", e.target.value, row);
              setIsChange(true);
            }}
            className="hidden-table-input"
            onBlur={() => saveChanges({ video_name: text }, row._id)}
          />
        </span>
      ),
    },
    {
      title: "Duration",
      dataIndex: "video_duration",
      key: "video_duration",
      render: (text, row) => (
        <span>{TimeCorrection(parseInt(Number(text)))}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <span>
          {text === "pending" ? (
            <Tag color="orange">Pending</Tag>
          ) : text === "indexed" ? (
            <Tag color="green">Indexed</Tag>
          ) : (
            text
          )}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      render: (text, row) => (
        <span>
          <Button
            onClick={() => {
              setVideos({
                ...videos,
                activeVideo: row,
              });
              navigate(`/crawler/${row._id}`);
            }}
            type="primary"
            size="small"
          >
            View
          </Button>
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row)}
            color="rgb(185, 40, 40)"
          >
            Delete
          </Tag>
        </span>
      ),
    },
  ];

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = videos.dataList.map((m) => (m._id === row._id ? row : m));
    setVideos({
      ...videos,
      dataList: allRow,
    });
  };

  const saveChanges = (obj, id) => {
    if (isChange) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(obj),
      };
      fetch(`${process.env.REACT_APP_API}/search_video/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setIsChange(false);
          if (data && data.isPatched) {
            message.success("Added");
          } else {
            message.error("Changes Not Saved");
          }
        });
    }
  };

  const deleteVideo = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/search_video/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isDeleted) {
          const deleted = videos.dataList.filter((f) => f._id !== id);
          setVideos({
            ...videos,
            dataList: deleted,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  return (
    <div className="compute-tab-c">
      <div className="video-bx-fv">
        <AddVideo />
        <div>
          <div className="icons-bx-consc">
            {!isCh ? (
              <Button
                size="small"
                onClick={() => setIsCh(!isCh)}
                type="primary"
              >
                Find Channel
              </Button>
            ) : (
              <div className="input-bccrw">
                <Input
                  value={C}
                  className="sarch-bx-cb"
                  placeholder="Channel Name"
                  onChange={(v) => {
                    setC(v.target.value);
                  }}
                />
                <Button
                  onClick={() => {
                    setVideos({
                      ...videos,
                      dataList: undefined,
                      totalPages: undefined,
                      pageNo: 1,
                    });
                    setCallAPI(callAPI + 1);
                  }}
                  type="primary"
                >
                  Find
                </Button>

                <MdClose
                  onClick={() => {
                    setIsCh(!isCh);
                    setC("");
                    setCallAPI(callAPI + 1);
                    setVideos({
                      ...videos,
                      dataList: undefined,
                      totalPages: undefined,
                      pageNo: 1,
                    });
                  }}
                  className="close-bx-d-in hover"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {loadeing ? (
        <div>
          <Spin />
        </div>
      ) : null}
      {videos ? (
        <Table
          pagination={{
            pageSize: 15,
            current: videos.pageNo,
            showSizeChanger: false,
            total: videos.totalPages * 15,
            onChange: (v) => {
              setVideos({ ...videos, dataList: undefined, pageNo: v });
              setCallAPI(callAPI + 1);
            },
            size: "small",
          }}
          columns={columns}
          dataSource={videos.dataList}
        />
      ) : null}

      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Video</h5>

        {dDelete ? (
          <div className="bx-form-brand">
            <h6>Are you sure you want to delete?</h6>
            <p className="delete-name-sc">
              <b>Name: </b>
              {dDelete.video_name}
            </p>
            <p>
              <b>Channel: </b>
              {dDelete.channel}
            </p>
            <div className="delet-btns-h">
              <ButtonB
                size="sm"
                onClick={() => displayDelete()}
                variant="light"
              >
                Cancel
              </ButtonB>{" "}
              <ButtonB
                onClick={() => {
                  deleteVideo(dDelete._id);
                  displayDelete();
                }}
                variant="danger"
                size="sm"
              >
                Yes
              </ButtonB>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
