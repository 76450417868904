import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, message, Modal, Row, Upload } from "antd";
import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { FaFile } from "react-icons/fa";
import { MdCloudUpload } from "react-icons/md";

export default function AddFont({ fileCommand, setfileCommand, row }) {
  const [attach, setAttach] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [UT, uploadType] = useState();

  const attachment = (id) => {
    const formData = new FormData();
    formData.append("file", UploadedFile[0].originFileObj);
    setUplaoded([]);
    const requestOptions = {
      method: "PATCH",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `${process.env.REACT_APP_API}/file_command/attach/${id}`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data.isSuccess) {
          const all = fileCommand.map((s) =>
            s._id === id ? data.fileCommandData : s
          );
          setfileCommand(all);
          message.success("Added");
        } else {
          message.error("Something went wrong");
        }
      })
      .finally(() => {});
  };
  const props = {
    name: "file",
    status: "done",
    multiple: false,
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      console.log(info);
      setUplaoded(info.fileList);
      // console.log(info.file.originFileObj);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };
  return (
    <div>
      <Button size="small" type="primary" onClick={() => setAttach(row._id)}>
        Add
      </Button>
      <Modal
        width={400}
        visible={attach !== undefined}
        onCancel={() => {
          setAttach();
          setUplaoded([]);
        }}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Upload File</h5>

        <div className="bx-form-brand">
          <div>
            <Upload
              // accept=""
              {...props}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <div className="delet-btns-h space-between">
              <Button
                onClick={() => {
                  attachment(attach);

                  setAttach();
                }}
                type="primary"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
