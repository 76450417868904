import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Button,
  InputNumber,
  message,
  Switch,
  Popconfirm,
  Modal,
  Input,
  notification,
} from "antd";
import ReactPlayer from "react-player";
import "./DescriptionTask.css";
import TagsGroup from "../../components/TagsGroup";
import { FaArrowLeft, FaArrowRight, FaPlay, FaTrash } from "react-icons/fa";
import { TimeCorrection } from "../../components/GlobalFunctions";
import TextArea from "antd/lib/input/TextArea";
export default function DescriptionTask() {
  const { videoTasks, setVideoTask } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [callApi2, setCallApi2] = useState(0);
  const [notSave, setNotSave] = useState(false);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [searchLabel, setSearchLabel] = useState("");
  const [editTime, setEditTIme] = useState();
  const [active, setActive] = useState(0);
  const [timer, setTimer] = useState(31);
  const [active_id2, setActiveId] = useState();
  const [displayName, setDisplayName] = useState();
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (videoTasks.DescPending === undefined) {
      getVideoTask();
      setActive(0);
      resettALL();
      setActiveId(window.localStorage.getItem("active_id2"));
    }
  }, [callApi]);

  const resettALL = () => {
    setNotSave(false);
    setViewThumbnails();
    setSearchLabel("");
  };

  const getVideoTask = (actId) => {
    let isId = actId ? actId.id : window.localStorage.getItem("active_id2");
    let search_status = isId ? "progress" : "pending";
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=1&limit=15&search_status=${search_status}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          console.log("not deleted");
          setVideoTask({
            ...videoTasks,
            DescPending: data.segmentList,
          });
        }
        console.log(data);
      });
  };

  const updateIntoDB = (row, name, isDone) => {
    const id = row._id;
    setNotSave(false);
    setDescription("");
    const formData = new FormData();
    formData.append(
      "search_status",
      row.search_status ? row.search_status : "pending"
    );
    formData.append("outline", JSON.stringify(row.outline));
    formData.append("video_file", row.file_url);
    formData.append("index_progress", row.index_progress);
    if (name) {
      formData.append("model_id", name);
    }

    const requestOptions = {
      method: "PUT",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (isDone) {
            window.location.reload();
          }
          message.success("Updated");
        } else {
          message.error("something went wrong");
        }

        console.log(data, "video_segment , update");
      });
  };

  const startBtnClicled = (name) => {
    setDisplayName();
    const video = videoTasks.DescPending.slice(active, active + 1)[0];
    if (video.search_status === "progress") {
      message.warning("Someone Already  Doing");
    } else {
      video.search_status = "progress";
      const all = videoTasks.DescPending.map((v) =>
        v._id === video._id ? video : v
      );
      setVideoTask({ ...videoTasks, DescPending: all });
      updateIntoDB(video, name);
      setActiveId(video._id);
      window.localStorage.setItem("active_id2", video._id);
    }
    // noti30S();
  };

  const updateChanges = (video, isDone) => {
    if (description.length) {
      const vt = videoTasks.DescPending.slice(active, active + 1)[0].outline;
      const newTimestamp = {
        description: description,
        timestamp: vt && vt.length ? vt.length * 20 : 1,
      };
      const oldOutline = video.outline ? video.outline : [];
      oldOutline.push(newTimestamp);
      video.outline = oldOutline;
      updateIntoDB(video);
    } else {
      updateIntoDB(video);
    }
  };

  const deleteTimeline = (tim, i, row) => {
    const outlines = row.outline;
    outlines.splice(i, 1);
    row.outline = outlines;
    const all = videoTasks.DescPending.map((v) =>
      v._id === row._id ? row : v
    );
    setVideoTask({ ...videoTasks, DescPending: all });
    setNotSave(true);
  };

  const setDescEdit = (tim, i, row, val) => {
    const outl = { ...row.outline[i] };
    const allOutline = row.outline;

    outl.description = val;
    allOutline[i] = outl;
    row.outline = allOutline;
    const all = videoTasks.DescPending.map((v) =>
      v._id === row._id ? row : v
    );
    setVideoTask({ ...videoTasks, DescPending: all });
    setNotSave(true);
  };

  return (
    <div>
      <div className="top-vt-c">
        <Button type="primary">Reload</Button>
      </div>
      <Row>
        {videoTasks.DescPending && videoTasks.DescPending.length ? (
          <Col md={{ span: 12 }} lg={{ span: 12 }}>
            <div className="r-l-divc">
              <div>
                {active === 0 ? null : (
                  <FaArrowLeft
                    onClick={() => {
                      resettALL();
                      setActive(active - 1);
                    }}
                  />
                )}
              </div>
              <div>
                {active + 1 === videoTasks.DescPending.length ? null : (
                  <FaArrowRight
                    onClick={() => {
                      resettALL();
                      setActive(active + 1);
                    }}
                  />
                )}
              </div>
            </div>
            {videoTasks.DescPending.slice(active, active + 1)
              .filter(
                (f) =>
                  f.search_status === "pending" ||
                  f.search_status === "progress"
              )
              .map((vt, vti) => {
                return (
                  <div key={vti} className="vt-vid-c">
                    <ReactPlayer
                      onPlay={(e) => console.log(e)}
                      controls
                      url={vt.file_url}
                    />
                    <div className="sav-ch-btn">
                      {active_id2 === vt._id ? (
                        <>
                          <Popconfirm
                            title="Make Sure Your Complete this Video?"
                            onConfirm={() => {
                              localStorage.removeItem("active_id2");
                              updateIntoDB(
                                {
                                  ...vt,
                                  cleaned: true,
                                  search_status: "pending",
                                },
                                undefined,
                                "pending"
                              );
                              setActiveId();
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button size="small" className="donebtns-c">
                              Done
                            </Button>
                          </Popconfirm>
                          {notSave ? (
                            <Button
                              onClick={() => updateChanges(vt)}
                              size="small"
                              type="primary"
                            >
                              Save Changes
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => setDisplayName("")}
                            className="plat-btn-strt"
                            size="small"
                            type="primary"
                          >
                            Start <FaPlay />
                          </Button>

                          {notSave ? (
                            <h5 className="red-clr-no-strt">
                              Press Start button before make changes in database
                            </h5>
                          ) : null}
                        </>
                      )}
                    </div>
                    <div>
                      <div className="saved-tim-wv">
                        {vt.outline &&
                          vt.outline.length &&
                          vt.outline.map((tm, tmi) => {
                            return (
                              <div className="tim-vid-mainc" key={tmi}>
                                <div className="trash-qa-s">
                                  <FaTrash
                                    onClick={() => {
                                      deleteTimeline(tm, tmi, vt);
                                    }}
                                  />
                                </div>
                                <h6 className="prev-tim-in">
                                  Timestamp:
                                  <b className="tim-c-b-d">
                                    {TimeCorrection(tm.timestamp)}
                                  </b>
                                </h6>
                                <TextArea
                                  autoSize={{
                                    minRows: 2,
                                    maxRows: 6,
                                  }}
                                  value={tm.description}
                                  onChange={(e) => {
                                    setDescEdit(tm, tmi, vt, e.target.value);
                                    setNotSave(true);
                                  }}
                                  className="edit-bx-c"
                                  placeholder="Add Description"
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </Col>
        ) : null}
        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="desc-r-bc">
            <TextArea
              rows={4}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setNotSave(true);
              }}
              placeholder="Add Description"
            />
          </div>
        </Col>
      </Row>

      <Modal
        className="no-h-bo-b-modal "
        visible={displayName !== undefined}
        onCancel={() => setDisplayName()}
      >
        <h4>Your Name Code</h4>
        <Input
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder="Name"
        />
        <Button
          className="btnc-s"
          type="primary"
          onClick={() => startBtnClicled(displayName)}
        >
          Save and Start
        </Button>
      </Modal>
    </div>
  );
}
