import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Popconfirm,
  Spin,
  Input,
} from "antd";
import { Button as ButtonB } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import TagsGroup from "../../../components/TagsGroup";
import DisplayTranscript from "./DisplayTranscript";

const { Option } = Select;
const { TextArea } = Input;
export default function SearchImages() {
  const navigate = useNavigate();

  const { search, setsearch, user } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [loading, setLoading] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isChange, setIsChange] = useState(false);
  const [dDelete, displayDelete] = useState();

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = search.images.map((m) => (m._id === row._id ? row : m));
    setsearch({
      ...search,
      images: allRow,
    });
  };

  const saveChanges = (obj, id, save, complete) => {
    if (isChange || save) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(obj),
      };
      fetch(`${process.env.REACT_APP_API}/search_video/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setIsChange(false);
          if (data && data.isPatched) {
            message.success("Added");
          } else {
            message.error("Changes Not Saved");
          }
        });
    }
  };

  const deleteVideo = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/search_video/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isDeleted) {
          const deleted = search.images.filter((f) => f._id !== id);
          setsearch({
            ...search,
            images: deleted,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  return (
    <div>
      <Row>
        <Col lg={{ span: 24 }}>
          <div>
            <Row>
              <Col md={{ span: 24 }}>
                <div>
                  {search.images && search.images.length === 0 ? (
                    <h1>No Data .</h1>
                  ) : null}
                  <Row>
                    {search.images &&
                      search.images.map((lb, lbi) => {
                        return (
                          <Col key={lbi} lg={{ span: 24 }} md={{ span: 24 }}>
                            <div className="p10-card">
                              <div className="searc-card-mainc">
                                <div className="lbl-c-col searc-card-bc">
                                  <div className="delete-icn-c">
                                    <span
                                      onClick={() => displayDelete(lb)}
                                      className="close-i-fd"
                                    >
                                      ❌
                                    </span>
                                  </div>
                                  <Row>
                                    <Col lg={{ span: 12 }}>
                                      <Card
                                      className="card-left-trfc"
                                      >
                                        <DisplayTranscript
                                          video={lb.transcript}
                                        />
                                      </Card>
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                      <Card>
                                        <img
                                          onClick={() =>
                                            setViewFile(lb.file_url)
                                          }
                                          className="lbl-mn-img"
                                          src={lb.file_url}
                                          alt="img"
                                        />
                                        <div className="bx-2-date-tm">
                                          <b>
                                            Start:
                                            <i>
                                              <Input
                                                value={lb.startTime}
                                                onChange={(e) => {
                                                  changeField(
                                                    "startTime",
                                                    e.target.value,
                                                    lb
                                                  );
                                                  setIsChange(true);
                                                }}
                                                className="hidden-table-input time-input-img"
                                                onBlur={() =>
                                                  saveChanges(
                                                    { startTime: lb.startTime },
                                                    lb._id
                                                  )
                                                }
                                              />
                                            </i>
                                          </b>
                                          <b>
                                            End:
                                            <i>
                                              <Input
                                                value={lb.endTime}
                                                onChange={(e) => {
                                                  changeField(
                                                    "endTime",
                                                    e.target.value,
                                                    lb
                                                  );
                                                  setIsChange(true);
                                                }}
                                                className="hidden-table-input time-input-img"
                                                onBlur={() =>
                                                  saveChanges(
                                                    { endTime: lb.endTime },
                                                    lb._id
                                                  )
                                                }
                                              />
                                            </i>
                                          </b>
                                        </div>
                                        <div className="lbl-s-e-c">
                                          <TagsGroup
                                            tags={lb.tags}
                                            onTagChange={(v) => {
                                              saveChanges(
                                                { tags: v },
                                                lb._id,
                                                true
                                              );
                                              changeField("tags", v, lb);
                                            }}
                                          />
                                        </div>
                                        <div className="lbl-s-e-c41">
                                          {lb.p_description &&
                                          lb.p_description.length ? (
                                            <div>
                                              <b>Description: </b>
                                              <TextArea
                                                autoSize={{
                                                  maxRows: 3,
                                                  minRows: 1,
                                                }}
                                                value={lb.p_description}
                                                placeholder="Description"
                                                onChange={(e) => {
                                                  changeField(
                                                    "p_description",
                                                    e.target.value,
                                                    lb
                                                  );
                                                  setIsChange(true);
                                                }}
                                                className=" "
                                                onBlur={() =>
                                                  saveChanges(
                                                    {
                                                      p_description:
                                                        lb.p_description,
                                                    },
                                                    lb._id
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <Button
                                              onClick={() => {
                                                changeField(
                                                  "p_description",
                                                  " ",
                                                  lb
                                                );
                                              }}
                                              size="small"
                                              type="primary"
                                            >
                                              Description
                                            </Button>
                                          )}

                                          {lb.headline && lb.headline.length ? (
                                            <div>
                                              <b>Headline: </b>
                                              <TextArea
                                                autoSize={{
                                                  maxRows: 3,
                                                  minRows: 1,
                                                }}
                                                value={lb.headline}
                                                placeholder="Description"
                                                onChange={(e) => {
                                                  changeField(
                                                    "headline",
                                                    e.target.value,
                                                    lb
                                                  );
                                                  setIsChange(true);
                                                }}
                                                className=" "
                                                onBlur={() =>
                                                  saveChanges(
                                                    { headline: lb.headline },
                                                    lb._id
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <Button
                                              onClick={() => {
                                                changeField(
                                                  "headline",
                                                  " ",
                                                  lb
                                                );
                                              }}
                                              size="small"
                                              type="primary"
                                            >
                                              Headline
                                            </Button>
                                          )}
                                        </div>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                  <div className="page-vid-c">
                    {totalPages ? (
                      <Pagination
                        pageSize={40}
                        current={pageNo}
                        size="small"
                        total={totalPages * 40}
                        onChange={(v) => {
                          setPageNo(v);
                          setsearch({
                            ...search,
                            images: undefined,
                          });
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          <img src={viewFile} alt="" />;
        </div>
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Video</h5>

        {dDelete ? (
          <div className="bx-form-brand">
            <h6>Are you sure you want to delete?</h6>

            <div className="bx-2-date-tm">
              <b>
                Start:
                <i>{dDelete.startTime}</i>
              </b>
              <b>
                End:
                <i>{dDelete.endTime}</i>
              </b>
            </div>
            <div className="delet-btns-h">
              <ButtonB
                size="sm"
                onClick={() => displayDelete()}
                variant="light"
              >
                Cancel
              </ButtonB>{" "}
              <ButtonB
                onClick={() => {
                  deleteVideo(dDelete._id);
                  displayDelete();
                }}
                variant="danger"
                size="sm"
              >
                Yes
              </ButtonB>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
