import React, { useState } from "react";
import SearchContext from "./SearchContext";

const ContextState = (props) => {
  const [active, setActive] = useState();
  const [allEmp, setallEmp] = useState([
    "AA_01",
    "NS_04",
    "SK_05",
    "KA_11",
    "MH_19",
    "SO_22",
    "HI_03",
    "AL_25",
    "NA_02",
  ]);

  const [timestampData, setTimeStamp] = useState();
  const [concept, setConcept] = useState();
  const [label, setlabel] = useState({
    pageNo: 1,
    pageNo4: 1,
    activeBTN: "Concepts",
    activeTab: "complete",
  });
  const [summary, setsummary] = useState({
    pageNo: 1,
    pageNo4: 1,
    activeBTN: "Concepts",
    activeTab: "complete",
  });
  const [plot, setplot] = useState({
    pageNo: 1,
    pageNo4: 1,
    activeBTN: "Concepts",
    activeTab: "complete",
  });
  const [object, setobject] = useState({
    pageNo: 1,
    pageNo4: 1,
    activeBTN: "Concepts",
    activeTab: "complete",
  });
  const [scene, setscene] = useState({
    pageNo: 1,
    pageNo4: 1,
    activeBTN: "Concepts",
    activeTab: "complete",
  });
  const [description, setdescription] = useState({
    pageNo: 1,
    pageNo4: 1,
    activeBTN: "Concepts",
    activeTab: "complete",
  });
  const [speaker, setspeaker] = useState({
    pageNo: 1,
    pageNo4: 1,
    activeBTN: "Concepts",
    activeTab: "complete",
  });
  const [shorts, setshorts] = useState({
    pageNo: 1,
    pageNo4: 1,
    activeBTN: "Concepts",
    activeTab: "pending",
  });

  const [review, setreview] = useState({
    pageNo: 1,
    pageNo2: 1,
    pageNo3: 1,
    tab: "Object",
    activeKey: "Speaker",
  });

  const [concepts, setConcepts] = useState({ gridView: true });
  const [channels, setChannels] = useState();

  const [videoTasks, setVideoTask] = useState({ gridView: false });
  const [videoConcept, setVideoConcept] = useState({
    selectedConcepts: [],
    selectedConcepts2: [],
    cleaned: false,
  });

  const [mambaFilter, setMambaFilter] = useState({
    page: 1,
    limit: 15,
    link: "",
    channel: "",
    pipeline: "",
    category: "",
    platform: "",
    date: "",
    data: undefined,
  });
  const [mambaFilter2, setMambaFilter2] = useState({
    page: 1,
    limit: 15,
    link: "",
    channel: "",
    pipeline: "",
    category: "",
    platform: "",
    date: "",
    data: undefined,
  });
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [videos, setVideos] = useState({
    pageNo: 1,
    pageNo2: 1,
    tab: "1",
  });
  const [url, setUrl] = useState({ pageNo: 1 });
  const [search, setsearch] = useState({});
  const [videosBeingUploaded, setVideosBeingUploaded] = useState([]);
  const [images, setImages] = useState({
    pageNo: 1,
  });
  const [images2, setImages2] = useState({
    pageNo: 1,
  });
  const [images3, setImages3] = useState({
    pageNo: 1,
  });
  const [images5, setImages5] = useState({
    pageNo: 1,
  });
  return (
    <SearchContext.Provider
      value={{
        images5, setImages5,
        images3, setImages3,
        images2,
        setImages2,
        images,
        setImages,
        plot,
        setplot,
        summary,
        setsummary,
        allEmp,
        setallEmp,
        object,
        setobject,
        review,
        setreview,
        search,
        setsearch,
        videosBeingUploaded,
        setVideosBeingUploaded,
        url,
        setUrl,
        shorts,
        setshorts,
        videos,
        setVideos,
        loading,
        setLoading,
        user,
        setUser,
        active,
        setActive,
        speaker,
        setspeaker,
        description,
        setdescription,
        scene,
        setscene,
        label,
        setlabel,
        timestampData,
        setTimeStamp,
        channels,
        setChannels,
        concept,
        setConcept,
        concepts,
        setConcepts,
        videoTasks,
        setVideoTask,
        mambaFilter,
        setMambaFilter,
        videoConcept,
        setVideoConcept,
        mambaFilter2,
        setMambaFilter2,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
export default ContextState;
