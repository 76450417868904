import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Spin,
  Input,
} from "antd";
import { CloseOutlined, TagFilled } from "@ant-design/icons";
import { IoIosClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AddInsight from "../../../components/AddInsight";
const { Option } = Select;
const { TextArea } = Input;

export default function Summary() {
  const navigate = useNavigate();
  const { summary, setsummary, user, review, setreview } =
    useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [general, setgeneral] = useState();
  const [callGeneral, setcallGeneral] = useState("");
  const [cLoader, setCLoader] = useState(false);

  useEffect(() => {
    if (summary.images === undefined) {
      getVideoTask();
    }
  }, [callApi]);

  useEffect(() => {
    getgeneral();
  }, [callGeneral]);

  const getgeneral = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/insight?page=1&limit=15&search=${callGeneral}&category=visual`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setgeneral(data.insightList);
        }
      });
  };

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${summary.pageNo}&limit=40&video_type=concept_image&headclip_id=${summary.activeVideo._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setsummary({
            ...summary,
            images: data.segmentList,
            totalPages: data.totalPages,
          });
        }
      });
  };

  const createSummary = () => {
    const obj = {};
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/video_segment/create_summary/${summary.activeVideo._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setCLoader(false);
      });
  };

  const changeFiled = (key, val, con) => {
    con[key] = val;
    const images = summary.images.map((l) => (l._id === con._id ? con : l));
    setsummary({
      ...summary,
      images,
    });
  };

  const updateFiled = (obj, id, type) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Updated");
        } else {
          message.error("something went wrong");
        }
      });
  };

  return (
    <div>
      <div>
        {summary.images ? (
          <Row>
            <Col md={{ span: 24 }} lg={{ span: 24 }}>
              <div className="space-between">
                <div className="sel-s-c">
                  <h5>
                    {summary.activeVideo.video_name}{" "}
                    <CloseOutlined
                      className="eye-icn-c close-icn"
                      onClick={() => {
                        setsummary({
                          ...summary,
                          activeVideo: undefined,
                          pageNo: 1,
                          pageNo4: 1,
                        });

                        navigate(`/summary`);
                      }}
                    />
                  </h5>
                </div>
                {cLoader ? (
                  <Spin />
                ) : (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      createSummary();
                      setCLoader(true);
                    }}
                  >
                    Create Summary
                  </Button>
                )}
              </div>
              <div>
                {summary.images && summary.images.length === 0 ? (
                  <h1>No Images Can Press Done</h1>
                ) : null}
                <Row>
                  {summary.images &&
                    summary.images.map((lb, lbi) => {
                      return (
                        <Col key={lbi} md={{ span: 6 }}>
                          <div className="lbl-c-col">
                            <Card>
                              <LazyLoadImage
                                effect="blur"
                                onClick={() => setViewFile(lb.file_url)}
                                className="lbl-mn-img"
                                src={lb.file_url}
                                alt="img"
                              />
                              <div style={{margin:"5px 0px"}}>
                                <TextArea
                                  placeholder="Add Overall Topic"
                                  autoSize={{
                                    minRows: 1,
                                    maxRows: 2,
                                  }}
                                  onChange={(e) =>
                                    changeFiled(
                                      "overall_topic",
                                      e.target.value,
                                      lb
                                    )
                                  }
                                  value={lb.overall_topic}
                                  onBlur={() =>
                                    updateFiled(
                                      { overall_topic: lb.overall_topic },
                                      lb._id
                                    )
                                  }
                                />
                              </div>
                              <TextArea
                                placeholder="Add summary"
                                autoSize={{
                                  minRows: 2,
                                  maxRows: 5,
                                }}
                                onChange={(e) =>
                                  changeFiled("summary", e.target.value, lb)
                                }

                                value={lb.summary}
                                onBlur={() =>
                                  updateFiled({ summary: lb.summary }, lb._id)
                                }
                              />

                              <div className="play-btn-vdc">
                                <div className="start-end-bx-lbl">
                                  <b>{lb.startTime}</b>
                                  <b>{lb.endTime}</b>
                                </div>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <div className="page-vid-c">
                  {summary.totalPages ? (
                    <Pagination
                      pageSize={40}
                      current={summary.pageNo}
                      size="small"
                      total={summary.totalPages * 40}
                      onChange={(v) => {
                        setsummary({
                          ...summary,
                          images: undefined,
                          pageNo: v,
                        });
                        setCallApi(callApi + 1);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Spin />
        )}
      </div>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <LazyLoadImage key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          <LazyLoadImage src={viewFile} alt="" />
        </div>
      </Modal>
    </div>
  );
}
