import React from "react";

export default function H6({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <h6 {...props}>{props.text}</h6>
    </>
  );
}
