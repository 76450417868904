import { Card, DatePicker, Spin, Statistic } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import "./account.css";

const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";
export default function Account() {
  const { loading, setLoading, user, setUser } = useContext(SearchContext);
  const [date, setDate] = useState({
    start: moment(new Date()).subtract(7, "days").format("MM/DD/YYYY"),
    end: moment(new Date()).format("MM/DD/YYYY"),
  });
  const [dur, setDur] = useState();
  const [callApi, setCallApi] = useState(1);
  const [laoding, seLoading] = useState(true);

  useEffect(() => {
    console.log(user);
    getDuration();
  }, [callApi]);

  const getDuration = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/timesheet/duration/${user.user_id}?date=${date.start}-${date.end}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        seLoading(false);
        if (data) {
          setDur(data);
        }
      });
  };
  const changeDate = (e) => {
    const start = moment(e[0]._d).format("MM/DD/YYYY");
    const end = moment(e[1]._d).format("MM/DD/YYYY");
    seLoading(true);
    setDate({ ...date, start, end });
    setCallApi(callApi + 1);
  };
  return (
    <div className="main-bx-lbl">
      {" "}
      <RangePicker
        onChange={changeDate}
        format={dateFormat}
        defaultValue={[
          moment(date.start, dateFormat),
          moment(date.end, dateFormat),
        ]}
      />
      <div className="account-amount-c">
        {loading ? <Spin /> : null}

        {dur && !loading ? (
          <div className="box-ac-m-p">
            <Statistic title="Total Minutes" value={dur.total_duration / 60} precision={0} />
            <Statistic
              title="Total Amount"
              value={(dur.total_duration / 60) * 0.06}
              precision={2}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
