import React from "react";

export default function H2({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <h2 {...props}>{props.text}</h2>
    </>
  );
}
