import { AutoComplete, Form, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import LoaderDots from "../../../components/LoaderDots";
import SearchContext from "../../../Context/Search/SearchContext";
import playIconblue from "../../../images/play-buttonb.png";
import TimeStampTable from "./TimeStampTable";

export default function TimeStamp() {
  const { timestampData, setTimeStamp } = useContext(SearchContext);
  const [search, setSearch] = useState("");

  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [resultNotFound, setResultNotFound] = useState(false);

  useEffect(() => {
    if (search !== "") {
      getTimestamp(search);
    }
  }, [page]);

  const getTimestamp = (txt) => {
    const obj = {
      query: txt,
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(
      `https://api.fussy.ai/internal/mamba/guide/test?p=${page}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);

        console.log(data, "guide/test");
        if (data) {
          setTimeStamp(data);
          setResultNotFound(false);
        } else {
          setResultNotFound(true);
        }
      });
  };
  return (
    <div>
      <div className="search-bx-main">
        <Form
          onFinish={() => {
            getTimestamp(search);
            setTimeStamp();
            setLoader(true);
          }}
        >
          <AutoComplete
            placeholder="Search"
            value={search}
            onSelect={(e) => {
              getTimestamp(e);
              setTimeStamp();
            }}
            onSearch={(e) => setSearch(e)}
            // placeholder="input here"
            size="large"
            className={`seletc-input-c `}
          >
            {/* {optionsS.length > 0
        ? optionsS.map((op, opi) => (
            <Option key={opi} value={op.search}>
              {op.search}
            </Option>
          ))
        : null} */}
          </AutoComplete>
        </Form>
        {loader ? (
          <span className={`loader-input-c `}>
            <LoaderDots />
          </span>
        ) : (
          <>
            <img
              className="voi-c-icn"
              onClick={() => {
                getTimestamp(search);
                setTimeStamp();
                setLoader(true);
              }}
              src={playIconblue}
              alt=""
            />
          </>
        )}
      </div>
      {timestampData ? (
        <TimeStampTable
          setPage={(v) => {
            setTimeStamp();
            setPage(v);
          }}
          page={page}
        />
      ) : null}
    </div>
  );
}
