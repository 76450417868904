import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Button, Input, Space, Spin, Switch, message } from "antd";
import { useNavigate } from "react-router-dom";
import InputData from "./components/InputData";
import { v4 as uuidv4 } from "uuid";

export default function ActiveAction() {
  const navigate = useNavigate();
  const [action, setAction] = useState({ pageNo: 1 });

  const [callApi, setCallApi] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingC, setLoadingC] = useState(false);

  useEffect(() => {
    if (action.activeAction === undefined) {
      console.log(window.location.pathname.slice(8));
      getAction(window.location.pathname.slice(8));
    } else {
      setLoading(false);
    }
  }, []);
  const getAction = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://api.display.video/api/list/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setAction({
            ...action,
            activeAction: data.listData,
          });
        }
      });
  };
  const getChat = (p, row, i) => {
    if (p === undefined || p.trim().length === 0) {
      return;
    }
    let id = uuidv4();

    const obj = {
      query: p,
      user_id: id,
      assistant_id: "64271717e33f38eaf18fe777",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://api.display.video/api/v3/chapter/chat`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoadingC(false);
        if (data && data.isSuccess) {
          // correctText(data.data);
          setOutout(row, i, data.data.output);
        } else {
          message.error("something went wrong");
        }
      });
  };
  const setOutout = (row, i, val) => {
    const all = action.activeAction.objectives;
    row.output = val;
    all[i] = row;
    setAction({
      ...action,
      activeAction: { ...action.activeAction, objectives: all },
    });
  };

  return (
    <div className="theme-bg-h">
      <div className="lists-ac-m ">
        {!loading && action.activeAction ? (
          <div>
            {action.activeAction.objectives.map((c, ci) => {
              return (
                <div className="card-txt-siuot s-act-c" key={ci}>
                  <b>Objective</b>
                  <p>{c.description}</p>
                  {c.data ? (
                    <div className="p-data-act">
                      <b>Action</b>
                      <p>{c.data}</p>
                    </div>
                  ) : null}
                  {c.input_data && c.input_data.length ? (
                    <InputData data={c.input_data} />
                  ) : null}
                  <div className="bottom-area-c">
                    <i>{c.status}</i>
                  </div>

                  <div className="">
                    <Input.TextArea
                      autoSize={{ minRows: 2, maxRows: 3 }}
                      value={action.search}
                      onChange={(e) =>
                        setAction({ ...action, search: e.target.value })
                      }
                      placeholder="Find"
                      className="searc-width-action"
                      size="large"
                    />
                    <Button
                      onClick={() => {
                        setLoadingC(ci);
                        getChat(action.search, c, ci);
                      }}
                      disabled={loadingC}
                      size="large"
                      type="primary"
                    >
                      {loadingC === ci ? <Spin /> : "Find"}
                    </Button>
                  </div>
                  {c.output ? (
                    <div className="">
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        value={c.output}
                        onChange={(e) => setOutout(c, ci, e.target.value)}
                        placeholder="Find"
                        className="searc-width-action"
                        size="large"
                      />
                      <Button
                        onClick={() => {
                          // setLoadingC(true);
                          // getChat(action.search, c, ci);
                        }}
                        disabled={loadingC}
                        size="large"
                        type="primary"
                      >
                        {loadingC ? <Spin /> : "Save"}
                      </Button>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : loading ? null : (
          <div>Data Not Found</div>
        )}
      </div>
    </div>
  );
}
