import { Input, message, Modal, Spin, Table, Tag } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";

export default function Language() {
  const [callAPI, setCallAPI] = useState(1);
  const [language, setlanguage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [dAdd, setdAdd] = useState();

  useEffect(() => {
    if (language === undefined) {
      setLoadeing(true);
      getlanguage();
    }
  }, [pageNo]);

  const getlanguage = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/language_template?page=${pageNo}&limit=15`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setlanguage(data.templateList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };
  const SaveLanguage = (row) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/language_template`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          const list = [...language];
          list.unshift(data.data);
          setlanguage(list);
        }
      });
  };

  const saveChanges = (row, id) => {
    if (isChange) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(
        `${process.env.REACT_APP_API}/language_template/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Deleted");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = language.filter((t) => t._id !== id);
    setlanguage(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/language_template/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = language.map((m) => (m._id === row._id ? row : m));
    setlanguage(allRow);
  };
  const columns = [
    {
      title: "Search Key",
      dataIndex: "search_key",
      key: "search_key",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Search Key"
          onChange={(e) => {
            changeField("search_key", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ search_key: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template",
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="Template"
          autoSize={{
            minRows: 1,
            maxRows: 3,
          }}
          onChange={(e) => {
            changeField("template", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ template: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            Delete
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      <Button onClick={() => setdAdd({ search_key: "", template: "" })}>
        Add
      </Button>
      {loadeing ? <Spin /> : null}
      {language ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setlanguage();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={language}
        />
      ) : null}
      <Modal
        visible={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Search Key:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="Search Key"
                      value={dAdd.search_key}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, search_key: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Template:</b>
                  </td>
                  <td>
                    <Input.TextArea
                      autoSize={{ minRows: 2, max: 4 }}
                      className="bx-fld-10t"
                      placeholder="Template"
                      value={dAdd.template}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, template: e.target.value })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-end">
              <Button
                onClick={() => {
                  SaveLanguage(dAdd);
                  setdAdd();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Invitation</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
