import { message, Tabs } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import Actions from "./tabs/Actions";
import Apparel from "./tabs/Apparel";
import DeleteExtraImages from "./tabs/DeleteExtraImages";
import Environment from "./tabs/Environment";
import Expression from "./tabs/Expression";
import Label from "./tabs/label/Label";
import Objects from "./tabs/Objects";
import Speaker from "./tabs/speaker/Index";
export default function DescriptionTabs() {
  const { review, setreview, user } = useContext(SearchContext);

  const changeTab = (v) => {
    setreview({ ...review, activeKey: v });
    // let status = review.doneVideo2.frame_status;
    // if (status !== "progress") {
    //   let change = true;
    //   if (status === "concepts" && v !== "Thumbnail") {
    //     change = false;
    //     message.warning("First Complete Labels");
    //   }
    //   if (change) {
    //     setreview({ ...review, activeKey: v });
    //   }
    // } else {
    //   message.warning("First Adjust Correct Images");
    // }
    console.log(v, review);
  };

  return (
    <div>
      <Tabs onChange={changeTab} activeKey={review.activeKey}>
        <Tabs.TabPane tab="Speaker" key="Speaker">
          <Speaker />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Label" key="Label">
          <Label />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Action" key="Actions">
          <Actions />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Environment" key="Environment">
          <Environment />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Apparel" key="Apparel">
          <Apparel />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Expression" key="Expression">
          <Expression />
        </Tabs.TabPane> */}
        {/* <Tabs.TabPane tab="Objects" key="Objects">
          <Objects />
        </Tabs.TabPane> */}
      </Tabs>
    </div>
  );
}
