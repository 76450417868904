import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  Row,
  Col,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import ReactPlayer from "react-player";
import TagsGroup from "../../../components/TagsGroup";

const { Option } = Select;
export default function AddNew({ folderVideo, setfolderVideo }) {
  const [callAPI, setCallAPI] = useState(1);
  const [minuteVideo, setminuteVideo] = useState();
  const [totalPages, setTotalPages] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);
  const [clip, setClip] = useState();
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const [used, setUsed] = useState("ready");

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [editVideo, setEditVideo] = useState();

  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [mData, setMData] = useState("");
  const [tagLoading, setTagLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [lengthS, setLengthS] = useState(0);
  const [video, setVideo] = useState();
  const [sceneData, setSceneData] = useState(); // Add style state
  const [editFolder, setEditFolder] = useState();

  useEffect(() => {
    if (minuteVideo === undefined) {
      setLoadeing(true);
      getminuteVideo();
    }
  }, [pageNo, callAPI]);

  const getminuteVideo = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/minute?page=${pageNo}&limit=6&sort_by=${-1}&media_type=original&status=${status}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeing(false);
        console.log(data);
        if (data && data.isSuccess) {
          setminuteVideo(data.minuteList);
          setTotalPages(data.totalPages);
          //   setGenerated({
          //     ...generated,
          //     videos: data.imageList,
          //     totalPages: data.totalPages,
          //   });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/folder`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        setTagLoading(false);
        if (response && response.isCreated) {
          let all = [response.folder_payload, ...folderVideo];
          setfolderVideo(all);

          setEditVideo();
          setClip();
          setVideo();
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
      // console.log(.url);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };

  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          <div className="vd-nm-pr img-prev-ibc">
            <ReactPlayer url={imageUrl} controls={false} />
          </div>
        </>
      );
    } else {
      return <span style={{ color: "black" }}>Drag or click to upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
  };

  const saveVideo = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f[0].originFileObj);

    formData.append("assistant_id", "");
    formData.append("video_duration", lengthS);

    formData.append("purpose", "video");
    formData.append("meta_data", mData);
    formData.append("user_id", "64dc1de41d6e72846d0bb3f2");

    setDisplay(false);
    const vidId = Math.random();

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/upload/video`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFileList([]);
        setUplaoded([]);
        setLoading(false);
        if (data && data.isSuccess) {
          setVideo(data.data);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const getVideoLength = (vLength) => {
    setLengthS(vLength);
    // if (vLength / 60 > 3) {
    //   setUplaoded([]);
    //   setVideoErr(true);
    // } else {
    //   setVideoErr(false);
    // }
  };

  return (
    <div>
      <Button onClick={() => setEditVideo({ core_label: "", caption: "" })}>
        Add
      </Button>
      <Modal
        visible={editVideo !== undefined}
        onCancel={() => {
          setEditVideo();
          setClip();
          setVideo();
        }}
        width={600}
        className="no-buttons-modal "
      >
        {video ? (
          <div>
            <h5>Video</h5>
            <div className="video-container radius-video ">
              <ReactPlayer controls url={video.file_url} playing />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h5>Core Lable</h5>
              {editVideo ? (
                <Input
                  value={editVideo.core_label}
                  placeholder="Core Label"
                  onChange={(e) => {
                    setEditVideo({ ...editVideo, core_label: e.target.value });
                  }}
                />
              ) : null}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h5>Caption</h5>
              {editVideo ? (
                <Input
                  value={editVideo.caption}
                  placeholder="caption"
                  onChange={(e) => {
                    setEditVideo({ ...editVideo, caption: e.target.value });
                  }}
                />
              ) : null}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => {
                  updateValue(
                    {
                      file_url: video.file_url,
                      core_label: editVideo.core_label,
                      segments: [video.file_url],
                      caption: editVideo.caption,
                    },
                    editVideo?._id
                  );
                }}
                size="sm"
              >
                Save Video
              </Button>
            </div>
          </div>
        ) : editVideo ? (
          <div>
            <h5>Add Video</h5>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Dragger
                showUploadList={false}
                maxCount={1}
                beforeUpload={handleBeforeUpload}
                {...props}
              >
                {renderFileName()}
              </Dragger>
              <div className="up-bx-mnbcs">
                {UploadedFile.length ? (
                  <Button type="link" onClick={removeFile}>
                    Change
                  </Button>
                ) : null}
                {UploadedFile.length ? (
                  loading ? (
                    <Spin />
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        setLoading(true);
                        saveVideo(UploadedFile);
                      }}
                    >
                      Attach
                    </Button>
                  )
                ) : null}
                {UploadedFile.length ? (
                  <div className="pic-2vid0c">
                    <ReactPlayer
                      url={URL.createObjectURL(UploadedFile[0].originFileObj)}
                      className=""
                      controls
                      playing
                      muted
                      onProgress={(e) => getVideoLength(e.loadedSeconds)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
