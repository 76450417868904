import { Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/Search/SearchContext";
import LabelD from "./components/Thumbnails";
export default function Label() {
  const { label, setlabel, user } = useContext(SearchContext);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    if (label.activeVideo === undefined) {
      getVideoTask(window.location.pathname.slice(6));
    }
  }, []);

  const getVideoTask = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/video_segment?_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setlabel({ ...label, activeVideo: data.segmentList[0] });
        }
      });
  };
  return <div>{label.activeVideo ? <LabelD /> : <Spin />}</div>;
}
