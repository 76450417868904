import { Input } from "antd";
import React from "react";

export default function InputE({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <input {...props} />
    </>
  );
}
