import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import { Row, Col, Card, Tag, Pagination, Spin } from "antd";
import moment from "moment";
import { TimeCorrection } from "../../../components/GlobalFunctions";
import { useNavigate } from "react-router-dom";

export default function Description() {
  const navigate = useNavigate();

  const { review, setreview, setActive, user } = useContext(SearchContext);
  useEffect(() => {
    setActive(window.location.pathname);
  }, []);
  const [callApi, setCallApi] = useState(0);
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    if (review.description === undefined) {
      getVideoTask();
      setLoading2(true);
    } else {
      setLoading2(false);
    }
  }, [callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${
        review.pageNo2
      }&limit=15&search_status=indexed&hide_file_url=${true}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setreview({
            ...review,
            description: data.segmentList,
            totalPages2: data.totalPages,
          });
        }
      });
  };

  return (
    <div className="main-bx-lbl">
      {loading2 ? <Spin /> : null}
      <Row>
        {review.description &&
          review.description.map((lb, lbi) => {
            return (
              <Col className="done-card-c" key={lbi} md={{ span: 6 }}>
                <div className="lbl-c-col">
                  <Card>
                    <div>
                      <div className="bx-2-date-tm">
                        <div>
                          <h6 className="name-tit-c">{lb.video_name}</h6>
                        </div>
                        <b>
                          Created At:
                          {lb.createdAt ? (
                            <i>
                              {moment(lb.createdAt).format(
                                "MMMM Do YYYY h:mm:ss"
                              )}
                            </i>
                          ) : null}
                        </b>
                        <b>
                          Updated:
                          {lb.model_start ? (
                            <i>
                              {moment(lb.updatedAt).format(
                                "MMMM Do YYYY h:mm:ss"
                              )}
                            </i>
                          ) : null}
                        </b>
                      </div>
                    </div>

                    <div className="bx-2-date-tm">
                      <b>
                        Start Time:
                        {lb.model_start ? (
                          <i>
                            {moment(lb.model_start).format(
                              "MMMM Do YYYY h:mm:ss"
                            )}
                          </i>
                        ) : null}
                      </b>
                      <b>
                        End Time:
                        {lb.model_end ? (
                          <i>
                            {moment(lb.model_end).format(
                              "MMMM Do YYYY h:mm:ss"
                            )}
                          </i>
                        ) : null}
                      </b>
                    </div>
                    <div
                      style={{ justifyContent: "space-between" }}
                      className="start-btn-c"
                    >
                      <span />
                      <Tag
                        onClick={() => {
                          setreview({
                            ...review,
                            doneVideo2: lb,
                          });
                          navigate(`/task/${lb._id}`);
                        }}
                        color="rgb(24, 24, 145)"
                      >
                        View
                      </Tag>
                    </div>
                    <div className="fl-c-botom">
                      <b>
                        {TimeCorrection(Math.floor(Number(lb.video_duration)))}
                      </b>
                    </div>
                  </Card>
                </div>
              </Col>
            );
          })}
      </Row>

      <div className="page-vid-c">
        {review.totalPages2 ? (
          <Pagination
            pageSize={15}
            current={review.pageNo2}
            size="small"
            total={review.totalPages2 * 15}
            onChange={(v) => {
              setreview({
                ...review,
                description: undefined,
                pageNo2: v,
              });
              setCallApi(callApi + 1);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
