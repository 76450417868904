import React from "react";
import AddVideo from "./components/AddVideo";

export default function Upload() {
  return (
    <div>
      <AddVideo />
    </div>
  );
}
