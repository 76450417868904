import { Col, Row } from "antd";
import React, { useEffect } from "react";
import Icons from "../../components/Icons";
import { Container } from "react-bootstrap";
import LoginForm from "./components/LoginForm";
import "./login.css";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <Row>
        <Col lg={{ span: 12 }} md={{ span: 12 }}>
          <div className="left-lg-c">
            <div className="left-lg-c2">
              <img src={Icons.Logo} alt="" />
              <h4>Visual Intelligence, Anytime, Anywhere</h4>
              <p>
               Video is the new text. Fussy helps you craft better stories by cutting down hours of organizing and editing, so you can create more content and interact with your audience in new ways.
              </p>
            </div>
          </div>
        </Col>

        <Col lg={{ span: 12 }} md={{ span: 12 }}>
          <div className="log-f-r-c">
            <LoginForm />
          </div>
        </Col>
      </Row>
    </div>
  );
}
