import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown, Dropdown } from "react-bootstrap";
import Icons from "./Icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Menu,
  Select,
  Dropdown as DropdownA,
  message,
  Spin,
} from "antd";

import "./style.css";
import SearchContext from "../Context/Search/SearchContext";

export default function Header() {
  const { active, setActive, user, setUser, setLoading } =
    useContext(SearchContext);

  const [loading, setLoadng] = useState();

  useEffect(() => {
    setActive(window.location.pathname);
  }, []);

  const navigate = useNavigate();
  const logout = () => {
    setUser();
    window.localStorage.removeItem("user");
    navigate("/login");
    setLoading(true);
    window.location.reload();
  };

  const connect = () => {
    const obj = {
      user_id: "6368c6013e74e9fa6ba788b6",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/chapter/import_data_vs`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoadng(false);
        if (data.isSuccess) {
          message.success("Added");
        } else {
          message.error("Error");
        }
      });
  };

  const DataMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setActive("/dataset");
          navigate("/dataset");
        }}
      >
        Dataset
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActive("/controller");
          navigate("/controller");
        }}
      >
        Controller
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActive("/mood");
          navigate("/mood");
        }}
      >
        Mood
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActive("/spider");
          navigate("/spider");
        }}
      >
        Spider
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActive("/reimagine");
          navigate("/reimagine");
        }}
      >
        ReImagine
      </Menu.Item>
    </Menu>
  );
  const DictionaryMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setActive("/theme");
          navigate("/theme");
        }}
      >
        Theme
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActive("/mamba");
          navigate("/mamba");
        }}
      >
        Mamba
      </Menu.Item>
    </Menu>
  );
  const OrganizationMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setActive("/organization");
          navigate("/organization");
        }}
      >
        Organization
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActive("/chat-log");
          navigate("/chat-log");
        }}
      >
        Chat Log
      </Menu.Item>
    </Menu>
  );
  const ExperimentMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setActive("/create");
          navigate("/create");
        }}
      >
        Lab
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActive("/imagine");
          navigate("/imagine");
        }}
      >
        Imagine
      </Menu.Item>
    </Menu>
  );
  const ProducttMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setActive("/upscale");
          navigate("/upscale");
        }}
      >
        Upscale
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActive("/images-verify");
          navigate("/images-verify");
        }}
      >
        Image V
      </Menu.Item>
    </Menu>
  );

  return (
    <Navbar className="header-top-f" expand="md">
      <Container className="main-nav-my">
        <Navbar.Brand href="#home">
          <img src={Icons.logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-ncnc" id="basic-navbar-nav">
          <Nav>
            <Nav.Link
              onClick={() => {
                setActive("/watch");
                navigate("/watch");
              }}
              active={active === "/watch"}
            >
              Watch
            </Nav.Link>
            <DropdownA trigger="hover" overlay={DataMenu}>
              <Nav.Link
                active={
                  active === "/dataset" ||
                  active === "/controller" ||
                  active === "/mood" ||
                  active === "/spider" ||
                  active === "/reimagine"
                }
              >
                Data
              </Nav.Link>
            </DropdownA>
            <DropdownA trigger="hover" overlay={DictionaryMenu}>
              <Nav.Link active={active === "/theme" || active === "/mamba"}>
                Dictionary
              </Nav.Link>
            </DropdownA>
            <DropdownA trigger="hover" overlay={OrganizationMenu}>
              <Nav.Link
                active={active === "/organization" || active === "/chat-log"}
              >
                Users
              </Nav.Link>
            </DropdownA>
            <DropdownA trigger="hover" overlay={ExperimentMenu}>
              <Nav.Link active={active === "/create" || active === "/imagine"}>
                Experiment
              </Nav.Link>
            </DropdownA>
            <DropdownA trigger="hover" overlay={ProducttMenu}>
              <Nav.Link
                active={active === "/upscale" || active === "/images-verify"}
              >
                Product
              </Nav.Link>
            </DropdownA>

            {/* <Nav.Link
              onClick={() => {
                setActive("/reupload");
                navigate("/reupload");
              }}
              active={active === "/reupload"}
            >
              Reupload
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/crawler");
                navigate("/crawler");
              }}
              active={active === "/crawler"}
            >
              Video
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/search-labels");
                navigate("/search-labels");
              }}
              active={active === "/search-labels"}
            >
              searchIMG
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/images");
                navigate("/images");
              }}
              active={active === "/images"}
            >
              Images
            </Nav.Link> */}
            <Nav.Link
              onClick={() => {
                setActive("/attachments");
                navigate("/attachments");
              }}
              active={active === "/attachments"}
            >
              Attachments
            </Nav.Link>
            {/* <Nav.Link
              onClick={() => {
                setActive("/cache");
                navigate("/cache");
              }}
              active={active === "/cache"}
            >
              Cache
            </Nav.Link> */}
            <Nav.Link
              onClick={() => {
                setActive("/list");
                navigate("/list");
              }}
              active={active === "/list"}
            >
              List
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setActive("/roleplay");
                navigate("/roleplay");
              }}
              active={active === "/roleplay"}
            >
              Role
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setActive("/livedata");
                navigate("/livedata");
              }}
              active={active === "/livedata"}
            >
              Live
            </Nav.Link>
            
            {/* <Nav.Link
              onClick={() => {
                setActive("/lens");
                navigate("/lens");
              }}
              active={active === "/lens"}
            >
              Lens
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/draw");
                navigate("/draw");
              }}
              active={active === "/draw"}
            >
              Draw
            </Nav.Link> */}

            {/* <Nav.Link
              onClick={() => {
                setActive("/entities");
                navigate("/entities");
              }}
              active={active === "/entities"}
            >
              Entities
            </Nav.Link> */}

            <a href="https://embed.axv.ai/api/folder/frame" target="blank">
              <Button>Caption</Button>
            </a>
            <a href=" https://embed.axv.ai/api/folder/split" target="blank">
              <Button>Split</Button>
            </a>
            {/* <Nav.Link
              onClick={() => {
                setActive("/label");
                navigate("/label");
              }}
              active={active === "/label"}
            >
              Label
            </Nav.Link> */}
            {/* <DropdownA trigger="click" overlay={menu}>
              <Nav.Link
                active={
                  active === "/attachments" ||
                  active === "/commands" ||
                  active === "/command-values"
                }
              >
                Function
              </Nav.Link>
            </DropdownA> */}
            {/* <DropdownA trigger="click" overlay={menu2}>
              <Nav.Link active={active === "/query" || active === "/general"}>
                Data
              </Nav.Link>
            </DropdownA> */}
            {/* <DropdownA trigger="click" overlay={menu3}>
              <Nav.Link
                active={
                  active === "/summary" ||
                  active === "/plot" ||
                  active === "/list" ||
                  active === "/caches" ||
                  active === "/product" ||
                  active === "/vision" ||
                  active === "/chapters"
                }
              >
                Visaudi
              </Nav.Link>
            </DropdownA> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/object");
                navigate("/object");
              }}
              active={active === "/object"}
            >
              Object
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/review");
                navigate("/review");
              }}
              active={active === "/review"}
            >
              Review
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/upload");
                navigate("/upload");
              }}
              active={active === "/upload"}
            >
              Upload
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/url");
                navigate("/url");
              }}
              active={active === "/url"}
            >
              URL
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/account");
                navigate("/account");
              }}
              active={active === "/account"}
            >
              Account
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/generator");
                navigate("/generator");
              }}
              active={active === "/generator"}
            >
              Generator
            </Nav.Link> */}
            {/* <Nav.Link
              onClick={() => {
                setActive("/topic");
                navigate("/topic");
              }}
              active={active === "/topic"}
            >
              Topic
            </Nav.Link> */}
          </Nav>
          {/* {user ? ( */}
          <div className="tpo-r-hrd">
            <Dropdown align="start">
              {user ? (
                <Dropdown.Toggle id="dropdown-basic1">
                  <div className="bx-drp-hr">
                    <b>👋 {user.name}</b>
                  </div>
                </Dropdown.Toggle>
              ) : null}

              <Dropdown.Menu>
                <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* {loading ? (
              <Spin />
            ) : (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  connect();
                  setLoadng(true);
                }}
              >
                Launch
              </Button>
            )} */}
          </div>
          {/* ) : null} */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
