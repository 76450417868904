import React, { useContext, useEffect, useRef, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Popconfirm,
  Spin,
} from "antd";
import { CloseOutlined, TagFilled } from "@ant-design/icons";
import ReactPlayer from "react-player";
import { timeToseconds } from "../../../components/GlobalFunctions";

const { Option } = Select;
export default function SceneImages() {
  const videoRef = useRef();
  const { scene, setscene } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (scene.images === undefined) {
      getVideoTask();
    }
  }, [callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${scene.pageNo}&limit=40&video_type=concept_image&headclip_id=${scene.activeVideo._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setscene({
            ...scene,
            images: data.segmentList,
            totalPages: data.totalPages,
          });
        }
      });
  };

  const updateTag = (val, con, type) => {
    let tags = con.tags ? con.tags : [];
    if (type === "remove") {
      tags = tags.filter((f) => f !== "new_scene");
    } else {
      tags.push("new_scene");
    }

    con.tags = tags;
    const images = scene.images.map((l) => (l._id === con._id ? con : l));
    setscene({
      ...scene,
      images,
    });
    updateFiled({ tags }, con._id);
  };
  const updateFiled = (obj, id, type) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (type === "done") {
            message.success(`Whole Video Complete `);
            const all = scene[scene.activeTab].map((r) =>
              r._id === scene.activeVideo._id
                ? {
                    ...scene.activeVideo,
                    model_i: "done",
                  }
                : r
            );

            setLoading(false);
            setscene({
              ...scene,
              activeVideo: undefined,
              [scene.activeTab]: all,
              images: undefined,
              pageNo: 1,
              pageNo4: 1,
            });
          }
        } else {
          message.error("something went wrong");
        }
      });
  };

  const changeTime = (time) => {
    videoRef.current.seekTo(time, "seconds");
  };
  return (
    <div>
      <div>
        <Row>
          <Col md={{ span: 24 }}>
            <div className="sel-s-c">
              <h5>
                {scene.activeVideo.video_name}{" "}
                <CloseOutlined
                  className="eye-icn-c close-icn"
                  onClick={() =>
                    setscene({
                      ...scene,
                      activeVideo: undefined,
                      images: undefined,
                      pageNo: 1,
                      pageNo4: 1,
                    })
                  }
                />
              </h5>
              {loading ? (
                <Spin />
              ) : (
                <Popconfirm
                  title={
                    <div>
                      <h6>Complete all Images?</h6>
                      <h6>Complete all pages?</h6>
                    </div>
                  }
                  onConfirm={() => {
                    setLoading(true);
                    updateFiled(
                      { model_i: "done" },
                      scene.activeVideo._id,
                      "done"
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">Press Button to Complete Video</Button>
                </Popconfirm>
              )}
            </div>

            <div>
              {scene.images && scene.images.length === 0 ? (
                <h1>No Images Can Press Done</h1>
              ) : null}
              <Row>
                {scene.images &&
                  scene.images.map((lb, lbi) => {
                    return (
                      <Col key={lbi} md={{ span: 8 }}>
                        <div className="lbl-c-col">
                          <Card>
                            <div>
                              {lb.tags &&
                              lb.tags.filter((f) => f === "new_scene")
                                .length ? null : (
                                <Button
                                  type="primary"
                                  size="small"
                                  onClick={(v) => updateTag(v, lb)}
                                >
                                  Scene
                                </Button>
                              )}
                            </div>
                            <img
                              onClick={() =>
                                setViewFile(timeToseconds(lb.startTime))
                              }
                              className="lbl-mn-img"
                              src={lb.file_url}
                              alt="img"
                            />
                            <div className="bx-2-date-tm">
                              <b>
                                <i>{lb.startTime}</i> - <i>{lb.endTime}</i>
                              </b>
                            </div>
                            <div className="lbl-s-e-c">
                              {lb.tags &&
                              lb.tags.filter((f) => f === "new_scene")
                                .length ? (
                                <Tag color="rgb(18, 128, 18)">
                                  New Scene{" "}
                                  <CloseOutlined
                                    className="eye-icn-c close-icn"
                                    onClick={(v) => updateTag(v, lb, "remove")}
                                  />
                                </Tag>
                              ) : null}
                            </div>
                          </Card>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
              <div className="page-vid-c">
                {scene.totalPages ? (
                  <Pagination
                    pageSize={40}
                    current={scene.pageNo}
                    size="small"
                    total={scene.totalPages * 40}
                    onChange={(v) => {
                      setscene({ ...scene, images: undefined, pageNo: v });
                      setCallApi(callApi + 1);
                    }}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          {/* <img src={viewFile} alt="" />; */}
          <ReactPlayer
            onPlay={(e) => changeTime(viewFile)}
            controls
            url={scene.activeVideo.file_url}
            ref={videoRef}
          />
        </div>
      </Modal>
    </div>
  );
}
