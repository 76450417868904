import { Table } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../../Context/Search/SearchContext";

export default function ConceptTable({ current, setPageNo }) {
  const { concepts, setConcepts } = useContext(SearchContext);

  const columns = [
    {
      title: "Saved Images",
      dataIndex: "saved_images",
      key: "saved_images",
      render: (text, row) => <img className="img-tblc" src={text[0]} alt="" />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => <b>{text}</b>,
    },
  ];
  return (
    <div>
      <Table
        pagination={{
          pageSize: 15,
          current: current,
          total: concepts.totalPages * 15,
          onChange: (v) => setPageNo(v),
          size:"small"
        }}
        columns={columns}
        dataSource={concepts.data}
      />
    </div>
  );
}
