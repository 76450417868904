import { Button, Input, message, Modal } from "antd";
import React, { useContext, useState } from "react";
import { BsPlus } from "react-icons/bs";
import SearchContext from "../Context/Search/SearchContext";

export default function AddInsight() {
  const { allEmp, user } = useContext(SearchContext);
  const [dAdd, setdAdd] = useState();

  const Savegeneral = (row) => {
    row.created_by = user.user_id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/insight/create`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Added");
          // const list = [...general];
          // list.unshift(data.data);
          // list.pop();
          // setgeneral(list);
        } else {
          message.error(data.msgError);
        }
      });
  };

  return (
    <div>
      {" "}
      {dAdd ? (
        <div className="bx-form-brand no-radius-input">
          <table>
            <tbody>
              <tr>
                <td>
                  <b style={{ marginRight: "10px" }}> Value:</b>
                </td>
                <td>
                  <Input
                    className="bx-fld-10t"
                    placeholder="Value"
                    value={dAdd.value}
                    onChange={(e) =>
                      setdAdd({ ...dAdd, value: e.target.value })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex-end">
            <Button
              type="primary"
              onClick={() => {
                Savegeneral(dAdd);
                setdAdd();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <Button
          type="primary"
          onClick={() =>
            setdAdd({ category: "visual", value: "", user_id: "core" })
          }
        >
          <BsPlus />
          Add
        </Button>
      )}
    </div>
  );
}
