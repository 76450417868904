import React, { useContext } from "react";
import { Drawer } from "antd";
import {
  FileOutlined,
  ShareAltOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Icons from "../../../components/Icons";
import moment from "moment";
import SearchContext from "../../../Context/Search/SearchContext";

export default function FileDrawer({ card, setSelectedCard }) {
  const { videoTasks, setVideoTask } = useContext(SearchContext);

  const deleteVideo = (row) => {
    const deleted = videoTasks.videos.filter((m) => m._id !== row._id);
    setVideoTask({ ...videoTasks, videos: deleted });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(`https://int.display.video/api/video_segment/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };
  
  return (
    <Drawer
      title={
        <div>
          <FileOutlined />
          <b> File Preview</b>
        </div>
      }
      placement="right"
      onClose={setSelectedCard}
      visible={card !== undefined}
    >
      {card ? (
        <div onClick={() => console.log(card)} className="drawer-mxx">
          <div>
            <div className="dr-c-mid">
              <img src={Icons.playBtnBlueIcon} alt="" />
              <b>{card.video_name}</b>
              <p>{moment(card.createdAt).format("MMMM Do YYYY")}</p>
            </div>
            <div className="dr-c-dec">
              <b>Description:</b>
              <p>{card.description}</p>
            </div>
            <div className="shared-with-c">
              <b>File Shared With:</b>
              <div className="shr-m-nm">
                <b>G</b> George Williams
              </div>
              <div className="shr-m-nm">
                <b>T</b> Tailer jr
              </div>
            </div>
          </div>
          <div className="dr-c-botom">
            <div className="dr-c-bot1">
              <ShareAltOutlined />

              <b>Share</b>
            </div>
            <div className="dr-c-bot1">
              <EditOutlined />

              <b>Edit</b>
            </div>
            <div
              onClick={() => {
                setSelectedCard();
                deleteVideo(card);
              }}
              className="dr-c-bot1"
            >
              <DeleteOutlined />

              <b>Delete</b>
            </div>
          </div>
        </div>
      ) : null}
    </Drawer>
  );
}
