import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContextState from "./Context/Search/ContextState";

import "./App.css";
import Index from "./pages";
function App() {
  return (
    <ContextState>
      <BrowserRouter>
        <Index />
      </BrowserRouter>
    </ContextState>
  );
}



export default App;
