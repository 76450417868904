import React, { useContext } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import { Tabs } from "antd";
import "./review.css";
import ReviewLabel from "./tabs/Label";
import Description from "./tabs/Description";
import SpeakerReview from "./tabs/Speaker";

const { TabPane } = Tabs;
export default function Review() {
  const { review, setreview } = useContext(SearchContext);

  return (
    <div className="main-bx-lbl">
      <div>
        <Tabs
          onChange={(v) => {
            setreview({
              ...review,
              tab: v,
            });
          }}
          activeKey={review.tab}
        >
          <TabPane tab="Object" key="Object">
            <ReviewLabel />
          </TabPane>
          <TabPane tab="Description" key="description">
            <Description />
          </TabPane>
          <TabPane tab="Speaker" key="speaker">
            <SpeakerReview />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
