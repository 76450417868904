import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  Row,
  Col,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import GreenTick from "../../components/GreenTick";
import SearchContext from "../../Context/Search/SearchContext";
import ReactPlayer from "react-player";
import CaptionsSearch from "./components/CaptionsSearch";
const { Option } = Select;
export default function Watch() {
  const { user, organization, setOrganization } = useContext(SearchContext);
  const [search2, setSearch2] = useState("");

  const [callAPI, setCallAPI] = useState(1);
  const [watchVideo, setwatchVideo] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);
  const [clip, setClip] = useState();
  const [display, setDisplay] = useState("");
  const [username, setUsername] = useState("");
  const [rank, setRank] = useState("");
  const [quene, setQuene] = useState("");
  const [count, setCount] = useState("");
  const [pageNoS, setPageNoS] = useState(1);
  const [isPages, setIsPages] = useState(true);
  const [editLoading, setEditLoading] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [editFolder, setEditFolder] = useState();
  const [editVideo, setEditVideo] = useState();
  const [sceneData, setSceneData] = useState(); // Add style state
  const [captionS, setCaptionS] = useState();
  const [captionAct, setCaptionAct] = useState();

  useEffect(() => {
    if (watchVideo === undefined) {
      setLoadeing(true);
      getwatchVideo();
    }
  }, [pageNo, callAPI]);

  const getFolder = (url, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/folder?page=1&limit=6&sort_by=${-1}&segments=${url}&standard=poor`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setEditFolder(data.folderList);
          if (data.folderList.length) {
            setSceneData({ ...sceneData, activeId: id });
          }
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };
  useEffect(() => {
    if (search2.length) {
      gethighlight24();
    }
  }, [search2, pageNoS]);

  const gethighlight24 = () => {
    let obj = { query: search2, page: pageNoS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://embed.axv.ai/api/image/watch/internal/search`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setwatchVideo(data);
          setTotalPages(1);
          setIsPages(false);
        } else {
        }
      });
  };

  const getwatchVideo = (id) => {
    console.log("sss");
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image/internal?page=${pageNo}&limit=6&sort_by=${-1}&media_type=original&status=generated&display=${display}&username=${username}&rank=${rank}&quene=${quene}&count=${count}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeing(false);
        console.log(data);
        if (data && data.isSuccess) {
          setIsPages(true);

          setwatchVideo(data.imageList);
          setTotalPages(data.totalPages);
          //   setGenerated({
          //     ...generated,
          //     videos: data.imageList,
          //     totalPages: data.totalPages,
          //   });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = watchVideo.map((m) => (m._id === row._id ? row : m));
    setwatchVideo(all);
    setIsChangeG(true);
  };
  const changeValue2 = (row, key, key2, i, val) => {
    row[key][i][key2] = val;
    setSceneData(row);

    const all = watchVideo.map((m) => (m._id === row._id ? row : m));
    setwatchVideo(all);
    setIsChangeG(true);
  };

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isSuccess) {
          let all = watchVideo.map((m) =>
            m._id === response.imageData._id ? response.imageData : m
          );
          setwatchVideo(all);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };

  const saveClip = () => {
    let obj = {
      start_time: TimeCorrection(startT),
      end_time: TimeCorrection(endT),
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/trim_video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const copyToClipboard = (video) => {
    const textToCopy = `https://www.olm.ai/share/${video._id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };

  const getSearch = (m) => {
    let obj = { query: m.captions, page: 1 };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/folder/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCaptionS(data);
        } else {
        }
      });
  };

  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };
  const formatter = (value) => TimeCorrection(value);

  const updateFolder = (obj, id, isUpdate) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/folder/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        setEditLoading(false);
        if (response && response.isPatched) {
          if (isUpdate === "row-update") {
            let all = captionS.map((m) =>
              m._id === response.patchedData._id ? response.patchedData : m
            );

            setCaptionS(all);
            setSceneData({ ...sceneData, newClip: undefined });
          }
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };
  const saveSpeed = (m) => {
    let obj = {
      video_id: m._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/speed/video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setEditLoading(false);
        if (response && response.success) {
          setSceneData({ ...sceneData, newClip: response.file_url });
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };
  const saveSlow = (m) => {
    let obj = {
      video_id: m._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/slow/video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setEditLoading(false);
        if (response && response.success) {
          setSceneData({ ...sceneData, newClip: response.file_url });
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };
  const deleteVideo = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isSuccess) {
          let all = watchVideo.filter((f) => f._id !== id);
          setwatchVideo(all);
          message.success("Deleted");
        } else {
          notification.error({
            message: "Data Not Deleted  Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };

  const columns = [
    {
      title: "Video",
      dataIndex: "file_url",
      key: "file_url",
      width: 400,
      render: (text, row) => (
        <div className="fl-c-mid221-dis">
          <ReactPlayer url={text} className="" controls />
        </div>
      ),
    },
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="query"
          onChange={(e) => {
            changeValue(row, "query", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ query: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },
    {
      title: "Preanimation",
      dataIndex: "preanimation",
      key: "preanimation",
      width: 200,
      render: (text, row) => (
        <div className="fl-c-mid221-dis2">
          <ReactPlayer url={text} className="" controls />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      width: 30,
      render: (text, row) => (
        <div>
          <Button onClick={() => setEditVideo(row)} size="small">
            Edit
          </Button>
          <Button
            style={{ marginTop: "10px" }}
            onClick={() => setSceneData(row)}
            size="small"
          >
            Scene
          </Button>
        </div>
      ),
    },
    {
      title: "Tags",
      dataIndex: "count",
      key: "count",
      width: 150,

      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="blue"
          onTagChange={(v) => {
            changeValue(row, "count", v);
            updateValue({ count: v }, row._id, true);
          }}
        />
      ),
    },
    // {
    //   title: "Attachment",
    //   dataIndex: "attachment",
    //   key: "attachment",
    //   width: 150,

    //   render: (text, row) => <span>{text}</span>,
    // },
    // {
    //   title: "Movement",
    //   dataIndex: "movement",
    //   key: "movement",
    //   width: 150,

    //   render: (text, row) => <span>{text}</span>,
    // },
    // {
    //   title: "Transformer",
    //   dataIndex: "transformer",
    //   key: "transformer",
    //   width: 150,

    //   render: (text, row) => <span>{text}</span>,
    // },
    {
      title: "Root_Image",
      dataIndex: "root_image",
      key: "root_image",
      width: 150,

      render: (text, row) => <img className="img-root-x" src={text} alt=""/>,
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      width: 30,
      render: (text, row) => (
        <InputNumber
          value={text}
          placeholder="rank"
          onChange={(e) => {
            changeValue(row, "rank", e);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ rank: text }, row._id);
            }
          }}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 30,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="username"
          onChange={(e) => {
            changeValue(row, "username", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ username: text }, row._id);
            }
          }}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Color Grade",
      dataIndex: "color_grade",
      key: "color_grade",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="color_grade"
          onChange={(e) => {
            changeValue(row, "color_grade", e.target.value.trim());
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ color_grade: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Quene",
      dataIndex: "quene",
      key: "quene",
      width: 30,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="Undefined"
          style={{ width: 120 }} // Set the desired width in pixels
          value={text ? text : "Undefined"}
          onChange={(value) => {
            changeValue(row, "quene", value);
            updateValue({ quene: value }, row._id);
          }}
        >
          <Option value={"progress"}>Progress</Option>
          <Option value={"complete"}>Complete</Option>
          <Option value={"failed"}>Failed</Option>
        </Select>
      ),
    },

    {
      title: "Title",
      dataIndex: "meta_data",
      key: "meta_data",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="Title"
          onChange={(e) => {
            changeValue(row, "meta_data", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ meta_data: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },

    {
      title: "Display",
      dataIndex: "display",
      key: "display",
      width: 30,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="Display"
          style={{ width: 100 }} // Set the desired width in pixels
          value={text ? text : "private"}
          onChange={(value) => {
            changeValue(row, "display", value);
            updateValue({ display: value }, row._id);
          }}
        >
          <Option value={"private"}>Private</Option>
          <Option value={"public"}>Public</Option>
        </Select>
      ),
    },
    {
      title: "Favs",
      dataIndex: "favs",
      key: "favs",
      width: 30,
      render: (text, row) => (
        <InputNumber
          value={text}
          placeholder="favs"
          onChange={(e) => {
            changeValue(row, "favs", e);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ favs: text }, row._id);
            }
          }}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Likes",
      dataIndex: "likes",
      key: "likes",
      width: 30,
      render: (text, row) => (
        <InputNumber
          value={text}
          placeholder="likes"
          onChange={(e) => {
            changeValue(row, "likes", e);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ likes: text }, row._id);
            }
          }}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <div className="table-top-nav">
        <span>
          <Input
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            placeholder="Username "
            style={{ width: "120px", marginLeft: "10px" }}
            allowClear
          />
          <Button
            onClick={() => {
              setwatchVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            size="sm"
          >
            <FaSearch />
          </Button>
        </span>
        <Input
          value={search2}
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }
            setwatchVideo();
            setPageNoS(1);
            setSearch2(e.target.value);
          }}
          placeholder="Search"
          style={{ width: "200px", marginLeft: "10px", borderRadius: "20px" }}
          allowClear
        />
        <span>
          <Input
            value={count}
            onChange={(e) => {
              setCount(e.target.value);
            }}
            placeholder="Tag"
            style={{ width: "120px", marginLeft: "10px" }}
            allowClear
          />
          <Button
            onClick={() => {
              setwatchVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            size="sm"
          >
            <FaSearch />
          </Button>
        </span>
        <InputNumber
          value={rank}
          onChange={(e) => {
            setRank(e === -1 ? "" : e);
            setwatchVideo();
            setPageNo(1);
            setCallAPI(callAPI + 1);
          }}
          min={-1}
          max={10}
          placeholder="Rank "
        />
        <Select
          placeholder="Category"
          onChange={(v) => {
            setDisplay(v);
            setwatchVideo();
            setPageNo(1);
            setCallAPI(callAPI + 1);
          }}
          value={display}
          style={{ width: "120px", marginLeft: "10px" }}
        >
          <Option disabeled value="">
            Display
          </Option>
          <Option value="public">Public</Option>
          <Option value="private">Private</Option>
        </Select>
        <Select
          placeholder="Category"
          onChange={(v) => {
            setQuene(v);
            setwatchVideo();
            setPageNo(1);
            setCallAPI(callAPI + 1);
          }}
          value={quene}
          style={{ width: "120px", marginLeft: "10px" }}
        >
          <Option disabeled value="">
            Quene
          </Option>
          <Option value="complete">Complete</Option>
          <Option value="progress">Progress</Option>
          <Option value="failed">Failed</Option>
        </Select>
      </div>

      <div style={{ width: "100%", overflowX: "auto" }}>
        {loadeing ? <Spin /> : null}
        {watchVideo ? (
          isPages ? (
            <Table
              pagination={{
                pageSize: 15,
                current: pageNo,
                total: totalPages * 15,
                onChange: (v) => {
                  setwatchVideo();
                  setPageNo(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={watchVideo}
            />
          ) : (
            <Table
              pagination={{
                pageSize: 20,
                current: pageNoS,
                total: 20 * 20,
                onChange: (v) => {
                  setwatchVideo();
                  setPageNoS(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={watchVideo}
            />
          )
        ) : null}

        <Modal
          visible={editVideo !== undefined}
          onCancel={() => {
            setEditVideo();
            setClip();
          }}
          width={600}
          className="no-buttons-modal "
        >
          {editVideo ? (
            <div>
              <div>
                <b>{editVideo.query}</b>
              </div>

              <div className="video-container radius-video">
                <ReactPlayer controls url={editVideo.file_url} playing />
              </div>
              <Collapse defaultActiveKey={""} accordion>
                <Collapse.Panel header="Clip" key="clip">
                  {clip ? (
                    <div>
                      <div className="video-container radius-video">
                        <ReactPlayer controls url={clip.file_ur} playing />
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            setClip();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            updateValue(
                              { file_url: clip?.file_ur },
                              editVideo?._id
                            );
                            setClip();
                            setEditVideo({
                              ...clip,
                              file_url: clip.file_ur,
                              isCliped: true,
                            });
                          }}
                          type="primary"
                        >
                          Save Clip
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Slider
                        tooltip={{
                          placement: "bottom",
                          formatter,
                        }}
                        range
                        value={[startT, endT]}
                        defaultValue={[startT, endT]}
                        onChange={(v) => {
                          setStartT(v[0]);
                          setEndT(v[1]);
                        }}
                      />
                      <Button type="primary" onClick={() => saveClip()}>
                        Clip
                      </Button>
                    </div>
                  )}
                </Collapse.Panel>
              </Collapse>
              <div>
                {" "}
                <Button
                  className="custom-button"
                  style={{ marginRight: "10px" }}
                  onClick={() => copyToClipboard(editVideo)}
                >
                  Share
                </Button>
              </div>
            </div>
          ) : null}
        </Modal>
      </div>
      <Modal
        visible={sceneData !== undefined}
        onCancel={() => {
          setSceneData();
          setCaptionS();
          setCaptionAct();
        }}
        className=" no-buttons-modal top-10-modal"
        width={1000}
      >
        {sceneData ? (
          <div>
            <Row>
              <Col span={4}></Col>
              <Col span={16}>
                <div className="video-container radius-video">
                  <ReactPlayer controls url={sceneData.file_url} playing />
                </div>
              </Col>
            </Row>
            <Row>
              {captionAct ? (
                <Col span={captionAct ? 10 : 12}>
                  <div className="scene-main-bx-ddata">
                    {sceneData.newClip ? (
                      <div>
                        <div className="video-container radius-video">
                          <ReactPlayer controls url={sceneData.newClip} />
                        </div>
                        {editLoading ? (
                          <Spin />
                        ) : (
                          <div>
                            <BButton
                              onClick={() => {
                                setEditLoading(true);
                                let seg = captionAct.segments;
                                seg[0] = sceneData.newClip;
                                updateFolder(
                                  { segments: seg },
                                  captionAct._id,
                                  "row-update"
                                );
                                setCaptionAct({ ...captionAct, segments: seg });
                              }}
                              type="primary"
                            >
                              Save
                            </BButton>
                            <BButton
                              onClick={() => {
                                setSceneData({
                                  ...sceneData,
                                  newClip: undefined,
                                });
                              }}
                            >
                              Cancel
                            </BButton>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MdClose
                            onClick={() => setCaptionAct()}
                            className=" hover"
                          />
                        </div>
                        <div className="video-container radius-video">
                          <ReactPlayer controls url={captionAct.segments} />
                        </div>
                        {editLoading ? (
                          <Spin />
                        ) : (
                          <div>
                            <Button
                              style={{ margin: "0px 10px" }}
                              type="primary"
                              onClick={() => {
                                setEditLoading(true);
                                saveSlow(captionAct);
                              }}
                            >
                              Slow
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                setEditLoading(true);
                                saveSpeed(captionAct);
                              }}
                            >
                              Speed
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              ) : null}
              {captionAct === undefined &&
                sceneData.scene_data.map((m, mi) => {
                  return (
                    <Col key={mi} span={captionS ? 10 : 12}>
                      <div className="scene-main-bx-ddata">
                        <div className="video-container radius-video">
                          <ReactPlayer controls url={m.video_url} />
                        </div>
                        <h6>
                          <Tag
                            onClick={() => console.log(editFolder)}
                            color="blue"
                          >
                            {m.theme}
                          </Tag>
                          {sceneData.activeId === m._id ? null : (
                            <Button
                              size="sm"
                              onClick={() => getFolder(m.video_url, m._id)}
                            >
                              Edit
                            </Button>
                          )}
                          {sceneData.activeId === m._id ? (
                            <Input.TextArea
                              value={m.captions}
                              placeholder="Title"
                              onChange={(e) => {
                                changeValue2(
                                  sceneData,
                                  "scene_data",
                                  "captions",
                                  mi,
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                updateFolder(
                                  { caption: m.captions },
                                  editFolder[0]._id
                                );
                              }}
                              autoSize={{ minRows: 2, maxRows: 8 }}
                              className="hidden-textarea"
                              style={{
                                minWidth: "150px",
                                border: "1px solid black",
                              }}
                            />
                          ) : (
                            m.captions
                          )}
                        </h6>
                        <div>{m.description}</div>
                        {editLoading ? (
                          <Spin />
                        ) : (
                          <div>
                            <Button
                              onClick={() => getSearch(m)}
                              style={{ margin: "10px" }}
                            >
                              <FaSearch />
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}
              <Col span={captionS ? 14 : 12}>
                <CaptionsSearch
                  getSearch={getSearch}
                  captionS={captionS}
                  sceneData={sceneData}
                  setCaptionS={setCaptionS}
                  setCaptionAct={setCaptionAct}
                />
              </Col>
            </Row>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Theme</h5>

        <div className="bx-form-brand">
          <h6 onClick={() => console.log(dDelete)}>
            Are you sure you want to delete?
          </h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteVideo(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
