import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Popover,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import AddFont from "./components/AddFile";
import SearchContext from "../../Context/Search/SearchContext";
import { v4 as uuidv4 } from "uuid";
const { Option } = Select;

export default function Attatchment() {
  const { allEmp, user } = useContext(SearchContext);

  const [Emp, setEmp] = useState("");
  const [callAPI, setCallAPI] = useState(1);
  const [fileCommand, setfileCommand] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [Cname, setCname] = useState("");
  const [cStatus, setCstatus] = useState("");
  const [extra, setextra] = useState();
  const [extraId, setextraId] = useState();
  const [isChangeG, setIsChangeG] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [dAdd, setdAdd] = useState();
  const [fileType, setfileType] = useState("overlay_image");
  const [displayAdd, setDisplayAdd] = useState(false);

  useEffect(() => {
    if (fileCommand === undefined) {
      setLoadeing(true);
      getfileCommand();
    }
  }, [pageNo, Cname, cStatus, fileCommand, Emp]);

  const getfileCommand = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/file_command?page=${pageNo}&limit=15&sort_by=${-1}&search=${Cname}&status=${cStatus}&file_type=${fileType}&created_by=${Emp}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setfileCommand(data.fileCommandList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };
  const SavefileCommand = (row, all) => {
    row.created_by = user.user_id;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/file_command`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          const list = [...all];
          list.unshift(data.data);
          message.success("added");
          setfileCommand(list);
        } else {
          message.error("something we wrong");
          setfileCommand(all);
        }
      });
  };

  const PreviewImage = () => {
    const obj = { query: dAdd.create };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/create/preview/image`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          removeBackground(data.data._id);
        } else {
        }
      });
  };

  const removeBackground = (id) => {
    const obj = { image_id: id };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `${process.env.REACT_APP_API}/create/image/remove_background/internal`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          setdAdd({ ...dAdd, file_url: data.data.data.file_url });
        } else {
        }
      });
  };
  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);
      closeFile();

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/file_command/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = fileCommand.filter((t) => t._id !== id);
    setfileCommand(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/file_command/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = fileCommand.map((m) => (m._id === row._id ? row : m));
    setfileCommand(allRow);
  };

  const displayFile = (row) => {
    const g = row.extra
      ? row.extra
      : { overlay_font_position: "", overlay_font_size: "" };

    setextraId(row._id);
    setextra(g);
  };

  const closeFile = () => {
    setextraId();
    setextra();

    setIsChangeG(false);
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 110,

      render: (text, row) => (
        <span>
          <Form.Select
            value={text}
            className="dropdown-status-comn"
            aria-label="Default select example"
            onChange={(v) => {
              changeField("status", v.target.value, row);
              saveChanges({ status: v.target.value }, row._id, true);
            }}
          >
            <option value="complete">Complete</option>
            <option value="pending">Pending</option>
            <option value="verified">verified</option>
            <option value="indexed">indexed</option>
            <option value="test">test</option>
          </Form.Select>
        </span>
      ),
    },
    {
      title: "description",
      dataIndex: "description",
      key: "description",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="description"
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Value",
      dataIndex: "core_word",
      key: "core_word",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="core_word"
          onChange={(e) => {
            changeField("core_word", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ core_word: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "File Type",
      dataIndex: "file_type",
      key: "file_type",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="File Type"
          onChange={(e) => {
            changeField("file_type", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ file_type: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "File url",
      dataIndex: "file_url",
      key: "file_url",
      width: 90,
      render: (text, row) => (
        <div className="img-bx-fc-attc">
          {text && (text.includes(".png") || text.includes(".jpg")) ? (
            <Popover
              content={<img className="img-bx-poup" src={text} alt="" />}
              trigger="hover"
            >
              <img src={text} alt="" />
            </Popover>
          ) : (
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              value={text}
              placeholder="File url"
              onChange={(e) => {
                changeField("file_url", e.target.value, row);
                setIsChange(true);
              }}
              onBlur={() => saveChanges({ file_url: text }, row._id)}
              className="hidden-textarea"
            />
          )}
        </div>
      ),
    },

    {
      title: "Usage",
      dataIndex: "usage",
      key: "usage",
      width: 150,
      render: (text, row) => (
        <InputNumber
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={text}
          placeholder="usage"
          onChange={(e) => {
            changeField("usage", e, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ usage: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "Extra",
      dataIndex: "extra",
      key: "extra",
      width: 40,

      render: (text, row) => (
        <span>
          <BButton
            onClick={() => {
              displayFile(row);
            }}
            type="primary"
            size="small"
          >
            extra
          </BButton>
        </span>
      ),
    },

    {
      title: "",
      dataIndex: "",
      key: "04",
      render: (text, row) => (
        <AddFont
          row={row}
          fileCommand={fileCommand}
          setfileCommand={setfileCommand}
        />
      ),
    },
    {
      title: "Emp",
      dataIndex: "created_by",
      key: "created_by",

      render: (text, row) => <b style={{ fontSize: "11px" }}>{text}</b>,
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      {loadeing ? <Spin /> : null}
      <div className="connap-filter-c space-between">
        <BButton
          type="primary"
          onClick={() => {
            if (dAdd === undefined) {
              setdAdd({
                file_url: "",
                file_type: "overlay_image",
                file_key: uuidv4(),
                description: "",
              });
            }
            setDisplayAdd(true);
          }}
        >
          <BsPlus />
          Add
        </BButton>
        <div>
          <Input
            value={Cname}
            onChange={(e) => {
              setCname(e.target.value);
              setfileCommand();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Search Description"
          />
          <Select
            placeholder="Category"
            onChange={(v) => {
              setCstatus(v);
              setfileCommand();
              setPageNo(1);
            }}
            value={cStatus}
          >
            <Option disabeled value="">
              Status
            </Option>
            <Option value="pending">pending</Option>
            <Option value="complete">complete</Option>
            <Option value="verified">verified</Option>
            <Option value="indexed">indexed</Option>
            <Option value="test">test</Option>
          </Select>
          <Select
            placeholder="File Type"
            onChange={(v) => {
              setfileType(v);
              setfileCommand();
              setPageNo(1);
            }}
            style={{ width: "180px" }}
            value={fileType}
          >
            <Option disabeled value="">
              File Type
            </Option>
            <Option value="font_style">font style</Option>
            <Option value="color_grade">color grade</Option>
            <Option value="overlay_image">overlay image</Option>
            <Option value="overlay_design_image">overlay design image</Option>
          </Select>
          <Select
            placeholder=" P Code"
            onChange={(v) => {
              setEmp(v);
              setPageNo(1);
              setfileCommand();
            }}
            value={Emp}
            style={{ width: "120px", marginLeft: "10px" }}
          >
            <Option disabeled value="">
              P Code
            </Option>
            {allEmp.map((em, emi) => {
              return (
                <Option key={emi} value={em}>
                  {em}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
      {fileCommand ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setfileCommand();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={fileCommand}
        />
      ) : null}
      <Modal
        visible={displayAdd}
        onCancel={() => setDisplayAdd(false)}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Create:</b>
                  </td>
                  <td style={{ display: "flex" }}>
                    <Input
                      className="bx-fld-10t"
                      placeholder="file_type"
                      value={dAdd.create}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, create: e.target.value })
                      }
                    />
                    {dAdd.create && dAdd.create.length ? (
                      <BButton
                        size="small"
                        type="primary"
                        onClick={() => PreviewImage()}
                      >
                        Generate
                      </BButton>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> File Url:</b>
                  </td>
                  <td>
                    {dAdd.file_url ? (
                      <img
                        src={dAdd.file_url}
                        alt=""
                        className="imag-moda-add"
                      />
                    ) : null}
                    <Input
                      className="bx-fld-10t"
                      placeholder="file_url"
                      value={dAdd.file_url}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, file_url: e.target.value })
                      }
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> File Type:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="file_type"
                      value={dAdd.file_type}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, file_type: e.target.value })
                      }
                    />
                  </td>
                </tr> */}

                {/* <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Key:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="file_key"
                      value={dAdd.file_key}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, file_key: e.target.value })
                      }
                    />
                  </td>
                </tr> */}
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Description:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="description"
                      value={dAdd.description}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, description: e.target.value })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-end">
              <Button
                onClick={() => {
                  SavefileCommand(dAdd, fileCommand);
                  setDisplayAdd(false);
                  setfileCommand();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Attatchment</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        width={520}
        visible={extra !== undefined}
        onCancel={() => {
          closeFile();
        }}
        className="no-f-no-h brand-modal top-20-x"
      >
        <h5 className="create-btn-h">Files</h5>

        <div className="bx-form-brand">
          {extra ? (
            <table className="table-bucket-aws table-b-guide-cm">
              <tbody>
                <tr>
                  <td>
                    <b>Position:</b>
                  </td>
                  <td>
                    <Input
                      onChange={(e) => {
                        setextra({
                          ...extra,
                          overlay_font_position: e.target.value,
                        });
                        setIsChangeG(true);
                      }}
                      value={extra.overlay_font_position}
                      placeholder="Position"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Size:</b>
                  </td>
                  <td>
                    <Input
                      onChange={(e) => {
                        setextra({
                          ...extra,
                          overlay_font_size: e.target.value,
                        });
                        setIsChangeG(true);
                      }}
                      value={extra.overlay_font_size}
                      placeholder="Size"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}

          <div>
            {isChangeG ? (
              <Button
                size="sm"
                onClick={() => saveChanges({ extra: extra }, extraId, true)}
              >
                Update
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
