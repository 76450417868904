import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Popconfirm,
  Spin,
  Input,
} from "antd";
import { Button as ButtonB } from "react-bootstrap";

import { CloseOutlined, TagFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TagsGroup from "../../components/TagsGroup";
import DisplayTranscript from "./DisplayTranscript";

const { Option } = Select;
const { TextArea } = Input;
export default function VideosImages() {
  const navigate = useNavigate();

  const { videos, setVideos, user } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [loading, setLoading] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isChange, setIsChange] = useState(false);
  const [dDelete, displayDelete] = useState();

  useEffect(() => {
    if (videos.activeVideo === undefined) {
      getVideoTask(window.location.pathname.slice(9));
    } else {
      getVideoImages(videos.activeVideo, window.location.pathname.slice(9));
    }
  }, [pageNo]);

  const getVideoTask = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/search_video/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          getVideoImages(data.video, id);
        }
      });
  };

  const getVideoImages = (activeData, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/search_video?page=${pageNo}&limit=40&video_type=concept_image&headclip_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.dataList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setVideos({
            ...videos,
            activeVideo: activeData,
            images: data.dataList,
          });
          setTotalPages(data.totalPages);
        }
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = videos.images.map((m) => (m._id === row._id ? row : m));
    setVideos({
      ...videos,
      images: allRow,
    });
  };

  const updateDesc = (val, con) => {
    con.videos = val;
    const images = videos.images.map((l) => (l._id === con._id ? con : l));
    setVideos({
      ...videos,
      images,
    });
  };

  const saveChanges = (obj, id, save, complete) => {
    if (isChange || save) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(obj),
      };
      fetch(`${process.env.REACT_APP_API}/search_video/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setIsChange(false);
          if (data && data.isPatched) {
            let allRow = undefined;
            if (complete) {
              if (videos.dataList) {
                const row = videos.activeVideo;
                row.status = "indexed";
                allRow = videos.dataList.map((m) =>
                  m._id === row._id ? row : m
                );
              }

              setVideos({
                ...videos,
                dataList: allRow,
                activeVideo: undefined,
                images: undefined,
              });
              navigate(`/crawler`);
            }
            message.success("Added");
          } else {
            message.error("Changes Not Saved");
          }
        });
    }
  };

  const deleteVideo = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/search_video/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isDeleted) {
          const deleted = videos.images.filter((f) => f._id !== id);
          setVideos({
            ...videos,
            images: deleted,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  return (
    <div>
      <Row>
        <Col lg={{ span: 6 }}>
          {videos.activeVideo ? (
            <DisplayTranscript video={videos.activeVideo} />
          ) : null}
        </Col>
        <Col lg={{ span: 18 }}>
          <div>
            {videos.images === undefined ? <Spin /> : null}
            {videos.activeVideo ? (
              <Row>
                <Col md={{ span: 24 }}>
                  <div className="sel-s-c">
                    <h5>
                      {videos.activeVideo.video_name}{" "}
                      <CloseOutlined
                        className="eye-icn-c close-icn"
                        onClick={() => {
                          setVideos({
                            ...videos,
                            activeVideo: undefined,
                            images: undefined,
                          });
                          navigate(`/crawler`);
                        }}
                      />
                    </h5>
                    {videos.activeVideo.status === "pending" ? (
                      loading ? (
                        <Spin />
                      ) : (
                        <Popconfirm
                          title={
                            <div>
                              <h6>Complete all Images?</h6>
                              <h6>Complete all pages?</h6>
                            </div>
                          }
                          onConfirm={() => {
                            setLoading(true);
                            saveChanges(
                              { status: "indexed" },
                              videos.activeVideo._id,
                              true,
                              "indexed"
                            );
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary">
                            Press Button to Complete Video
                          </Button>
                        </Popconfirm>
                      )
                    ) : null}
                  </div>

                  <div>
                    {videos.images && videos.images.length === 0 ? (
                      <h1>No Images Can Press Done</h1>
                    ) : null}
                    <Row>
                      {videos.images &&
                        videos.images.map((lb, lbi) => {
                          return (
                            <Col
                              key={lbi}
                              md={{ span: 12 }}
                              sm={{ span: 24 }}
                              lg={{ span: 8 }}
                            >
                              <div className="lbl-c-col">
                                <div className="delete-icn-c">
                                  <span
                                    onClick={() => displayDelete(lb)}
                                    className="close-i-fd"
                                  >
                                    ❌
                                  </span>
                                </div>
                                <Card>
                                  <img
                                    onClick={() => setViewFile(lb.file_url)}
                                    className="lbl-mn-img"
                                    src={lb.file_url}
                                    alt="img"
                                  />
                                  <div className="bx-2-date-tm">
                                    <b>
                                      Start:
                                      <i>
                                        <Input
                                          value={lb.startTime}
                                          onChange={(e) => {
                                            changeField(
                                              "startTime",
                                              e.target.value,
                                              lb
                                            );
                                            setIsChange(true);
                                          }}
                                          className="hidden-table-input time-input-img"
                                          onBlur={() =>
                                            saveChanges(
                                              { startTime: lb.startTime },
                                              lb._id
                                            )
                                          }
                                        />
                                      </i>
                                    </b>
                                    <b>
                                      End:
                                      <i>
                                        <Input
                                          value={lb.endTime}
                                          onChange={(e) => {
                                            changeField(
                                              "endTime",
                                              e.target.value,
                                              lb
                                            );
                                            setIsChange(true);
                                          }}
                                          className="hidden-table-input time-input-img"
                                          onBlur={() =>
                                            saveChanges(
                                              { endTime: lb.endTime },
                                              lb._id
                                            )
                                          }
                                        />
                                      </i>
                                    </b>
                                  </div>
                                  <div className="lbl-s-e-c">
                                    <TagsGroup
                                      tags={lb.tags}
                                      onTagChange={(v) => {
                                        saveChanges({ tags: v }, lb._id, true);
                                        changeField("tags", v, lb);
                                      }}
                                    />
                                  </div>
                                  <div className="lbl-s-e-c41">
                                    {lb.p_description &&
                                    lb.p_description.length ? (
                                      <div>
                                        <b>Description: </b>
                                        <TextArea
                                          autoSize={{ maxRows: 3, minRows: 1 }}
                                          value={lb.p_description}
                                          placeholder="Description"
                                          onChange={(e) => {
                                            changeField(
                                              "p_description",
                                              e.target.value,
                                              lb
                                            );
                                            setIsChange(true);
                                          }}
                                          className=" "
                                          onBlur={() =>
                                            saveChanges(
                                              {
                                                p_description: lb.p_description,
                                              },
                                              lb._id
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <Button
                                        onClick={() => {
                                          changeField("p_description", " ", lb);
                                        }}
                                        size="small"
                                        type="primary"
                                      >
                                        Description
                                      </Button>
                                    )}

                                    {lb.headline && lb.headline.length ? (
                                      <div>
                                        <b>Headline: </b>
                                        <TextArea
                                          autoSize={{ maxRows: 3, minRows: 1 }}
                                          value={lb.headline}
                                          placeholder="Description"
                                          onChange={(e) => {
                                            changeField(
                                              "headline",
                                              e.target.value,
                                              lb
                                            );
                                            setIsChange(true);
                                          }}
                                          className=" "
                                          onBlur={() =>
                                            saveChanges(
                                              { headline: lb.headline },
                                              lb._id
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <Button
                                        onClick={() => {
                                          changeField("headline", " ", lb);
                                        }}
                                        size="small"
                                        type="primary"
                                      >
                                        Headline
                                      </Button>
                                    )}
                                  </div>
                                </Card>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                    <div className="page-vid-c">
                      {totalPages ? (
                        <Pagination
                          pageSize={40}
                          current={pageNo}
                          size="small"
                          total={totalPages * 40}
                          onChange={(v) => {
                            setPageNo(v);
                            setVideos({
                              ...videos,
                              images: undefined,
                            });
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </div>
        </Col>
      </Row>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          <img src={viewFile} alt="" />;
        </div>
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Video</h5>

        {dDelete ? (
          <div className="bx-form-brand">
            <h6>Are you sure you want to delete?</h6>

            <div className="bx-2-date-tm">
              <b>
                Start:
                <i>{dDelete.startTime}</i>
              </b>
              <b>
                End:
                <i>{dDelete.endTime}</i>
              </b>
            </div>
            <div className="delet-btns-h">
              <ButtonB
                size="sm"
                onClick={() => displayDelete()}
                variant="light"
              >
                Cancel
              </ButtonB>{" "}
              <ButtonB
                onClick={() => {
                  deleteVideo(dDelete._id);
                  displayDelete();
                }}
                variant="danger"
                size="sm"
              >
                Yes
              </ButtonB>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
