import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
} from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
const { Option } = Select;
export default function Command() {
  const [callAPI, setCallAPI] = useState(1);
  const [Commands, setCommands] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [load, setLoad] = useState(false);
  const [dAdd, setdAdd] = useState();
  const [sv, setSv] = useState();
  const [svId, setSvId] = useState();
  const [fts_filecommand, setFile] = useState();
  const [fts_filecommandId, setFileId] = useState();
  const [fts_commandvalue, setValue] = useState();
  const [fts_commandvalueId, setValueId] = useState();
  const [dFIle, setdisplayFile] = useState();
  const [Cname, setCname] = useState("");

  const [cCategroy, setCcategory] = useState("");
  const [cStatus, setCstatus] = useState("");

  useEffect(() => {
    if (Commands === undefined) {
      setLoadeing(true);
      getCommands();
    }
  }, [pageNo, Cname, cCategroy, cStatus]);

  const getCommands = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/creation_command?page=${pageNo}&limit=15&sort_by=${-1}&search=${Cname}&category=${cCategroy}&status=${cStatus}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setCommands(data.commandList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };
  const SaveCommands = (row) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/creation_command`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        if (data.isSuccess) {
          const list = [...Commands];
          list.unshift(data.data);
          setCommands(list);
        }
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);
      closeFile();
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(
        `${process.env.REACT_APP_API}/creation_command/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            const allRow = Commands.map((m) =>
              m._id === data.commandData._id ? data.commandData : m
            );
            setCommands(allRow);
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = Commands.filter((t) => t._id !== id);
    setCommands(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/creation_command/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = Commands.map((m) => (m._id === row._id ? row : m));
    setCommands(allRow);
  };

  const changeFile = (row, key, val) => {
    row[key] = val;
    const all = fts_filecommand.map((m) => (m.id === row.id ? row : m));
    setFile(all);
    setIsChangeG(true);
  };

  const deleteFile = (id) => {
    const all = fts_filecommand.filter((f) => f.id !== id);
    setFile(all);
    setIsChangeG(true);
  };

  const displayFile = (row) => {
    const g = row.fts_filecommand;
    for (let i = 0; i < g.length; i++) {
      g[i].id = i;
    }
    setFileId(row._id);
    setFile(g);
  };
  const closeFile = () => {
    setFile();
    setFileId();
    setValueId();
    setValue();
    setSv();
    setSvId();

    setIsChangeG(false);
  };

  const displayValue = (row) => {
    const g = row.fts_commandvalue;
    for (let i = 0; i < g.length; i++) {
      g[i].id = i;
    }
    setValueId(row._id);
    setValue(g);
  };

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = fts_commandvalue.map((m) => (m.id === row.id ? row : m));
    setValue(all);

    setIsChangeG(true);
  };

  const deleteValue = (id) => {
    const all = fts_commandvalue.filter((f) => f.id !== id);
    setValue(all);

    setIsChangeG(true);
  };

  const displaySv = (row) => {
    const g = row.select_value ? row.select_value : [];
    for (let i = 0; i < g.length; i++) {
      g[i].id = i;
    }
    setSvId(row._id);
    setSv(g);
  };

  const changeSV = (row, key, val) => {
    row[key] = val;
    const all = sv.map((m) => (m.id === row.id ? row : m));
    setSv(all);
    setIsChangeG(true);
  };

  const deleteSV = (id) => {
    const all = sv.filter((f) => f.id !== id);
    setSv(all);
    setIsChangeG(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 110,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Name"
          onChange={(e) => {
            changeField("name", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ name: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "description",
      dataIndex: "description",
      key: "description",

      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="description"
          autoSize={{
            minRows: 1,
            maxRows: 3,
          }}
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 110,

      render: (text, row) => (
        <Input
          value={text}
          placeholder="category"
          onChange={(e) => {
            changeField("category", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ category: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "Command",
      dataIndex: "command",
      key: "command",
      width: 300,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="command"
          autoSize={{
            minRows: 1,
            maxRows: 3,
          }}
          onChange={(e) => {
            changeField("command", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ command: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 110,

      render: (text, row) => (
        <span>
          <Form.Select
            value={text}
            className="dropdown-status-comn"
            aria-label="Default select example"
            onChange={(v) => {
              changeField("status", v.target.value, row);
              saveChanges({ status: v.target.value }, row._id, true);
            }}
          >
            <option value="complete">Complete</option>
            <option value="pending">Pending</option>
            <option value="verified">verified</option>
            <option value="indexed">indexed</option>
            <option value="test">test</option>
          </Form.Select>
        </span>
      ),
    },
    {
      title: "SV",
      dataIndex: "select_value",
      key: "select_value",
      width: 70,

      render: (text, row) => (
        <span>
          <BButton
            onClick={() => {
              displaySv(row);
            }}
            type="primary"
            size="small"
          >
            SV
          </BButton>
        </span>
      ),
    },

    {
      title: "F",
      dataIndex: "fts_filecommand",
      key: "fts_filecommand",
      width: 40,

      render: (text, row) => (
        <span>
          <BButton
            onClick={() => {
              displayFile(row);
            }}
            type="primary"
            size="small"
          >
            F
          </BButton>
        </span>
      ),
    },

    {
      title: "V",
      dataIndex: "fts_commandvalue",
      key: "fts_commandvalue",
      width: 40,

      render: (text, row) => (
        <span>
          <BButton
            onClick={() => {
              displayValue(row);
            }}
            type="primary"
            size="small"
          >
            V
          </BButton>
        </span>
      ),
    },

    {
      title: "",
      dataIndex: "",
      key: "04",
      render: (text, row) =>
        load === row._id ? (
          <Spin />
        ) : (
          <BButton
            onClick={() => {
              setLoad(row._id);
              SaveCommands({
                name: row.name,
                description: row.description,
                category: row.category,
                command: row.command,
              });
            }}
            type="primary"
            size="small"
          >
            Du
          </BButton>
        ),
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 40,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="table-top-nav">
        <Button size="sm" onClick={() => setdAdd({ name: "" })}>
          <BsPlus />
          Add
        </Button>
        <div className="connap-filter-c">
          <Input
            value={Cname}
            onChange={(e) => {
              setCname(e.target.value);
              setCommands();
              setPageNo(1);
            }}
            placeholder="Search Name"
          />

          <Select
            placeholder="Category"
            onChange={(v) => {
              setCstatus(v);
              setCommands();
              setPageNo(1);
            }}
            value={cStatus}
          >
            <Option disabeled value="">
              Status
            </Option>
            <Option value="pending">pending</Option>
            <Option value="complete">complete</Option>
            <Option value="verified">verified</Option>
            <Option value="indexed">indexed</Option>
            <Option value="test">test</Option>
          </Select>
          <Select
            placeholder="Category"
            onChange={(v) => {
              setCcategory(v);
              setCommands();
              setPageNo(1);
            }}
            value={cCategroy}
          >
            <Option disabeled value="">
              Category
            </Option>
            <Option value="curves">curves</Option>
            <Option value="color_grade">color_grade</Option>
            <Option value="video_text">video_text</Option>
            <Option value="video_speed">video_speed</Option>
            <Option value="after_effects">after_effects</Option>
            <Option value="color_balance">color_balance</Option>
            <Option value="style">style</Option>
            <Option value="watermark">watermark</Option>
            <Option value="output">output</Option>
            <Option value="overlay">overlay</Option>
            <Option value="color_grade_label">color_grade_label</Option>
          </Select>
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {Commands ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setCommands();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={Commands}
        />
      ) : null}
      <Modal
        visible={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Name:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="Name"
                      value={dAdd.name}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, name: e.target.value })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-end">
              <Button
                onClick={() => {
                  SaveCommands(dAdd);
                  setdAdd();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dFIle !== undefined}
        onCancel={() => setdisplayFile()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">FIle URL</h5>

        <div className="bx-form-brand">
          <h6>{dFIle}</h6>
        </div>
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Invitation</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        width={520}
        visible={fts_filecommand !== undefined}
        onCancel={() => {
          closeFile();
        }}
        className="no-f-no-h brand-modal top-20-x"
      >
        <h5 className="create-btn-h">Files</h5>

        <div className="bx-form-brand">
          {fts_filecommand &&
            fts_filecommand.map((b, bi) => {
              return (
                <table key={bi} className="table-bucket-aws table-b-guide-cm">
                  <tbody>
                    <tr>
                      <td>
                        <b>Default Value:</b>
                      </td>
                      <td>
                        <div className="close-butcke-bx">
                          <MdClose
                            onClick={() => deleteFile(b.id)}
                            className="close-bx-d-in hover"
                          />
                        </div>
                        <Input
                          onChange={(e) =>
                            changeFile(b, "default_value", e.target.value)
                          }
                          value={b.default_value}
                          placeholder="Title"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>File Type:</b>
                      </td>
                      <td>
                        <Input
                          onChange={(e) =>
                            changeFile(b, "file_type", e.target.value)
                          }
                          value={b.file_type}
                          placeholder="Title"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          <div className="flex-end">
            <AiOutlinePlus
              onClick={() =>
                setFile([
                  ...fts_filecommand,
                  {
                    default_value: "",
                    file_type: "",
                    id: fts_filecommand.length
                      ? fts_filecommand[fts_filecommand.length - 1].id + 1
                      : 0,
                  },
                ])
              }
              className="icn-side-c"
            />
          </div>
          <div>
            {isChangeG ? (
              <Button
                size="sm"
                onClick={() =>
                  saveChanges(
                    { fts_filecommand: fts_filecommand },
                    fts_filecommandId,
                    true
                  )
                }
              >
                Update
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
      <Modal
        width={520}
        visible={fts_commandvalue !== undefined}
        onCancel={() => {
          closeFile();
        }}
        className="no-f-no-h brand-modal top-20-x"
      >
        <h5 className="create-btn-h">Value</h5>

        <div className="bx-form-brand">
          {fts_commandvalue &&
            fts_commandvalue.map((b, bi) => {
              return (
                <table key={bi} className="table-bucket-aws table-b-guide-cm">
                  <tbody>
                    <tr>
                      <td>
                        <b>Default Value:</b>
                      </td>
                      <td>
                        <div className="close-butcke-bx">
                          <MdClose
                            onClick={() => deleteValue(b.id)}
                            className="close-bx-d-in hover"
                          />
                        </div>
                        <Input
                          onChange={(e) =>
                            changeValue(b, "default_value", e.target.value)
                          }
                          value={b.default_value}
                          placeholder="Title"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Value Type:</b>
                      </td>
                      <td>
                        <Input
                          onChange={(e) =>
                            changeValue(b, "value_type", e.target.value)
                          }
                          value={b.value_type}
                          placeholder="Title"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          <div className="flex-end">
            <AiOutlinePlus
              onClick={() =>
                setValue([
                  ...fts_commandvalue,
                  {
                    default_value: "",
                    value_type: "",
                    id: fts_commandvalue.length
                      ? fts_commandvalue[fts_commandvalue.length - 1].id + 1
                      : 0,
                  },
                ])
              }
              className="icn-side-c"
            />
          </div>
          <div>
            {isChangeG ? (
              <Button
                size="sm"
                onClick={() =>
                  saveChanges(
                    { fts_commandvalue: fts_commandvalue },
                    fts_commandvalueId,
                    true
                  )
                }
              >
                Update
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
      <Modal
        width={520}
        visible={sv !== undefined}
        onCancel={() => {
          closeFile();
        }}
        className="no-f-no-h brand-modal top-20-x"
      >
        <h5 className="create-btn-h">Value</h5>

        <div className="bx-form-brand">
          {sv &&
            sv.map((b, bi) => {
              return (
                <table key={bi} className="table-bucket-aws table-b-guide-cm">
                  <tbody>
                    <tr>
                      <td>
                        <b>Select Type:</b>
                      </td>
                      <td>
                        <div className="close-butcke-bx">
                          <MdClose
                            onClick={() => deleteSV(b.id)}
                            className="close-bx-d-in hover"
                          />
                        </div>
                        <Input
                          onChange={(e) =>
                            changeSV(b, "select_type", e.target.value)
                          }
                          value={b.select_type}
                          placeholder="Title"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Description:</b>
                      </td>
                      <td>
                        <Input
                          onChange={(e) =>
                            changeSV(b, "description", e.target.value)
                          }
                          value={b.description}
                          placeholder="Title"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          <div className="flex-end">
            <AiOutlinePlus
              onClick={() =>
                setSv([
                  ...sv,
                  {
                    select_type: "",
                    description: "",
                    id: sv.length ? sv[sv.length - 1].id + 1 : 0,
                  },
                ])
              }
              className="icn-side-c"
            />
          </div>
          <div>
            {isChangeG ? (
              <Button
                size="sm"
                onClick={() => saveChanges({ select_value: sv }, svId, true)}
              >
                Update
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
