import { Button, Form, Input, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import SearchImages from "./component/SearchImages";

export default function Search() {
  const { search, setsearch } = useContext(SearchContext);
  const [callAPI, setCallAPI] = useState(1);
  const [loadeing, setLoadeing] = useState(false);

  useEffect(() => {
    console.log("1");
    if (search.images === undefined && callAPI !== 1) {
      console.log("2");

      setLoadeing(true);
      getSearch();
    }
  }, [callAPI]);

  const getSearch = () => {
    const obj = {
      query: search.value,
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(`https://int.display.video/api/search_video/full-text/new`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoadeing(false);
        if (data) {
          setsearch({ ...search, images: data });
        }
      });
  };

  return (
    <div className="main-bx-lbl">
      <div className="search-bx-src">
        <Form
          onFinish={() => {
            setsearch({
              ...search,
              images: undefined,
              totalPages: undefined,
              pageNo: 1,
            });
            setCallAPI(callAPI + 1);
          }}
        >
          <div className="input-bccrw">
            <Input
              value={search.value}
              className="sarch-bx-cb"
              placeholder="Search"
              onChange={(e) => {
                setsearch({ ...search, value: e.target.value });
              }}
            />
            <Button htmlType="submit" type="primary">
              Find
            </Button>
          </div>
        </Form>
      </div>
      {loadeing ? <Spin /> : null}

      <SearchImages />
    </div>
  );
}
