import React from "react";
import { Divider } from "antd";

export default function DividerElm({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}{" "}
      <Divider {...props}>{props.text}</Divider>
    </>
  );
}
