import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import DisplayInTable from "./components/DisplayInTable";
import "./url.css";
export default function URL() {
  const { url, setUrl } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);

  useEffect(() => {
    if (url.videos === undefined) {
      getVideoTask();
    }
  }, []);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/elink?page=${url.pageNo}&limit=15`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setUrl({
            ...url,
            videos: data.elinkList,
            totalPages: data.totalPages,
          });
        }
      });
  };
  return (
    <div>
      <DisplayInTable />
    </div>
  );
}
