import { Button, Modal } from "antd";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

export default function AddChannel() {
  const [display, setDisplay] = useState(false);
  const [newC, setNewC] = useState({
    business_name: "",
    category: [],
    creator_names: [],
    employee_count: 0,
    location: "",
    related_channels: [],
    subscriber_count: 0,
    total_views: 0,
    business_created: new Date(),
    most_recent_content: new Date(),
    oldest_content: new Date(),
  });

  const handleCancel = () => {
    setDisplay(false);
    setNewC({
      business_name: "",
      category: [],
      creator_names: [],
      employee_count: 0,
      location: "",
      related_channels: [],
      subscriber_count: 0,
      total_views: 0,
      business_created: new Date(),
      most_recent_content: new Date(),
      oldest_content: new Date(),
    });
  };
  const saveNewChannel = (e) => {
    e.preventDefault();
    console.log(newC);
    handleCancel();
    saveInDatabase();
  };

  const saveInDatabase = () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(newC),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(`https://int.display.video/api/channel`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        window.location.reload();
        console.log(data, "guide/test");
      });
  };

  return (
    <div>
      <Button onClick={() => setDisplay(true)} type="primary">
        <FaPlus />
      </Button>
      <Modal
        className="no-h-bo-b-modal"
        visible={display}
        onCancel={handleCancel}
      >
        <form onSubmit={saveNewChannel} className="add-channel-form">
          <div className="form-group">
            <label htmlFor="business_name">Business Name</label>
            <input
              onChange={({ target }) =>
                setNewC({ ...newC, business_name: target.value })
              }
              value={newC.business_name}
              type="text"
              className="form-control"
              id="business_name"
              placeholder="Business Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="location">location</label>
            <input
              onChange={({ target }) =>
                setNewC({ ...newC, location: target.value })
              }
              value={newC.location}
              type="text"
              className="form-control"
              id="location"
              placeholder="location"
            />
          </div>
          <div className="form-group">
            <label htmlFor="employee_count">Employee Count</label>
            <input
              onChange={({ target }) =>
                setNewC({ ...newC, employee_count: Number(target.value) })
              }
              value={newC.employee_count}
              type="Number"
              className="form-control"
              id="employee_count"
              placeholder="Employee Count"
            />
          </div>
          <div className="form-group">
            <label htmlFor="subscriber_count">Subscriber Count</label>
            <input
              onChange={({ target }) =>
                setNewC({ ...newC, subscriber_count: Number(target.value) })
              }
              value={newC.subscriber_count}
              type="Number"
              className="form-control"
              id="subscriber_count"
              placeholder="Subscriber Count"
            />
          </div>
          <div className="form-group">
            <label htmlFor="total_views">Total Views</label>
            <input
              onChange={({ target }) =>
                setNewC({ ...newC, total_views: Number(target.value) })
              }
              value={newC.total_views}
              type="Number"
              className="form-control"
              id="total_views"
              placeholder="Total Views"
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </Modal>
    </div>
  );
}
