import {
  Col,
  Pagination,
  Row,
  Form,
  AutoComplete,
  Input,
  Button,
  Checkbox,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Card, Container, ListGroup } from "react-bootstrap";
import { FaArrowLeft, FaTable } from "react-icons/fa";
import DisplayInTable from "./components/DisplayInTable";
import FileCards from "./components/FileCards";
import "./videofiles.css";
import Icons from "../../components/Icons";
import { BiCard } from "react-icons/bi";
import { StarOutlined, SearchOutlined } from "@ant-design/icons";
import SearchCard from "./components/SearchCard";
import SearchContext from "../../Context/Search/SearchContext";

const { Option } = Select;
export default function VideoFiles() {
  const { videoTasks, setVideoTask , setActive} = useContext(SearchContext);
  useEffect(() => {
    setActive(window.location.pathname);
  }, []);
  const [pageNo, setPageNo] = useState(1);
  const [videoType, setVideoType] = useState("");
  const [cleaned, setCleaned] = useState("");
  const [callApi, setCallApi] = useState(0);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (videoTasks.videos === undefined) {
      getVideoTask();
    }
  }, [pageNo, callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${pageNo}&limit=15&cleaned=${cleaned}&status=${status}&video_type=${videoType}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setVideoTask({
            ...videoTasks,
            videos: data.segmentList,
            totalPages: data.totalPages,
          });
        }
      });
  };

  return (
    <div>
      {" "}
      <div className="bg-dashboard">
        <Container>
          <div className="concept-main-c">
            <Row>
              <Col md={{ span: 24 }}>
                <div className="cards-top-drp">
                  <b className="add-ttl-vc">Your Videos</b>
                  <div>
                    <Checkbox
                      checked={cleaned}
                      onChange={(e) => {
                        setCleaned(e.target.checked);
                        setVideoTask({ ...videoTasks, videos: undefined });
                        setPageNo(1);
                        setCallApi(callApi + 1);
                      }}
                    >
                      Cleaned
                    </Checkbox>
                    <Select
                      value={status}
                      onChange={(e) => {
                        setStatus(e);
                        setVideoTask({ ...videoTasks, videos: undefined });
                        setPageNo(1);
                        setCallApi(callApi + 1);
                      }}
                      className="status-slct"
                    >
                      <Option disabled value="">
                        Status
                      </Option>
                      <Option value="pending">Pending</Option>
                      <Option value="progress">Progress</Option>
                      <Option value="done">Done</Option>
                    </Select>
                    <Select
                      value={videoType}
                      onChange={(e) => {
                        setVideoType(e);
                        setVideoTask({ ...videoTasks, videos: undefined });
                        setPageNo(1);
                        setCallApi(callApi + 1);
                      }}
                      className="status-slct"
                    >
                      <Option disabled value="">
                        Video Type
                      </Option>
                      <Option value="original">Original</Option>
                      <Option value="supercut">Supercut</Option>
                      <Option value="moment">Moment</Option>
                    </Select>
                  </div>
                  <div className="rt-troc">
                    <Button
                      onClick={(e) => {
                        setVideoTask({ ...videoTasks, videos: undefined });
                        setCallApi(callApi + 1);
                      }}
                      size="small"
                      type="primary"
                    >
                      Reload
                    </Button>

                    {!videoTasks.gridView ? (
                      <FaTable
                        className={videoTasks.gridView ? "active-tbl" : ""}
                        onClick={() =>
                          setVideoTask({
                            ...videoTasks,
                            gridView: !videoTasks.gridView,
                          })
                        }
                      />
                    ) : (
                      <BiCard
                        className={!videoTasks.gridView ? "active-tbl" : ""}
                        onClick={() =>
                          setVideoTask({
                            ...videoTasks,
                            gridView: !videoTasks.gridView,
                          })
                        }
                      />
                    )}

                    <StarOutlined />
                  </div>
                </div>
                {videoTasks.videoSeach ? (
                  <div>
                    {videoTasks.videoSeach.map((vs, vsi) => {
                      return (
                        <div key={vsi}>
                          <SearchCard data={vs} />
                        </div>
                      );
                    })}
                  </div>
                ) : videoTasks.videos ? (
                  <>
                    <div>
                      {videoTasks.gridView ? <DisplayInTable /> : <FileCards />}
                    </div>
                  </>
                ) : null}
              </Col>
            </Row>
            <div className="page-vid-c">
              {videoTasks.totalPages ? (
                <Pagination
                  pageSize={15}
                  current={pageNo}
                  size="small"
                  total={videoTasks.totalPages * 15}
                  onChange={(v) => {
                    setVideoTask({ ...videoTasks, videos: undefined });
                    setPageNo(v);
                  }}
                />
              ) : null}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
