import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Popconfirm,
  Spin,
  Input,
} from "antd";
import { Button as ButtonB } from "react-bootstrap";

import { CloseOutlined, TagFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TagsGroup from "../../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose } from "react-icons/md";
const { Option } = Select;
const { TextArea } = Input;
export default function Environment() {
  const navigate = useNavigate();

  const { review, setreview, user } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [loading, setLoading] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isChange, setIsChange] = useState(false);
  const [dDelete, displayDelete] = useState();
  const [duplicate, setDuplicate] = useState();
  const [clip, setClip] = useState();

  useEffect(() => {
    setLoading(true);
    if (review.doneVideo2 === undefined) {
      getVideoTask(window.location.pathname.slice(13));
    } else {
      getVideoimages2(review.doneVideo2, window.location.pathname.slice(13));
    }
  }, [pageNo]);

  const getVideoTask = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/video_segment?_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          getVideoimages2(data.segmentList[0], id);
        }
      });
  };

  const getVideoimages2 = (activeData, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${pageNo}&limit=40&video_type=concept_image&headclip_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          setLoading(false);
          // let nd = [];
          // nd.push(data.segmentData);
          setreview({
            ...review,
            doneVideo2: activeData,
            images2: data.segmentList,
          });
          setTotalPages(data.totalPages);
        }
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = review.images2.map((m) => (m._id === row._id ? row : m));
    setreview({
      ...review,
      images2: allRow,
    });
  };

  const updateDesc = (val, con) => {
    con.review = val;
    const images2 = review.images2.map((l) => (l._id === con._id ? con : l));
    setreview({
      ...review,
      images2,
    });
  };

  const saveChanges = (obj, id, save) => {
    if (isChange || save) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(obj),
      };
      fetch(`${process.env.REACT_APP_API}/video_segment/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setIsChange(false);
          if (data && data.isSuccess) {
            message.success("Added");
            if (save === "indexed") {
              navigate("/description");
            }
          } else {
            message.error("Changes Not Saved");
          }
        });
    }
  };

  const deleteVideo = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const deleted = review.images2.filter((f) => f._id !== id);
          setreview({
            ...review,
            images2: deleted,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const saveDuplicate = () => {
    const obj = {
      startTime: duplicate.startTime,
      endTime: duplicate.end,
      model_type: "environment",
    };
    setDuplicate();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `${process.env.REACT_APP_API}/video_segment/duplicate_model/${duplicate._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Added");
          getVideoimages2(
            review.doneVideo2,
            window.location.pathname.slice(13)
          );
        } else {
          message.error("Changes Not Saved");
        }
      });
  };

  const saveClip = (id) => {
    const obj = {
      doc_id: id,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `${process.env.REACT_APP_API}/video_segment/add_searchkey/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setClip();
        if (data && data.isSuccess) {
          message.success("Added");
        } else {
          message.error("Changes Not Saved");
        }
      });
  };

  const changeModelsField = (row, key, val) => {
    row[key] = val;
    const models = row.models;
    let field = row.models[key];
    field = val;
    models[key] = field;
    row.models = models;
    const images2 = review.images2.map((l) => (l._id === row._id ? row : l));
    setreview({
      ...review,
      images2,
    });
  };

  return (
    <div>
      <div>
        {loading ? <Spin /> : null}
        {review.doneVideo2 ? (
          <Row>
            <Col md={{ span: 24 }}>
              <div className="sel-s-c">
                <h5>
                  {review.doneVideo2.video_name}{" "}
                  <CloseOutlined
                    className="eye-icn-c close-icn"
                    onClick={() => {
                      setreview({
                        ...review,
                        doneVideo2: undefined,
                        images2: undefined,
                      });
                      navigate(`/description`);
                    }}
                  />
                </h5>
                {review.doneVideo2.search_status !== "indexed" ? (
                  loading ? (
                    <Spin />
                  ) : (
                    <Popconfirm
                      title={
                        <div>
                          <p>
                            {" "}
                            All Tabs Complete? , Check this link in sheet to
                            confirm?
                          </p>
                          <h6>{window.location.href}</h6>
                        </div>
                      }
                      onConfirm={() => {
                        setLoading(true);
                        saveChanges(
                          { search_status: "indexed" },
                          review.doneVideo2._id,
                          "indexed"
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary">
                        Press Button to Complete Video
                      </Button>
                    </Popconfirm>
                  )
                ) : null}
              </div>

              <div>
                {review.images2 && review.images2.length === 0 ? (
                  <h1>No images2 Can Press Done</h1>
                ) : null}
                <Row>
                  {review.images2 &&
                    review.images2.map((lb, lbi) => {
                      return (
                        <Col key={lbi} lg={{ span: 6 }} md={{ span: 12 }}>
                          <div className="lbl-c-col">
                            <div className="delete-icn-c">
                              <span
                                onClick={() => displayDelete(lb)}
                                className="close-i-fd"
                              >
                                ❌
                              </span>
                            </div>
                            <Card>
                              <img
                                onClick={() => setViewFile(lb.file_url)}
                                className="lbl-mn-img"
                                src={lb.file_url}
                                alt="img"
                              />
                              <div className="bx-2-date-tm">
                                <b>
                                  Start:
                                  <i>
                                    <Input
                                      value={lb.startTime}
                                      onChange={(e) => {
                                        changeField(
                                          "startTime",
                                          e.target.value,
                                          lb
                                        );
                                        setIsChange(true);
                                      }}
                                      className="hidden-table-input time-input-img"
                                      onBlur={() =>
                                        saveChanges(
                                          { startTime: lb.startTime },
                                          lb._id
                                        )
                                      }
                                    />
                                  </i>
                                </b>
                                <b>
                                  End:
                                  <i>
                                    <Input
                                      value={lb.endTime}
                                      onChange={(e) => {
                                        changeField(
                                          "endTime",
                                          e.target.value,
                                          lb
                                        );
                                        setIsChange(true);
                                      }}
                                      className="hidden-table-input time-input-img"
                                      onBlur={() =>
                                        saveChanges(
                                          { endTime: lb.endTime },
                                          lb._id
                                        )
                                      }
                                    />
                                  </i>
                                </b>
                              </div>
                              <div className="lbl-s-e-c">
                                <div className="tbl-bx-con-desc">
                                  <Input.TextArea
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                    onChange={(e) =>
                                      changeModelsField(
                                        lb,
                                        "environment",
                                        e.target.value
                                      )
                                    }
                                    value={lb.models?.environment}
                                    placeholder="Environment"
                                    onBlur={() =>
                                      saveChanges(
                                        { models: lb.models },
                                        lb._id,
                                        true
                                      )
                                    }
                                  />
                                </div>
                                <div className="desc-cr-up-pl">
                                  {clip === lb._id ? (
                                    <Spin />
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        saveClip(lb._id);
                                        setClip(lb._id);
                                      }}
                                      size="small"
                                      type="primary"
                                      className="clip-btn-desc-b"
                                    >
                                      Clip
                                    </Button>
                                  )}
                                  {duplicate &&
                                  duplicate._id === lb._id ? null : (
                                    <div>
                                      {lb.models?.environment &&
                                      lb.models?.environment.length ? (
                                        <Button
                                          onClick={() => setDuplicate(lb)}
                                          size="small"
                                          type="primary"
                                        >
                                          Duplicate
                                        </Button>
                                      ) : null}
                                    </div>
                                  )}
                                </div>
                                {duplicate && duplicate._id === lb._id ? (
                                  <div className="box-dup-main0-c">
                                    <b>Add duplicate end time</b>
                                    <div className="bx-2-date-tm">
                                      <b>
                                        Start:
                                        <i>
                                          <Input
                                            disabled
                                            value={duplicate.startTime}
                                            className=" time-input-img"
                                          />
                                        </i>
                                      </b>
                                      <b>
                                        End:
                                        <i>
                                          <Input
                                            value={
                                              duplicate.end
                                                ? duplicate.end
                                                : "00:00:00"
                                            }
                                            onChange={(e) => {
                                              setDuplicate({
                                                ...duplicate,
                                                end: e.target.value.trim(),
                                              });
                                            }}
                                            className=" time-input-img"
                                            onBlur={() =>
                                              saveChanges(
                                                { endTime: lb.endTime },
                                                lb._id
                                              )
                                            }
                                          />
                                        </i>
                                      </b>
                                    </div>
                                    <div className="save-dup-bt">
                                      {duplicate.end &&
                                      duplicate.end.length === 8 ? (
                                        <Button
                                          onClick={saveDuplicate}
                                          type="primary"
                                          size="small"
                                        >
                                          Save Duplicate
                                        </Button>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <div className="page-vid-c">
                  {totalPages ? (
                    <Pagination
                      pageSize={40}
                      current={pageNo}
                      size="small"
                      total={totalPages * 40}
                      onChange={(v) => {
                        setPageNo(v);
                        setreview({
                          ...review,
                          images2: undefined,
                        });
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        ) : null}
      </div>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images2</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          <img src={viewFile} alt="" />;
        </div>
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Video</h5>

        {dDelete ? (
          <div className="bx-form-brand">
            <h6>Are you sure you want to delete?</h6>

            <div className="bx-2-date-tm">
              <b>
                Start:
                <i>{dDelete.startTime}</i>
              </b>
              <b>
                End:
                <i>{dDelete.endTime}</i>
              </b>
            </div>
            <div className="delet-btns-h">
              <ButtonB
                size="sm"
                onClick={() => displayDelete()}
                variant="light"
              >
                Cancel
              </ButtonB>{" "}
              <ButtonB
                onClick={() => {
                  deleteVideo(dDelete._id);
                  displayDelete();
                }}
                variant="danger"
                size="sm"
              >
                Yes
              </ButtonB>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
