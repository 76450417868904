import React from "react";

import AllElements from "../AllElements";

export default function Th({ props }) {
  return (
    <th {...props}>
      <AllElements props={props} />
    </th>
  );
}
