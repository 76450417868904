import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Card } from "react-bootstrap";
import { Steps, Tag } from "antd";
import { TimeCorrection } from "../../../components/GlobalFunctions";
const { Step } = Steps;
export default function SearchCard({ data }) {
  const playerRef = React.useRef();

  const [timelineList, setTimelineList] = useState();
  const [sAct, setAct] = useState(0);

  useEffect(() => {
    combineTimeline("");
  }, []);

  const combineTimeline = (val) => {
    const timelines =
      data.timeline &&
      data.timeline.length &&
      data.timeline.filter(
        (f) => f.concepts.filter((f) => f.toLowerCase().includes(val)).length
      );
    const matchList = timelineMatche(timelines === 0 ? [] : timelines);
    setTimelineList(matchList);
    console.log(matchList);
  };

  const timelineMatche = (timelines) => {
    for (let i = 0; i < timelines.length - 1; i++) {
      if (timelines[i].concepts.length === timelines[i + 1].concepts.length) {
        let match = false;
        for (let j = 0; j < timelines[i].concepts.length; j++) {
          if (
            timelines[i].concepts[j] === timelines[i + 1].concepts[j] &&
            timelines[i + 1].timestamp - timelines[i].timestamp < 6
          ) {
            match = true;
          } else {
            match = false;
            j = timelines[i].concepts.length;
          }
        }

        timelines[i].matched = match;
      } else {
        timelines[i].matched = false;
      }
    }

    const matchList = combineMatche(timelines);

    return matchList;
  };

  const combineMatche = (list) => {
    let index = false;
    console.log(list);
    for (let i = 0; i < list.length; i++) {
      if (list[i].matched) {
        if (index !== false) {
          list[index].end = list[i + 1].timestamp;
          list[i + 1].hidden = true;
        } else {
          list[i].end = list[i + 1].timestamp;
          index = i;
          list[i + 1].hidden = true;
        }
      } else {
        // console.log("else", list[i]);
        index = false;
      }
    }
    return list;
  };

  const videoTimeChange = (i) => {
    console.log(playerRef, i);
    playerRef.current.seekTo(i, "seconds");
  };

  return (
    <Card className="vd-crd-src">
      <div className="sc-bc-1">
        <div
          // onClick={() => {
          //   setVideoTask({ ...videoTasks, activeVideo: vd });
          //   navigate(`/video/${vd._id}`);
          // }}
          className="fl-c-mid bx-2-lf"
        >
          <ReactPlayer
            url={data.file_url}
            ref={playerRef}
            className=""
            controls
          />
        </div>
        <Steps current={sAct} onChange={(v) => setAct(v)} progressDot>
          {timelineList &&
            timelineList
              .filter(
                (f) => f.hidden === undefined && f.concepts[0] !== "Nothing"
              )
              .map((sm, smi) => {
                return (
                  <Step
                    onClick={(e) => videoTimeChange(sm.timestamp)}
                    key={smi}
                    subTitle={
                      sm.end
                        ? TimeCorrection(sm.timestamp) +
                          " - " +
                          TimeCorrection(sm.end)
                        : TimeCorrection(sm.timestamp)
                    }
                    description={
                      <div className="tags-blb-c">
                        {sm.concepts.map((cp, cpi) => {
                          return (
                            <Tag key={cpi} color="#87d068">
                              {cp}
                            </Tag>
                          );
                        })}
                      </div>
                    }
                  />
                );
              })}
        </Steps>
      </div>
    </Card>
  );
}
