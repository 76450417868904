import React from "react";
import Div from "./elements/Div";

import Image from "./elements/Image";
import Link from "./elements/Link";
import P from "./elements/P";
import H1 from "./elements/H1";
import H2 from "./elements/H2";
import H3 from "./elements/H3";
import H4 from "./elements/H4";
import H5 from "./elements/H5";
import H6 from "./elements/H6";
import DividerElm from "./elements/DividerElm";
import ParagraphElm from "./elements/ParagraphElm";
import Table from "./elements/Table";
import THead from "./elements/THead";
import TBody from "./elements/TBody";
import Tr from "./elements/Tr";
import Td from "./elements/Td";
import Th from "./elements/Th";
import ButtonElm from "./elements/ButtonElm";
import InputE from "./elements/Input";
import Ul from "./elements/Ul";
import Li from "./elements/Li";
import B from "./elements/B";
import Ol from "./elements/Ol";

export default function AllElements({ props }) {
  return props.data
    ? props.data.map((v, vi) => {
        return v.element === "div" ? (
          <Div props={v} />
        ) : v.element === "input" ? (
          <InputE props={v} />
        ) : v.element === "button" ? (
          <ButtonElm props={v} />
        ) : v.element === "img" ? (
          <Image props={v} />
        ) : v.element === "a" ? (
          <Link props={v} />
        ) : v.element === "p" ? (
          <P props={v} />
        ) : v.element === "h1" ? (
          <H1 props={v} />
        ) : v.element === "h2" ? (
          <H2 props={v} />
        ) : v.element === "h3" ? (
          <H3 props={v} />
        ) : v.element === "h4" ? (
          <H4 props={v} />
        ) : v.element === "h5" ? (
          <H5 props={v} />
        ) : v.element === "h6" ? (
          <H6 props={v} />
        ) : v.element === "table" ? (
          <Table props={v} />
        ) : v.element === "thead" ? (
          <THead props={v} />
        ) : v.element === "tbody" ? (
          <TBody props={v} />
        ) : v.element === "tr" ? (
          <Tr props={v} />
        ) : v.element === "td" ? (
          <Td props={v} />
        ) : v.element === "th" ? (
          <Th props={v} />
        ) : v.element === "ul" ? (
          <Ul props={v} />
        ) : v.element === "ol" ? (
          <Ol props={v} />
        ) : v.element === "li" ? (
          <Li props={v} />
        ) : v.element === "b" ? (
          <B props={v} />
        ) : // ) : v.element === "generate" ? (
        //   <Generate props={v} />
        v.element === "divider" ? (
          <DividerElm props={v} />
        ) : v.element === "paragraph" ? (
          <ParagraphElm props={v} />
        ) : null;
      })
    : null;
}
