import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  Row,
  Col,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import ReactPlayer from "react-player";
import TagsGroup from "../../components/TagsGroup";
const { Option } = Select;
export default function ReUpload() {
  const [callAPI, setCallAPI] = useState(1);
  const [watchVideo, setwatchVideo] = useState();
  const [totalPages, setTotalPages] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);
  const [clip, setClip] = useState();
  const [username, setUsername] = useState("");
  const [predict, setPredict] = useState("pending");
  const [used, setUsed] = useState("ready");

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [editVideo, setEditVideo] = useState();

  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [mData, setMData] = useState("");
  const [tagLoading, setTagLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [lengthS, setLengthS] = useState(0);
  const [video, setVideo] = useState();
  const [sceneData, setSceneData] = useState(); // Add style state
  const [editFolder, setEditFolder] = useState();

  useEffect(() => {
    if (watchVideo === undefined) {
      setLoadeing(true);
      getwatchVideo();
    }
  }, [pageNo, callAPI]);

  const getwatchVideo = (id) => {
    console.log("sss");
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image/internal?page=${pageNo}&limit=6&sort_by=${-1}&media_type=original&status=generated&username=${username}&predict=${predict}&used=${used}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeing(false);
        console.log(data);
        if (data && data.isSuccess) {
          setwatchVideo(data.imageList);
          setTotalPages(data.totalPages);
          //   setGenerated({
          //     ...generated,
          //     videos: data.imageList,
          //     totalPages: data.totalPages,
          //   });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = watchVideo.map((m) => (m._id === row._id ? row : m));
    setwatchVideo(all);
    setIsChangeG(true);
  };

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        setTagLoading(false);
        if (response && response.isSuccess) {
          let all = watchVideo.map((m) =>
            m._id === response.imageData._id ? response.imageData : m
          );
          setwatchVideo(all);
          setEditVideo();
          setClip();
          setVideo();
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const saveClip = () => {
    let obj = {
      start_time: TimeCorrection(startT),
      end_time: TimeCorrection(endT),
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/trim_video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };
  const formatter = (value) => TimeCorrection(value);
  const changeValue2 = (row, key, key2, i, val) => {
    row[key][i][key2] = val;
    setSceneData(row);

    const all = watchVideo.map((m) => (m._id === row._id ? row : m));
    setwatchVideo(all);
    setIsChangeG(true);
  };
  const updateFolder = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/folder/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isPatched) {
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };
  const getFolder = (url, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/folder?page=1&limit=6&sort_by=${-1}&segments=${url}&standard=poor`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setEditFolder(data.folderList);
          if (data.folderList.length) {
            setSceneData({ ...sceneData, activeId: id });
          }
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const columns = [
    {
      title: "Video",
      dataIndex: "file_url",
      key: "file_url",
      width: 400,
      render: (text, row) => (
        <div className="fl-c-mid221-dis">
          <ReactPlayer url={text} className="" controls />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "001",
      key: "001",
      width: 30,
      render: (text, row) =>
        tagLoading ===row._id ? (
          <Spin />
        ) : (
          <div style={{ width: "100px" }}>
            <Button
              onClick={() => {
                setTagLoading(row._id);
                updateValue(
                  {
                    predict: "complete",
                    count: row.count ? [...row.count, "cache"] : ["cache"],
                  },
                  row._id
                );
              }}
              style={{ marginBottom: "10px" }}
              size="sm"
            >
              Cache
            </Button>
            <Button
              onClick={() => {
                setTagLoading(row._id);

                updateValue(
                  {
                    predict: "complete",
                    count: row.count
                      ? row.count.filter((f) => f !== "cache")
                      : [],
                  },
                  row._id
                );
              }}
              size="sm"
            >
              Not Cache
            </Button>
          </div>
        ),
    },
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="query"
          onChange={(e) => {
            changeValue(row, "query", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ query: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Tags",
      dataIndex: "count",
      key: "count",
      width: 150,

      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="blue"
          onTagChange={(v) => {
            changeValue(row, "count", v);
            updateValue({ count: v }, row._id, true);
          }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      width: 30,
      render: (text, row) => (
        <span>
          <Button onClick={() => setEditVideo(row)} size="small">
            Edit
          </Button>
          <Button
            style={{ marginTop: "10px" }}
            onClick={() => setSceneData(row)}
            size="small"
          >
            Scene
          </Button>
        </span>
      ),
    },

    {
      title: "Predict",
      dataIndex: "predict",
      key: "predict",
      width: 30,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="predict"
          style={{ width: 100 }} // Set the desired width in pixels
          value={text}
          onChange={(value) => {
            changeValue(row, "predict", value);
            updateValue({ predict: value }, row._id);
          }}
        >
          <Option value="pending">Pending</Option>
          <Option value="complete">Complete</Option>
          <Option value="verified">Verified</Option>
        </Select>
      ),
    },
  ];

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
      // console.log(.url);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };

  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          <div className="vd-nm-pr img-prev-ibc">
            <ReactPlayer url={imageUrl} controls={false} />
          </div>
        </>
      );
    } else {
      return <span style={{color:"black"}}>Drag or click to upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
  };

  const saveVideo = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f[0].originFileObj);

    formData.append("assistant_id", "");
    formData.append("video_duration", lengthS);

    formData.append("purpose", "video");
    formData.append("meta_data", mData);
    formData.append("user_id", "64dc1de41d6e72846d0bb3f2");

    setDisplay(false);
    const vidId = Math.random();

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/upload/video`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFileList([]);
        setUplaoded([]);
        setLoading(false);
        if (data && data.isSuccess) {
          setVideo(data.data);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const getVideoLength = (vLength) => {
    setLengthS(vLength);
    // if (vLength / 60 > 3) {
    //   setUplaoded([]);
    //   setVideoErr(true);
    // } else {
    //   setVideoErr(false);
    // }
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ justifyContent: "flex-end" }} className="table-top-nav">
        <Select
          placeholder="Category"
          onChange={(v) => {
            setPredict(v);
            setwatchVideo();
            setPageNo(1);
            setCallAPI(callAPI + 1);
          }}
          value={predict}
          style={{ width: "120px", marginLeft: "10px" }}
        >
          <Option disabeled value="">
            Predict
          </Option>
          <Option value="pending">Pending</Option>
          <Option value="complete">Complete</Option>
          <Option value="verified">Verified</Option>
        </Select>
        <Select
          placeholder="used"
          onChange={(v) => {
            setUsed(v);
            setwatchVideo();
            setPageNo(1);
            setCallAPI(callAPI + 1);
          }}
          value={used}
          style={{ width: "120px", marginLeft: "10px" }}
        >
          <Option disabeled value="">
            Used
          </Option>
          <Option value="ready">Ready</Option>
          <Option value="quality">Quality</Option>
        </Select>
      </div>

      <div style={{ width: "100%", overflowX: "auto" }}>
        {loadeing ? <Spin /> : null}
        {watchVideo ? (
          <Table
            pagination={{
              pageSize: 15,
              current: pageNo,
              total: totalPages * 15,
              onChange: (v) => {
                setwatchVideo();
                setPageNo(v);
              },
              size: "small",
              showSizeChanger: false,
            }}
            columns={columns}
            dataSource={watchVideo}
          />
        ) : null}

        <Modal
          visible={editVideo !== undefined}
          onCancel={() => {
            setEditVideo();
            setClip();
            setVideo();
          }}
          width={600}
          className="no-buttons-modal "
        >
          {video ? (
            <div>
              <h4>New Video</h4>
              <div className="video-container radius-video ">
                <ReactPlayer controls url={video.file_url} playing />
              </div>
              <Button
                onClick={() => {
                  updateValue(
                    {
                      file_url: video.file_url,
                      first_frame: editVideo.file_url,
                    },
                    editVideo?._id
                  );
                  setClip();
                  setEditVideo({
                    ...clip,
                    file_url: video.file_url,
                  });
                }}
                type="primary"
              >
                Save Video
              </Button>
            </div>
          ) : editVideo ? (
            <div>
              <div>
                <b>{editVideo.query}</b>
              </div>

              <div className="video-container radius-video ">
                <ReactPlayer controls url={editVideo.file_url} playing />
              </div>
              <Collapse defaultActiveKey={""} accordion>
                <Collapse.Panel header="Clip" key="clip">
                  {clip ? (
                    <div>
                      <div className="video-container radius-video">
                        <ReactPlayer controls url={clip.file_ur} playing />
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            setClip();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            updateValue(
                              { file_url: clip?.file_ur },
                              editVideo?._id
                            );
                            setClip();
                            setEditVideo({
                              ...clip,
                              file_url: clip.file_ur,
                              isCliped: true,
                            });
                          }}
                          type="primary"
                        >
                          Save Clip
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Slider
                        tooltip={{
                          placement: "bottom",
                          formatter,
                        }}
                        range
                        value={[startT, endT]}
                        defaultValue={[startT, endT]}
                        onChange={(v) => {
                          setStartT(v[0]);
                          setEndT(v[1]);
                        }}
                      />
                      <Button type="primary" onClick={() => saveClip()}>
                        Clip
                      </Button>
                    </div>
                  )}
                </Collapse.Panel>
              </Collapse>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Dragger
                  showUploadList={false}
                  maxCount={1}
                  beforeUpload={handleBeforeUpload}
                  {...props}
                >
                  {renderFileName()}
                </Dragger>
                <div className="up-bx-mnbcs">
                  {UploadedFile.length ? (
                    <Button type="link" onClick={removeFile}>
                      Change
                    </Button>
                  ) : null}
                  {UploadedFile.length ? (
                    loading ? (
                      <Spin />
                    ) : (
                      <Button
                        type="primary"
                        onClick={() => {
                          setLoading(true);
                          saveVideo(UploadedFile);
                        }}
                      >
                        Attach
                      </Button>
                    )
                  ) : null}
                  {UploadedFile.length ? (
                    <div className="pic-2vid0c">
                      <ReactPlayer
                        url={URL.createObjectURL(UploadedFile[0].originFileObj)}
                        className=""
                        controls
                        playing
                        muted
                        onProgress={(e) => getVideoLength(e.loadedSeconds)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </Modal>
        <Modal
          visible={sceneData !== undefined}
          onCancel={() => setSceneData()}
          className=" no-buttons-modal top-10-modal"
          width={1000}
        >
          {sceneData ? (
            <div>
              <Row>
                <Col span={4}></Col>
                <Col span={16}>
                  <div className="video-container radius-video">
                    <ReactPlayer controls url={sceneData.file_url} playing />
                  </div>
                </Col>
              </Row>
              <Row>
                {sceneData.scene_data.map((m, mi) => {
                  return (
                    <Col key={mi} span={12}>
                      <div className="scene-main-bx-ddata">
                        <div className="video-container radius-video">
                          <ReactPlayer controls url={m.video_url} />
                        </div>
                        <h6>
                          <Tag
                            onClick={() => console.log(editFolder)}
                            color="blue"
                          >
                            {m.theme}
                          </Tag>
                          {sceneData.activeId === m._id ? null : (
                            <Button
                              size="sm"
                              onClick={() => getFolder(m.video_url, m._id)}
                            >
                              Edit
                            </Button>
                          )}
                          {sceneData.activeId === m._id ? (
                            <Input.TextArea
                              value={m.captions}
                              placeholder="Title"
                              onChange={(e) => {
                                changeValue2(
                                  sceneData,
                                  "scene_data",
                                  "captions",
                                  mi,
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                updateFolder(
                                  { caption: m.captions },
                                  editFolder[0]._id
                                );
                              }}
                              autoSize={{ minRows: 2, maxRows: 8 }}
                              className="hidden-textarea"
                              style={{
                                minWidth: "150px",
                                border: "1px solid black",
                              }}
                            />
                          ) : (
                            m.captions
                          )}
                        </h6>
                        <div>{m.description}</div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          ) : null}
        </Modal>
      </div>
    </div>
  );
}
