import React from "react";

export default function NotFound() {
  return (
    <section class="page_404">
      <div class="col-sm-10 col-sm-offset-1  text-center">
        <div class="four_zero_four_bg">
          <h4 class="text-center ">No Video Pending</h4>
        </div>
        <div class="contant_box_404">
          <h3 class="h2"> Check page again later if any video pending!</h3>
    
        </div>
      </div>
    </section>
  );
}
