import { Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/Search/SearchContext";
import Speaker from "./components/Speaker";

export default function Index() {
  const { speaker, setspeaker, user } = useContext(SearchContext);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    if (speaker.activeVideo === undefined) {
      getVideoTask(window.location.pathname.slice(6));
    }
  }, []);

  const getVideoTask = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/video_segment?_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setspeaker({ ...speaker, activeVideo: data.segmentList[0] });
        }
      });
  };
  return <div>{speaker.activeVideo ? <Speaker /> : <Spin />}</div>;
}
