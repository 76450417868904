import { Tabs } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import AddVideo from "./components/AddVideo";
import DisplayDoneVideos from "./components/DisplayDoneVideos";
import DisplayVideo from "./components/DisplayVideos";
import "./crawler.css";
export default function Crawler() {
  const { videos, setVideos } = useContext(SearchContext);

  return (
    <div className="main-bx-lbl">
      <Tabs
        onChange={(v) => setVideos({ ...videos, tab: v })}
        activeKey={videos.tab}
      >
        <Tabs.TabPane tab="Pending" key="1">
          <DisplayVideo />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Done" key="2">
          <DisplayDoneVideos />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
