import {
  Button,
  Col,
  List,
  message,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { FaTrash } from "react-icons/fa";
import SearchContext from "../../../Context/Search/SearchContext";

export default function TimeStampCards({ setPage , page }) {
  const { timestampData, setTimeStamp } = useContext(SearchContext);
  const [edited, setEdited] = useState();
  const [openQA, setOpenQA] = useState();
  const [openShop, setOpenShop] = useState();
  const [enableUpdate, setEnableUpdate] = useState(false);

  const editData = (key, value, row) => {
    setEdited(true);
    row[key] = value;
    const changedRows = timestampData.map((m) => (m._id === row._id ? row : m));
    setTimeStamp(changedRows);
  };

  const editDatabase = (row, isUpdate) => {
    if (edited) {
      const requestOptions = {
        method: "PUT",
        body: JSON.stringify(row),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
        },
      };

      fetch(`https://api.fussy.ai/internal/mamba/${row._id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isUpdated) {
            message.success("Updated");
            if (isUpdate) {
              const changedRows = timestampData.map((m) =>
                m._id === row._id ? row : m
              );
              setTimeStamp(changedRows);
            }
          } else {
            message.error("something went wrong");
          }
          setEdited(false);

          console.log(data, "guide/test");
        });
    }
  };

  const updateForQA = (row) => {
    console.log(row);
    setEnableUpdate(false);
    editDatabase(row, true);
    setOpenQA();
    setOpenShop();
  };

  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };

  const timeToseconds = (time) => {
    let hours = time.slice(0, 2);
    hours = hours * 3600;
    let minuts = time.slice(3, 5);
    minuts = minuts * 60;
    let seconds = time.slice(6);
    seconds = Number(seconds) + Number(minuts) + Number(hours);
    return seconds;
  };

  const changeTimestamp = (row, value) => {
    timeToseconds(value.trim());
    // editData("headline", e.target.value, tm)
  };

  const removeQA = (i, all) => {
    all.splice(i, 1);
    setEdited(true);
    setOpenQA({ ...openQA, qa: all });
    setEnableUpdate(true);
  };
  const removeShop = (i, all) => {
    all.splice(i, 1);
    setEdited(true);
    setOpenShop({ ...openShop, shop: all });
    setEnableUpdate(true);
  };

  const changeQA = (value, key, index, all) => {
    all[index][key] = value;
    setOpenQA({ ...openQA, qa: all });
    setEnableUpdate(true);
  };

  const changeShop = (value, key, index, all) => {
    all[index][key] = value;
    setOpenShop({ ...openShop, shop: all });
    setEnableUpdate(true);
  };

  const columns = [
    {
      title: "TS",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text, row) => (
        <a
          target="blank"
          href={
            row.platform.toLowerCase() === "spotify"
              ? row.link + `?t=${row.timestamp}`
              : `` + row.platform.toLowerCase() === "youtube"
              ? row.link + `&t=${row.timestamp}s`
              : `` + row.platform.toLowerCase() !== "spotify" &&
                row.platform.toLowerCase() !== "youtube"
              ? row.link
              : ``
          }
          className="start-n-l-b"
        >
          <b className="tim-tim-c">{TimeCorrection(text)}</b>
        </a>
      ),
    },
    {
      title: "Video Name",
      dataIndex: "",
      key: "video_name",

      render: (text, row) => (
        <textarea
          style={{ width: "150px" }}
          onChange={(e) => editData("video_name", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.video_name ? row.video_name : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="Video Name"
        />
      ),
    },
    {
      title: "Headline",
      dataIndex: "",
      key: "headline",
      render: (text, row) => (
        <textarea
          style={{ width: "120px" }}
          onChange={(e) => editData("headline", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.headline ? row.headline : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="Headline"
        />
      ),
    },
    {
      title: "Quote",
      dataIndex: "",
      key: "quote",
      render: (text, row) => (
        <textarea
          style={{ width: "120px" }}
          onChange={(e) => editData("quote", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.quote ? row.quote : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="Quote"
        />
      ),
    },
    {
      title: "Subcategory",
      dataIndex: "",
      key: "sub_category",
      render: (text, row) => (
        <textarea
          style={{ width: "150px" }}
          onChange={(e) => editData("sub_category", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.sub_category ? row.sub_category : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="Subcategory"
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "",
      key: "description",
      render: (text, row) => (
        <textarea
          style={{ width: "150px" }}
          onChange={(e) => editData("description", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.description ? row.description : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="Description"
        />
      ),
    },
    {
      title: "After Context",
      dataIndex: "",
      key: "after_context",
      render: (text, row) => (
        <textarea
          style={{ width: "170px" }}
          onChange={(e) => editData("after_context", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.after_context ? row.after_context : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="After Context"
        />
      ),
    },

    {
      title: "Previous Context",
      dataIndex: "",
      key: "previous_context",
      render: (text, row) => (
        <textarea
          style={{ width: "170px" }}
          onChange={(e) => editData("previous_context", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.previous_context ? row.previous_context : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="Previous Context"
        />
      ),
    },

    {
      title: "Int Description",
      dataIndex: "",
      key: "int_description",
      render: (text, row) => (
        <textarea
          style={{ width: "170px" }}
          onChange={(e) => editData("int_description", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.int_description ? row.int_description : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="Int Description"
        />
      ),
    },

    {
      title: "QA",
      dataIndex: "",
      key: "qa",
      render: (text, row) => (
        <Button type="primary" size="small" onClick={() => setOpenQA(row)}>
          QA
        </Button>
      ),
    },
    {
      title: "Shop",
      dataIndex: "",
      key: "sp",
      render: (text, row) => (
        <Button type="primary" size="small" onClick={() => setOpenShop(row)}>
          Shop
        </Button>
      ),
    },

    {
      title: "Platform",
      dataIndex: "",
      key: "platform",
      render: (text, row) => (
        <textarea
          style={{ width: "100px" }}
          onChange={(e) => editData("platform", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.platform ? row.platform : ""}
          type="text"
          className="form-control "
          aria-label="Default"
          placeholder="Platform"
        />
      ),
    },

    {
      title: "Channel",
      dataIndex: "",
      key: "channel",
      render: (text, row) => (
        <textarea
          style={{ width: "150px" }}
          onChange={(e) => editData("channel", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.channel ? row.channel : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="Channel"
        />
      ),
    },
    {
      title: "Link",
      dataIndex: "",
      key: "link",
      render: (text, row) => (
        <textarea
          style={{ width: "150px" }}
          onChange={(e) => editData("link", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          value={row.link ? row.link : ""}
          type="text"
          className="form-control c-descrp-imp"
          aria-label="Default"
          placeholder="link"
        />
      ),
    },
    {
      title: "pipeline",
      dataIndex: "pipeline",
      key: "pipeline",
      render: (text, row) => (
        <select
          style={{ width: "100px" }}
          value={text}
          onChange={(e) => editData("pipeline", e.target.value, row)}
          onBlur={() => editDatabase(row)}
          className={`form-select form-control ${
            text === "quene" ? " red-color" : ""
          }
          ${text === "pending" ? " orange-color" : ""}
          ${text === "removal" ? " orange-color" : ""}
          ${text === "processing" ? " orange-color" : ""}
          ${text === "review" ? " orange-color" : ""}
          ${text === "complete" ? " green-color" : ""}
          ${text === "indexed" ? " green-color" : ""}`}
          aria-label="Default select example"
        >
          <option style={{ color: "red" }} value="quene">
            Quene
          </option>
          <option style={{ color: "orange" }} value="pending">
            Pending
          </option>
          <option style={{ color: "orange" }} value="processing">
            Processing
          </option>
          <option style={{ color: "orange" }} value="removal">
            Removal
          </option>
          <option style={{ color: "orange" }} value="review">
            Review
          </option>
          <option style={{ color: "green" }} value="complete">
            Complete
          </option>
          <option style={{ color: "green" }} value="indexed">
            Indexed
          </option>
        </select>
      ),
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, row) => (
        <b style={{ width: "150px" }}>{moment(text).format("MMM Do YYYY")}</b>
      ),
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "delete",
      render: (text, row) => (
        <div className="trash-qa-s">
          <FaTrash onClick={() => removeSearchR(row)} />
        </div>
      ),
    },
  ];

  const removeSearchR = (row) => {
    const changedRows = timestampData.filter((m) => m._id !== row._id);
    setTimeStamp(changedRows);

    const requestOptions = {
      method: "DELETE",
      body: JSON.stringify(row),
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(`https://api.fussy.ai/internal/mamba/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isRemoved) {
        } else {
          message.error("something went wrong");
        }
      });
  };

  return (
    <div>
      <Table
        className="channle-table timestamp-table"
        columns={columns}
        dataSource={timestampData}
        pagination={{
          defaultCurrent: 1,
          pageSize: 100,
          total: 1000,
          current: page,
          onChange: (e) => setPage(e),
        }}
      />
      <Modal
        className="no-h-bo-b-modal top-20h-m"
        visible={openQA !== undefined}
        onCancel={() => setOpenQA()}
      >
        {/* <h5>Questions & Answers</h5> */}
        {openQA && openQA.qa && openQA.qa.length ? (
          <div>
            <div className="q-open-qa">
              {openQA.qa.map((qa, qi) => {
                return (
                  <div className="qa-map-c" key={qi}>
                    <div className="trash-qa-s">
                      <FaTrash onClick={() => removeQA(qi, openQA.qa)} />
                    </div>
                    <input
                      onChange={(e) => {
                        changeQA(e.target.value, "question", qi, openQA.qa);
                        setEdited(true);
                      }}
                      value={qa.question}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Add Question"
                    />
                    <textarea
                      onChange={(e) => {
                        setEdited(true);
                        changeQA(e.target.value, "answer", qi, openQA.qa);
                      }}
                      value={qa.answer}
                      type="text"
                      className="form-control a-fld-qa"
                      aria-label="Default"
                      placeholder="Add Answer"
                    />
                  </div>
                );
              })}
            </div>
            <div className="add-ip0c">
              <Button
                onClick={() =>
                  setOpenQA({
                    ...openQA,
                    qa: [...openQA.qa, { question: "", answer: "" }],
                  })
                }
                className=""
              >
                Add
              </Button>
              {enableUpdate ? (
                <div>
                  <Button
                    onClick={() => {
                      updateForQA(openQA);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenQA();
                      setEnableUpdate(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <h4>No Question Found</h4>
            <div className="add-ip0c">
              <Button
                onClick={() =>
                  setOpenQA({
                    ...openQA,
                    qa: [{ question: "", answer: "" }],
                  })
                }
                className=""
              >
                Add
              </Button>
              {enableUpdate ? (
                <div>
                  <Button
                    onClick={() => {
                      updateForQA(openQA);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenQA();
                      setEnableUpdate(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Modal>

      <Modal
        className="no-h-bo-b-modal top-20h-m"
        visible={openShop !== undefined}
        onCancel={() => setOpenShop()}
        width={700}
      >
        {/* <h5>Questions & Answers</h5> */}
        {openShop && openShop.shop && openShop.shop.length ? (
          <div>
            <div className="q-open-qa">
              {openShop.shop.map((sp, spi) => {
                return (
                  <div className="qa-map-c" key={spi}>
                    <div className="trash-qa-s">
                      <FaTrash onClick={() => removeShop(spi, openShop.shop)} />
                    </div>
                    <textarea
                      onChange={(e) => {
                        changeShop(e.target.value, "url", spi, openShop.shop);
                        setEdited(true);
                      }}
                      value={sp.url}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Add url"
                    />
                    <textarea
                      onChange={(e) => {
                        changeShop(e.target.value, "image", spi, openShop.shop);
                        setEdited(true);
                      }}
                      value={sp.image}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Image"
                    />
                    <textarea
                      onChange={(e) => {
                        changeShop(e.target.value, "name", spi, openShop.shop);
                        setEdited(true);
                      }}
                      value={sp.name}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Add name"
                    />
                    <input
                      onChange={(e) => {
                        changeShop(e.target.value, "store", spi, openShop.shop);
                        setEdited(true);
                      }}
                      value={sp.store}
                      type="text"
                      className="form-control q-fld-qa"
                      aria-label="Default"
                      placeholder="Add store"
                    />
                  </div>
                );
              })}
            </div>
            <div className="add-ip0c">
              <Button
                onClick={() =>
                  setOpenShop({
                    ...openShop,
                    shop: [
                      ...openShop.shop,
                      { url: "", image: "", name: "", store: "" },
                    ],
                  })
                }
                className=""
              >
                Add
              </Button>
              {enableUpdate ? (
                <div>
                  <Button
                    onClick={() => {
                      updateForQA(openShop);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenShop();
                      setEnableUpdate(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <h4>No Shop Found</h4>
            <div className="add-ip0c">
              <Button
                onClick={() =>
                  setOpenShop({
                    ...openShop,
                    shop: [{ url: "", image: "", name: "", store: "" }],
                  })
                }
                className=""
              >
                Add
              </Button>
              {enableUpdate ? (
                <div>
                  <Button
                    onClick={() => {
                      updateForQA(setOpenShop);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenShop();
                      setEnableUpdate(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
