import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import GreenTick from "../../components/GreenTick";
import SearchContext from "../../Context/Search/SearchContext";

export default function Entity() {
  const { user, organization, setOrganization } = useContext(SearchContext);

  const [callAPI, setCallAPI] = useState(1);
  const [Entity, setEntity] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [dAdd, setdAdd] = useState();
  const [Description, setDescription] = useState();
  const [DescriptionId, setDescriptionId] = useState();

  useEffect(() => {
    if (Entity === undefined) {
      setLoadeing(true);
      getEntity();
    }
  }, [pageNo]);

  const getEntity = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/entities?page=${pageNo}&limit=15&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setEntity(data.entitiesList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };
  const SaveEntity = (row) => {
    row.user_id = user.user_id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/entities`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          const list = [...Entity];
          list.unshift(data.data);
          setEntity(list);
        }
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);
      closeDescription();
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/entities/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = Entity.filter((t) => t._id !== id);
    setEntity(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/entities/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = Entity.map((m) => (m._id === row._id ? row : m));
    setEntity(allRow);
  };

  const changeDescription = (row, key, val) => {
    row[key] = val;
    const all = Description.map((m) => (m.id === row.id ? row : m));
    setDescription(all);
    setIsChangeG(true);
  };

  const deleteDescription = (id) => {
    const all = Description.filter((f) => f.id !== id);
    setDescription(all);
    setIsChangeG(true);
  };

  const displayDescription = (row) => {
    const g = row.description;
    for (let i = 0; i < g.length; i++) {
      g[i].id = i;
    }
    setDescriptionId(row._id);
    setDescription(g);
  };
  const closeDescription = () => {
    setDescription();
    setDescriptionId();
    setIsChangeG(false);
  };

  const columns = [
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="value"
          onChange={(e) => {
            changeField("value", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ value: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "user_id",
      dataIndex: "user_id",
      key: "user_id",
      width: 150,
      render: (text, row) => text,
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      <Button size="sm" onClick={() => setdAdd({ value: "" })}>
        <BsPlus />
        Add
      </Button>
      {loadeing ? <Spin /> : null}
      {Entity ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setEntity();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={Entity}
        />
      ) : null}
      <Modal
        visible={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Value:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="Name"
                      value={dAdd.value}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, value: e.target.value })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-end">
              <Button
                onClick={() => {
                  SaveEntity(dAdd);
                  setdAdd();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Entity</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        width={520}
        visible={Description !== undefined}
        onCancel={() => {
          closeDescription();
        }}
        className="no-f-no-h brand-modal top-20-x"
      >
        <h5 className="create-btn-h">Description</h5>

        <div className="bx-form-brand">
          {Description &&
            Description.map((b, bi) => {
              return (
                <table key={bi} className="table-bucket-aws table-b-guide-cm">
                  <tbody>
                    <tr>
                      <td>
                        <b>Usage:</b>
                      </td>
                      <td>
                        <div className="close-butcke-bx">
                          <MdClose
                            onClick={() => deleteDescription(b.id)}
                            className="close-bx-d-in hover"
                          />
                        </div>
                        <InputNumber
                          onChange={(e) => changeDescription(b, "usage", e)}
                          value={b.usage}
                          placeholder="Usage"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Content:</b>
                      </td>
                      <td>
                        <Input.TextArea
                          autoSize={{
                            minRows: 1,
                            maxRows: 3,
                          }}
                          onChange={(e) =>
                            changeDescription(b, "content", e.target.value)
                          }
                          value={b.content}
                          placeholder="Content"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          <div className="flex-end">
            <AiOutlinePlus
              onClick={() =>
                setDescription([
                  ...Description,
                  {
                    content: "",
                    usage: 0,
                    id: Description.length
                      ? Description[Description.length - 1].id + 1
                      : 0,
                  },
                ])
              }
              className="icn-side-c"
            />
          </div>
          <div>
            {isChangeG ? (
              <Button
                size="sm"
                onClick={() =>
                  saveChanges({ description: Description }, DescriptionId, true)
                }
              >
                Update
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
