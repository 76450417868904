import React from "react";

import AllElements from "../AllElements";

export default function Div({ props }) {
  return (
    <div
      style={props.style ? props.style : {}}
      className={`${props.col ? `col-${props.col}` : ""}  ${props.className}`}
    >
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <AllElements props={props} />
    </div>
  );
}
