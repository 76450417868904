import React from "react";

export default function H4({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <h4 {...props}>{props.text}</h4>
    </>
  );
}
