import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

import Loader from "../../../images/spinner.gif";
import Tick from "../../../images/tick.png";
import Cross from "../../../images/cross.png";
import ErrorImg from "../../../images/error.svg";

const VideosUploading = ({ data, onVideoRemove }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div
      className={`videos-uploading-modal ${data.length > 0 ? "active" : ""} ${
        !isActive ? "lowered" : ""
      }`}
    >
      <div className="head" onClick={() => setIsActive(!isActive)}>
        Operation in progress... <FaChevronDown />{" "}
      </div>
      <div className="body">
        {data.map((el, idx) => {
          if (el.name.length > 18) {
            el.name = el.name.substring(0, 18) + "...";
          }

          return (
            <div className="uploaded-item" key={"uploaded-item" + idx}>
              {el.name}
              {el.isLoading ? (
                <img src={Loader} title="loader" alt="loader" />
              ) : (
                <div className="completed">
                  {el.success ? (
                    <img
                      className="tick"
                      src={Tick}
                      title="tick"
                      width={20}
                      alt="tick"
                    />
                  ) : (
                    <img
                      className="error"
                      src={ErrorImg}
                      title="error"
                      width={20}
                      alt="error"
                    />
                  )}
                  <img
                    onClick={() => onVideoRemove(el.id)}
                    className="cross"
                    src={Cross}
                    title="cross"
                    width={22}
                    alt="cross"
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VideosUploading;
