import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  AutoComplete,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import GreenTick from "../../components/GreenTick";
import SearchContext from "../../Context/Search/SearchContext";
import ReactPlayer from "react-player";
import AddNew from "./component/AddNew";
const { Option } = Select;
export default function Folders() {
  const { user, organization, setOrganization } = useContext(SearchContext);

  const [callAPI, setCallAPI] = useState(1);
  const [folderVideo, setfolderVideo] = useState();
  const [totalPages, setTotalPages] = useState();
  const [caption, setCaption] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [highlights, sethighlights] = useState();

  const [standard, setstandard] = useState("");
  const [action, setAction] = useState("");
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);

  const [status, setStatus] = useState("caption_pending");
  const [clip, setClip] = useState();
  const [movement, setMovement] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [pageNoS, setPageNoS] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [isPages, setIsPages] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [editVideo, setEditVideo] = useState();
  useEffect(() => {
    if (highlights === undefined && search1.length) {
      gethighlight23();
    }
  }, [search1, pageNoS]);

  const gethighlight23 = () => {
    let obj = { query: search1, page: pageNoS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/highlight/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          sethighlights(data);
          setTotalPages(1);
          setIsPages(false);
        } else {
        }
      });
  };
  useEffect(() => {
    if (search2.length) {
      gethighlight24();
    }
  }, [search2, pageNoS]);

  const gethighlight24 = () => {
    let obj = { query: search2, page: pageNoS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/folder/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setfolderVideo(data);
          setTotalPages(1);
          setIsPages(false);
        } else {
        }
      });
  };
  useEffect(() => {
    if (folderVideo === undefined) {
      setLoadeing(true);
      getfolderVideo();
    }
  }, [pageNo, callAPI]);

  const getfolderVideo = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));
    let experts = [...[], search1];
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/folder?page=${pageNo}&limit=6&sort_by=${-1}&standard=${standard}&caption=${caption}&status=${status}&expert=${experts}&action=${action}&movement=${movement}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeing(false);
        if (data && data.isSuccess) {
          setfolderVideo(data.folderList);
          setTotalPages(data.totalPages);
          setIsPages(true);

          //   setGenerated({
          //     ...generated,
          //     videos: data.imageList,
          //     totalPages: data.totalPages,
          //   });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = folderVideo.map((m) => (m._id === row._id ? row : m));
    setfolderVideo(all);
    setIsChangeG(true);
  };

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/folder/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isPatched) {
          let all = folderVideo.map((m) =>
            m._id === response.patchedData._id ? response.patchedData : m
          );
          setfolderVideo(all);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };

  const columns = [
    {
      title: "Video",
      dataIndex: "segments",
      key: "segments",
      width: 400,
      render: (text, row) =>
        text && text.length ? (
          <div className="fl-c-mid221-dis">
            <ReactPlayer url={text[0]} className="" controls />
          </div>
        ) : null,
    },
    {
      title: "Caption",
      dataIndex: "caption",
      key: "caption",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="caption"
          onChange={(e) => {
            changeValue(row, "caption", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ caption: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="actions"
          onChange={(e) => {
            changeValue(row, "action", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ action: text }, row._id);
            }
          }}
          autoSize={{ minRows: 1, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },
    {
      title: "Movement",
      dataIndex: "movement",
      key: "movement",
      width: 30,
      render: (text, row) => (
        <Select
          value={text}
          showSearch
          placeholder="Movement"
          optionFilterProp="children"
          onChange={(value) => {
            changeValue(row, "movement", value);
            updateValue({ movement: value }, row._id);
          }}
          style={{ width: "120px" }}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: "walk",
              label: "walk",
            },
            {
              value: "run",
              label: "run",
            },
            {
              value: "jump",
              label: "jump",
            },
            {
              value: "swim",
              label: "swim",
            },
            {
              value: "punch",
              label: "punch",
            },
            {
              value: "fight",
              label: "fight",
            },
            {
              value: "shoot",
              label: "shoot",
            },
            {
              value: "cry",
              label: "cry",
            },
            {
              value: "fly",
              label: "fly",
            },

            {
              value: "crawl",
              label: "crawl",
            },
            {
              value: "climb",
              label: "climb",
            },
            {
              value: "slice",
              label: "slice",
            },
            {
              value: "sing",
              label: "sing",
            },
            {
              value: "chase",
              label: "chase",
            },
          ]}
        />
      ),
    },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      width: 30,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="Undefined"
          style={{ width: 120 }} // Set the desired width in pixels
          value={text ? text : "Undefined"}
          onChange={(value) => {
            changeValue(row, "standard", value);
            updateValue({ standard: value }, row._id);
          }}
        >
          <Option value={"poor"}>Poor</Option>
          <Option value={"review"}>Review</Option>
          <Option value={"repeat"}>Repeat</Option>
          <Option value={"confirmed"}>Confirmed</Option>
        </Select>
      ),
    },

    {
      title: "Keywords",
      dataIndex: "keywords",
      key: "keywords",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="keywords"
          onChange={(e) => {
            changeValue(row, "keywords", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ keywords: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },

    {
      title: "Core Label",
      dataIndex: "core_label",
      key: "core_label",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="core_label"
          onChange={(e) => {
            changeValue(row, "core_label", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ core_label: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 30,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="Undefined"
          style={{ width: 120 }} // Set the desired width in pixels
          value={text ? text : "Undefined"}
          onChange={(value) => {
            changeValue(row, "status", value);
            updateValue({ status: value }, row._id);
          }}
        >
          <Option value={"caption_pending"}>Caption Pending</Option>
          <Option value={"complete"}>Complete</Option>
        </Select>
      ),
    },

    {
      title: "Usage",
      dataIndex: "usage",
      key: "usage",
      width: 30,
      render: (text, row) => (
        <InputNumber
          value={text}
          placeholder="usage"
          onChange={(e) => {
            changeValue(row, "usage", e);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ usage: text }, row._id);
            }
          }}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      width: 30,
      render: (text, row) => (
        <div>
          <Button onClick={() => setEditVideo(row)} size="small">
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };
  const formatter = (value) => TimeCorrection(value);

  const saveClip = () => {
    let obj = {
      start_time: TimeCorrection(startT),
      end_time: TimeCorrection(endT),
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/trim_video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setEditLoading(false);

        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };
  const saveSpeed = () => {
    let obj = {
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/speed/video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setEditLoading(false);

        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };
  const saveSlow = () => {
    let obj = {
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/slow/video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setEditLoading(false);

        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="table-top-nav">
        <AddNew folderVideo={folderVideo} setfolderVideo={setfolderVideo} />
        <span>
          <Input
            value={caption}
            onChange={(e) => {
              setCaption(e.target.value);
              setfolderVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            placeholder="Caption"
            style={{ width: "200px", marginLeft: "10px", borderRadius: "20px" }}
            allowClear
          />
          <Input
            value={action}
            onChange={(e) => {
              setAction(e.target.value);
              setfolderVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            placeholder="Action"
            style={{ width: "200px", marginLeft: "10px", borderRadius: "20px" }}
            allowClear
          />
          <Select
            placeholder="Standard"
            onChange={(v) => {
              setstandard(v);
              setfolderVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            value={standard}
            style={{ width: "160px", marginLeft: "10px" }}
          >
            <Option value={""}>All Standard</Option>
            <Option value={"poor"}>Poor</Option>
            <Option value={"review"}>Review</Option>
            <Option value={"repeat"}>Repeat</Option>
            <Option value={"confirmed"}>Confirmed</Option>
            <Option value={"processed"}>Processed</Option>
          </Select>
          <Select
            placeholder="Status"
            onChange={(v) => {
              setStatus(v);
              setfolderVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            value={status}
            style={{ width: "160px", margin: "0px 10px" }}
          >
            <Option value={""}>All Status</Option>

            <Option value={"caption_pending"}>Caption Pending</Option>
            <Option value={"complete"}>Complete</Option>
            <Option value={"related"}>Related</Option>
            <Option value={"done"}>Done</Option>
          </Select>
          <Select
            value={movement}
            showSearch
            placeholder="Movement"
            optionFilterProp="children"
            onChange={(value) => {
              setMovement(value);
              setfolderVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            style={{ width: "150px" }}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: "",
                label: "All Movement",
              },
              {
                value: "walk",
                label: "walk",
              },
              {
                value: "run",
                label: "run",
              },
              {
                value: "jump",
                label: "jump",
              },
              {
                value: "swim",
                label: "swim",
              },
              {
                value: "punch",
                label: "punch",
              },
              {
                value: "fight",
                label: "fight",
              },
              {
                value: "shoot",
                label: "shoot",
              },
              {
                value: "cry",
                label: "cry",
              },
              {
                value: "fly",
                label: "fly",
              },

              {
                value: "crawl",
                label: "crawl",
              },
              {
                value: "climb",
                label: "climb",
              },
              {
                value: "slice",
                label: "slice",
              },
              {
                value: "sing",
                label: "sing",
              },
              {
                value: "chase",
                label: "chase",
              },
            ]}
          />
          <AutoComplete
            options={
              highlights &&
              highlights.map((m) => {
                return { value: m.text };
              })
            }
            onSelect={(e) => {
              setStatus("");
              setSearch1(e);
              setPageNo(1);
              setfolderVideo();
              setCallAPI(callAPI + 1);
            }}
            onSearch={(e) => {
              setSearch1(e);
              setPageNoS(1);
              sethighlights();
            }}
            value={search1}
            placeholder="Experts"
            className="bx-fld-10t"
            style={{ width: "200px", marginRight: "10px" }}
            allowClear
          />

          <Input
            value={search2}
            onChange={(e) => {
              setfolderVideo();
              setPageNoS(1);
              setSearch2(e.target.value);
            }}
            placeholder="Search"
            style={{ width: "200px", marginLeft: "10px", borderRadius: "20px" }}
            allowClear
          />
        </span>
      </div>

      <div style={{ width: "100%", overflowX: "auto" }}>
        {loadeing ? <Spin /> : null}
        {folderVideo ? (
          isPages ? (
            <Table
              pagination={{
                pageSize: 15,
                current: pageNo,
                total: totalPages * 15,
                onChange: (v) => {
                  setfolderVideo();
                  setPageNo(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={folderVideo}
            />
          ) : (
            <Table
              pagination={{
                pageSize: 20,
                current: pageNoS,
                total: 20 * 20,
                onChange: (v) => {
                  setfolderVideo();
                  setPageNoS(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={folderVideo}
            />
          )
        ) : null}
      </div>

      <Modal
        visible={editVideo !== undefined}
        onCancel={() => {
          setEditVideo();
          setClip();
        }}
        width={600}
        className="no-buttons-modal "
      >
        {editVideo ? (
          <div>
            <div>
              <b>{editVideo.query}</b>
            </div>

            <div className="video-container radius-video">
              <ReactPlayer
                controls
                url={editVideo.segments && editVideo.segments[0]}
                playing
              />
            </div>

            {clip ? (
              <div>
                <div className="video-container radius-video">
                  <ReactPlayer
                    controls
                    url={clip.file_url ? clip.file_url : clip.file_ur}
                    playing
                  />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setClip();
                    }}
                  >
                    Cancel
                  </Button>
                  {clip && clip.file_url ? (
                    <Button
                      onClick={() => {
                        let seg = editVideo.segments;
                        seg[0] = clip?.file_url;
                        updateValue({ segments: seg }, editVideo?._id);
                        setClip();
                        setEditVideo({
                          ...editVideo,
                          segments: seg,
                          isCliped: true,
                        });
                      }}
                      type="primary"
                    >
                      Save Clip
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        updateValue(
                          { file_url: clip?.file_ur },
                          editVideo?._id
                        );
                        setClip();
                        setEditVideo();
                      }}
                      type="primary"
                    >
                      Save Clip
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <Slider
                  tooltip={{
                    placement: "bottom",
                    formatter,
                  }}
                  range
                  value={[startT, endT]}
                  defaultValue={[startT, endT]}
                  onChange={(v) => {
                    setStartT(v[0]);
                    setEndT(v[1]);
                  }}
                />
                {editLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Button
                      type="primary"
                      onClick={() => {
                        setEditLoading(true);
                        saveClip();
                      }}
                    >
                      Clip
                    </Button>
                    <Button
                      style={{ margin: "0px 10px" }}
                      type="primary"
                      onClick={() => {
                        setEditLoading(true);
                        saveSlow();
                      }}
                    >
                      Slow
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        setEditLoading(true);
                        saveSpeed();
                      }}
                    >
                      Speed
                    </Button>
                  </div>
                )}
              </div>
            )}
            <div></div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
