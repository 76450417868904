import React from "react";

import AllElements from "../AllElements";

export default function Td({ props }) {
  return (
    <td {...props}>
      <AllElements props={props} />
    </td>
  );
}
