import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  Row,
  Col,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import ReactPlayer from "react-player";
import TagsGroup from "../../../components/TagsGroup";

const { Option } = Select;
export default function AddNewC({ content, setcontent }) {
  const [addNew, setAddNew] = useState();

  const AddValue = (obj, id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/content`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isCreated) {
          let all = [response.content_payload, ...content];
          setcontent(all);

          setAddNew();
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  return (
    <div>
      <Button onClick={() => setAddNew({ mappingType: "" })}>Add</Button>
      <Modal
        visible={addNew !== undefined}
        onCancel={() => {
          setAddNew();
        }}
        width={400}
        className="no-buttons-modal "
      >
        {addNew ? (
          <div>
            <h5>Add Category</h5>
            <Select
              placeholder="Undefined"
              style={{ width: 200 }}
              value={addNew.mappingType}
              onChange={(value) => {
                setAddNew({ ...addNew, mappingType: value });
              }}
            >
              <Option disabeled value={""}>Select Mapping Type</Option>
              <Option value={"equivalent"}>Equivalent</Option>
              <Option value={"explicit"}>Explicit</Option>
            </Select>
            <h5 style={{marginTop:"20px"}}>Add Synonyms</h5>
            <TagsGroup
              tags={addNew.synonyms ? addNew.synonyms : []}
              color="black"
              onTagChange={(v) => {
                setAddNew({ ...addNew,synonyms: v  });
              }}
            />
            <div className="addd-new0bx">
              <Button
                onClick={() => {
                  AddValue(addNew);
                  setAddNew();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
