import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Switch, message } from "antd";
import "./action.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
export default function Action() {
  const navigate = useNavigate();
  const [action, setAction] = useState({ pageNo: 1 });

  const [callApi, setCallApi] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (action.data === undefined) {
      getImages();
    }
  }, [callApi]);
  const getImages = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://api.display.video/api/list?page=${
        action.pageNo
      }&limit=15&sort_by=${-1}&status=pending`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setAction({
            ...action,
            data: data.listList,
            totalPages: data.totalPages,
          });
        }
      });
  };
  const changeField = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://api.display.video/api/list/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          const all = action.data.map((a) =>
            a._id === data.listData._id ? data.listData : a
          );
          setAction({
            ...action,
            data: all,
          });
        } else {
          message.error("Something Went Wrong ");
        }
      });
  };
  return (
    <div className="theme-bg-h">
      <div className="lists-ac-m">
        {action.data &&
          action.data.map((d, di) => {
            return (
              <div className="action-crd-bx" key={di}>
                <div className="actin-t-s">
                  <b>{d.action_type}</b>{" "}
                  <Switch
                    className="brand-switch"
                    checked={d.value === "on" ? true : false}
                    onChange={(e) =>
                      changeField(
                        {
                          value: e ? "on" : "off",
                        },
                        d._id
                      )
                    }
                  />
                </div>

                <p>{d.description}</p>
                <div className="actin-t-s">
                  <h6>
                    <i>{d.status}</i>
                  </h6>
                  <span className="icos-date-act">
                    <i>{moment(d.createdAt).fromNow()}</i>
                    <BsFillArrowRightCircleFill
                      onClick={() => {
                        setAction({
                          ...action,
                          activeAction: d,
                        });
                        navigate(`/action/${d._id}`);
                      }}
                    />
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
