import React from "react";

export default function H3({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <h3 {...props}>{props.text}</h3>
    </>
  );
}
