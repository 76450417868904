import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Spin,
} from "antd";
import { CloseOutlined, TagFilled } from "@ant-design/icons";

import ConceptsTop from "./ConceptsTop";
import { TimeCorrection } from "../../../components/GlobalFunctions";
const { Option } = Select;
export default function Label() {
  const { label, setlabel, user } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [clipArray, setClipArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (label.images === undefined) {
      getVideoTask();
    }
  }, [callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${label.pageNo}&limit=40&video_type=concept_image&headclip_id=${label.activeVideo._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setlabel({
            ...label,
            images: data.segmentList,
            totalPages: data.totalPages,
          });
        }
      });
  };

  const updateConcept = (val, con) => {
    console.log(val, con);
    let concepts = con.concepts;
    const falseLables = label.activeVideo.concept_index
      .filter((f) => f.indexed === false)
      .slice(0, 5);
    for (let i = 0; i < falseLables.length; i++) {
      concepts = concepts.filter((f) => f !== falseLables[i].concept_name);
    }
    for (let i = 0; i < val.length; i++) {
      concepts = concepts.filter((f) => f !== val[i]);
      concepts.push(val[i]);
    }
    con.concepts = concepts;
    const images = label.images.map((l) => (l._id === con._id ? con : l));
    setlabel({
      ...label,
      images,
    });
  };
  const updateFiled = (obj, id, type) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (type === "indexed") {
            updateTimeSheet(label.activeVideo);
          }
        } else {
          message.error("something went wrong");
        }
      });
  };
  const updateTimeSheet = (video) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/timesheet?model_id=${user.user_id}&sheet_duration=progress&sheet_type=label&video_id=${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data &&
          data.isSuccess &&
          data.timesheetList &&
          data.timesheetList.length
        ) {
          updateTID(data.timesheetList[0]._id);
        }
      });
  };

  const updateTID = (id) => {
    const obj = {
      sheet_duration: "indexed",
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/timesheet/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          const all = label[label.activeTab].map((r) =>
            r._id === label.activeVideo._id
              ? {
                  ...label.activeVideo,
                  search_status: "indexed",
                }
              : r
          );

          setLoading(false);
          setlabel({
            ...label,
            activeVideo: undefined,
            [label.activeTab]: all,
            images: undefined,
            pageNo: 1,
            pageNo4: 1,
          });
          message.success(`Whole Video Complete `);
        } else {
          setLoading(false);
          message.error("something went wrong");
        }
      });
  };
  const postNewClip = (video, image) => {
    const formData = new FormData();
    formData.append("user_id", video.user_id);
    formData.append("video_name", video.video_name);
    formData.append("video_type", "subclip");
    formData.append("status", "automated");
    // formData.append("label", video.label);
    formData.append("file_link", image.headclip_id);
    formData.append("startTime", image.startTime);
    formData.append("endTime", image.endTime);
    formData.append("headclip_id", video._id);

    const labels = image.concepts.filter((lfc) =>
      video.concept_index
        .filter((f) => f.indexed === false)
        .filter((f) => f.concept_name === lfc).length
        ? true
        : false
    );
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].trim() !== "") {
        formData.append("concepts", labels[i]);
      }
    }

    const requestOptions = {
      method: "POST",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/clip`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data && data.isSuccess) {
          message.success("Success");
        } else {
          message.error("Data Not Saved Try Again ");
        }
      });
  };

  const postCombine = (video, conceptValue) => {
    const formData = new FormData();
    formData.append("method", "headclip");
    formData.append("concept_value", conceptValue);
    formData.append("video_name", video.video_name);
    formData.append("user_id", video.user_id);

    formData.append("concepts", conceptValue);
    formData.append("headclip_id", video._id);
    formData.append("video_type", "supercut");
    formData.append("status", "automated");
    formData.append("int_label", "1");
    formData.append("label", "1");

    const requestOptions = {
      method: "POST",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/combine`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Added");
        } else {
          message.error("Something Went Wrong");
          const all = clipArray.filter((f) => f !== conceptValue);
          setClipArray(all);
        }
      });
  };

  return (
    <div>
      {label.activeVideo.concept_index &&
      label.activeVideo.concept_index.filter((f) => f.indexed === false)
        .length ? (
        <div>
          <ConceptsTop />

          <Row>
            <Col md={{ span: 21 }}>
              <div className="sel-s-c">
                <h5>
                  {label.activeVideo.video_name}{" "}
                  <CloseOutlined
                    className="eye-icn-c close-icn"
                    onClick={() =>
                      setlabel({
                        ...label,
                        activeVideo: undefined,
                        images: undefined,
                        pageNo: 1,
                        pageNo4: 1,
                      })
                    }
                  />
                </h5>
              </div>

              <div>
                {label.images && label.images.length === 0 ? (
                  <h1>No Images Can Press Done</h1>
                ) : null}
                <Row>
                  {label.images &&
                    label.images.map((lb, lbi) => {
                      return (
                        <Col key={lbi} md={{ span: 8 }}>
                          <div className="lbl-c-col">
                            <Card>
                              <img
                                onClick={() => setViewFile(lb.file_url)}
                                className="lbl-mn-img"
                                src={lb.file_url}
                                alt="img"
                              />
                              <div className="lbl-s-e-c">
                                {label.activeVideo.concept_index ? (
                                  <Select
                                    style={{ width: "100%" }}
                                    value={lb.concepts.filter((lfc) =>
                                      label.activeVideo.concept_index
                                        .filter((f) => f.indexed === false)
                                        .slice(0, 5)
                                        .filter((f) => f.concept_name === lfc)
                                        .length > 0
                                        ? true
                                        : false
                                    )}
                                    onChange={(v) => updateConcept(v, lb)}
                                    onBlur={(v) =>
                                      updateFiled(
                                        { concepts: lb.concepts },
                                        lb._id
                                      )
                                    }
                                    // onChange={(v) => console.log(v)}
                                    placeholder="Select Label"
                                    mode="multiple"
                                  >
                                    {label.activeVideo.concept_index
                                      .filter((f) => f.indexed === false)
                                      .slice(0, 5)
                                      .map((lb, lbi) => {
                                        return (
                                          <Option
                                            value={lb.concept_name}
                                            key={lbi}
                                          >
                                            <div className="lbl-drp-c">
                                              {lb.concept_name}{" "}
                                            </div>
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                ) : null}
                                <div className="flex-end">
                                  {lb.concepts.filter((lfc) =>
                                    label.activeVideo.concept_index
                                      .filter((f) => f.indexed === false)
                                      .filter((f) => f.concept_name === lfc)
                                      .length
                                      ? true
                                      : false
                                  ).length ? (
                                    <div>
                                      {lb.file_url === loader ? (
                                        <Spin />
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            postNewClip(label.activeVideo, lb);
                                            setLoader(lb.file_url);
                                          }}
                                          type="primary"
                                          size="small"
                                        >
                                          Clip
                                        </Button>
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <div className="page-vid-c">
                  {label.totalPages ? (
                    <Pagination
                      pageSize={40}
                      current={label.pageNo}
                      size="small"
                      total={label.totalPages * 40}
                      onChange={(v) => {
                        setlabel({ ...label, images: undefined, pageNo: v });
                        setCallApi(callApi + 1);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </Col>
            <Col md={{ span: 3 }}>
              {" "}
              <Affix offsetTop={10}>
                {/* <LabelSIdebar setViewThumbnails={setViewThumbnails} /> */}
              </Affix>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <div className="sel-s-c">
            <h5>
              {label.activeVideo.video_name}{" "}
              <CloseOutlined
                className="eye-icn-c close-icn"
                onClick={() =>
                  setlabel({
                    ...label,
                    activeVideo: undefined,
                    pageNo: 1,
                    pageNo4: 1,
                  })
                }
              />
            </h5>
          </div>
          <div>
            <h5>Super clip</h5>
            {label.activeVideo.concept_index
              .filter((f) =>
                f.indexed === true &&
                clipArray.filter((cf) => cf === f.concept_name).length === 0
                  ? true
                  : false
              )
              .map((vcn, vci) => {
                return (
                  <div className="clipdvc-" key={vci}>
                    <h6>{vcn.concept_name} </h6>
                    <Button
                      onClick={() => {
                        setClipArray([...clipArray, vcn.concept_name]);
                        postCombine(label.activeVideo, vcn.concept_name);
                      }}
                      type="primary"
                    >
                      Save
                    </Button>
                  </div>
                );
              })}
          </div>
          {label.activeVideo.concept_index.length === clipArray.length ? (
            loading ? (
              <Spin />
            ) : (
              <Button
                onClick={() => {
                  updateFiled(
                    { frame_status: "indexed", model_end: new Date() },
                    label.activeVideo._id,
                    "indexed"
                  );
                  setLoading(true);
                }}
                type="primary"
              >
                Press Button to Complete Video
              </Button>
            )
          ) : null}
        </div>
      )}

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          <img src={viewFile} alt="" />;
        </div>
      </Modal>
    </div>
  );
}
