import {
  Button,
  Input,
  message,
  Modal,
  Upload,
  Select,
  DatePicker,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import Icons from "../../../components/Icons";
import VideosUploading from "./VideosUploading";

const { TextArea } = Input;
const { Option } = Select;

export default function AddVideo() {
  const [display, setDisplay] = useState(false);
  const { videos, setVideos, videosBeingUploaded, setVideosBeingUploaded } =
    useContext(SearchContext);
  const [UploadedFile, setUplaoded] = useState([]);
  const [video_name, setVideoName] = useState("");
  const [channel, setChannel] = useState("");
  const [platform, setPlatform] = useState("");

  const [date, setVideoDate] = useState("");
  const [description, setDescription] = useState("");
  const [UT, uploadType] = useState();
  const [videolink, setVideoLink] = useState("");
  const [lisUpdate, setlisUpdate] = useState();

  useEffect(() => {
    if (lisUpdate) {
      setVideos({
        ...videos,
        dataList: lisUpdate,
      });
    }
  }, [lisUpdate]);

  const saveFile = () => {
    const formData = new FormData();
    formData.append(
      "file",
      UploadedFile.length ? UploadedFile[0].originFileObj : ""
    );
    formData.append("user_id", "646919468e0568df1ec2a589");
    formData.append("video_duration", 2);

    formData.append("video_type", "original");
    formData.append("url", videolink.trim());
    formData.append("status", "pending");
    const videoName = video_name.length
      ? video_name
      : UploadedFile.length
      ? UploadedFile[0].name
      : "";
    formData.append("video_name", videoName);
    formData.append("date", date);
    formData.append("platform", platform);
    formData.append("channel", channel.trim());
    formData.append("description", description);

    closeModel();
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    const vidId = Math.random();

    setVideosBeingUploaded((prevState) => {
      return [...prevState, { id: vidId, name: videoName, isLoading: true }];
    });

    fetch(`https://embed.axv.ai/api/image/upload/video`, requestOptions)
      .then((response) => {
        setVideosBeingUploaded((prevState) => {
          const newState = [...prevState];

          const idx = newState.findIndex((el) => el.id === vidId);
          if (idx > -1) {
            newState[idx].isLoading = false;
            newState[idx].success = response.status === 200;
          }

          return newState;
        });

        return response.json();
      })
      .then((data) => {
        if (data && data.isSuccess) {
          const list = videos.dataList ? videos.dataList : [];
          if (list.length) {
            list.pop();
          }
          list.unshift(data.newVideo);
          setVideos({
            ...videos,
            dataList: [],
          });
          setlisUpdate(list);
        }
        if (data && data.msgError) {
          notification.error({
            message: data.msgError,
            description: `Video =  ${videoName}`,
            duration: 0,
          });
        }
      })
      .finally(() => {});
  };
  const onVideoRemove = (vidId) => {
    setVideosBeingUploaded((prevState) => {
      const newState = [...prevState];

      const filtered = newState.filter((el) => el.id !== vidId);

      return filtered;
    });
  };

  const closeModel = () => {
    setUplaoded([]);
    setVideoName("");
    setVideoDate("");
    setDisplay(false);
    uploadType();
    setVideoLink("");
    setChannel("");
    setPlatform("");
    setDescription("");
  };

  const props = {
    name: "file",
    status: "done",
    multiple: false,
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      console.log(info);
      setUplaoded(info.fileList);
      // console.log(info.file.originFileObj);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };
  const changeVideoLink = (l) => {
    if (l.indexOf("https://") !== -1) {
      let link = l;
      if (l.indexOf("www.youtube.com") !== -1) {
        link = l.indexOf("&") === -1 ? l : l.slice(0, l.indexOf("&"));
      } else if (
        l.indexOf("netflix.com") !== -1 ||
        l.indexOf("www.tiktok.com") !== -1 ||
        l.indexOf("twitter.com") !== -1
      ) {
        link = l.indexOf("&") === -1 ? l : l.slice(0, l.indexOf("?"));
      }
      setVideoLink(link);
    }
  };

  return (
    <>
      <VideosUploading
        data={videosBeingUploaded}
        onVideoRemove={onVideoRemove}
      />
      <Button
        size="small"
        onClick={() => {
          setDisplay(true);
        }}
        type="primary"
      >
        Upload
      </Button>
      <Modal
        className="no-h-bo-b-modal top-20-x brand-modal"
        visible={display}
        onCancel={closeModel}
        width={600}
      >
        <div>
          <h5 className="create-btn-h ">
            Content Upload <img src={Icons[UT]} alt="" />
          </h5>
          <div className=" bx-form-brand">
            <div className="vid-ad-vx tag-grou-lake-c">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h6>Platform</h6>
                    </td>
                    <td>
                      {" "}
                      <Select
                        placeholder="Platform"
                        onChange={(v) => setPlatform(v)}
                        value={platform}
                      >
                        <Option value="twitter">Twitter</Option>
                        <Option value="tiktok">TikTok</Option>
                        <Option value="youtube">YouTube</Option>
                        <Option value="netflix">Netflix</Option>
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <td className="link-bx-tt">
                      <h6>Link:</h6>
                    </td>
                    <td>
                      <div className="link-i-s">
                        <TextArea
                          value={videolink}
                          placeholder="Video Link"
                          onChange={(e) => {
                            changeVideoLink(e.target.value);
                          }}
                          className="link-input-cs"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Channel</h6>
                    </td>
                    <td>
                      <Input
                        value={channel}
                        placeholder="Video Name"
                        onChange={(e) => setChannel(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Name</h6>
                    </td>
                    <td>
                      <Input
                        value={video_name}
                        placeholder="Video Name"
                        onChange={(e) => setVideoName(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="link-bx-tt">
                      <h6>Description:</h6>
                    </td>
                    <td>
                      <div className="link-i-s">
                        <TextArea
                          value={description}
                          placeholder="Description"
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          className="link-input-cs"
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Video</h6>
                    </td>
                    <td>
                      {display ? (
                        <Upload
                          accept="3GPP, AVI, FLV, MOV, MPEG4, MPEGPS, WebM , MPEG4"
                          {...props}
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <h6>Date</h6>
                    </td>
                    <td>
                      <DatePicker
                        value={date}
                        onChange={(e) => setVideoDate(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mrgn-btn">
              {UploadedFile.length ? (
                <Button
                  type="primary"
                  onClick={() => {
                    saveFile();

                    if (window.localStorage.getItem("view") === null) {
                      window.localStorage.setItem("view", 1);
                    }
                  }}
                >
                  Upload
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
