import { Alert } from "antd";
import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { BiLineChart, BiBarChart } from "react-icons/bi";

export default class Chart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEmpty: false,
      chartType: "bar",
      series: [],
      options: {
        
        chart: {
          toolbar: false,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },

        yaxis: {
          title: {
            text: "Minutes",
          },
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  componentDidMount() {
    this.convertData(this.props.data.totalPerDay);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.chartType !== this.state.chartType) {
      this.setState({
        display: true,
      });
    }
  }

  convertData = (data) => {
    let { series, options } = this.state;
    series = [];
    let empty = true;
    options.xaxis.categories = [];
    console.log(data);
    const name = "aa";
    const amount = [];
    const date = [];
    for (let i = 0; i < data.length; i++) {
      date.push(data[i]._id);
      amount.push(data[i].count);
      if (data[i].count > 0) {
        empty = false;
      }
    }
    series.push({ name, data: amount });
    options.xaxis.categories = date;
    this.setState({ series, options, display: true, isEmpty: empty });
  };

  render() {
    const { chartType, display, isEmpty } = this.state;
    return (
      <div>
        <div className="chr-ty-c">
          <BiBarChart
            onClick={() =>
              this.setState({
                chartType: "bar",
                display: false,
              })
            }
            style={{ marginRight: "10px" }}
            className={`brand-icon ${chartType !== "line" ? " act-chrtc" : ""}`}
          />{" "}
          <BiLineChart
            onClick={() =>
              this.setState({
                chartType: "line",
                display: false,
              })
            }
            className={`brand-icon ${chartType === "line" ? " act-chrtc" : ""}`}
          />
        </div>
        {isEmpty ? (
          <div className="no-heigh-div-c">
            <Alert
              message="No"
              description="no non no nononon on ono n on on on on ononono nonono"
              type="info"
              showIcon
              className="brand-alert"
            />
          </div>
        ) : display ? (
          <ReactApexChart
            onClick={() => console.log(this.state)}
            options={this.state.options}
            series={this.state.series}
            type={chartType}
            height={400}
          />
        ) : null}
      </div>
    );
  }
}
