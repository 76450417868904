import React, { useEffect, useState } from "react";
import { Checkbox, DatePicker, Button } from "antd";
import BarChart from "./components/BarChart";
import "./Employee.css";
import moment from "moment";
import BarChartLoader from "../../components/BarChartLoader";

const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";
export default function Employee() {
  const [chartData, setChartData] = useState();
  const [unique, setUnique] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [callAPI, setCallAPI] = useState(1);
  const [query, setQuery] = useState([]);
  const [date, setDate] = useState({
    start: moment(new Date()).subtract(10, "days").format("MM/DD/YYYY"),
    end: moment(new Date()).format("MM/DD/YYYY"),
  });
  const [names, setNames] = useState([
    "AA_01",
    "NA_02",
    "KA_03",
    "NS_04",
    "SK_05",
    "SN_06",
    "BI_07",
    "AY_08",
    "FA_12",
    "NA_13",
    "FR_17",
  ]);

  useEffect(() => {
    if (callAPI !== 1) {
      getEmployee();
    }
  }, [callAPI]);

  const getEmployee = async (e) => {
    const obj = {
      query,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://api.fussy.ai/internal/mamba/count_with_date`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (response && response.length) {
          setChartData(response);
        }
      })
      .catch((err) => {});
  };

  const onChange = (val, name) => {
    if (val) {
      setQuery([
        ...query,
        {
          date: `${date.start} -${date.end}`,
          field: "employee_n",
          value: name,
          is_date: false,
          unique: unique,
        },
      ]);
    } else {
      const filter = query.filter((f) => f.value !== name);
      setQuery(filter);
    }
    setSelectedName(name);
  };

  const changeDate = (e) => {
    const start = moment(e[0]._d).format("MM/DD/YYYY");
    const end = moment(e[1]._d).format("MM/DD/YYYY");
    setDate({ ...date, start, end });
    if (query.length) {
      const newQuery = [...query];
      for (let i = 0; i < newQuery.length; i++) {
        newQuery[i].date = `${start} -${end}`;
      }
      setQuery(newQuery);
    }
  };

  const changeUnique = (val) => {
    if (query.length) {
      const newQuery = [...query];
      for (let i = 0; i < newQuery.length; i++) {
        newQuery[i].unique = val;
      }
      setQuery(newQuery);
    } else {
      setQuery([
        ...query,
        {
          date: `${date.start} -${date.end}`,
          field: "employee_n",
          value: selectedName,
          is_date: false,
          unique: val,
        },
      ]);
    }
  };

  return (
    <div className="container">
      <div className=" bg-light names-box-e">
        <div className="emb-bx-t1">
          <h5>All Employee</h5>
          <div>
            <Checkbox
              checked={unique}
              onChange={(e) => {
                setUnique(e.target.checked);
                changeUnique(e.target.checked);
              }}
            >
              Unique
            </Checkbox>
            <RangePicker
              onChange={changeDate}
              bordered={false}
              format={dateFormat}
              defaultValue={[
                moment(date.start, dateFormat),
                moment(date.end, dateFormat),
              ]}
            />
          </div>
        </div>
        <div className="namex-bc-2">
          {names.map((n, i) => {
            return (
              <div className="check-emp-b" key={i}>
                <Checkbox
                  checked={query.filter((f) => f.value === n).length}
                  onChange={({ target }) => onChange(target.checked, n)}
                >
                  {n}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="get-btn-emp">
          {loading ? null : (
            <Button
              onClick={() => {
                setCallAPI(callAPI + 1);
                setLoading(true);
                setChartData();
              }}
              type="primary"
            >
              Get
            </Button>
          )}
        </div>
      </div>
      <div>
        {loading ? (
          <div className="bc-load">
            <BarChartLoader />
          </div>
        ) : null}
        {chartData ? (
          <div className="shadow-sm">
            <BarChart data={chartData} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
