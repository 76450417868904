import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { FaUser, FaLock } from "react-icons/fa";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  message,
  notification,
  Modal,
} from "antd";
import { Button as BButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AiFillMail } from "react-icons/ai";
import bcrypt from "bcryptjs";
import SearchContext from "../../../Context/Search/SearchContext";


const NormalLoginForm = () => {
  const { loading, setLoading, setUser, user } = useContext(SearchContext);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [verifyD, setVerifyD] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [vError, setVError] = useState();
  const [u, setU] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    addUserLogin(form);
  };

  const addUserLogin = (user) => {
    const pass = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    const obj = {
      password: pass,
      email: user.email,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/consumer/auth/login`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isLogged && response.email_verified) {
          window.localStorage.setItem("user", JSON.stringify(response));
          setForm({
            email: "",
            password: "",
          });
          navigate("/");
          setLoading(false);
          setUser(response);
        } else if (!response.email_verified && response.isLogged) {
          setVerifyD(true);
          setU(response);
        } else {
          notification.error({
            message: "Error",
            description: response.msgError,
          });
        }
      });
  };

  const changeField = (key, val, row) => {
    // console.log(key, val, row);
    row[key] = val;
    setForm({ ...form, [key]: val });
  };

  const verifyEmail = () => {
    const obj = {
      code: Number(verifyCode),
      email: form.email,
      verification_for: "new_registration",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/verification/verify`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let usr = u;
          usr.email_verified = true;
          window.localStorage.setItem("user", JSON.stringify(usr));
          setForm({
            email: "",
            password: "",
          });
          navigate("/");
          setLoading(false);
          setUser(usr);
        } else {
          setVError(response.msgError);
        }
      });
  };
  return (
    <>
      <Form onSubmit={handleSubmit} className="login-form">
        <h4>Log In</h4>
        <Form.Item>
          <Input
            onChange={(e) =>
              changeField("email", e.target.value.toLowerCase(), form)
            }
            size="large"
            prefix={<AiFillMail />}
            placeholder="Email"
            value={form.email}
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            onChange={(e) => changeField("password", e.target.value, form)}
            size="large"
            prefix={<FaLock />}
            type="password"
            placeholder="Password"
            value={form.password}
          />
        </Form.Item>
        <Form.Item>
          <div className="for-reg-c">
            <a className="login-form-forgot" href="">
              Forgot password
            </a>
            <div>
              Or <Link to="/signup">register now!</Link>
            </div>
          </div>
          <div className="login-btn-c">
            <Button
              onClick={handleSubmit}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Modal
        width={400}
        className="no-h-bo-b-modal no-close-model"
        visible={verifyD}
      >
        <div className="verifybtn-c">
          <h5> Enter Verify Code</h5>
          <p>
            Please check your email <b>{form.email}</b> for verification code
          </p>
          <input
            onChange={(e) => setVerifyCode(e.target.value)}
            className={`form-control ${vError ? " error-input" : ""} `}
            type="number"
            value={verifyCode}
            placeholder="Verify code"
          />
          {vError ? <i>{vError}</i> : null}
          <div className="flex-end">
            <BButton onClick={verifyEmail}>Verify</BButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NormalLoginForm;
