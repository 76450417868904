import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Tag,
  message,
  Tabs,
  Spin,
  Modal,
  Input,
  Pagination,
} from "antd";
import Thumbnails from "./components/Thumbnails";
import { buildQueries } from "@testing-library/react";
import moment from "moment";
import { TimeCorrection } from "../../components/GlobalFunctions";
import { useNavigate } from "react-router-dom";
import AddPlot from "./components/AddPlot";
import { Button } from "react-bootstrap";

const { TabPane } = Tabs;
export default function Plot() {
  const navigate = useNavigate();

  const { plot, setplot, setActive, user, setreview, review } =
    useContext(SearchContext);
  useEffect(() => {
    setActive(window.location.pathname);
  }, []);
  const [callApi, setCallApi] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [verify, setVerify] = useState("");
  const [search, setsearch] = useState("");

  useEffect(() => {
    if (plot[plot.activeTab] === undefined) {
      getVideoTask();
    } else {
      setLoading2(false);
    }
  }, [callApi, search]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${
        plot.pageNo4
      }&limit=15&frame_status=indexed&hide_file_url=${true}&tags=${verify}&video_name=${search}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setplot({
            ...plot,
            [plot.activeTab]: data.segmentList,
            totalPages4: data.totalPages,
          });
        }
      });
  };

  return (
    <div className="main-bx-lbl">
      <div className="table-top-nav">
        <AddPlot />
        <Input
          value={search}
          onChange={(e) => {
            setplot({
              ...plot,
              [plot.activeTab]: undefined,
              pageNo4: 1,
              totalPages4: 1,
            });
            setsearch(e.target.value);
            setLoading2(true);
          }}
          className="search-d-at"
          placeholder="Search Name "
        />
        {loading2 ? (
          <Spin />
        ) : (
          <Button
            size="sm"
            onClick={() => {
              setplot({
                ...plot,
                [plot.activeTab]: undefined,
                totalPages4: undefined,
                pageNo4: 1,
              });
              setLoading2(true);
              setVerify(verify === "pending" ? "" : "pending");
              setCallApi(callApi + 1);
            }}
          >
            {verify === "pending" ? "All" : "Pending"}
          </Button>
        )}
      </div>
      <div>
        {loading2 ? (
          <Spin />
        ) : plot[plot.activeTab] && plot[plot.activeTab].length === 0 ? (
          <h1 onClick={() => console.log(plot)}>No Video</h1>
        ) : null}
        <Row>
          {plot[plot.activeTab] &&
            plot[plot.activeTab]
              .filter((f) => f.frame_status === "indexed")
              .map((lb, lbi) => {
                return (
                  <Col key={lbi} md={{ span: 6 }}>
                    <div className="lbl-c-col">
                      <Card>
                        <h6>{lb.video_name}</h6>
                        <div className="bx-2-date-tm">
                          <b>
                            Created At:
                            {lb.createdAt ? (
                              <i>
                                {moment(lb.createdAt).format(
                                  "MMMM Do YYYY h:mm:ss"
                                )}
                              </i>
                            ) : null}
                          </b>
                        </div>
                        <div className="start-btn-c">
                          {loading === lb._id ? (
                            <Spin />
                          ) : (
                            <Tag
                              onClick={() => {
                                navigate(`/plot/${lb._id}`);
                              }}
                              color="rgb(24, 24, 145)"
                            >
                              Start
                            </Tag>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                );
              })}
        </Row>

        <div className="page-vid-c">
          {plot.totalPages4 ? (
            <Pagination
              pageSize={15}
              current={plot.pageNo4}
              size="small"
              total={plot.totalPages4 * 15}
              onChange={(v) => {
                setplot({
                  ...plot,
                  [plot.activeTab]: undefined,
                  pageNo4: v,
                });
                setCallApi(callApi + 1);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
