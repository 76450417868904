import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  DatePicker,
  InputNumber,
  message,
  Modal,
  Row,
  Slider,
  Spin,
  Tooltip,
} from "antd";
import Chart from "./Chart";
import { Button, ListGroup } from "react-bootstrap";
import {
  FaCheck,
  FaCheckCircle,
  FaQuestion,
  FaQuestionCircle,
} from "react-icons/fa";
import SearchContext from "../../../Context/Search/SearchContext";
import { TimeCorrection } from "../../../components/GlobalFunctions";

import { BsFillCloudCheckFill, BsFillLightningFill } from "react-icons/bs";
import { GoDatabase } from "react-icons/go";

const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";

export default function Compute() {
  const { user, organization, setOrganization } = useContext(SearchContext);
  const [callAPI, setCallAPI] = useState(1);
  const [Compute, setCompute] = useState();

  const [date, setDate] = useState({
    start: moment(new Date()).subtract(10, "days").format("MM/DD/YYYY"),
    end: moment(new Date()).format("MM/DD/YYYY"),
    minutes: 0,
    storage: 0,
    trigger: 0,
  });

  useEffect(() => {
    getCompute();
  }, [callAPI]);

  const getCompute = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/minute/count?date=${date.start}-${
        date.end
      }&concept_ai=${true}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data && data.isSuccess) {
          setCompute(data);
        }
      });
  };

  const changeDate = (e) => {
    const start = moment(e[0]._d).format("MM/DD/YYYY");
    const end = moment(e[1]._d).format("MM/DD/YYYY");
    setCompute();
    setDate({ ...date, start, end });
    setCallAPI(callAPI + 1);
  };

  return (
    <div className="compute-tab-c">
      <RangePicker
        onChange={changeDate}
        format={dateFormat}
        defaultValue={[
          moment(date.start, dateFormat),
          moment(date.end, dateFormat),
        ]}
      />
      {Compute ? (
        <Row>
          <Col lg={{ span: 18 }} md={{ span: 18 }}>
            <Chart data={Compute} />
          </Col>
          <Col lg={{ span: 6 }} md={{ span: 6 }}>
            <ListGroup className="computer-list-t" as="ul">
              <ListGroup.Item as="li" active>
                <b>Total</b>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <span>
                  Minutes{" "}
                  <Tooltip
                    placement="left"
                    title="prompt text prompt text prompt text"
                  >
                    <FaQuestionCircle />
                  </Tooltip>
                </span>{" "}
                <i>{Compute.totalMinutes}</i>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <span>
                  Compute Cost{" "}
                  <Tooltip
                    placement="left"
                    title="prompt text prompt text prompt text"
                  >
                    <FaQuestionCircle />
                  </Tooltip>
                </span>{" "}
                <i>${Compute.totalComputeCost.toFixed(2)}</i>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <span>
                  Storage Cost{" "}
                  <Tooltip
                    placement="left"
                    title="prompt text prompt text prompt text"
                  >
                    <FaQuestionCircle />
                  </Tooltip>
                </span>{" "}
                <i>${Compute.totalStorageCost.toFixed(2)}</i>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      ) : null}
    </div>
  );
}
