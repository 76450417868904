import React from "react";

import AllElements from "../AllElements";

export default function THead({ props }) {
  return (
    <thead {...props}>
      <AllElements props={props} />
    </thead>
  );
}
