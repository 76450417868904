import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Popover,
} from "antd";
import moment from "moment";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import TagsGroup from "../../components/TagsGroup";
import SearchContext from "../../Context/Search/SearchContext";
const { Option } = Select;
export default function Vision() {
  const { allEmp, user } = useContext(SearchContext);

  const [Emp, setEmp] = useState("");
  const [chapter, setchapter] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [dAdd, setdAdd] = useState();
  const [categroy, setCategory] = useState("");
  const [search, setsearch] = useState("");
  const [sVision, setSVision] = useState("");
  const [moments, setMomets] = useState([]);
  const [general, setgeneral] = useState();
  const [activeID, setactiveId] = useState();
  const [callGeneral, setcallGeneral] = useState("");
  const [channel, setChannel] = useState("");

  useEffect(() => {
    if (chapter === undefined) {
      setLoadeing(true);
      getchapter();
    }
  }, [pageNo, categroy, search, sVision , channel]);

  useEffect(() => {
    getgeneral();
  }, [callGeneral]);

  const getgeneral = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/insight?page=1&limit=15&search=${callGeneral}&category=visual`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setgeneral(data.insightList);
        }
      });
  };
  const getchapter = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/chapter?page=${pageNo}&limit=15&search=${search}&vision=${sVision}&channel=${channel}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setchapter(data.chapterList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };

  const saveChanges = (row, id, save) => {
    console.log(row);
    if (isChange || save) {
      setIsChange(false);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/chapter/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = chapter.filter((t) => t._id !== id);
    setchapter(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/chapter/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = chapter.map((m) => (m._id === row._id ? row : m));
    setchapter(allRow);
  };

  const columns = [
    {
      title: "File url",
      dataIndex: "file_url",
      key: "file_url",
      width: 180,
      render: (text, row) => (
        <Popover
          placement="right"
          content={<img className="img-bx-poup img300c" src={text} alt="" />}
          trigger="hover"
        >
          <img className="img-table-tc" src={text} alt="" />
        </Popover>
      ),
    },
    {
      title: "vision",
      dataIndex: "vision",
      key: "vision",
      width: 200,

      render: (text, row) => (
        <div className="import-things-bfc">
          <div>
            <Select
              showSearch
              style={{ width: "100%" }}
              value={activeID === row._id ? moments : []}
              onChange={(v) => {
                setMomets(v);
                setIsChange(true);
              }}
              onFocus={() => setactiveId(row._id)}
              onBlur={(v) => {
                changeField("vision", moments.concat(text), row);
                saveChanges({ vision: moments.concat(text) }, row._id);
                setMomets([]);
              }}
              onSearch={(e) => setcallGeneral(e)}
              placeholder="Select main object"
              mode="multiple"
            >
              {general &&
                general
                  .filter((f) =>
                    text.filter((fm) => fm === f.value).length ? false : true
                  )
                  .map((lb, lbi) => {
                    return (
                      <Option value={lb.value} key={lbi}>
                        <div className="lbl-drp-c">{lb.value} </div>
                      </Option>
                    );
                  })}
            </Select>
            <div>
              {text &&
                text.slice(0, 8).map((m, mi) => {
                  return (
                    <Tag className="brand-tag" key={mi}>
                      {m}
                      <IoIosClose
                        onClick={() => {
                          changeField(
                            "vision",
                            text.filter((f) => f !== m),
                            row
                          );
                          saveChanges(
                            { vision: text.filter((f) => f !== m) },
                            row._id,
                            true
                          );
                        }}
                        className="hover"
                      />
                    </Tag>
                  );
                })}
              {text && text.length > 8 ? "..." : ""}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 50,
      render: (text, row) => <span>{moment(text).format("MMM Do YYYY")}</span>,
    },
    {
      title: "Video Name",
      dataIndex: "video_name",
      key: "video_name",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={text}
          placeholder="video_name"
          onChange={(e) => {
            changeField("video_name", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ video_name: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Overall Topic",
      dataIndex: "overall_topic",
      key: "overall_topic",
      width: 120,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={text}
          placeholder="overall_topic"
          onChange={(e) => {
            changeField("overall_topic", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ overall_topic: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      width: 220,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={text}
          placeholder="summary"
          onChange={(e) => {
            changeField("summary", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ summary: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="table-top-nav">
        <div className="search-bx-vs-flt">
          <Input
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
              setchapter();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Search "
          />
          <Input
            value={channel}
            onChange={(e) => {
              setChannel(e.target.value);
              setchapter();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Channel Name "
          />
          <Select
            showSearch
            style={{ width: "100%" }}
            value={sVision}
            onChange={(v) => {
              setSVision(v);
              setchapter();
              setPageNo(1);
            }}
            onSearch={(e) => setcallGeneral(e)}
            placeholder="Select main object"
          >
            <Option value={""}>All Vision</Option>
            {general &&
              general.map((lb, lbi) => {
                return (
                  <Option value={lb.value} key={lbi}>
                    <div className="lbl-drp-c">{lb.value} </div>
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {chapter ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setchapter();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={chapter}
        />
      ) : null}

      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Chapter</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
