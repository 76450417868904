import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Tag,
  message,
  Tabs,
  Spin,
  Modal,
  Input,
  Button,
  Pagination,
} from "antd";
import Thumbnails from "./components/Thumbnails";
import { buildQueries } from "@testing-library/react";
import moment from "moment";
import { TimeCorrection } from "../../components/GlobalFunctions";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
export default function Summary() {
  const navigate = useNavigate();

  const { summary, setsummary, setActive, user, setreview, review } =
    useContext(SearchContext);
  useEffect(() => {
    setActive(window.location.pathname);
  }, []);
  const [callApi, setCallApi] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [emp, setEmp] = useState("");

  useEffect(() => {
    if (summary[summary.activeTab] === undefined) {
      getVideoTask();
    } else {
      setLoading2(false);
    }
  }, [callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${
        summary.pageNo4
      }&limit=15&frame_status=indexed&hide_file_url=${true}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setsummary({
            ...summary,
            [summary.activeTab]: data.segmentList,
            totalPages4: data.totalPages,
          });
        }
      });
  };

  return (
    <div className="main-bx-lbl">
      <div>
        {loading2 ? (
          <Spin />
        ) : summary[summary.activeTab] &&
          summary[summary.activeTab].length === 0 ? (
          <h1 onClick={() => console.log(summary)}>No Video</h1>
        ) : null}
        <Row>
          {summary[summary.activeTab] &&
            summary[summary.activeTab].map((lb, lbi) => {
              return (
                <Col key={lbi} md={{ span: 6 }}>
                  <div className="lbl-c-col">
                    <Card>
                      <h6>{lb.video_name}</h6>
                      <div className="bx-2-date-tm">
                        <b>
                          Created At:
                          {lb.createdAt ? (
                            <i>
                              {moment(lb.createdAt).format(
                                "MMMM Do YYYY h:mm:ss"
                              )}
                            </i>
                          ) : null}
                        </b>
                      </div>
                      <div className="start-btn-c">
                        {loading === lb._id ? (
                          <Spin />
                        ) : (
                          <Tag
                            onClick={() => {
                              navigate(`/summary/${lb._id}`);
                            }}
                            color="rgb(24, 24, 145)"
                          >
                            Start
                          </Tag>
                        )}
                      </div>
                    </Card>
                  </div>
                </Col>
              );
            })}
        </Row>

        <div className="page-vid-c">
          {summary.totalPages4 ? (
            <Pagination
              pageSize={15}
              current={summary.pageNo4}
              size="small"
              total={summary.totalPages4 * 15}
              onChange={(v) => {
                setsummary({
                  ...summary,
                  [summary.activeTab]: undefined,
                  pageNo4: v,
                });
                setCallApi(callApi + 1);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
