import {
  Input,
  message,
  Image,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import SearchContext from "../../Context/Search/SearchContext";

const { Option } = Select;
export default function LiveData() {
  const { allEmp, user } = useContext(SearchContext);

  const [list, setlist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [search, setsearch] = useState("");
  const [userId, setUserId] = useState("");
  const [stream_id, setStream_id] = useState("");

  useEffect(() => {
    if (list === undefined) {
      setLoadeing(true);
      getlist();
    }
  }, [pageNo, userId, stream_id, search]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/vector?page=${pageNo}&limit=15&sort_by=${-1}&user_id=${userId}&search=${search}&stream_id=${stream_id}&status=automated`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setlist(data.vectorList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };

  const deleteInvite = (id) => {
    const filtereted = list.filter((t) => t._id !== id);
    setlist(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/vector/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const columns = [
    {
      title: "Url",
      dataIndex: "file_url",
      key: "file_url",
      render: (text, row) => {
        return (
          <>
            <Image width={400} src={text} alt="" />
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "image_description",
      key: "image_description",
      render: (text, row) => (
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>{text}</div>
      ),
    },
    {
      title: "Positive Feedback",
      dataIndex: "positive_feedback",
      key: "positive_feedback",
      render: (text, row) => (
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>{text}</div>
      ),
    },
    {
      title: "Negative Feedback",
      dataIndex: "negative_feedback",
      key: "negative_feedback",
      render: (text, row) => (
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>{text}</div>
      ),
    },

    {
      title: "UX Feedback",
      dataIndex: "ux_feedback",
      key: "ux_feedback",
      render: (text, row) => (
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>{text}</div>
      ),
    },
    {
      title: "stream id",
      dataIndex: "stream_id",
      key: "stream_id",
      render: (text, row) => <span>{text}</span>,
    },
    {
      title: "user id",
      dataIndex: "user_id",
      key: "user_id",
      render: (text, row) => <span>{text}</span>,
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => <span>{text}</span>,
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="table-top-nav">
        <div>
          <Input
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
              setlist();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Search Value "
            size="large"
            allowClear
            style={{marginRight:"10px"}}
          />

          <Input
            value={stream_id}
            onChange={(e) => {
              setStream_id(e.target.value.trim());
              setlist();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Stream ID "
            size="large"
            allowClear
            style={{marginRight:"10px"}}

          />

          <Input
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value.trim());
              setlist();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="User ID "
            size="large"
            allowClear
            style={{marginRight:"10px"}}

          />
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {list ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setlist();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={list}
        />
      ) : null}

      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete List</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
