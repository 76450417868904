import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export default class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          type: "date",
          categories: [],
        },
        yaxis: {
          title: {
            text: "Videos",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return +val + " Videos";
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.convertData(this.props.data);
  }
  convertData = (data) => {
    let { series, options } = this.state;
    series = [];
    options.xaxis.categories = [];
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      const name = data[i].value;
      const amount = [];
      const date = [];
      for (let j = 0; j < data[i]?.amount?.length; j++) {
        amount.push(data[i].amount[j].count);
        date.push(data[i].amount[j]._id);
      }
      series.push({ name, data: amount });
      options.xaxis.categories =
        options.xaxis.categories.length > date.length
          ? options.xaxis.categories
          : date;
    }
    this.setState({ series, options });
  };

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        height={350}
      />
    );
  }
}
