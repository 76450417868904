import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
} from "antd";
import { CloseOutlined, TagFilled } from "@ant-design/icons";

import { FaEye, FaBookmark, FaCross } from "react-icons/fa";
import { BiStar } from "react-icons/bi";
import moment from "moment";

export default function DoneVideo() {
  const { shorts, setshorts } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();

  useEffect(() => {
    if (shorts.images2 === undefined) {
      getVideoTask();
    }
  }, [callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${pageNo}&limit=200&video_type=concept_image&headclip_id=${shorts.activeDone._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setshorts({
            ...shorts,
            images2: data.segmentList,
            totalPages2: data.totalPages,
          });
        }
      });
  };

  return (
    <div>
      <Row>
        <Col md={{ span: 24 }}>
          <div className="sel-s-c tc-tit-lbl">
            <h5>
              {shorts.activeDone.video_name}{" "}
              <CloseOutlined
                className="eye-icn-c close-icn"
                onClick={() =>
                  setshorts({
                    ...shorts,
                    activeDone: undefined,
                    images2: undefined,
                  })
                }
              />
            </h5>
            <div>
              <div className="bx-2-date-tm">
                <div>
                  <Tag color="rgb(32, 32, 124)">
                    {shorts.activeDone.shorts_id}
                  </Tag>
                </div>
                <b>
                  Created At:
                  {shorts.activeDone.createdAt ? (
                    <i>
                      {moment(shorts.activeDone.createdAt).format(
                        "MMMM Do YYYY h:mm:ss"
                      )}
                    </i>
                  ) : null}
                </b>
              </div>
            </div>
          </div>

          <div>
            <Row>
              {shorts.images2 &&
                shorts.images2.map((lb, lbi) => {
                  return (
                    <Col key={lbi} lg={{ span: 6 }} md={{ span: 8 }}>
                      <div className="lbl-c-col">
                        <Card>
                          <img
                            onClick={() => setViewFile(lb.file_url)}
                            className="lbl-mn-img"
                            src={lb.file_url}
                            alt=""
                          />
                          <div className="bx-2-date-tm">
                            <b>
                              Start:
                              <i>{lb.startTime}</i>
                            </b>
                          </div>

                          <div className="txt-desc-dc">{lb.shorts}</div>
                        </Card>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <div className="page-vid-c">
              {shorts.totalPages2 ? (
                <Pagination
                  pageSize={200}
                  current={pageNo}
                  size="small"
                  total={shorts.totalPages2 * 200}
                  onChange={(v) => {
                    setshorts({ ...shorts, images2: undefined });
                    setPageNo(v);
                    setCallApi(callApi + 1);
                  }}
                />
              ) : null}
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={500}
      >
        <div className="view-f-img">
          <img src={viewFile} alt="" />;
        </div>
      </Modal>
    </div>
  );
}
