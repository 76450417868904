import { Button, Progress, Table, Tag } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import AddVideo from "./AddVideo";

export default function DisplayInTable() {
  const { url, setUrl } = useContext(SearchContext);
  const columns = [
    {
      title: "URL",
      dataIndex: "request_url",
      key: "request_url",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Projects",
      dataIndex: "projects",
      key: "projects",
      render: (text, row) => (
        <b>
          {text.map((t, ti) => {
            return <Tag key={ti}>{t}</Tag>;
          })}
        </b>
      ),
    },
    {
      title: "concept_ai",
      dataIndex: "concept_ai",
      key: "concept_ai",
      render: (text, row) => <div>{text ? "Yes" : "No"}</div>,
    },
    {
      title: "transcribe_ai",
      dataIndex: "transcribe_ai",
      key: "transcribe_ai",
      render: (text, row) => <div>{text ? "Yes" : "No"}</div>,
    },
    {
      title: "user_id",
      dataIndex: "user_id",
      key: "user_id",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      render: (text, row) => <AddVideo oVideo={row} />,
    },
  ];

  return (
    <div>
      <div className="vd-tbl-grd">
        {url.videos ? (
          <Table columns={columns} dataSource={url.videos} />
        ) : null}
      </div>
    </div>
  );
}
