import { Alert, Button, Col, Input, message, Row, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import moment from "moment";
let myInterval;
export default function ImagesLens() {
  const { images2, setImages2, user } = useContext(SearchContext);
  const [loadeing, setLoadeing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [callAPI, setCallAPI] = useState(1);
  const [total, setTotal] = useState(1);
  const [ftune, setFtune] = useState();
  const [sheet, setSHeet] = useState({  });

  useEffect(() => {
    if (images2.data === undefined) {
      setLoadeing(true);
      getImages();
    }
  }, [callAPI]);

  useEffect(() => {
    if (total === 0) {
      myInterval = setInterval(() => {
        setLoadeing(true);
        getImages();
      }, 5000);
    }
  }, [total]);

  const getImages = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/image/internal?page=${
        images2.pageNo
      }&limit=15&sort_by=${-1}&predict=pending`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.imageList) {
          setImages2({
            ...images2,
            data: data.imageList,
            totalPages: data.totalPages,
          });

          if (data.imageList.length) {
            var msg = new SpeechSynthesisUtterance();
            const name = user ? user.name : "Sir";
            msg.text = `Hi ${name} You Got Some Work To Do`;
            window.speechSynthesis.speak(msg);
            setTotal(1);
            clearInterval(myInterval);
          } else {
            setTotal(0);
          }
        }
        setLoadeing(false);
      });
  };
  const startClick = (lb) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    const api = `${process.env.REACT_APP_API}/image/${lb._id}`;
    fetch(api, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.imageData) {
          if (data.imageData.predict === "pending") {
            beginProcess({ predict: "progress" }, data.imageData._id);
            setImages2({
              ...images2,
              activeData: data.imageData,
            });
            getTune(data.imageData);
          } else {
            setFtune();
            const filtered = images2.data.filter(
              (f) => f._id !== data.imageData._id
            );
            message.warning("Someone Alreday Started");
            setImages2({
              ...images2,
              data: filtered,
            });
            if (filtered.length) {
              clearInterval(myInterval);
            } else {
              setTotal(0);
            }
          }
        } else {
          message.warning("something went wrong");
        }
      });
  };

  const startTimer = (row) => {
    const obj = {
      model_id: user.user_id,
      // context: row.context,
      predict: row.predict,
      file_url: row.file_url,
      startTime: new Date(),
      sheet_duration: "start",
      video_id:row._id
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(`https://int.display.video/api/timesheet`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isCreated) {
          setSHeet({ ...sheet, id: data.timesheet_payload?._id });
        }
      });
  };
  const updateTID = (id , row) => {
    const obj = {
      sheet_duration: "end",
      endTime: new Date(),
      predict: row.predict
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/timesheet/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
        }
      });
  };

  const getTune = (row) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/image/internal?page=1&limit=15&sort_by=${-1}&media_type=fine_tune&assistant_id=${
        row.assistant_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        startTimer(row)
        if (data && data.isSuccess) {
          setFtune(data.imageList);
        } else {
          // message.warning("Something went wrong");
        }
      });
  };
  const beginProcess = (obj, id, complete) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setLoadingC(false);
        if (data && data.isSuccess) {
          if (complete) {
            const filtered = images2.data.filter(
              (f) => f._id !== data.imageData._id
            );
            setImages2({
              ...images2,
              activeData: undefined,
              data: filtered,
            });
            if (filtered.length) {
              clearInterval(myInterval);
            } else {
              setTotal(0);
            }
            message.success("Successfully added move to next");
          } else {
            message.success("Work Faster");
          }
        } else {
          setImages2({
            ...images2,
            activeData: undefined,
          });
          message.error("something went wrong");
        }
      });
  };
  return (
    <div onClick={() => console.log(ftune)} className="images2-pages-s">
      <Row>
        <Col md={{ span: 10 }} lg={{ span: 10 }}>
          <div>
            {images2.data && images2.data.length === 0 ? (
              <div className="no-heigh-div-c">
                <Alert
                  message="No"
                  description="no non no nononon on ono n on on on on ononono nonono"
                  type="info"
                  showIcon
                  className="brand-alert"
                />
              </div>
            ) : null}
            {images2.data &&
              images2.data.slice(0, 1).map((im, imi) => {
                return (
                  <div className="imgs-bx-cs" key={imi}>
                    <img src={im.file_url} alt="" />
                    {images2.activeData ? null : loading === im._id ? (
                      <Spin />
                    ) : (
                      <Button
                        onClick={() => {
                          setLoading(im._id);
                          startClick(im);
                        }}
                        type="primary"
                      >
                        Start
                      </Button>
                    )}
                  </div>
                );
              })}
          </div>
        </Col>
        <Col md={{ span: 14 }} lg={{ span: 14 }}>
          {images2.activeData ? (
            <div className="images2-right-des-s">
              <h6>{images2.activeData.instructions}</h6>
              <div className="txtbx-c">
                <Input.TextArea
                  placeholder="Answer"
                  value={images2.activeData.predict_lens}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  onChange={(e) =>
                    setImages2({
                      ...images2,
                      activeData: {
                        ...images2.activeData,
                        predict_lens: e.target.value,
                      },
                    })
                  }
                />
                <div className="button-scol-v-col">
                  {/* <div className="comple-btn-dc">
                    {loadingC ? (
                      <Spin />
                    ) : (
                      <Button
                        onClick={() => {
                          setLoadingC(true);
                          beginProcess(
                            {
                              predict: "complete",
                              predict_lens: images2.activeData.predict_lens
                                ? images2.activeData.predict_lens
                                : "",
                            },
                            images2.activeData._id,
                            true
                          );
                          setFtune()
                        }}
                        type="primary"
                      >
                        Complete
                      </Button>
                    )}
                  </div> */}
                  <div className="comple-btn-dc">
                    {loadingC ? (
                      <Spin />
                    ) : (
                      <Button
                        onClick={() => {
                          setLoadingC(true);
                          setFtune()
                          beginProcess(
                            {
                              predict: "verified",
                              predict_lens: images2.activeData.predict_lens
                                ? images2.activeData.predict_lens
                                : "",
                            },
                            images2.activeData._id,
                            true
                          );
                          updateTID(sheet.id , images2.activeData)
                        }}
                        type="primary"
                      >
                        Verify
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <Row>
                {ftune &&
                  ftune.map((im, imi) => {
                    return (
                      <Col
                        style={{ marginBottom: "10px" }}
                        key={imi}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                      >
                        <div className={`img-bx-imgsc `}>
                          <div>
                            <img src={im.file_url} alt="" />
                          </div>
                          <div className="img-bx-imgdt">
                            <div className="img-r-ast-b2">{im.meta_data}</div>

                            {/* <i>{moment(im.createdAt).fromNow()}</i> */}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          ) : (
            <div className="no-heigh-div-c">
              <Alert
                message="No"
                description="no non no nononon on ono n on on on on ononono nonono"
                type="info"
                showIcon
                className="brand-alert"
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
