import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Button,
  InputNumber,
  message,
  Switch,
  Popconfirm,
  Modal,
  Input,
  notification,
  Steps,
  Tag,
} from "antd";
import ReactPlayer from "react-player";
import TagsGroup from "../../components/TagsGroup";
import { FaArrowLeft, FaArrowRight, FaPlay, FaTrash } from "react-icons/fa";
import { TimeCorrection } from "../../components/GlobalFunctions";

const { Step } = Steps;

export default function VideoDetail() {
  const { videoTasks, setVideoTask, videoConcept, setVideoConcept } =
    useContext(SearchContext);
  const playerRef = React.useRef();

  const [callApi, setCallApi] = useState(0);
  const [callApi2, setCallApi2] = useState(0);
  const [notSave, setNotSave] = useState(false);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [searchLabel, setSearchLabel] = useState("");
  const [editTime, setEditTIme] = useState();
  const [timer, setTimer] = useState(31);
  const [sLabel, setSLabel] = useState("");
  const [callCombine, setCallCombine] = useState(0);
  const [timelineList, setTimelineList] = useState();
  const [sAct, setAct] = useState(0);

  useEffect(() => {
    if (videoConcept.concept === undefined) {
      getConcept();
    }
  }, [callApi2]);
  useEffect(() => {
    combineTimeline(sLabel);
  }, [callCombine]);

  const getConcept = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    // fetch(`https://int.display.video/api/channel?page=1&limit=15`, requestOptions)
    fetch(`https://int.display.video/api/concept`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.conceptList) {
          setVideoConcept({
            ...videoConcept,
            concept: data.conceptList,
            // selectedConcepts: [],
          });
        }
        console.log(data, "concept");
      });
  };

  const updateChanges = (video) => {
    // videoConcept;
    if (videoConcept.selectedConcepts.length) {
      if (videoConcept.timestamp) {
        const newTimestamp = {
          concepts: videoConcept.selectedConcepts,
          timestamp: videoConcept.timestamp,
        };
        const oldTimeline = video.timeline ? video.timeline : [];
        oldTimeline.push(newTimestamp);
        video.timeline = oldTimeline;
        video.internal_index = oldTimeline.length;
        let a = video.video_duration ? video.video_duration : "0";
        a = Number(a);
        a = Math.floor(a);
        a = a / 5;
        a = Math.ceil(a);
        video.index_progress = Math.floor((oldTimeline.length / a) * 100);
        updateIntoDB(video);
        setVideoConcept({
          ...videoConcept,
          selectedConcepts: [],
          timestamp: undefined,
        });
        if (videoConcept.selectedConcepts[0] !== "Nothing") {
          postNewClip(video, newTimestamp);
        } else {
          console.log("else");
          postNewClip2(video, newTimestamp);
        }
      } else {
        message.warning("Please Add Timestamp");
      }
    } else {
      updateIntoDB(video);
      setVideoConcept({
        ...videoConcept,
        selectedConcepts: [],
        timestamp: undefined,
      });
    }
  };

  const postNewClip2 = (video, timeline) => {
    let toatSize = Number(video.video_duration);
    toatSize = parseInt(toatSize) / 5;
    toatSize = parseInt(toatSize);
    const obj = {
      internal_index: video.timeline.length,
      user_id: video.user_id,
      video_name: video.video_name,
      video_type: "subclip",
      status: "automated",
      int_description: video.int_description,
      description: video.description,
      end_index: toatSize,
      concepts: "Nothing",
      headclip_id: video._id,
      file_url: video.file_url,
    };

    const requestOptions = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/file_url`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const postNewClip = (video, timeline) => {
    let toatSize = Number(video.video_duration);
    toatSize = parseInt(toatSize) / 5;
    toatSize = parseInt(toatSize);
    const formData = new FormData();
    formData.append("internal_index", video.timeline.length);
    formData.append("user_id", video.user_id);
    formData.append("headclip_id", video._id);

    formData.append("video_name", video.video_name);
    formData.append("video_type", "subclip");
    formData.append("status", "automated");
    formData.append("int_description", video.int_description);
    formData.append("description", video.description);
    formData.append("end_index", toatSize);
    formData.append("file_link", video._id);
    formData.append("startTime", TimeCorrection(timeline.timestamp));
    formData.append(
      "endTime",
      TimeCorrection(timeline.timestamp === 1 ? 5 : timeline.timestamp + 5)
    );

    for (let i = 0; i < timeline.concepts.length; i++) {
      formData.append("concepts", timeline.concepts[i]);
    }

    const requestOptions = {
      method: "POST",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/clip`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const updateIntoDB = (row) => {
    const id = row._id;
    setNotSave(false);
    const formData = new FormData();
    formData.append("internal_index", row.internal_index.toString());
    formData.append("status", row.status ? row.status : "pending");
    formData.append("cleaned", row.cleaned);
    formData.append("video_type", row.video_type);
    formData.append("timeline", JSON.stringify(row.timeline));
    formData.append("video_file", row.file_url);
    formData.append("index_progress", row.index_progress);

    const requestOptions = {
      method: "PUT",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Updated");
        } else {
          message.error("something went wrong");
        }

        console.log(data, "video_segment , update");
      });
  };

  const deleteTimeline = (tim, i, row) => {
    const timelines = row.timeline;
    timelines.splice(i, 1);
    row.timeline = timelines;
    setVideoTask({ ...videoTasks, activeVideo: row });
    setNotSave(true);
  };

  const adjustTimeline = (value, index, row, key) => {
    row.timeline[index][key] = value;
    setVideoTask({ ...videoTasks, activeVideo: row });
    setNotSave(true);
  };

  const saveConcept = (tag) => {
    const { selectedConcepts } = videoConcept;
    const filter = selectedConcepts.filter((t) => t === tag);
    let removed;
    let added;
    if (filter.length) {
      removed = selectedConcepts.filter((t) => t !== tag);
    } else {
      added = [...selectedConcepts, tag];
    }

    if (videoConcept.timestamp === undefined) {
      const vt = videoTasks.activeVideo.timeline;

      setVideoConcept({
        ...videoConcept,
        selectedConcepts: removed ? removed : added,
        timestamp: vt && vt.length ? vt.length * 5 : 1,
      });
    } else {
      setVideoConcept({
        ...videoConcept,
        selectedConcepts: removed ? removed : added,
      });
    }
    setNotSave(true);
  };

  const combineTimeline = (val) => {
    const timelines =
      videoTasks.activeVideo.timeline &&
      videoTasks.activeVideo.timeline.length &&
      videoTasks.activeVideo.timeline.filter(
        (f) => f.concepts.filter((f) => f.toLowerCase().includes(val)).length
      );
    const matchList = timelineMatche(timelines === 0 ? [] : timelines);
    setTimelineList(matchList);
    console.log(matchList);
  };

  const timelineMatche = (timelines) => {
    for (let i = 0; i < timelines.length - 1; i++) {
      if (timelines[i].concepts.length === timelines[i + 1].concepts.length) {
        let match = false;
        for (let j = 0; j < timelines[i].concepts.length; j++) {
          if (
            timelines[i].concepts[j] === timelines[i + 1].concepts[j] &&
            timelines[i + 1].timestamp - timelines[i].timestamp < 6
          ) {
            match = true;
          } else {
            match = false;
            j = timelines[i].concepts.length;
          }
        }

        timelines[i].matched = match;
      } else {
        timelines[i].matched = false;
      }
    }

    const matchList = combineMatche(timelines);

    return matchList;
  };

  const combineMatche = (list) => {
    let index = false;
    console.log(list);
    for (let i = 0; i < list.length; i++) {
      if (list[i].matched) {
        if (index !== false) {
          list[index].end = list[i + 1].timestamp;
          list[i + 1].hidden = true;
        } else {
          list[i].end = list[i + 1].timestamp;
          index = i;
          list[i + 1].hidden = true;
        }
      } else {
        // console.log("else", list[i]);
        index = false;
      }
    }
    return list;
  };

  const videoTimeChange = (i) => {
    console.log(playerRef, i);
    playerRef.current.seekTo(i, "seconds");
  };

  return (
    <div>
      <Row>
        {videoTasks.activeVideo ? (
          <Col md={{ span: 18 }} lg={{ span: 18 }}>
            <div>
              <div className="vt-vid-c">
                <ReactPlayer
                  onPlay={(e) => console.log(e)}
                  controls
                  url={videoTasks.activeVideo.file_url}
                  ref={playerRef}
                />
              </div>
              <div className="sav-ch-btn">
                <span>
                  {notSave ? (
                    <Button
                      onClick={() => updateChanges(videoTasks.activeVideo)}
                      size="small"
                      type="primary"
                    >
                      Save Changes
                    </Button>
                  ) : null}
                </span>
                <span className="crd-bx-cntr">
                  <b>{videoTasks.activeVideo.model_id}</b>
                </span>
              </div>
              {videoConcept.selectedConcepts.length ? (
                <div className="nv-tsk-c">
                  <h6 className="newly-s-h6">
                    Timestamp:
                    {editTime === "edit" ? (
                      <InputNumber
                        min={0}
                        value={videoConcept.timestamp}
                        onChange={(v) =>
                          setVideoConcept({
                            ...videoConcept,
                            timestamp: v,
                          })
                        }
                        onBlur={() => setEditTIme()}
                      />
                    ) : (
                      <b
                        className="tim-c-b-d"
                        onDoubleClick={() => setEditTIme("edit")}
                      >
                        {TimeCorrection(videoConcept.timestamp)}
                      </b>
                    )}
                    <span>(press save changes)</span>
                  </h6>
                  <TagsGroup
                    tags={videoConcept.selectedConcepts}
                    onTagChange={(v) =>
                      setVideoConcept({
                        ...videoConcept,
                        selectedConcepts: v,
                      })
                    }
                  />
                </div>
              ) : null}
              <div className="search-lbl-c">
                <Input
                  value={sLabel}
                  onChange={(e) => {
                    setSLabel(e.target.value.toLowerCase());
                    setCallCombine(callCombine + 1);
                  }}
                  placeholder="Search Label"
                />
              </div>
              <Steps
                className="bx-1---c"
                current={sAct}
                onChange={(v) => setAct(v)}
                progressDot
              >
                {timelineList &&
                  timelineList
                    .filter(
                      (f) =>
                        f.hidden === undefined && f.concepts[0] !== "Nothing"
                    )
                    .map((sm, smi) => {
                      return (
                        <Step
                          onClick={(e) => videoTimeChange(sm.timestamp)}
                          key={smi}
                          subTitle={
                            sm.end
                              ? TimeCorrection(sm.timestamp) +
                                " - " +
                                TimeCorrection(sm.end)
                              : TimeCorrection(sm.timestamp)
                          }
                          description={
                            <div className="tags-blb-c">
                              {sm.concepts.map((cp, cpi) => {
                                return (
                                  <Tag
                                    key={cpi}
                                    color={
                                      videoTasks.activeVideo.cleaned
                                        ? "#87d068"
                                        : "#f50"
                                    }
                                  >
                                    {cp}
                                  </Tag>
                                );
                              })}
                            </div>
                          }
                        />
                      );
                    })}
              </Steps>
            </div>
          </Col>
        ) : null}
        <Col md={{ span: 6 }} lg={{ span: 6 }}>
          <div className="label-h-in-c">
            {/* <h5>Labels</h5> */}
            <Input
              placeholder="Search Label"
              onChange={(e) => setSearchLabel(e.target.value)}
            />
          </div>
          <div className="con-vd-c">
            {videoConcept.concept &&
              videoConcept.concept
                .filter((f) =>
                  f.name.toLowerCase().includes(searchLabel.toLowerCase())
                )
                .map((vt, vti) => {
                  return (
                    <div key={vti} className="select-cn-cc">
                      {/* <div>
                        {vt.saved_images && vt.saved_images.length ? (
                          <img
                            alt=""
                            className="img-thum-con"
                            src={vt.saved_images[0]}
                            onClick={() => setViewThumbnails(vt)}
                          />
                        ) : null}
                      </div> */}
                      <div
                        key={vti}
                        onClick={() => saveConcept(vt.name)}
                        className={`name-bx-field-t3 ${
                          videoConcept.selectedConcepts &&
                          videoConcept.selectedConcepts.filter(
                            (t) => t === vt.name
                          ).length
                            ? " active-icn-f"
                            : ""
                        }`}
                      >
                        {vt.name}
                      </div>
                    </div>
                  );
                })}
          </div>
        </Col>
      </Row>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
