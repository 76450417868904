import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Select, Space } from "antd";
import BarChart from "./components/BarChart";
import "./Analysis.css";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";
export default function Analysis() {
  const [chartData, setChartData] = useState();
  const [field, setField] = useState("");
  const [value, setValue] = useState("");
  const [unique, setUnique] = useState(false);
  const [callAPI, setCallAPI] = useState(1);
  const [query, setQuery] = useState([]);
  const [date, setDate] = useState({
    start: moment(new Date()).subtract(10, "days").format("MM/DD/YYYY"),
    end: moment(new Date()).format("MM/DD/YYYY"),
  });
  const [names, setNames] = useState([
    "AA_01",
    "NA_02",
    "KA_03",
    "NS_04",
    "SK_05",
    "SN_06",
    "BI_07",
    "AY_08",
    "FA_12",
    "NA_13",
  ]);

  useEffect(() => {
    if (callAPI !== 1) {
      getAnalysis();
    }
  }, [callAPI]);

  const getAnalysis = async (e) => {
    const obj = {
      query,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://api.fussy.ai/internal/mamba/count_with_date`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.length) {
          setChartData(response);
        }
      })
      .catch((err) => {});
  };

  const changeDate = (e) => {
    const start = moment(e[0]._d).format("MM/DD/YYYY");
    const end = moment(e[1]._d).format("MM/DD/YYYY");
    setDate({ ...date, start, end });
    if (query.length) {
      const newQuery = [...query];
      for (let i = 0; i < newQuery.length; i++) {
        newQuery[i].date = `${start} -${end}`;
      }
      setQuery(newQuery);
    }
  };

  const changeField = (e) => {
    if (query.length) {
      const newQuery = [...query];
      for (let i = 0; i < newQuery.length; i++) {
        newQuery[i].field = field;
      }
      setQuery(newQuery);
    } else {
      setQuery([
        ...query,
        {
          date: `${date.start} -${date.end}`,
          field: field,
          value: value,
          is_date: false,
        },
      ]);
    }
  };

  const changeValue = (e) => {
    if (query.length) {
      const newQuery = [...query];
      for (let i = 0; i < newQuery.length; i++) {
        newQuery[i].value = value;
      }
      setQuery(newQuery);
    } else {
      setQuery([
        ...query,
        {
          date: `${date.start} -${date.end}`,
          field: field,
          value: value,
          is_date: false,
        },
      ]);
    }
  };

  const changeUnique = (val) => {
    if (query.length) {
      const newQuery = [...query];
      for (let i = 0; i < newQuery.length; i++) {
        newQuery[i].unique = val;
      }
      setQuery(newQuery);
    } else {
      setQuery([
        ...query,
        {
          date: `${date.start} -${date.end}`,
          field: field,
          value: value,
          is_date: false,
          unique: val,
        },
      ]);
    }
  };

  const getChart = () => {
    setChartData();
    setCallAPI(callAPI + 1);
  };

  return (
    <div className="container">
      <div className=" bg-light names-box-e">
        <div className="emb-bx-t1">
          <h5>Analysis</h5>
          <div>
            <Checkbox
              checked={unique}
              onChange={(e) => {
                setUnique(e.target.checked);
                changeUnique(e.target.checked);
              }}
            >
              Unique
            </Checkbox>
            <RangePicker
              onChange={changeDate}
              format={dateFormat}
              defaultValue={[
                moment(date.start, dateFormat),
                moment(date.end, dateFormat),
              ]}
            />
          </div>
        </div>
        <div className="namex-bc-2">
          <Input
            size="large"
            placeholder="Add Field"
            onBlur={changeField}
            value={field}
            onChange={(e) => setField(e.target.value)}
          />

          <Input
            size="large"
            placeholder="Add Value"
            onBlur={changeValue}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>

        <div className="flex-end">
          <Button onClick={getChart}>Get</Button>
        </div>
      </div>
      <div>
        {chartData ? (
          <div className="shadow-sm">
            <BarChart data={chartData} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
