import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  AutoComplete,
  notification,
  Button,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import TagsGroup from "../../components/TagsGroup";
import Action from "./components/Action";
import CountdownTimer from "./components/CountdownTimer";
import StartGame from "./components/StartGame";
import SearchContext from "../../Context/Search/SearchContext";

const { Option } = Select;
export default function FeedPage() {
  const { allEmp, user } = useContext(SearchContext);

  const [callAPI, setCallAPI] = useState(1);
  const [rolePlay, setrolePlay] = useState();
  const [totalPages, setTotalPages] = useState();
  const [status, setStatus] = useState("");
  const [agent_id, setAgent_id] = useState("");
  const [tags, setTags] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [feed_type, setFeed_type] = useState("public_game");

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);

  useEffect(() => {
    if (rolePlay === undefined || callAPI > 1) {
      // setLoadeing(true);

      getrolePlay();
    }
  }, [pageNo, callAPI]);

  const getrolePlay = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/feed?page=${pageNo}&limit=15&sort_by=${-1}&status=${status}&tags=${tags}&agent_id=${agent_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setrolePlay(data.feedList);
          setTotalPages(data.totalPages);
          setTimeout(() => {
            setCallAPI(callAPI + 1);
          }, 10000);
        }

        setLoadeing(false);
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/feed/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isPatched) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = rolePlay.map((m) => (m._id === row._id ? row : m));
    setrolePlay(allRow);
  };

  const gamingColumns = [
    {
      title: "id",
      dataIndex: "_id",
      key: "_id",
      render: (text, row) => <span style={{fontSize:"11px"}}>{text} </span>,
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "Game",
      dataIndex: "game",
      key: "game",
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "Stream ID",
      dataIndex: "stream_id",
      key: "stream_id",
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "Stream",
      dataIndex: "stream_url",
      key: "stream_url",
      render: (text, row) => (
        <a href={row.stream_url} target="blank">
          <Button size="small" type="primary">
            Watch
          </Button>
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "Coundown",
      dataIndex: "date",
      key: "date",
      render: (text, row) => <CountdownTimer date={text} />,
    },
    {
      title: "Runtime",
      dataIndex: "runtime",
      key: "runtime",
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "",
      dataIndex: "01",
      key: "01",
      render: (text, row) => <StartGame saveChanges={saveChanges} row={row} />,
    },
  ];

 
  return (
    <div>
      <div style={{ justifyContent: "center" }} className="table-top-nav">
        <div className="form-add--theme">
          <BButton
            type={status === "" ? "primary" : "default"}
            onClick={() => {
              setAgent_id("");
              setStatus("");
              setrolePlay();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
          >
            All
          </BButton>
          <BButton
            type={status === "pending" ? "primary" : "default"}
            onClick={() => {
              setStatus("pending");
              setrolePlay();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
          >
            Pending
          </BButton>
          <BButton
            type={status === "downloading" ? "primary" : "default"}
            onClick={() => {
              setStatus("downloading");
              setrolePlay();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
          >
            Downloading
          </BButton>
          <BButton
            type={status === "dimulating" ? "primary" : "default"}
            onClick={() => {
              setStatus("dimulating");
              setrolePlay();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
          >
            Simulating
          </BButton>
          <BButton
            type={status === "complete" ? "primary" : "default"}
            onClick={() => {
              setStatus("complete");
              setrolePlay();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
          >
            Complete
          </BButton>
        </div>
      </div>
      {status && status.length ? (
        <div className="table-top-nav">
          <span />
          {agent_id && agent_id.length ? (
            <BButton
              type="primary"
              onClick={() => {
                setAgent_id("");
                setrolePlay();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
            >
              All Task
            </BButton>
          ) : (
            <BButton
              type="primary"
              onClick={() => {
                setAgent_id(user?.user_id);
                setrolePlay();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
            >
              My Task
            </BButton>
          )}
        </div>
      ) : null}

      {loadeing ? <Spin /> : null}
      {rolePlay ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setrolePlay();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={gamingColumns}
          dataSource={rolePlay}
        />
      ) : null}
    </div>
  );
}
