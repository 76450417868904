import React, { useContext, useEffect, useState } from "react";
import { Drawer, Input, Steps, Tabs, Tag } from "antd";
import {
  FileOutlined,
  ShareAltOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Icons from "../../../components/Icons";
import moment from "moment";
import { TimeCorrection } from "../../../components/GlobalFunctions";
const { TabPane } = Tabs;
const { Step } = Steps;
export default function InfoVideo({ card, setSelectedCard }) {
  const [sLabel, setSLabel] = useState("");
  const [sLabel2, setSLabel2] = useState("");
  const [timelineList, setTimelineList] = useState();
  const [labelsList, setLabelsList] = useState();
  const [callCombine, setCallCombine] = useState(0);

  useEffect(() => {
    combineTimeline(sLabel);
    const oneList = timelinefilter(card.timeline);
    setLabelsList(oneList);
  }, [callCombine]);

  const combineTimeline = (val) => {
    const timelines =
      card.timeline &&
      card.timeline.length &&
      card.timeline.filter(
        (f) => f.concepts.filter((f) => f.toLowerCase().includes(val)).length
      );
    const matchList = timelineMatche(timelines);

    setTimelineList(matchList);
    console.log(matchList);
  };

  const timelineMatche = (timelines) => {
    for (let i = 0; i < timelines.length - 1; i++) {
      if (timelines[i].concepts.length === timelines[i + 1].concepts.length) {
        let match = false;
        for (let j = 0; j < timelines[i].concepts.length; j++) {
          if (timelines[i].concepts[j] === timelines[i + 1].concepts[j]) {
            match = true;
          } else {
            match = false;
            j = timelines[i].concepts.length;
          }
        }

        timelines[i].matched = match;
      } else {
        timelines[i].matched = false;
      }
    }

    const matchList = combineMatche(timelines);

    return matchList;
  };

  const combineMatche = (list) => {
    let index = false;
    for (let i = 0; i < list.length; i++) {
      if (list[i].matched) {
        if (index) {
          list[index].end = list[i + 1].timestamp;
          list[i + 1].hidden = true;
        } else {
          index = i;
          list[i].end = list[i + 1].timestamp;
          list[i + 1].hidden = true;
        }
      } else {
        console.log("else", list[i]);
        index = false;
      }
    }
    return list;
  };

  const timelinefilter = (timelines) => {
    let total = [];
    for (let i = 0; i < timelines.length; i++) {
      for (let j = 0; j < timelines[i].concepts.length; j++) {
        const filter = total.filter((t) => t !== timelines[i].concepts[j]);
        filter.push(timelines[i].concepts[j]);
        total = filter;
      }
    }
    return total;
  };

  return (
    <Drawer
      title={
        <div>
          <FileOutlined />
          <b> File Preview</b>
        </div>
      }
      placement="right"
      onClose={setSelectedCard}
      visible={card !== undefined}
    >
      {card ? (
        <div onClick={() => console.log(card)} className="drawer-mxx ">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Labels" key="1">
              <div className="search-lbl-c">
                <Input
                  value={sLabel2}
                  onChange={(e) => {
                    setSLabel2(e.target.value.toLowerCase());
                  }}
                  placeholder="Search Label"
                />
              </div>
              {labelsList &&
                labelsList
                  .filter(
                    (f) => ("Nothing" !== f) & f.toLowerCase().includes(sLabel2)
                  )
                  .map((l, li) => {
                    return (
                      <Tag className="tg-lbl-t1" color="#084b83" key={li}>
                        {l}
                      </Tag>
                    );
                  })}
            </TabPane>
            <TabPane tab="Timelines" className="tm-tb2-in2" key="2">
              <div className="search-lbl-c">
                <Input
                  value={sLabel}
                  onChange={(e) => {
                    setSLabel(e.target.value.toLowerCase());
                    combineTimeline(e.target.value.toLowerCase());
                  }}
                  placeholder="Search Label"
                />
              </div>
              <Steps progressDot>
                {timelineList &&
                  timelineList
                    .filter(
                      (f) =>
                        f.hidden === undefined && f.concepts[0] !== "Nothing"
                    )
                    .map((sm, smi) => {
                      return (
                        <Step
                          key={smi}
                          subTitle={
                            sm.end
                              ? TimeCorrection(sm.timestamp) +
                                " - " +
                                TimeCorrection(sm.end)
                              : TimeCorrection(sm.timestamp)
                          }
                          description={
                            <div className="tags-blb-c">
                              {sm.concepts.map((cp, cpi) => {
                                return (
                                  <Tag key={cpi} color="#87d068">
                                    {cp}
                                  </Tag>
                                );
                              })}
                            </div>
                          }
                        />
                      );
                    })}
              </Steps>
            </TabPane>
          </Tabs>
        </div>
      ) : null}
    </Drawer>
  );
}
