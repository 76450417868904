import React from "react";

export default function B({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <b {...props}>{props.text}</b>
    </>
  );
}
