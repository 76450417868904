import React from "react";

import AllElements from "../AllElements";

export default function TBody({ props }) {
  return (
    <tbody {...props}>
      <AllElements props={props} />
    </tbody>
  );
}
