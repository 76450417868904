import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Upload,
  Collapse,
  Select,
  Tooltip,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useContext, useState } from "react";
import {
  FaArrowLeft,
  FaCheck,
  FaCheckCircle,
  FaCross,
  FaPlus,
  FaQuestionCircle,
} from "react-icons/fa";
import Icons from "../../../components/Icons";
import VideosUploading from "./VideosUploading";
import { Button as BButton } from "react-bootstrap";
// import VideoAdd30 from "../../../components/VideoAdd30";
import { HiScissors } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { TbWorldUpload } from "react-icons/tb";
import { MdCloudUpload, MdOutlineEmojiPeople } from "react-icons/md";
import { BiRun, BiWalk } from "react-icons/bi";
import SearchContext from "../../../Context/Search/SearchContext";

const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;

export default function AddVideo() {
  const { videosBeingUploaded, setVideosBeingUploaded } =
    useContext(SearchContext);

  const [display, setDisplay] = useState(false);

  const [UploadedFile, setUplaoded] = useState([]);
  const [concept_ai, setconcept_ai] = useState(true);
  const [transcribe_ai, setTranscribe_ai] = useState(false);
  const [supercut_ai, setSupercut_ai] = useState(false);
  const [project, setproject] = useState();
  const [fol, setFol] = useState();
  const [ff, setFF] = useState();
  const [video_name, setVideoName] = useState("");
  const [channel, setChannel] = useState("");
  const [date, setVideoDate] = useState("");
  const [description, setDescription] = useState("");
  const [pro, setPro] = useState("");
  const [UT, uploadType] = useState("Upload");
  const [videolink, setVideoLink] = useState("");
  const [display30, setDisplay30] = useState(false);
  const [linkErr, setLinkErr] = useState(false);
  const [lChkErr, setLChkErr] = useState(false);
  const [frequency, setFrequency] = useState("default");

  const findLinkE = (v) => {
    if (
      v.indexOf("www.youtube.com") === -1 &&
      v.indexOf("www.tiktok.com") === -1
    ) {
      setLChkErr(true);
    } else {
      setLinkErr(false);
      setLChkErr(false);
    }
    if (v.length === 0) {
      setLChkErr(false);
    }
  };

  const saveLink = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    if (
      videolink.indexOf("www.youtube.com") === -1 &&
      videolink.indexOf("www.tiktok.com") === -1
    ) {
      setLinkErr(true);
    } else {
      const obj = {
        request_url: videolink,
        user_id: user.user_id,
        concept_ai,
        transcribe_ai,
        projects: "chapter",
      };
      closeModel();

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(obj),
      };
      fetch(`${process.env.REACT_APP_API}/elink`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response && response.isCreated) {
            message.success("Added");
          } else {
            message.error("Something Went Wrong");
          }
        });
    }
  };

  const saveFile = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));

    const formData = new FormData();
    formData.append(
      "video_file",
      UploadedFile.length ? UploadedFile[0].originFileObj : ""
    );
    formData.append("concept_ai", concept_ai);
    formData.append("transcribe_ai", concept_ai);
    formData.append("upload", concept_ai ? "run_model" : "");

    formData.append("video_type", "original");
    formData.append("status", "pending");
    formData.append("model_build", frequency);
    formData.append("projects", "chapter");

    // formData.append("concepts", []);
    formData.append("int_description", "rhl");
    formData.append("web_url", description);
    formData.append("channel", channel);
    formData.append("internal_index", 0);
    formData.append("user_id", user.user_id);
    const videoName = video_name.length
      ? video_name
      : UploadedFile.length
      ? UploadedFile[0].name
      : "";
    formData.append("video_name", videoName);
    formData.append("date", date);

    closeModel();
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    const vidId = Math.random();

    setVideosBeingUploaded((prevState) => {
      return [...prevState, { id: vidId, name: videoName, isLoading: true }];
    });

    fetch(`${process.env.REACT_APP_API}/video_segment`, requestOptions)
      .then((response) => {
        setVideosBeingUploaded((prevState) => {
          const newState = [...prevState];

          const idx = newState.findIndex((el) => el.id === vidId);
          if (idx > -1) {
            newState[idx].isLoading = false;
            newState[idx].success = response.status === 200;
          }

          return newState;
        });

        return response.json();
      })
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Added");
        } else if (data.msgError === "Please Add Payment Method") {
          setDisplay30(true);
        } else {
          message.error("Something Went Wrong");
        }
        console.log(data, "video_segment");
      })
      .finally(() => {});
  };
  const onVideoRemove = (vidId) => {
    setVideosBeingUploaded((prevState) => {
      const newState = [...prevState];

      const filtered = newState.filter((el) => el.id !== vidId);

      return filtered;
    });
  };



  const closeModel = () => {
    setUplaoded([]);
    setconcept_ai(true);
    setTranscribe_ai(false);
    setSupercut_ai(false);
    setVideoName("");
    setVideoDate("");
    setDescription("");
    setDisplay(false);
    setVideoLink("");
    setChannel("")
    setLinkErr(false);
    setLChkErr(false);
    setFrequency("default");
  };

  const props = {
    name: "file",
    status: "done",
    multiple: false,
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      console.log(info);
      setUplaoded(info.fileList);
      // console.log(info.file.originFileObj);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };

  return (
    <>
      <VideosUploading
        data={videosBeingUploaded}
        onVideoRemove={onVideoRemove}
      />
      {/* {display30 ? <VideoAdd30 setDisplay30={(v) => setDisplay30(v)} /> : null} */}
      <Button
        size="small"
        onClick={() => {
          setDisplay(true);
        }}
        type="primary"
      >
        Upload
      </Button>
      <Modal
        className="no-h-bo-b-modal top-20-x brand-modal"
        visible={display}
        onCancel={closeModel}
        width={600}
      >
        {UT ? (
          <div>
            <h5 className="create-btn-h ">
              Content Upload <img src={Icons[UT]} alt="" />
            </h5>
            <div className=" bx-form-brand">
              <div className="vid-ad-vx tag-grou-lake-c">
                <table>
                  <tbody>
                    {UT === "URL" ? (
                      <tr>
                        <td className="link-bx-tt">
                          <h6>Link:</h6>
                        </td>
                        <td>
                          <div className="link-i-s">
                            <TextArea
                              value={videolink}
                              placeholder="Video Link"
                              onChange={(e) => {
                                setVideoLink(e.target.value);
                                findLinkE(e.target.value);
                              }}
                              className="link-input-cs"
                              autoSize={{ minRows: 1, maxRows: 2 }}
                            />
                            {!lChkErr ? (
                              videolink.length ? (
                                <FaCheckCircle className="gren-chk-i" />
                              ) : null
                            ) : (
                              <AiOutlineClose className="red-chk-i" />
                            )}
                          </div>
                          {linkErr ? (
                            <p
                              style={{ marginTop: "-15px" }}
                              className="err-txt-bx"
                            >
                              At this time, we cannot process this link{" "}
                              <Tooltip
                                placement="right"
                                title="prompt text prompt text prompt text"
                              >
                                <FaQuestionCircle className="aut-q-lf " />
                              </Tooltip>
                            </p>
                          ) : null}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <h6>Video</h6>
                        </td>
                        <td>
                          {display ? (
                            <Upload
                              accept="3GPP, AVI, FLV, MOV, MPEG4, MPEGPS, WebM , MPEG4"
                              {...props}
                              maxCount={1}
                            >
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          ) : null}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <table>
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <h6>Name</h6>
                      </td>
                      <td>
                        {" "}
                        <Input
                          value={video_name}
                          placeholder="Video Name"
                          onChange={(e) => setVideoName(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <h6>Channel</h6>
                      </td>
                      <td>
                        {" "}
                        <Input
                          value={channel}
                          placeholder="Channel name"
                          onChange={(e) => setChannel(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <h6>Date</h6>
                      </td>
                      <td>
                        <DatePicker
                          value={date}
                          onChange={(e) => setVideoDate(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <h6>URL</h6>
                      </td>
                      <td>
                        {" "}
                        <TextArea
                          value={description}
                          placeholder="URL"
                          onChange={(e) =>
                            setDescription(
                              e.target.value.indexOf("&") === -1
                                ? e.target.value
                                : e.target.value.slice(
                                    0,
                                    e.target.value.indexOf("&")
                                  )
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mrgn-btn">
                {UploadedFile.length ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      saveFile();

                      if (window.localStorage.getItem("view") === null) {
                        window.localStorage.setItem("view", 1);
                      }
                    }}
                  >
                    Upload
                  </Button>
                ) : null}
                {videolink.length ? (
                  <Button
                    onClick={() => {
                      saveLink();
                    }}
                    type="primary"
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="uupload-type-c">
            <h5 className="create-btn-h">Upload Type</h5>
            <div className=" bx-form-brand">
              <Row>
                <Col span={6}></Col>
                <Col span={12}>
                  <div className="p-10">
                    <div
                      onClick={() => uploadType("Upload")}
                      className={`bx-select-c ${
                        UT === "Upload" ? " activecnc" : ""
                      }`}
                    >
                      <h6>
                        <MdCloudUpload className="brand-icon" />
                      </h6>
                      <b>Direct Upload</b>
                      <p>Upload videos directly from your device.</p>
                    </div>
                  </div>
                </Col>
                <Col span={6}></Col>
              </Row>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
