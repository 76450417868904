import { Col, Row, Card, Tabs } from "antd";
import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./Billing.css";
import Compute from "./components/Compute";
import Transactions from "./components/Transactions";
const { TabPane } = Tabs;
export default function Billing() {
  return (
    <div className="bg-dashboard">
      <Container>
        <div className=" billing-page">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Compute" key="1">
              <Compute />
            </TabPane>
            <TabPane tab="Transactions" key="3">
              <Transactions />
            </TabPane>
          </Tabs>
        </div>
      </Container>
    </div>
  );
}
