import { Button, message, Upload } from "antd";
import React, { useEffect, useState, useRef } from "react";

const API_KEY = "KqExT5fAmyXkk_SjcT-u0";

export default function Admits() {
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState("");

  const ImportCsv = async (e) => {
    e.preventDefault();
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      console.log(inputRef?.current?.files[0]);
      formData.append("csv_file", inputRef?.current?.files[0]);

      const requestOptions = {
        method: "POST",
        body: formData,
        headers: {
          "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
        },
      };
      try {
        const res = await fetch(
          "https://api.fussy.ai/internal/mamba/update_by_csv",
          requestOptions
        );

        const response = await res.json();
        if (res.status === 200) {
          message.success(response.message);
        } else {
          message.error("something went wrong");
        }
        setSelectedFile("");

        // checkTranscripts();

        resolve(true);
      } catch (error) {
        // console.log(error, 'Ssss');
        resolve(true);
      }
    });
  };

  return (
    <div>
      <form onSubmit={ImportCsv}>
        <input
          onChange={(e) => setSelectedFile(e.target.value)}
          ref={inputRef}
          type="file"
          name="csv"
          value={selectedFile}
        />
        <button type="submit">Import</button>
      </form>
    </div>
  );
}
