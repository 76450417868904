import React from "react";

export default function H1({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <h1 {...props}>{props.text}</h1>
    </>
  );
}
