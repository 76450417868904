import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Tag,
  message,
  Tabs,
  Spin,
  Modal,
  Input,
  Button,
  Pagination,
} from "antd";
import Thumbnails from "./components/Thumbnails";
import "./description.css";
import { buildQueries } from "@testing-library/react";
import DoneVideo from "./components/DoneVideo";
import moment from "moment";
import { TimeCorrection } from "../../components/GlobalFunctions";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
export default function Description() {
  const navigate = useNavigate();
  const { description, setdescription, setActive, user, review, setreview } =
    useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [emp, setEmp] = useState("");

  useEffect(() => {
    setActive(window.location.pathname);
  }, []);
  useEffect(() => {
    if (description[description.activeTab] === undefined) {
      getVideoTask();
    }
  }, [callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${
        description.pageNo4
      }&limit=15&search_status=${description.activeTab}&hide_file_url=${true}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setdescription({
            ...description,
            [description.activeTab]: data.segmentList,
            totalPages4: data.totalPages,
          });
        }
      });
  };
  const updateFiled = (obj, id, row) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.isSuccess) {
          setreview({
            ...review,
            doneVideo2: row,
          });
          navigate(`/task/${row._id}`);
        } else {
          message.error("something went wrong");
        }
      });
  };

  const startClick = (lb) => {
    // startTimer(lb);
    updateFiled(
      {
        search_status: "progress",
      },
      lb._id,
      lb
    );
  };
  // const startTimer = (row) => {
  //   const obj = {
  //     sheet_duration: "progress",
  //     video_name: row.video_name,
  //     model_id: user.user_id,
  //     video_id: row._id,
  //     user_id: row.user_id,
  //     startTime: new Date(),
  //     video_duration: row.video_duration,
  //     sheet_type: "description",
  //   };
  //   const requestOptions = {
  //     method: "POST",
  //     body: JSON.stringify(obj),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
  //     },
  //   };

  //   fetch(`https://int.display.video/api/timesheet`, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data && data.isCreated) {
  //         updateFiled(
  //           {
  //             search_status: "progress",
  //           },
  //           row._id,
  //           row
  //         );

  //       }
  //     });
  // };
  const progressClick = (lb) => {
    if (lb.description_id === user.user_id) {
      setdescription({
        ...description,
        activeVideo: lb,
      });
    } else {
      message.warning("Employee Name Not Match");
    }
  };

  return (
    <div className="main-bx-lbl">
      {description.activeVideo ? (
        <Thumbnails />
      ) : (
        <div>
          <Tabs
            onChange={(v) => {
              setdescription({
                ...description,
                [description.activeTab]: undefined,
                totalPages: undefined,
                totalPages4: undefined,
                activeTab: v,
                pageNo: 1,
                pageNo4: 1,
              });
              setLoading2(true);
              setCallApi(callApi + 1);
            }}
            activeKey={description.activeTab}
          >
            <TabPane tab="Pending" key="complete">
              {loading2 ? (
                <Spin />
              ) : description[description.activeTab] &&
                description[description.activeTab].filter(
                  (f) => f.search_status === description.activeTab
                ).length === 0 ? (
                <h1>No Video</h1>
              ) : null}
              <Row>
                {description[description.activeTab] &&
                  description[description.activeTab]
                    .filter((f) => f.search_status === description.activeTab)
                    .map((lb, lbi) => {
                      return (
                        <Col key={lbi} md={{ span: 6 }}>
                          <div className="lbl-c-col">
                            <Card>
                              <h6>{lb.video_name}</h6>
                              <div className="bx-2-date-tm">
                                <b>
                                  Created At:
                                  {lb.createdAt ? (
                                    <i>
                                      {moment(lb.createdAt).format(
                                        "MMMM Do YYYY h:mm:ss"
                                      )}
                                    </i>
                                  ) : null}
                                </b>
                              </div>
                              <div className="start-btn-c">
                                {loading === lb._id ? (
                                  <Spin />
                                ) : (
                                  <Tag
                                    onClick={() => {
                                      setLoading(lb._id);
                                      startClick(lb);
                                    }}
                                    color="rgb(24, 24, 145)"
                                  >
                                    Start
                                  </Tag>
                                )}
                              </div>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
              </Row>
            </TabPane>
            <TabPane tab="Progress" key="progress">
              {loading2 ? (
                <Spin />
              ) : description[description.activeTab] &&
                description[description.activeTab].filter(
                  (f) => f.search_status === description.activeTab
                ).length === 0 ? (
                <h1>No Video</h1>
              ) : null}
              <Row>
                {description[description.activeTab] &&
                  description[description.activeTab]
                    .filter((f) => f.search_status === description.activeTab)
                    .map((lb, lbi) => {
                      return (
                        <Col key={lbi} md={{ span: 6 }}>
                          <div className="lbl-c-col">
                            <Card>
                              <div>
                                By: <Tag color="blue">{lb.description_id}</Tag>
                              </div>
                              <h6>{lb.video_name}</h6>
                              <div className="bx-2-date-tm">
                                <b>
                                  Created At:
                                  {lb.createdAt ? (
                                    <i>
                                      {moment(lb.createdAt).format(
                                        "MMMM Do YYYY h:mm:ss"
                                      )}
                                    </i>
                                  ) : null}
                                </b>
                              </div>
                              <div className="bx-2-date-tm">
                                <b>
                                  Start Time:
                                  {lb.model_start ? (
                                    <i>
                                      {moment(lb.model_start).format(
                                        "MMMM Do YYYY h:mm:ss"
                                      )}
                                    </i>
                                  ) : null}
                                </b>
                              </div>
                              <div className="bx-2-date-tm">
                                <b>
                                  Updated:
                                  {lb.model_start ? (
                                    <i>
                                      {moment(lb.updatedAt).format(
                                        "MMMM Do YYYY h:mm:ss"
                                      )}
                                    </i>
                                  ) : null}
                                </b>
                              </div>

                              <div className="start-btn-c">
                                <Tag
                                  onClick={() => {
                                    progressClick(lb);
                                  }}
                                  color="rgb(24, 24, 145)"
                                >
                                  Progress
                                </Tag>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      );
                    })}
              </Row>
            </TabPane>
            <TabPane tab="Done" key="indexed">
              {loading2 ? (
                <Spin />
              ) : description[description.activeTab] &&
                description[description.activeTab].filter(
                  (f) => f.search_status === description.activeTab
                ).length === 0 ? (
                <h1>No Video </h1>
              ) : null}
              {description.activeDone ? (
                <DoneVideo />
              ) : (
                <Row>
                  {description[description.activeTab] &&
                    description[description.activeTab]
                      .filter((f) => f.search_status === description.activeTab)
                      .map((lb, lbi) => {
                        return (
                          <Col
                            className="done-card-c"
                            key={lbi}
                            md={{ span: 6 }}
                          >
                            <div className="lbl-c-col">
                              <Card>
                                <div>
                                  <div className="bx-2-date-tm">
                                    <div>
                                      <h6 className="name-tit-c">
                                        {lb.video_name}
                                      </h6>
                                    </div>
                                    <b>
                                      Created At:
                                      {lb.createdAt ? (
                                        <i>
                                          {moment(lb.createdAt).format(
                                            "MMMM Do YYYY h:mm:ss"
                                          )}
                                        </i>
                                      ) : null}
                                    </b>
                                    <b>
                                      Updated:
                                      {lb.model_start ? (
                                        <i>
                                          {moment(lb.updatedAt).format(
                                            "MMMM Do YYYY h:mm:ss"
                                          )}
                                        </i>
                                      ) : null}
                                    </b>
                                  </div>
                                </div>

                                <div className="bx-2-date-tm">
                                  <b>
                                    Start Time:
                                    {lb.model_start ? (
                                      <i>
                                        {moment(lb.model_start).format(
                                          "MMMM Do YYYY h:mm:ss"
                                        )}
                                      </i>
                                    ) : null}
                                  </b>
                                  <b>
                                    End Time:
                                    {lb.model_end ? (
                                      <i>
                                        {moment(lb.model_end).format(
                                          "MMMM Do YYYY h:mm:ss"
                                        )}
                                      </i>
                                    ) : null}
                                  </b>
                                </div>
                                <div
                                  style={{ justifyContent: "space-between" }}
                                  className="start-btn-c"
                                >
                                  <Tag color="blue">{lb.description_id}</Tag>
                                  <Tag
                                    onClick={() => {
                                      setdescription({
                                        ...description,
                                        activeDone: lb,
                                      });
                                    }}
                                    color="rgb(24, 24, 145)"
                                  >
                                    View
                                  </Tag>
                                </div>
                                <div className="fl-c-botom">
                                  <b>
                                    {TimeCorrection(
                                      Math.floor(Number(lb.video_duration))
                                    )}
                                  </b>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        );
                      })}
                </Row>
              )}
            </TabPane>
          </Tabs>
          <div className="page-vid-c">
            {description.totalPages4 ? (
              <Pagination
                pageSize={15}
                current={description.pageNo4}
                size="small"
                total={description.totalPages4 * 15}
                onChange={(v) => {
                  setdescription({
                    ...description,
                    [description.activeTab]: undefined,
                    pageNo4: v,
                  });
                  setCallApi(callApi + 1);
                }}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
