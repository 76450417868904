import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  Row,
  Col,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import ReactPlayer from "react-player";
import TagsGroup from "../../components/TagsGroup";
const { Option } = Select;
export default function Upscale() {
  const [callAPI, setCallAPI] = useState(1);
  const [minuteVideo, setminuteVideo] = useState();
  const [totalPages, setTotalPages] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);
  const [clip, setClip] = useState();
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const [used, setUsed] = useState("ready");
  const [search2, setSearch2] = useState("");
  const [pageNoS, setPageNoS] = useState(1);
  const [isPages, setIsPages] = useState(true);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [editVideo, setEditVideo] = useState();

  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [mData, setMData] = useState("");
  const [tagLoading, setTagLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [lengthS, setLengthS] = useState(0);
  const [video, setVideo] = useState();
  const [sceneData, setSceneData] = useState(); // Add style state
  const [editFolder, setEditFolder] = useState();
  const [enhance, setenhance] = useState("pending");

  useEffect(() => {
    if (minuteVideo === undefined) {
      setLoadeing(true);
      getminuteVideo();
    }
  }, [pageNo, callAPI]);

  const getminuteVideo = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image/internal?page=${pageNo}&limit=6&sort_by=${-1}&enhance=${enhance}&media_type=original&status=${status}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeing(false);
        console.log(data);
        if (data && data.isSuccess) {
          setminuteVideo(data.imageList);
          setTotalPages(data.totalPages);
          setIsPages(true);

          //   setGenerated({
          //     ...generated,
          //     videos: data.imageList,
          //     totalPages: data.totalPages,
          //   });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };
  useEffect(() => {
    if (search2.length) {
      gethighlight24();
    }
  }, [search2, pageNoS]);
  const gethighlight24 = () => {
    let obj = { query: search2, page: pageNoS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/image/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setminuteVideo(data);
          setTotalPages(1);
          setIsPages(false);
        } else {
        }
      });
  };

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = minuteVideo.map((m) => (m._id === row._id ? row : m));
    setminuteVideo(all);
    setIsChangeG(true);
  };

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        setTagLoading(false);
        if (response && response.isSuccess) {
          let all = minuteVideo.map((m) =>
            m._id === response.imageData._id ? response.imageData : m
          );
          setminuteVideo(all);
          setEditVideo();
          setClip();
          setVideo();
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const saveClip = () => {
    let obj = {
      start_time: TimeCorrection(startT),
      end_time: TimeCorrection(endT),
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/trim_video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };
  const formatter = (value) => TimeCorrection(value);
  const changeValue2 = (row, key, key2, i, val) => {
    row[key][i][key2] = val;
    setSceneData(row);

    const all = minuteVideo.map((m) => (m._id === row._id ? row : m));
    setminuteVideo(all);
    setIsChangeG(true);
  };
  const updateFolder = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/folder/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isPatched) {
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };
  const getFolder = (url, id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/folder?page=1&limit=6&sort_by=${-1}&segments=${url}&standard=poor`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setEditFolder(data.folderList);
          if (data.folderList.length) {
            setSceneData({ ...sceneData, activeId: id });
          }
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const columns = [
    {
      title: "Video",
      dataIndex: "file_url",
      key: "file_url",
      width: 400,
      render: (text, row) => (
        <div className="fl-c-mid221-dis">
          <ReactPlayer url={text} className="" controls />
        </div>
      ),
    },
    {
      title: "Caption",
      dataIndex: "caption",
      key: "caption",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="caption"
          onChange={(e) => {
            changeValue(row, "caption", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ caption: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },
    {
      title: "Enhance",
      dataIndex: "enhance",
      key: "enhance",
      width: 30,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="Enhance"
          style={{ width: 130 }} // Set the desired width in pixels
          value={text}
          onChange={(value) => {
            changeValue(row, "enhance", value);
            setIsChange(true);
            updateValue({ enhance: value }, row._id, true);
          }}
        >
          <Option value={"pending"}>Pending </Option>
          <Option value={"complete"}>Complete </Option>
          <Option value={"failed"}>failed </Option>
        </Select>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 30,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="status"
          style={{ width: 130 }} // Set the desired width in pixels
          value={text}
          onChange={(value) => {
            changeValue(row, "status", value);
            setIsChange(true);
            updateValue({ status: value }, row._id, true);
          }}
        >
          <Option value={"pending"}>Pending</Option>
          <Option value={"complete"}>Complete</Option>
          <Option value={"cleaned"}>Cleaned</Option>
        </Select>
      ),
    },
    {
      title: "Usage",
      dataIndex: "usage",
      key: "usage",
      width: 30,
      render: (text, row) => <span>{text}</span>,
    },

    {
      title: "",
      dataIndex: "",
      key: "01",
      width: 30,
      render: (text, row) => (
        <span>
          <Button onClick={() => setEditVideo(row)} size="small">
            Edit
          </Button>
          {/* <Button
            style={{ marginTop: "10px" }}
            onClick={() => setSceneData(row)}
            size="small"
          >
            Scene
          </Button> */}
        </span>
      ),
    },
  ];

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
      // console.log(.url);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };

  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          <div className="vd-nm-pr img-prev-ibc">
            <ReactPlayer url={imageUrl} controls={false} />
          </div>
        </>
      );
    } else {
      return <span style={{ color: "black" }}>Drag or click to upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
  };

  const saveVideo = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f[0].originFileObj);

    formData.append("assistant_id", "");
    formData.append("video_duration", lengthS);

    formData.append("purpose", "video");
    formData.append("meta_data", mData);
    formData.append("user_id", "64dc1de41d6e72846d0bb3f2");

    setDisplay(false);
    const vidId = Math.random();

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/upload/video`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFileList([]);
        setUplaoded([]);
        setLoading(false);
        if (data && data.isSuccess) {
          setVideo(data.data);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };

  const getVideoLength = (vLength) => {
    setLengthS(vLength);
    // if (vLength / 60 > 3) {
    //   setUplaoded([]);
    //   setVideoErr(true);
    // } else {
    //   setVideoErr(false);
    // }
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ justifyContent: "flex-end" }} className="table-top-nav">
        <Select
          placeholder="Status"
          onChange={(v) => {
            setStatus(v);
            setminuteVideo();
            setPageNo(1);
            setCallAPI(callAPI + 1);
          }}
          value={status}
          style={{ width: "120px", marginLeft: "10px" }}
        >
          <Option disabeled value="">
            All Status
          </Option>
          <Option value={"pending"}>Pending</Option>
          <Option value={"complete"}>Complete</Option>
          <Option value={"cleaned"}>Cleaned</Option>
        </Select>
        <Select
          placeholder="Enhance"
          onChange={(v) => {
            setenhance(v);
            setminuteVideo();
            setPageNo(1);
            setCallAPI(callAPI + 1);
          }}
          value={enhance}
          style={{ width: "160px", marginLeft: "10px" }}
        >
          <Option value={"pending"}>Pending Enhance</Option>
          <Option value={"complete"}>Complete Enhance</Option>
          <Option value={"failed"}>failed Enhance</Option>
        </Select>
        <Input
          value={search2}
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }
            setminuteVideo();
            setPageNoS(1);
            setSearch2(e.target.value);
          }}
          placeholder="Search"
          style={{ width: "200px", marginLeft: "10px", borderRadius: "20px" }}
          allowClear
        />
      </div>

      <div style={{ width: "100%", overflowX: "auto" }}>
        {loadeing ? <Spin /> : null}
        {minuteVideo ? (
          isPages ? (
            <Table
              pagination={{
                pageSize: 15,
                current: pageNo,
                total: totalPages * 15,
                onChange: (v) => {
                  setminuteVideo();
                  setPageNo(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={minuteVideo}
            />
          ) : (
            <Table
              pagination={{
                pageSize: 20,
                current: pageNoS,
                total: 20 * 20,
                onChange: (v) => {
                  setminuteVideo();
                  setPageNoS(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={minuteVideo}
            />
          )
        ) : null}

        <Modal
          visible={editVideo !== undefined}
          onCancel={() => {
            setEditVideo();
            setClip();
            setVideo();
          }}
          width={600}
          className="no-buttons-modal "
        >
          {video ? (
            <div>
              <h4>New Video</h4>
              <div className="video-container radius-video ">
                <ReactPlayer controls url={video.file_url} playing />
              </div>
              {video.verifyLogo === undefined ? (
                <Button onClick={() => setVideo({ ...video, verifyLogo: 1 })}>
                  60fps and 2k/4k confirm and no logo
                </Button>
              ) : video.verifyLogo === 1 ? (
                <Button onClick={() => setVideo({ ...video, verifyLogo: 2 })}>
                  i confirm no logo at end of video{" "}
                </Button>
              ) : video.verifyLogo === 2 ? (
                <Button onClick={() => setVideo({ ...video, verifyLogo: 3 })}>
                  i have check video again and no logo at end of video
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    updateValue(
                      {
                        file_url: video.file_url,
                        first_frame: editVideo.file_url,
                      },
                      editVideo?._id
                    );
                    setClip();
                    setEditVideo({
                      ...clip,
                      file_url: video.file_url,
                    });
                  }}
                  type="primary"
                >
                  Save Video
                </Button>
              )}
            </div>
          ) : editVideo ? (
            <div>
              <div>
                <b>{editVideo.query}</b>
              </div>

              <div className="video-container radius-video ">
                <ReactPlayer controls url={editVideo.file_url} playing />
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <Dragger
                  showUploadList={false}
                  maxCount={1}
                  beforeUpload={handleBeforeUpload}
                  {...props}
                >
                  {renderFileName()}
                </Dragger>
                <div className="up-bx-mnbcs">
                  {UploadedFile.length ? (
                    <Button type="link" onClick={removeFile}>
                      Change
                    </Button>
                  ) : null}
                  {UploadedFile.length ? (
                    loading ? (
                      <Spin />
                    ) : (
                      <Button
                        type="primary"
                        onClick={() => {
                          setLoading(true);
                          saveVideo(UploadedFile);
                        }}
                      >
                        Attach
                      </Button>
                    )
                  ) : null}
                  {UploadedFile.length ? (
                    <div className="pic-2vid0c">
                      <ReactPlayer
                        url={URL.createObjectURL(UploadedFile[0].originFileObj)}
                        className=""
                        controls
                        playing
                        muted
                        onProgress={(e) => getVideoLength(e.loadedSeconds)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </Modal>
        <Modal
          visible={sceneData !== undefined}
          onCancel={() => setSceneData()}
          className=" no-buttons-modal top-10-modal"
          width={1000}
        >
          {sceneData ? (
            <div>
              <Row>
                <Col span={4}></Col>
                <Col span={16}>
                  <div className="video-container radius-video">
                    <ReactPlayer controls url={sceneData.file_url} playing />
                  </div>
                </Col>
              </Row>
              <Row>
                {sceneData.scene_data.map((m, mi) => {
                  return (
                    <Col key={mi} span={12}>
                      <div className="scene-main-bx-ddata">
                        <div className="video-container radius-video">
                          <ReactPlayer controls url={m.video_url} />
                        </div>
                        <h6>
                          <Tag
                            onClick={() => console.log(editFolder)}
                            color="blue"
                          >
                            {m.theme}
                          </Tag>
                          {sceneData.activeId === m._id ? null : (
                            <Button
                              size="sm"
                              onClick={() => getFolder(m.video_url, m._id)}
                            >
                              Edit
                            </Button>
                          )}
                          {sceneData.activeId === m._id ? (
                            <Input.TextArea
                              value={m.captions}
                              placeholder="Title"
                              onChange={(e) => {
                                changeValue2(
                                  sceneData,
                                  "scene_data",
                                  "captions",
                                  mi,
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                updateFolder(
                                  { caption: m.captions },
                                  editFolder[0]._id
                                );
                              }}
                              autoSize={{ minRows: 2, maxRows: 8 }}
                              className="hidden-textarea"
                              style={{
                                minWidth: "150px",
                                border: "1px solid black",
                              }}
                            />
                          ) : (
                            m.captions
                          )}
                        </h6>
                        <div>{m.description}</div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          ) : null}
        </Modal>
      </div>
    </div>
  );
}
