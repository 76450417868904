import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import LayoutP from "../components/Layout";
import SearchContext from "../Context/Search/SearchContext";
import Login from "./login/Login";
import SignUp from "./signup/Signup";
import Employee from "./Employee/Employee";
import Analysis from "./Analysis/Analysis";
import PageNotFound from "./pageNotFound/PageNotFound";
import Search2 from "./Search2/Search";

import Channels from "./Channels/Channels";
import Admits from "./Admits/Admits";
import Concepts from "./Concepts/Concepts";
import VideoTask from "./VideoTask/VideoTask";
import Shopping from "./Shopping/Shopping";
import VideoFiles from "./VideoFiles/VideoFiles";
import VideoDetail from "./videoDetail/VideoDetail";
import Templates from "./Templates/Concepts";
import DescriptionTask from "./DescriptionTask/DescriptionTask";
import Speaker from "./Speaker/Speaker";
import Screenshots from "./screenshoot/Screenshoot";
import Label from "./Label/Label";
import Scene from "./scenes/Scene";
import Description from "./description/Description";
import SpeakerLabel from "./SpeakerLabel/SpeakerLabel";
import Billing from "./Billing/Billing";
import PendingWork from "../components/PendingWork";
import Account from "./Account/Account";
import Crawler from "./Crawler/Crawler";
import Shorts from "./Shorts/Shorts";
import URL from "./URL/URL";
import CrawlerImages from "./CrawlerImages/CrawlerImages";
import LabelAdminEdit from "./Label/LabelAdminEdit";
import Search from "./Search/Search";
import Review from "./review/Review";
import SpeakerDone from "./SpeakerLabel/SpeakerDone";
import Language from "./language/Language";
import DescriptionTabs from "./DescriptionModels/DescriptionTabs";
import Command from "./Commands/Commands";
import Generator from "./Generator/Generator";
import Topic from "./Topic/Topic";
import General from "./General/General";
import ObjectActive from "./object/ObjectActive";
import Object from "./object/Object";
import Attatchments from "./Attatchments/Attatchments";
import ReviewObject from "./review/ObjectReview";
import Query from "./Query/Query";
import CommandValues from "./CommandValues/CommandValues";
import Summary from "./summary/Summary";
import SummaryActive from "./summary/SummaryActive";
import Upload from "./Upload/Upload";
import List from "./List/List";
import Chapter from "./Chapter/Chapter";
import Product from "./Product/Product";
import Vision from "./Vision/Vision";
import PlotActive from "./plot/PlotActive";
import Plot from "./plot/Plot";
import Entity from "./Entity/Entity";
import Images from "./Images/Images";
import Organization from "./Organization/Organization";
import ChatLog from "./ChatLog/ChatLog";
import ImagesVerify from "./ImagesVerify/ImagesVerify";
import Action from "./action/Action";
import ActiveAction from "./action/ActiveAction";
import ImagesLens from "./ImagesLens/Images";
import SearchImages from "./searchImages/SearchImages";
import DrawImages from "./drawImages/DrawImages";
import Watch from "./watch/Watch";
import ReUpload from "./reUpload/ReUpload";
import CreateVideo from "./createVideo/CreateVideo";
import Folders from "./folder/Folders";
import ThemePage from "./theme/Theme";
import CreateVideoEmployee from "./createVideoEmployee/CreateVideoEmployee";
import Cache from "./Cache/Cache";
import Upscale from "./Upscale/Upscale";
import Spider from "./Spider/Spider";
import Mood from "./Mood/Mood";
import Mamba from "./mamba/Mamba";
import Controller from "./Controller/Controller";
import ReImagine from "./Reimagine/ReImagine";
import Schema from "./schema/Schema";
import FeedPage from "./rolePlay/RolePlay";
import LiveData from "./LiveData/LiveData";

export default function Index() {
  const navigate = useNavigate();
  const { loading, setLoading, user, setUser } = useContext(SearchContext);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    const route = window.location.pathname;
    if (user || u) {
      setUser(user ? user : u);
      setLoading(false);
      setDisplay(false);
      if (route === "/signup" || route === "/login" || route === "/") {
        navigate("/watch");
      }
    } else {
      if (route === "/login") {
        navigate("/login");
      } else {
        navigate("/signup");
      }
      setDisplay(false);
    }
  }, []);

  return (
    <div>
      {display ? <p>Loading</p> : null}
      {/* {user ? <PendingWork /> : null} */}
      <Routes>
        {loading ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
          </>
        ) : user ? (
          <>
            <Route path="/" element={<LayoutP ActivePage={<VideoFiles />} />} />
            <Route path="/watch" element={<LayoutP ActivePage={<Watch />} />} />
            <Route
              path="/upscale"
              element={<LayoutP ActivePage={<Upscale />} />}
            />
            <Route
              path="/roleplay"
              element={<LayoutP ActivePage={<FeedPage />} />}
            />
            <Route
              path="/theme"
              element={<LayoutP ActivePage={<ThemePage />} />}
            />
            <Route
              path="/livedata"
              element={<LayoutP ActivePage={<LiveData />} />}
            />
            <Route
              path="/dataset"
              element={<LayoutP ActivePage={<Folders />} />}
            />
            <Route
              path="/reupload"
              element={<LayoutP ActivePage={<ReUpload />} />}
            />
            <Route
              path="/reimagine"
              element={<LayoutP ActivePage={<ReImagine />} />}
            />
            <Route
              path="/imagine"
              element={<LayoutP ActivePage={<CreateVideoEmployee />} />}
            />
            <Route path="/label" element={<LayoutP ActivePage={<Label />} />} />
            <Route
              path="/schema"
              element={<LayoutP ActivePage={<Schema />} />}
            />
            <Route
              path="/draw"
              element={<LayoutP ActivePage={<DrawImages />} />}
            />
            <Route
              path="/actions"
              element={<LayoutP ActivePage={<Action />} />}
            />
            <Route
              path="/spider"
              element={<LayoutP ActivePage={<Spider />} />}
            />
            <Route
              path="/controller"
              element={<LayoutP ActivePage={<Controller />} />}
            />
            <Route path="/mood" element={<LayoutP ActivePage={<Mood />} />} />
            <Route path="/mamba" element={<LayoutP ActivePage={<Mamba />} />} />
            <Route
              path="/action/:id"
              element={<LayoutP ActivePage={<ActiveAction />} />}
            />
            <Route
              path="/organization"
              element={<LayoutP ActivePage={<Organization />} />}
            />
            <Route
              path="/chat-log"
              element={<LayoutP ActivePage={<ChatLog />} />}
            />
            <Route
              path="/images"
              element={<LayoutP ActivePage={<Images />} />}
            />
            <Route
              path="/lens"
              element={<LayoutP ActivePage={<ImagesLens />} />}
            />
            <Route
              path="/images-verify"
              element={<LayoutP ActivePage={<ImagesVerify />} />}
            />
            <Route
              path="/entities"
              element={<LayoutP ActivePage={<Entity />} />}
            />
            <Route
              path="/vision"
              element={<LayoutP ActivePage={<Vision />} />}
            />
            <Route path="/query" element={<LayoutP ActivePage={<Query />} />} />
            <Route
              path="/product"
              element={<LayoutP ActivePage={<Product />} />}
            />
            <Route
              path="/create"
              element={<LayoutP ActivePage={<CreateVideo />} />}
            />
            <Route
              path="/attachments"
              element={<LayoutP ActivePage={<Attatchments />} />}
            />
            <Route
              path="/command-values"
              element={<LayoutP ActivePage={<CommandValues />} />}
            />
            <Route
              path="/general"
              element={<LayoutP ActivePage={<General />} />}
            />
            <Route path="/list" element={<LayoutP ActivePage={<List />} />} />
            <Route path="/cache" element={<LayoutP ActivePage={<Cache />} />} />
            <Route
              path="/chapters"
              element={<LayoutP ActivePage={<Chapter />} />}
            />
            <Route
              path="/label/:id"
              element={<LayoutP ActivePage={<LabelAdminEdit />} />}
            />
            <Route
              path="/object"
              element={<LayoutP ActivePage={<Object />} />}
            />
            <Route
              path="/upload"
              element={<LayoutP ActivePage={<Upload />} />}
            />
            <Route
              path="/summary/:id"
              element={<LayoutP ActivePage={<SummaryActive />} />}
            />
            <Route
              path="/summary"
              element={<LayoutP ActivePage={<Summary />} />}
            />
            <Route
              path="/plot/:id"
              element={<LayoutP ActivePage={<PlotActive />} />}
            />
            <Route path="/plot" element={<LayoutP ActivePage={<Plot />} />} />
            <Route
              path="/object/:id"
              element={<LayoutP ActivePage={<ObjectActive />} />}
            />
            <Route path="/topic" element={<LayoutP ActivePage={<Topic />} />} />
            <Route
              path="/search"
              element={<LayoutP ActivePage={<Search />} />}
            />
            <Route
              path="/commands"
              element={<LayoutP ActivePage={<Command />} />}
            />
            <Route
              path="/search-labels"
              element={<LayoutP ActivePage={<SearchImages />} />}
            />
            <Route
              path="/generator"
              element={<LayoutP ActivePage={<Generator />} />}
            />
            <Route
              path="/shorts"
              element={<LayoutP ActivePage={<Shorts />} />}
            />
            <Route
              path="/crawler"
              element={<LayoutP ActivePage={<Crawler />} />}
            />
            <Route
              path="/crawler/:id"
              element={<LayoutP ActivePage={<CrawlerImages />} />}
            />

            <Route
              path="/usage"
              element={<LayoutP ActivePage={<Billing />} />}
            />
            <Route
              path="/review"
              element={<LayoutP ActivePage={<Review />} />}
            />
            <Route
              path="/review/object/:id"
              element={<LayoutP ActivePage={<ReviewObject />} />}
            />
            <Route
              path="/language"
              element={<LayoutP ActivePage={<Language />} />}
            />
            <Route
              path="/speaker"
              element={<LayoutP ActivePage={<SpeakerLabel />} />}
            />
            <Route
              path="/speaker/:id"
              element={<LayoutP ActivePage={<SpeakerDone />} />}
            />
            <Route path="/scene" element={<LayoutP ActivePage={<Scene />} />} />
            <Route
              path="/account"
              element={<LayoutP ActivePage={<Account />} />}
            />
            <Route
              path="/description"
              element={<LayoutP ActivePage={<Description />} />}
            />
            <Route
              path="/task/:id"
              element={<LayoutP ActivePage={<DescriptionTabs />} />}
            />
            <Route
              path="/templates"
              element={<LayoutP ActivePage={<Templates />} />}
            />
            <Route
              path="/description"
              element={<LayoutP ActivePage={<DescriptionTask />} />}
            />
            <Route
              path="/screenshots"
              element={<LayoutP ActivePage={<Screenshots />} />}
            />
            <Route
              path="/speaker-s"
              element={<LayoutP ActivePage={<Speaker />} />}
            />

            <Route
              path="/video/:id"
              element={<LayoutP ActivePage={<VideoDetail />} />}
            />
            <Route
              path="/video-task"
              element={<LayoutP ActivePage={<VideoTask />} />}
            />
            <Route
              path="/employee-data"
              element={<LayoutP ActivePage={<Employee />} />}
            />
            <Route
              path="/analysis"
              element={<LayoutP ActivePage={<Analysis />} />}
            />
            <Route
              path="/search2"
              element={<LayoutP ActivePage={<Search2 />} />}
            />
            <Route
              path="/shopping"
              element={<LayoutP ActivePage={<Shopping />} />}
            />
            <Route
              path="/concepts"
              element={<LayoutP ActivePage={<Concepts />} />}
            />
            <Route
              path="/admits"
              element={<LayoutP ActivePage={<Admits />} />}
            />
            <Route
              path="/channels"
              element={<LayoutP ActivePage={<Channels />} />}
            />
            <Route path="/url" element={<LayoutP ActivePage={<URL />} />} />
            <Route
              path="*"
              element={<LayoutP ActivePage={<PageNotFound />} />}
            />
          </>
        ) : null}
      </Routes>
    </div>
  );
}
