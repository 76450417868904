import React, { useState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import {
  FaUser,
  FaLock,
  FaMailBulk,
  FaMailchimp,
  FaBusinessTime,
} from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import { Form, Icon, Input, Button, Checkbox, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { v4 as uuidv4 } from "uuid";

const salt = bcrypt.genSaltSync(10);
const NormalLoginForm = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    country: "country",
    organization_id: "",
    user_id: "",
    permission: "none",
    language: "English",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    addUser(form);
  };

  const addUser = (user) => {
    user.password = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    let id = uuidv4();
    user.profile_id = id;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(user),
    };
    fetch(`${process.env.REACT_APP_API}/consumer/users`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isCreated) {
          setForm({
            email: "",
            name: "",
            password: "",
            country: "country",
            organization_id: "",
            user_id: "",
            permission: "none",
            language: "English",
          });
          message.success("Register Success");
          navigate("/login");
        } else if (response.isExists) {
          message.warning("user already available");
        }
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    setForm({ ...form, [key]: val });
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <h4>Sign up</h4>
      <Form.Item>
        <Input
          onChange={(e) => changeField("name", e.target.value, form)}
          size="large"
          prefix={<FaUser />}
          placeholder="Name"
          value={form.name}
        />
      </Form.Item>
      <Form.Item>
        <Input
          onChange={(e) => changeField("user_id", e.target.value, form)}
          size="large"
          prefix={<FaUser />}
          placeholder="ID"
          value={form.user_id}
        />
      </Form.Item>
      <Form.Item>
        <Input
          onChange={(e) =>
            changeField("email", e.target.value.toLowerCase(), form)
          }
          size="large"
          prefix={<AiFillMail />}
          placeholder="Email"
          value={form.email}
        />
      </Form.Item>
      <Form.Item>
        <Input.Password
          onChange={(e) => changeField("password", e.target.value, form)}
          size="large"
          prefix={<FaLock />}
          type="password"
          placeholder="Password"
          value={form.password}
        />
      </Form.Item>

      <Form.Item>
        <div className="for-reg-c">
          <div />
          <Link to="/login">Already Have an account!</Link>
        </div>
        <div className="login-btn-c">
          <Button
            onClick={handleSubmit}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Sign up
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default NormalLoginForm;
