import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Button,
  InputNumber,
  message,
  Switch,
  Popconfirm,
  Modal,
  Input,
  notification,
  Tag,
} from "antd";
import ReactPlayer from "react-player";
import "./videotask.css";
import TagsGroup from "../../components/TagsGroup";
import { FaArrowLeft, FaArrowRight, FaPlay, FaTrash } from "react-icons/fa";
import { TimeCorrection } from "../../components/GlobalFunctions";
import NotFound from "../../components/NotFound";
import LoadingText from "../../components/LoadingText";
export default function VideoTask() {
  const { videoTasks, setVideoTask, videoConcept, setVideoConcept } =
    useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);
  const [callApi2, setCallApi2] = useState(0);
  const [notSave, setNotSave] = useState(false);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [searchLabel, setSearchLabel] = useState("");
  const [editTime, setEditTIme] = useState();
  const [active, setActive] = useState(0);
  const [timer, setTimer] = useState(31);
  const [active_id, setActiveId] = useState();
  const [displayName, setDisplayName] = useState();
  const [allLabels, setAllLable] = useState();
  const [conceptValue, setConceptValue] = useState();

  useEffect(() => {
    if (videoTasks.videosPending === undefined) {
      getVideoTask();
      setActive(0);
      resettALL();
      setActiveId(window.localStorage.getItem("active_id"));
    }
  }, [callApi]);

  useEffect(() => {
    if (callApi2 !== 0) {
      getConcept();
    }
  }, [callApi2]);

  const resettALL = () => {
    setNotSave(false);
    setViewThumbnails();
    setSearchLabel("");
    setVideoConcept({
      ...videoConcept,
      selectedConcepts: [],
      timestamp: undefined,
    });
  };

  const getVideoTask = (actId) => {
    let isId = actId ? actId.id : window.localStorage.getItem("active_id");
    let status = isId ? "progress" : "pending";
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    const api = isId
      ? `https://int.display.video/api/video_segment?_id=${isId}`
      : `https://int.display.video/api/video_segment?page=1&limit=15&cleaned=${
          videoConcept.cleaned
        }&status=pending&is_subclip=${false}&concept_ai=${true}`;
    fetch(api, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          if (status === "progress") {
            checkIdActive(data.segmentList, isId);
          } else {
            setVideoTask({
              ...videoTasks,
              videosPending: data.segmentList,
            });

            if (data.segmentList.length) {
              setCallApi2(callApi2 + 1);
            }
          }
        }
        console.log(data);
      });
  };
  const checkIdActive = (videos, id) => {
    const vd = videos.filter((f) => f._id === id);
    if (vd.length) {
      setVideoTask({
        ...videoTasks,
        videosPending: videos,
      });
    } else {
      localStorage.removeItem("active_id");
      setActiveId();
      getVideoTask({ id: undefined });
    }
    if (videos.length) {
      setCallApi2(callApi2 + 1);
    }
  };

  const getConcept = () => {
    const vt = videoTasks.videosPending.slice(active, active + 1)[0];
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/concept?user_id=${vt.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.conceptList) {
          const list = data.conceptList;
          list.unshift({ name: "Nothing", saved_images: [] });
          setVideoConcept({
            ...videoConcept,
            concept: list,
            // selectedConcepts: [],
          });
        }
        console.log(data, "concept");
      });
  };

  const updateIntoDB = (row, name, isDone) => {
    const id = row._id;
    setNotSave(false);
    const formData = new FormData();
    formData.append("internal_index", row.internal_index.toString());
    formData.append("status", row.status ? row.status : "pending");
    formData.append("cleaned", row.cleaned);
    formData.append("timeline", JSON.stringify(row.timeline));
    formData.append("video_file", row.file_url);
    formData.append("index_progress", row.index_progress);
    if (name) {
      formData.append("model_id", name);
    }

    const requestOptions = {
      method: "PUT",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (isDone) {
            window.location.reload();
          }
          message.success("Updated");
        } else {
          message.error("something went wrong");
        }

        console.log(data, "video_segment , update");
      });
  };
  const saveConcept = (tag) => {
    const { selectedConcepts } = videoConcept;
    const filter = selectedConcepts.filter((t) => t === tag);
    let removed;
    let added;
    if (filter.length) {
      removed = selectedConcepts.filter((t) => t !== tag);
    } else {
      added = [...selectedConcepts, tag];
    }

    if (videoConcept.timestamp === undefined) {
      const vt = videoTasks.videosPending.slice(active, active + 1)[0].timeline;

      setVideoConcept({
        ...videoConcept,
        selectedConcepts: removed ? removed : added,
        timestamp: vt && vt.length ? vt.length * 5 : 1,
      });
    } else {
      setVideoConcept({
        ...videoConcept,
        selectedConcepts: removed ? removed : added,
      });
    }
    setNotSave(true);
  };

  const updateChanges = (video, isDone) => {
    // videoConcept;
    if (videoConcept.selectedConcepts.length) {
      if (videoConcept.timestamp) {
        const newTimestamp = {
          concepts: videoConcept.selectedConcepts,
          timestamp: videoConcept.timestamp,
        };
        const oldTimeline = video.timeline ? video.timeline : [];
        oldTimeline.push(newTimestamp);
        video.timeline = oldTimeline;
        video.internal_index = oldTimeline.length;
        let a = video.video_duration ? video.video_duration : "0";
        a = Number(a);
        a = Math.floor(a);
        a = a / 5;
        a = Math.ceil(a);
        video.index_progress = Math.floor((oldTimeline.length / a) * 100);
        updateIntoDB(video);
        setVideoConcept({
          ...videoConcept,
          selectedConcepts: [],
          timestamp: undefined,
        });
        if (videoConcept.selectedConcepts[0] !== "Nothing") {
          postNewClip(video, newTimestamp);
        } else {
          postNewClip2(video, newTimestamp);
        }
      } else {
        message.warning("Please Add Timestamp");
      }
    } else {
      updateIntoDB(video);
      setVideoConcept({
        ...videoConcept,
        selectedConcepts: [],
        timestamp: undefined,
      });
    }
  };

  const postNewClip = (video, timeline) => {
    let toatSize = Number(video.video_duration);
    toatSize = parseInt(toatSize) / 5;
    toatSize = parseInt(toatSize);
    const formData = new FormData();
    // formData.append("internal_index", video.timeline.length);
    formData.append("user_id", video.user_id);
    formData.append("video_name", video.video_name);
    formData.append("headclip_id", video._id);

    formData.append("video_type", "subclip");
    formData.append("status", "automated");
    formData.append("int_description", video.int_description);
    formData.append("description", video.description);
    // formData.append("end_index", toatSize);
    formData.append("file_link", video._id);
    formData.append("startTime", TimeCorrection(timeline.timestamp));
    formData.append(
      "endTime",
      TimeCorrection(timeline.timestamp === 1 ? 5 : timeline.timestamp + 5)
    );

    for (let i = 0; i < timeline.concepts.length; i++) {
      formData.append("concepts", timeline.concepts[i]);
    }

    const requestOptions = {
      method: "POST",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/clip`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const postNewClip2 = (video, timeline) => {
    let toatSize = Number(video.video_duration);
    toatSize = parseInt(toatSize) / 5;
    toatSize = parseInt(toatSize);
    const obj = {
      internal_index: video.timeline.length,
      user_id: video.user_id,
      video_name: video.video_name,
      video_type: "subclip",
      status: "automated",
      int_description: video.int_description,
      description: video.description,
      end_index: toatSize,
      concepts: "Nothing",
      headclip_id: video._id,
      file_url: video.file_url,
    };

    const requestOptions = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/file_url`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const adjustTimeline = (value, index, row, key) => {
    row.timeline[index][key] = value;
    const all = videoTasks.videosPending.map((v) =>
      v._id === row._id ? row : v
    );
    setVideoTask({ ...videoTasks, videosPending: all });
    setNotSave(true);
  };

  const deleteTimeline = (tim, i, row) => {
    const timelines = row.timeline;
    timelines.splice(i, 1);
    row.timeline = timelines;
    const all = videoTasks.videosPending.map((v) =>
      v._id === row._id ? row : v
    );
    setVideoTask({ ...videoTasks, videosPending: all });
    setNotSave(true);
  };

  const noti30S = () => {
    const myInterval = setInterval(() => {
      const time = timer - 1;
      setTimer(time);
      if (timer === 0) {
        clearInterval(myInterval);
      }
      console.log(time, "time , ", timer);
    }, 1000);

    notification.open({
      message: "Timer STart",
      duration: 0,
      description: <div> {timer}</div>,
    });
  };

  const startBtnClicled = (name) => {
    setDisplayName();
    const video = videoTasks.videosPending.slice(active, active + 1)[0];
    if (video.status === "progress") {
      message.warning("Someone Already  Doing");
    } else {
      video.status = "progress";
      const all = videoTasks.videosPending.map((v) =>
        v._id === video._id ? video : v
      );
      setVideoTask({ ...videoTasks, videosPending: all });
      updateIntoDB(video, name);
      setActiveId(video._id);
      window.localStorage.setItem("active_id", video._id);
    }
    // noti30S();
  };

  const timelinefilter = (timelines) => {
    let total = [];
    for (let i = 0; i < timelines.length; i++) {
      for (let j = 0; j < timelines[i].concepts.length; j++) {
        const filter = total.filter((t) => t !== timelines[i].concepts[j]);
        filter.push(timelines[i].concepts[j]);
        total = filter;
      }
    }
    return total;
  };

  const displaySupercut = (timelines) => {
    const all = timelinefilter(timelines);
    setAllLable(all);
  };

  const saveCombine = () => {
    const video = videoTasks.videosPending.slice(active, active + 1)[0];
    postCombine(video);
  };

  const postCombine = (video) => {
    const formData = new FormData();
    formData.append("method", "headclip");
    formData.append("concept_value", conceptValue);
    formData.append("video_name", video.video_name);
    formData.append("user_id", video.user_id);

    formData.append("concepts", conceptValue);
    formData.append("headclip_id", video._id);
    formData.append("video_type", "supercut");
    formData.append("status", "automated");
    formData.append("int_description", "1");
    formData.append("description", "1");

    const requestOptions = {
      method: "POST",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/combine`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setConceptValue();
        if (data.isSuccess) {
          message.success("Added");
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  return (
    <div>
      <div className="top-vt-c">
        <b className="cl-t-v-c">
          Cleaned
          <Switch
            checked={videoConcept.cleaned}
            onChange={(val) => {
              setVideoTask({ ...videoTasks, videosPending: undefined });
              setVideoConcept({ ...videoConcept, cleaned: val });
              setCallApi(callApi + 1);
            }}
          />
        </b>
        <Button
          onClick={() =>
            displaySupercut(
              videoTasks.videosPending.slice(active, active + 1)[0].timeline
            )
          }
          type="primary"
        >
          Supercut
        </Button>
      </div>
      <Row>
        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          {videoTasks.videosPending && videoTasks.videosPending.length ? (
            <div>
              <div className="r-l-divc">
                <div>
                  {active === 0 ? null : (
                    <FaArrowLeft
                      onClick={() => {
                        resettALL();
                        setActive(active - 1);
                        setVideoConcept({
                          ...videoConcept,
                          concept: undefined,
                        });
                        setCallApi2(callApi2 + 1);
                      }}
                    />
                  )}
                </div>
                <div>
                  {active + 1 === videoTasks.videosPending.length ? null : (
                    <FaArrowRight
                      onClick={() => {
                        resettALL();
                        setActive(active + 1);
                        setVideoConcept({
                          ...videoConcept,
                          concept: undefined,
                        });
                        setCallApi2(callApi2 + 1);
                      }}
                    />
                  )}
                </div>
              </div>
              {videoTasks.videosPending
                .slice(active, active + 1)
                .filter(
                  (f) => f.status === "pending" || f.status === "progress"
                )
                .map((vt, vti) => {
                  return (
                    <div key={vti} className="vt-vid-c">
                      <ReactPlayer
                        onPlay={(e) => console.log(e)}
                        controls
                        url={vt.file_url}
                      />
                      <div className="sav-ch-btn">
                      <a href={vt.file_url} download>Dwonload</a>
                        {active_id === vt._id ? (
                          <>
                            <Popconfirm
                              title="Make Sure Your Complete this Video?"
                              onConfirm={() => {
                                localStorage.removeItem("active_id");
                                updateIntoDB(
                                  {
                                    ...vt,
                                    cleaned: true,
                                    status: "done",
                                  },
                                  undefined,
                                  "done"
                                );
                                setActiveId();
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button size="small" className="donebtns-c">
                                Done
                              </Button>
                            </Popconfirm>
                            {notSave ? (
                              <Button
                                onClick={() => updateChanges(vt)}
                                size="small"
                                type="primary"
                              >
                                Save Changes
                              </Button>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={() => setDisplayName("")}
                              className="plat-btn-strt"
                              size="small"
                              type="primary"
                            >
                              Start <FaPlay />
                            </Button>

                            {notSave ? (
                              <h5 className="red-clr-no-strt">
                                Press Start button before make changes in
                                database
                              </h5>
                            ) : null}
                          </>
                        )}
                      </div>
                      {videoConcept.selectedConcepts.length ? (
                        <div className="nv-tsk-c">
                          <h6 className="newly-s-h6">
                            Timestamp:
                            {editTime === "edit" ? (
                              <InputNumber
                                min={0}
                                value={videoConcept.timestamp}
                                onChange={(v) =>
                                  setVideoConcept({
                                    ...videoConcept,
                                    timestamp: v,
                                  })
                                }
                                onBlur={() => setEditTIme()}
                              />
                            ) : (
                              <b
                                className="tim-c-b-d"
                                onDoubleClick={() => setEditTIme("edit")}
                              >
                                {TimeCorrection(videoConcept.timestamp)}
                              </b>
                            )}
                            <span>(press save changes)</span>
                          </h6>
                          <TagsGroup
                            tags={videoConcept.selectedConcepts}
                            onTagChange={(v) =>
                              setVideoConcept({
                                ...videoConcept,
                                selectedConcepts: v,
                              })
                            }
                          />
                        </div>
                      ) : null}
                      <div className="saved-tim-wv">
                        {vt.timeline &&
                          vt.timeline.length &&
                          vt.timeline.map((tm, tmi) => {
                            return (
                              <div className="tim-vid-mainc" key={tmi}>
                                <div className="trash-qa-s">
                                  <FaTrash
                                    onClick={() => {
                                      deleteTimeline(tm, tmi, vt);
                                    }}
                                  />
                                </div>
                                <h6 className="prev-tim-in">
                                  Timestamp:
                                  {editTime === tmi ? (
                                    <InputNumber
                                      min={0}
                                      value={tm.timestamp}
                                      onChange={(v) =>
                                        adjustTimeline(v, tmi, vt, "timestamp")
                                      }
                                      onBlur={() => setEditTIme()}
                                    />
                                  ) : (
                                    <b
                                      className="tim-c-b-d"
                                      onDoubleClick={() => setEditTIme(tmi)}
                                    >
                                      {TimeCorrection(tm.timestamp)}
                                    </b>
                                  )}
                                </h6>
                                <TagsGroup
                                  tags={tm.concepts}
                                  onTagChange={(v) =>
                                    adjustTimeline(v, tmi, vt, "concepts")
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div>
              {videoTasks.videosPending ? (
                <div>
                  {videoTasks.videosPending.length === 0 ? <NotFound /> : null}
                </div>
              ) : (
                <div style={{ marginTop: "100px" }}>
                  {" "}
                  <LoadingText />
                </div>
              )}
            </div>
          )}
        </Col>
        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="label-h-in-c">
            <h5>Labels</h5>
            <Input
              placeholder="Search Label"
              onChange={(e) => setSearchLabel(e.target.value)}
            />
          </div>
          <div className="con-vd-c">
            {videoConcept.concept &&
              videoConcept.concept
                .filter((f) =>
                  f.name.toLowerCase().includes(searchLabel.toLowerCase())
                )
                .map((vt, vti) => {
                  return (
                    <div key={vti} className="select-cn-cc">
                      <div>
                        {vt.saved_images && vt.saved_images.length ? (
                          <img
                            alt=""
                            className="img-thum-con"
                            src={vt.saved_images[0]}
                            onClick={() => setViewThumbnails(vt)}
                          />
                        ) : null}
                      </div>
                      <div
                        key={vti}
                        onClick={() => saveConcept(vt.name)}
                        className={`name-bx-field-t3 ${
                          videoConcept.selectedConcepts &&
                          videoConcept.selectedConcepts.filter(
                            (t) => t === vt.name
                          ).length
                            ? " active-icn-f"
                            : ""
                        }`}
                      >
                        {vt.name}
                      </div>
                    </div>
                  );
                })}
          </div>
        </Col>
      </Row>

      <Modal
        className="no-h-bo-b-modal "
        visible={allLabels !== undefined}
        onCancel={() => setAllLable()}
      >
        {allLabels ? (
          <div className="th-imgs-thum">
            <h5> Superclip</h5>
            <div className="dv-cm-dc">
              {allLabels.map((th, thi) => {
                return (
                  <div className="">
                    <Tag
                      onClick={() => setConceptValue(th)}
                      className={`tg-lbl-t1 ${
                        conceptValue === th ? " acvtc" : ""
                      }`}
                      color="#084b83"
                      key={thi}
                    >
                      {th}
                    </Tag>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {conceptValue ? <Button onClick={saveCombine}>Save</Button> : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={displayName !== undefined}
        onCancel={() => setDisplayName()}
      >
        <h4>Your Name Code</h4>
        <Input
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder="Name"
        />
        <Button
          className="btnc-s"
          type="primary"
          onClick={() => startBtnClicled(displayName)}
        >
          Save and Start
        </Button>
      </Modal>
    </div>
  );
}
