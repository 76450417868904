import { Table, DatePicker, Spin } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import VideoView from "./VideoView";
const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";

export default function Transactions() {
  const [billing, setBilling] = useState();
  const [loadeing, setLoadeing] = useState(true);
  const [loadV, setLoadV] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [callAPI, setCallAPI] = useState(1);
  const [displayVideo, setDisplayVideo] = useState();

  const [date, setDate] = useState({
    start: moment(new Date()).subtract(10, "days").format("MM/DD/YYYY"),
    end: moment(new Date()).format("MM/DD/YYYY"),
  });
  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    if (user) {
      getTransactions(user);
      setLoadeing(true);
    }
  }, [pageNo, callAPI]);

  const getTransactions = (user) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/minute?page=${pageNo}&limit=10`,
      //   date=${date.start}-${date.end}&
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setBilling(data);
        }
        setLoadeing(false);
        console.log(data);
      });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, row) => moment(text).format("MMMM Do YYYY"),
    },
    {
      title: "Video Name",
      dataIndex: "video_name",
      key: "video_name",
      render: (text, row) => <span>{text}</span>,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (text, row) => <span>{text.toFixed(2)}</span>,
    },
    {
      title: "Compute Cost",
      dataIndex: "compute_cost",
      key: "compute_cost",
      render: (text, row) => <span>${text.toFixed(2)}</span>,
    },
    {
      title: "Storage Cost",
      dataIndex: "storage_cost",
      key: "storage_cost",
      render: (text, row) => <span>${text.toFixed(2)}</span>,
    },
    {
      title: "",
      dataIndex: "",
      key: "01",
      render: (text, row) => (
        <span>
          {loadV === row.minutes_id ? (
            <Spin />
          ) : (
            <Button
              onClick={() => {
                setDisplayVideo(row);
                setLoadV(row.minutes_id);
              }}
              size="sm"
            >
              View
            </Button>
          )}
        </span>
      ),
    },
  ];
  const changeDate = (e) => {
    const start = moment(e[0]._d).format("MM/DD/YYYY");
    const end = moment(e[1]._d).format("MM/DD/YYYY");
    setBilling();
    setDate({ ...date, start, end });
    setCallAPI(callAPI + 1);
  };
  return (
    <div className="compute-tab-c">
      <RangePicker
        onChange={changeDate}
        format={dateFormat}
        defaultValue={[
          moment(date.start, dateFormat),
          moment(date.end, dateFormat),
        ]}
      />
      {loadeing ? (
        <div>
          <Spin />
        </div>
      ) : null}
      {billing ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: billing.totalPages * 10,
            onChange: (v) => setPageNo(v),
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={billing.minuteList}
        />
      ) : null}
      {displayVideo ? (
        <VideoView
          setDisplayVideo={() => {
            setDisplayVideo();
            setLoadV();
          }}
          data={displayVideo}
        />
      ) : null}
    </div>
  );
}
