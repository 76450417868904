import React from "react";

import AllElements from "../AllElements";

export default function Ul({ props }) {
  return (
    <ul {...props}>
      <AllElements props={props} />
    </ul>
  );
}
