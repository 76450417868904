import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Button,
  InputNumber,
  message,
  Switch,
  Popconfirm,
  Modal,
  Input,
  notification,
  Tag,
  Tooltip,
  Card,
} from "antd";
import ReactPlayer from "react-player";
import "./speaker.css";
import TagsGroup from "../../components/TagsGroup";
import {
  FaArrowLeft,
  FaArrowRight,
  FaEdit,
  FaPlay,
  FaTrash,
} from "react-icons/fa";
import { TimeCorrection } from "../../components/GlobalFunctions";
export default function Speaker() {
  const { videoTasks, setVideoTask, videoConcept, setVideoConcept } =
    useContext(SearchContext);
  const playerRef = React.useRef();

  const [callApi, setCallApi] = useState(0);
  const [callApi2, setCallApi2] = useState(0);
  const [notSave, setNotSave] = useState(false);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [searchLabel, setSearchLabel] = useState("");
  const [editTime, setEditTIme] = useState();
  const [active, setActive] = useState(0);
  const [timer, setTimer] = useState(31);
  const [active_tr, setActiveId] = useState();
  const [displayName, setDisplayName] = useState();
  const [allLabels, setAllLable] = useState();
  const [conceptValue, setConceptValue] = useState();
  const [SegmentNames, setSegmentNames] = useState();
  const [segmentList, setSegmentList] = useState();
  const [activeName, setActiveName] = useState("");
  const [callTranscript, setCallTranscript] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [bulkName, setBulkName] = useState();
  const [ooneName, setOneName] = useState();

  useEffect(() => {
    if (videoTasks.videosTrans === undefined) {
      getVideoTask();
      setActive(0);
      resettALL();
      setActiveId(window.localStorage.getItem("active_tr"));
    }
  }, [callApi]);

  useEffect(() => {
    if (callApi2 !== 0) {
      getConcept();
    }
  }, [callApi2]);

  useEffect(() => {
    if (callTranscript !== 0) {
      const vt = videoTasks.videosTrans.slice(active, active + 1)[0];
      adjustTranscript(vt.transcript);
    }
  }, [callTranscript]);

  const resettALL = () => {
    setNotSave(false);
    setViewThumbnails();
    setSearchLabel("");
    setVideoConcept({
      ...videoConcept,
      selectedConcepts: [],
      timestamp: undefined,
    });
  };

  const getVideoTask = (actId) => {
    let isId = actId ? actId.id : window.localStorage.getItem("active_tr");
    let model_s = isId ? "progress" : "pending";
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=1&limit=15&model_s=${model_s}&is_subclip=${false}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          if (model_s === "progress") {
            checkIdActive(data.segmentList, isId);
          } else {
            setVideoTask({
              ...videoTasks,
              videosTrans: data.segmentList,
            });
            setCallTranscript(callTranscript + 1);

            if (data.segmentList.length) {
              setCallApi2(callApi2 + 1);
            }
          }
        }
        console.log(data);
      });
  };
  const checkIdActive = (videos, id) => {
    const vd = videos.filter((f) => f._id === id);
    if (vd.length) {
      setVideoTask({
        ...videoTasks,
        videosTrans: videos,
      });
      setCallTranscript(callTranscript + 1);
    } else {
      localStorage.removeItem("active_tr");
      setActiveId();
      getVideoTask({ id: undefined });
    }
    if (videos.length) {
      setCallApi2(callApi2 + 1);
    }
  };

  const getConcept = () => {
    const vt = videoTasks.videosTrans.slice(active, active + 1)[0];
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/concept?user_id=${vt.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.conceptList) {
          const list = data.conceptList;
          list.unshift({ name: "Unknown", saved_images: [] });
          setVideoConcept({
            ...videoConcept,
            conceptTrns: list,
            // selectedConcepts: [],
          });
        }
        console.log(data, "concept");
      });
  };

  const updateIntoDB = (row, name, isDone) => {
    const id = row._id;
    setNotSave(false);
    const formData = new FormData();
    formData.append("model_s", row.model_s ? row.model_s : "pending");
    formData.append("video_file", row.file_url);
    if (name) {
      formData.append("model_id", name);
    }

    const requestOptions = {
      method: "PUT",

      headers: {
        // "Content-Type": "multipart/form-data",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (isDone) {
            window.location.reload();
          }
          message.success("Updated");
        } else {
          message.error("something went wrong");
        }

        console.log(data, "video_segment , update");
      });
  };

  const noti30S = () => {
    const myInterval = setInterval(() => {
      const time = timer - 1;
      setTimer(time);
      if (timer === 0) {
        clearInterval(myInterval);
      }
      console.log(time, "time , ", timer);
    }, 1000);

    notification.open({
      message: "Timer STart",
      duration: 0,
      description: <div> {timer}</div>,
    });
  };

  const startBtnClicled = (name) => {
    setDisplayName();
    const video = videoTasks.videosTrans.slice(active, active + 1)[0];
    if (video.model_s === "progress") {
      message.warning("Someone Already  Doing");
    } else {
      video.model_s = "progress";
      const all = videoTasks.videosTrans.map((v) =>
        v._id === video._id ? video : v
      );
      setVideoTask({ ...videoTasks, videosTrans: all });
      updateIntoDB(video, name);
      setActiveId(video._id);
      window.localStorage.setItem("active_tr", video._id);
    }
    // noti30S();
  };

  const adjustTranscript = (transcript) => {
    const speakerLabels = transcript.speaker_labels
      ? transcript.speaker_labels
      : {};
    const items = transcript.items ? transcript.items : [];
    const segments = speakerLabels.segments ? speakerLabels.segments : [];

    let listNames = [];
    for (let i = 0; i < segments.length; i++) {
      const segItem = segments[i].items ? segments[i].items : [];
      for (let j = 0; j < segItem.length; j++) {
        const iRow = segItem[j];
        const filtered = items.filter(
          (tm) => tm.start_time === iRow.start_time
        );
        if (filtered.length) {
          iRow.alternatives = filtered[0].alternatives;
          segItem[j] = iRow;
        }
      }
      listNames = listNames.filter((f) => f !== segItem[0].speaker_label);

      listNames.push(segItem[0].speaker_label);
      segments[i].items = segItem;
    }
    setSegmentList(segments);
    setSegmentNames(listNames);
  };
  const videoTimeAdjust = (time) => {
    time = time.toString();
    time = parseInt(time);
    playerRef.current.seekTo(time, "seconds");
  };

  const updatespeakerBulk = (oldName, newName) => {
    setBulkName();
    setVideoConcept({
      ...videoConcept,
      selectedTrns: undefined,
    });
    const video = videoTasks.videosTrans.slice(active, active + 1)[0];

    const obj = {
      old_value: oldName,
      new_value: newName,
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/video_segment/speaker_label/${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          liveUpdateBulk(oldName, newName);
        }
        // console.log(data, "video_segment , update");
      });
  };

  const liveUpdateBulk = (oldName, newName) => {
    const video = videoTasks.videosTrans.slice(active, active + 1)[0];
    console.log(oldName, newName, video);
    const transcript = video.transcript;
    if (transcript.speaker_labels) {
      const speaker = transcript.speaker_labels;
      for (let j = 0; j < speaker.segments.length; j++) {
        if (speaker.segments[j].speaker_label === oldName) {
          speaker.segments[j].speaker_label = newName;
        }
      }
      transcript.speaker_labels = speaker;
    }

    video.transcript = transcript;
    const all = videoTasks.videosTrans.map((v) =>
      v._id === video._id ? video : v
    );
    console.log(all);
    const index = SegmentNames.indexOf(oldName); // 👉️  0

    SegmentNames.splice(index, 1, newName);
    setSegmentNames(SegmentNames);
    setVideoTask({ ...videoTasks, videosTrans: all });
  };

  const changeSingleName = (oldName, newName, time) => {
    const video = videoTasks.videosTrans.slice(active, active + 1)[0];
    const transcript = video.transcript;
    const speaker_labels = transcript.speaker_labels;
    const segments = speaker_labels.segments;
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].start_time === time) {
        segments[i].speaker_label = newName;
      }
    }
    speaker_labels.segments = segments;
    transcript.speaker_labels = speaker_labels;
    video.transcript = transcript;
    //incomplete code
  };

  return (
    <div>
      <Row>
        {videoTasks.videosTrans && videoTasks.videosTrans.length ? (
          <Col md={{ span: 14 }} lg={{ span: 14 }}>
            <div className="r-l-divc">
              <div>
                {active === 0 ? null : (
                  <FaArrowLeft
                    onClick={() => {
                      resettALL();
                      setActive(active - 1);
                      setVideoConcept({
                        ...videoConcept,
                        conceptTrns: undefined,
                      });
                      setCallApi2(callApi2 + 1);
                    }}
                  />
                )}
              </div>
              <div>
                {active + 1 === videoTasks.videosTrans.length ? null : (
                  <FaArrowRight
                    onClick={() => {
                      resettALL();
                      setActive(active + 1);
                      setVideoConcept({
                        ...videoConcept,
                        conceptTrns: undefined,
                      });
                      setCallApi2(callApi2 + 1);
                    }}
                  />
                )}
              </div>
            </div>
            {videoTasks.videosTrans
              .slice(active, active + 1)
              .filter(
                (f) => f.model_s === "pending" || f.model_s === "progress"
              )
              .map((vt, vti) => {
                return (
                  <div key={vti} className="vt-vid-c">
                    <ReactPlayer
                      onPlay={(e) => console.log(e)}
                      onProgress={(e) => {
                        setPlayedSeconds(parseInt(e.playedSeconds));
                      }}
                      controls
                      url={vt.file_url}
                      ref={playerRef}
                    />
                    <div className="sav-ch-btn">
                      <a href={vt.file_url} download>
                        Dwonload
                      </a>

                      {active_tr === vt._id ? (
                        <>
                          <Popconfirm
                            title="Make Sure Your Complete this Video?"
                            onConfirm={() => {
                              localStorage.removeItem("active_tr");
                              updateIntoDB(
                                {
                                  ...vt,
                                  model_s: "done",
                                },
                                undefined,
                                "done"
                              );
                              setActiveId();
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button size="small" className="donebtns-c">
                              Done
                            </Button>
                          </Popconfirm>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => setDisplayName("")}
                            className="plat-btn-strt"
                            size="small"
                            type="primary"
                          >
                            Start <FaPlay />
                          </Button>

                          {notSave ? (
                            <h5 className="red-clr-no-strt">
                              Press Start button before make changes in database
                            </h5>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            <div>
              <div className="label-h-in-c">
                <h5>Labels</h5>
                <Input
                  placeholder="Search Label"
                  onChange={(e) => setSearchLabel(e.target.value)}
                />
              </div>
              <div className="con-vd-c">
                {videoConcept.conceptTrns &&
                  videoConcept.conceptTrns
                    .filter((f) =>
                      f.name.toLowerCase().includes(searchLabel.toLowerCase())
                    )
                    .map((vt, vti) => {
                      return (
                        <div key={vti} className="select-cn-cc">
                          <div>
                            {vt.saved_images && vt.saved_images.length ? (
                              <img
                                alt=""
                                className="img-thum-con"
                                src={vt.saved_images[0]}
                                onClick={() => setViewThumbnails(vt)}
                              />
                            ) : null}
                          </div>
                          <div
                            key={vti}
                            onClick={() =>
                              setVideoConcept({
                                ...videoConcept,
                                selectedTrns: vt.name,
                              })
                            }
                            className={`name-bx-field-t3 ${
                              videoConcept.selectedTrns === vt.name
                                ? " active-icn-f"
                                : ""
                            }`}
                          >
                            {vt.name}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </Col>
        ) : null}

        <Col md={{ span: 9, offset: 1 }} lg={{ span: 9, offset: 1 }}>
          <div className="lbls-name-tg">
            {SegmentNames &&
              SegmentNames.map((sl, sli) => {
                return (
                  <div key={sli}>
                    <Tag
                      color={activeName === sl ? "#161d84" : "default"}
                      onClick={() => setActiveName(sl)}
                    >
                      {sl.replace("spk_", "Speaker ")}
                    </Tag>
                    <FaEdit
                      onClick={() => setBulkName(sl)}
                      className="editbx-cas"
                    />
                  </div>
                );
              })}
            {activeName !== "" ? (
              <Tag onClick={() => setActiveName("")}>All</Tag>
            ) : null}
          </div>
          <div className="trn-bsmx">
            {segmentList &&
              segmentList
                .filter((f) => f.speaker_label.includes(activeName))
                .map((sl, sli) => {
                  return (
                    <Card className="crd-bx-trns" key={sli}>
                      <div className="bx-nm-crd">
                        <h5>
                          {sl.speaker_label.replace("spk_", "Speaker ")}
                          <FaEdit
                            onClick={() => setOneName(sl)}
                            className="edit-fa-sp"
                          />
                        </h5>
                        <span>
                          <i>From:</i>
                          {TimeCorrection(parseInt(Number(sl.start_time)))}
                        </span>
                      </div>
                      <p>
                        {sl.items.map((t, ti) => {
                          return (
                            <span
                              className={`txt-spn-slct ${
                                playedSeconds === parseInt(Number(t.start_time))
                                  ? " active-cls-p"
                                  : ""
                              } ${t.search ? " serch-highlight" : ""}`}
                              onClick={() => videoTimeAdjust(t.start_time)}
                              key={ti}
                            >
                              {t.alternatives[0].content}{" "}
                            </span>
                          );
                        })}
                      </p>
                      {/* <div className="trn-card-cim">
                                  <img src={Icons.scissors} alt="" />
                                  <img src={Icons.highlighter} alt="" />
                                  <img src={Icons.bubbleChat} alt="" />
                                </div> */}
                    </Card>
                  );
                })}
          </div>
        </Col>
      </Row>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={displayName !== undefined}
        onCancel={() => setDisplayName()}
      >
        <h4>Your Name Code</h4>
        <Input
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder="Name"
        />
        <Button
          className="btnc-s"
          type="primary"
          onClick={() => startBtnClicled(displayName)}
        >
          Save and Start
        </Button>
      </Modal>

      <Modal
        className="no-h-bo-b-modal "
        visible={bulkName !== undefined}
        onCancel={() => setBulkName()}
        width={700}
      >
        <div className="label-h-in-c">
          <h5>
            Replace <Tag>{bulkName}</Tag> with{" "}
            <Tag>{videoConcept.selectedTrns}</Tag>
          </h5>
          <Input
            placeholder="Search Label"
            onChange={(e) => setSearchLabel(e.target.value)}
          />
        </div>
        <div className="con-vd-c">
          {videoConcept.conceptTrns &&
            videoConcept.conceptTrns
              .filter((f) =>
                f.name.toLowerCase().includes(searchLabel.toLowerCase())
              )
              .map((vt, vti) => {
                return (
                  <div key={vti} className="select-cn-cc">
                    <div>
                      {vt.saved_images && vt.saved_images.length ? (
                        <img
                          alt=""
                          className="img-thum-con"
                          src={vt.saved_images[0]}
                          onClick={() => setViewThumbnails(vt)}
                        />
                      ) : null}
                    </div>
                    <div
                      key={vti}
                      onClick={() =>
                        setVideoConcept({
                          ...videoConcept,
                          selectedTrns: vt.name,
                        })
                      }
                      className={`name-bx-field-t3 ${
                        videoConcept.selectedTrns === vt.name
                          ? " active-icn-f"
                          : ""
                      }`}
                    >
                      {vt.name}
                    </div>
                  </div>
                );
              })}
        </div>
        <Button
          className="btnc-s"
          type="primary"
          onClick={() => updatespeakerBulk(bulkName, videoConcept.selectedTrns)}
        >
          Save and Replace
        </Button>
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={ooneName ? true : false}
        onCancel={() => setOneName()}
        width={700}
      >
        {ooneName ? (
          <div>
            <div className="label-h-in-c">
              <h6>
                Replace <Tag>{ooneName.speaker_label}</Tag> with{" "}
                <Tag>{videoConcept.selectedTrns}</Tag> at{" "}
                {TimeCorrection(parseInt(Number(ooneName.start_time)))}
              </h6>
              <Input
                placeholder="Search Label"
                onChange={(e) => setSearchLabel(e.target.value)}
              />
            </div>
            <div className="con-vd-c">
              {videoConcept.conceptTrns &&
                videoConcept.conceptTrns
                  .filter((f) =>
                    f.name.toLowerCase().includes(searchLabel.toLowerCase())
                  )
                  .map((vt, vti) => {
                    return (
                      <div key={vti} className="select-cn-cc">
                        <div>
                          {vt.saved_images && vt.saved_images.length ? (
                            <img
                              alt=""
                              className="img-thum-con"
                              src={vt.saved_images[0]}
                              onClick={() => setViewThumbnails(vt)}
                            />
                          ) : null}
                        </div>
                        <div
                          key={vti}
                          onClick={() =>
                            setVideoConcept({
                              ...videoConcept,
                              selectedTrns: vt.name,
                            })
                          }
                          className={`name-bx-field-t3 ${
                            videoConcept.selectedTrns === vt.name
                              ? " active-icn-f"
                              : ""
                          }`}
                        >
                          {vt.name}
                        </div>
                      </div>
                    );
                  })}
            </div>
            <Button
              className="btnc-s"
              type="primary"
              onClick={() =>
                changeSingleName(
                  ooneName.speaker_label,
                  videoConcept.selectedTrns,
                  ooneName.start_time
                )
              }
            >
              Save and Replace
            </Button>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
