import React, { useEffect, useState } from "react";
import { Button } from "antd";
export default function ButtonElm({ props }) {
  const [p, setP] = useState({});
  const [fun, setFun] = useState();

  useEffect(() => {
    setFun(props.onClick);
    setP({ ...props, onClick: () => executeFunction() });
  }, []);

  const executeFunction = () => {
    try {
      // console.log("Before executing function");
      const wrapperFunction = () => {
        console.log("Inside function: additional logic");
        eval("(" + fun + ")()");
      };
      wrapperFunction();
    } catch (error) {
      console.error("Error executing function:", error);
    }
  };
          
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <button {...p}>{props.text}</button>
    </>
  );
}
