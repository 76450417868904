import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import SearchContext from "../../Context/Search/SearchContext";
const { Option } = Select;
export default function General() {
  const { allEmp, user } = useContext(SearchContext);

  const [Emp, setEmp] = useState("");
  const [general, setgeneral] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [dAdd, setdAdd] = useState();
  const [search, setSearch] = useState("");
  const [cStatus, setCstatus] = useState("");

  useEffect(() => {
    if (general === undefined) {
      setLoadeing(true);
      getgeneral();
      console.log(user);
    }
  }, [pageNo, search, cStatus, Emp]);

  const getgeneral = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/insight?page=${pageNo}&limit=15&sort_by=${-1}&search=${search}&status=${cStatus}&created_by=${Emp}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setgeneral(data.insightList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };
  const Savegeneral = (row) => {
    row.created_by = user.user_id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/insight/create`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Row added in first page");
          // const list = [...general];
          // list.unshift(data.data);
          // list.pop();
          // setgeneral(list);
        } else {
          message.error(data.msgError);
        }
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/insight/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = general.filter((t) => t._id !== id);
    setgeneral(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/insight/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = general.map((m) => (m._id === row._id ? row : m));
    setgeneral(allRow);
  };

  const columns = [
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="value"
          onChange={(e) => {
            changeField("value", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ value: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="category"
          onChange={(e) => {
            changeField("category", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ category: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "Section",
      dataIndex: "section",
      key: "section",

      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="blue"
          onTagChange={(v) => {
            changeField("section", v, row);
            saveChanges({ section: v }, row._id, true);
          }}
        />
      ),
    },
    {
      title: "Related Words",
      dataIndex: "related_data",
      key: "related_data",

      render: (text, row) => (
        <TagsGroup
          color="black"
          tags={text ? text : []}
          onTagChange={(v) => {
            changeField("related_data", v, row);
            saveChanges({ related_data: v }, row._id, true);
          }}
        />
      ),
    },
    {
      title: "Query",
      dataIndex: "query",
      key: "query",

      render: (text, row) => (
        <TagsGroup
          color="black"
          tags={text ? text : []}
          onTagChange={(v) => {
            changeField("query", v, row);
            saveChanges({ query: v }, row._id, true);
          }}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 110,

      render: (text, row) => (
        <span>
          <Form.Select
            value={text}
            className="dropdown-status-comn"
            aria-label="Default select example"
            onChange={(v) => {
              changeField("status", v.target.value, row);
              saveChanges({ status: v.target.value }, row._id, true);
            }}
          >
            <option value="pending">Pending</option>
            <option value="review">Review</option>
            <option value="complete">Complete</option>
          </Form.Select>
        </span>
      ),
    },

    {
      title: "Emp",
      dataIndex: "created_by",
      key: "created_by",

      render: (text, row) => <b style={{ fontSize: "11px" }}>{text}</b>,
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="table-top-nav">
        <Button
          size="sm"
          onClick={() => setdAdd({ category: "", value: "", user_id: "" })}
        >
          <BsPlus />
          Add
        </Button>
        <div>
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setgeneral();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Search "
          />
          <Select
            placeholder="Category"
            onChange={(v) => {
              setCstatus(v);
              setPageNo(1);
              setgeneral();
            }}
            value={cStatus}
            style={{ width: "120px", marginLeft: "10px" }}
          >
            <Option disabeled value="">
              Status
            </Option>
            <Option value="pending">pending</Option>
            <Option value="review">Review</Option>
            <Option value="complete">complete</Option>
          </Select>
          <Select
            placeholder=" P Code"
            onChange={(v) => {
              setEmp(v);
              setPageNo(1);
              setgeneral();
            }}
            value={Emp}
            style={{ width: "120px", marginLeft: "10px" }}
          >
            <Option disabeled value="">
              P Code
            </Option>
            {allEmp.map((em, emi) => {
              return (
                <Option key={emi} value={em}>
                  {em}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {general ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setgeneral();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={general}
        />
      ) : null}
      <Modal
        visible={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Value:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="Value"
                      value={dAdd.value}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, value: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Category:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="category"
                      value={dAdd.category}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, category: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> User ID:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="user_id"
                      value={dAdd.user_id}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, user_id: e.target.value })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-end">
              <Button
                onClick={() => {
                  Savegeneral(dAdd);
                  setdAdd();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Invitation</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
