import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
} from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function Query() {
  const [callAPI, setCallAPI] = useState(1);
  const [query, setquery] = useState();
  const [totalPages, setTotalPages] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [dAdd, setdAdd] = useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (query === undefined) {
      setLoadeing(true);
      getquery();
    }
  }, [pageNo, search]);

  const getquery = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/fussy_query?page=${pageNo}&limit=15&sort_by=${-1}&search=${search}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setquery(data.queryList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const columns = [
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      width: 300,
      render: (text, row) => text,
    },
    {
      title: "Query Type",
      dataIndex: "query_type",
      key: "query_type",
      width: 50,

      render: (text, row) => <Tag className="brand-tag">{text}</Tag>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 70,
      render: (text, row) => moment(text).format("MMM Do YYYY"),
    },
    {
      title: "Headclip id",
      dataIndex: "headclip_id",
      key: "headclip_id",
      width: 100,
      render: (text, row) => text,
    },
    {
      title: "User id",
      dataIndex: "user_id",
      key: "user_id",
      width: 100,
      render: (text, row) => text,
    },
  ];
  return (
    <div>
      {/* <div className="connap-filter-c space-between">
        <Input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setquery();
          }}
          className="search-d-at"
          placeholder="Search "
        />
      </div> */}
      {loadeing ? <Spin /> : null}
      {query ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setquery();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={query}
        />
      ) : null}
    </div>
  );
}
