import { Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import ObjectD from "./components/Thumbnails";
export default function SummaryActive() {
  const { summary, setsummary, user } = useContext(SearchContext);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    if (summary.activeVideo === undefined) {
      getVideoTask(window.location.pathname.slice(9));
    }
  }, []);

  const getVideoTask = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setsummary({
            ...summary,
            activeVideo: data.segmentList[0],
            images: undefined,
            totalPages: undefined,
          });
        }
      });
  };
  return <div>{summary.activeVideo ? <ObjectD /> : <Spin />}</div>;
}
