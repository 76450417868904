import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  AutoComplete,
  Pagination,
  Tabs,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SearchContext from "../../Context/Search/SearchContext";
import ReactPlayer from "react-player";
import { BiTime } from "react-icons/bi";
import axios from "axios";
import TagsGroup from "../../components/TagsGroup";
import { FaSearch } from "react-icons/fa";

const { Option } = Select;
export default function ReImagine() {
  const { user, organization, setOrganization } = useContext(SearchContext);
  const [count, setCount] = useState("");

  const [callAPI, setCallAPI] = useState(1);
  const [callAPI2, setCallAPI2] = useState(1);
  const [folderVideo, setfolderVideo] = useState();
  const [totalPages, setTotalPages] = useState();
  const [caption, setCaption] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [highlights, sethighlights] = useState();
  const [textField, setTextField] = useState(""); // State to store text input
  const [duration, setDuration] = useState(1); // State to store duration input

  const [standard, setstandard] = useState("");
  const [action, setAction] = useState("");
  const [tags, setTags] = useState("");
  const [tab, setActiveTab] = useState("1");
  const [endT, setEndT] = useState(10);
  const [reimagine, setReimagine] = useState();

  const [status, setStatus] = useState("");
  const [clip, setClip] = useState();
  const [movement, setMovement] = useState("");
  const [startT, setStartT] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [pageNoS, setPageNoS] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [isPages, setIsPages] = useState(true);
  const [generated, setGenerated] = useState({ pageNo: 1 });
  const [rImage, setRImage] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [editVideo, setEditVideo] = useState();

  useEffect(() => {
    getVideos();
  }, []);

  useEffect(() => {
    if (callAPI2 > 1) {
      getVideos();
    }
  }, [callAPI2]);

  const getVideos = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image/internal?page=${
        generated.pageNo
      }&limit=8&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=original&status=generated&count=${count}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // setLoading2(false);
        if (generated.pageNo === 1) {
          setTimeout(() => {
            setCallAPI2(callAPI2 + 1);
          }, 20000);
        }
        if (data && data.isSuccess) {
          setGenerated({
            ...generated,
            videos: data.imageList,
            totalPages: data.totalPages,
          });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  useEffect(() => {
    if (highlights === undefined && search1.length) {
      gethighlight23();
    }
  }, [search1, pageNoS]);

  const gethighlight23 = () => {
    let obj = { query: search1, page: pageNoS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/highlight/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          sethighlights(data);
          setTotalPages(1);
          setIsPages(false);
        } else {
        }
      });
  };
  useEffect(() => {
    if (search2.length) {
      gethighlight24();
    }
  }, [search2, pageNoS]);

  const gethighlight24 = () => {
    let obj = { query: search2, page: pageNoS };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/folder/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setfolderVideo(data);
          setTotalPages(1);
          setIsPages(false);
        } else {
        }
      });
  };
  useEffect(() => {
    if (folderVideo === undefined) {
      setLoadeing(true);
      getfolderVideo();
    }
  }, [pageNo, callAPI]);

  const getfolderVideo = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));
    let experts = [...[], search1];
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/folder?page=${pageNo}&limit=8&sort_by=${-1}&standard=${standard}&caption=${caption}&status=${status}&expert=${experts}&action=${action}&movement=${movement}&tags=${tags}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeing(false);
        if (data && data.isSuccess) {
          setfolderVideo(data.folderList);
          setTotalPages(data.totalPages);
          setIsPages(true);

          //   setGenerated({
          //     ...generated,
          //     videos: data.imageList,
          //     totalPages: data.totalPages,
          //   });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = folderVideo.map((m) => (m._id === row._id ? row : m));
    setfolderVideo(all);
    setIsChangeG(true);
  };

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/folder/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isPatched) {
          let all = folderVideo.map((m) =>
            m._id === response.patchedData._id ? response.patchedData : m
          );
          setfolderVideo(all);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };

  const handleSendClick = async (video) => {
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/remix/internal",
        {
          query: textField,
          user_id: user && user.user_id,
          duration: duration,
          // voice,
          image_id: video._id,
        }
      );
      if (response.data?.isSuccess) {
        // openNotificationWithIcon();
        message.success("success");

        setReimagine();
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };

  const handleSendClick2 = async (video) => {
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/remix",
        {
          query: textField,
          user_id: user && user.user_id,
          duration: duration,
          // voice,
          image_id: video._id,
        }
      );
      if (response.data?.isSuccess) {
        // openNotificationWithIcon();
        message.success("success");
        setReimagine();
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };

  const updateValue2 = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isSuccess) {
          const all = generated.videos.map((m) =>
            m._id === response.imageData._id ? response.imageData : m
          );
          setGenerated({ ...generated, videos: all });
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };
  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };
  const formatter = (value) => TimeCorrection(value);

  const changeValue2 = (row, key, val) => {
    row[key] = val;
    const all = generated.videos.map((m) => (m._id === row._id ? row : m));
    setGenerated({ ...generated, videos: all });
    setIsChangeG(true);
  };
  const saveClip = () => {
    let obj = {
      start_time: TimeCorrection(startT),
      end_time: TimeCorrection(endT),
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/trim_video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setEditLoading(false);

        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };
  const saveSpeed = () => {
    let obj = {
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/speed/video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setEditLoading(false);

        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };
  const saveSlow = () => {
    let obj = {
      video_id: editVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/slow/video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setEditLoading(false);

        if (response && response.success) {
          setClip(response);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
        }
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <Tabs
        onChange={(v) => setActiveTab(v)}
        activeKey={tab}
        defaultActiveKey={tab}
      >
        <Tabs.TabPane tab="Videos" key="1">
          <Input
            value={caption}
            onChange={(e) => {
              setCaption(e.target.value);
              setfolderVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            placeholder="Caption"
            style={{ width: "200px", marginLeft: "10px", borderRadius: "20px" }}
            allowClear
          />
          <Input
            value={tags}
            onChange={(e) => {
              setTags(e.target.value);
              setfolderVideo();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            placeholder="Tags"
            style={{ width: "200px", marginLeft: "10px", borderRadius: "20px" }}
            allowClear
          />
          <div className="row">
            {folderVideo &&
              folderVideo.map((f, fi) => {
                return (
                  <div key={fi} className="col-lg-3 col-md-4">
                    <div className="scene-main-bx-ddata">
                      <div className="video-container radius-video">
                        <ReactPlayer controls url={f.file_url} />
                      </div>
                      <Input.TextArea
                        value={f.caption}
                        placeholder="Add Caption"
                        onChange={(e) => {
                          changeValue(f, "caption", e.target.value);
                          setIsChange(true);
                        }}
                        onBlur={() => {
                          if (isChange) {
                            updateValue({ caption: f.caption }, f._id);
                          }
                        }}
                        autoSize={{ minRows: 2, maxRows: 8 }}
                        className="hidden-textarea"
                        style={{ minWidth: "150px" }}
                      />
                      <div>
                        <b>Tags: </b>
                        <TagsGroup
                          tags={f.tags ? f.tags : []}
                          color="blue"
                          onTagChange={(v) => {
                            changeValue(f, "tags", v);
                            updateValue({ tags: v }, f._id, true);
                          }}
                        />
                      </div>
                      <div className="space-between">
                        <BButton
                          type="primary"
                          onClick={() => setEditVideo(f)}
                          size="small"
                        >
                          Edit
                        </BButton>
                        <BButton
                          onClick={() => {
                            setReimagine(f);
                          }}
                          type="primary"
                          size="small"
                          style={{ marginTop: "15px" }}
                        >
                          Reimagine
                        </BButton>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {totalPages ? (
            <Pagination
              style={{ margin: "10px auto" }}
              pageSize={8}
              current={pageNo}
              total={totalPages * 8}
              onChange={(v) => {
                setfolderVideo();
                setPageNo(v);
              }}
              showSizeChanger={false}
            />
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Created" key="2">
          <Input
            value={count}
            onChange={(e) => {
              setCount(e.target.value);
            }}
            placeholder="Tag"
            style={{ width: "120px", marginLeft: "10px" }}
            allowClear
          />
          <Button
            onClick={() => {
              setGenerated({ ...generated, pageNo: 1, videos: undefined });
              setCallAPI2(callAPI2 + 1);
            }}
            size="sm"
          >
            <FaSearch />
          </Button>
          <div className="row">
            {generated.videos &&
              generated.videos.map((f, fi) => {
                return (
                  <div key={fi} className="col-lg-3 col-md-4">
                    <div className="scene-main-bx-ddata">
                      <div className="video-container radius-video">
                        <ReactPlayer controls url={f.file_url} />
                      </div>
                      <h6>{f.query}</h6>
                      <div>
                        <b>Tags: </b>
                        <TagsGroup
                          tags={f.count ? f.count : []}
                          color="blue"
                          onTagChange={(v) => {
                            changeValue2(folderVideo, "count", v);
                            updateValue2({ count: v }, f._id, true);
                          }}
                        />
                      </div>
                      <div className="space-between">
                        <BButton
                          onClick={() => {
                            setRImage(f);
                          }}
                          type="primary"
                          size="small"
                          style={{ marginTop: "15px" }}
                        >
                          Root Image
                        </BButton>
                        <BButton
                          onClick={() => {
                            setReimagine(f);
                          }}
                          type="primary"
                          size="small"
                          style={{ marginTop: "15px" }}
                        >
                          Reimagine
                        </BButton>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {generated.totalPages ? (
            <Pagination
              style={{ margin: "10px auto" }}
              pageSize={8}
              current={generated.pageNo}
              total={generated.totalPages * 8}
              onChange={(v) => {
                setGenerated({ ...generated, pageNo: v, videos: undefined });
                setCallAPI2(callAPI2 + 1);
              }}
              showSizeChanger={false}
            />
          ) : null}
        </Tabs.TabPane>
      </Tabs>

      <Modal
        visible={rImage}
        onCancel={() => {
          setRImage();
        }}
        width={700}
        className="no-buttons-modal "
      >
        {rImage ? (
          <img className="root-img-b" src={rImage.root_image} alt="" />
        ) : null}
      </Modal>

      <Modal
        visible={reimagine}
        onCancel={() => {
          setReimagine();
        }}
        width={600}
        className="no-buttons-modal "
      >
        <div className="create-tab">
          {/* Create Tab Content Goes Here */}
          <div
            className="InputContainer"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Input.TextArea
              placeholder="Type your text here"
              value={textField}
              className="form-control border-0  px-4"
              onChange={(e) => setTextField(e.target.value)}
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          </div>
          <div style={{ flexDirection: "row" }} className="duration-box-m">
            <div className="InputContainer">
              <Select
                placeholder="Time"
                style={{ width: 120 }} // Set the desired width in pixels
                value={duration}
                onChange={(value) => setDuration(value)}
              >
                {/* <Option disabeled value={""}>
              Duration
            </Option> */}
                <Option value={1}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  3s
                </Option>
                <Option value={2}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  6s
                </Option>
                <Option value={4}>
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  12s
                </Option>
                <Option value={6}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  18s
                </Option>
                <Option value={8}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  24s
                </Option>
                <Option value={10}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  30s
                </Option>
                <Option value={12}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  36s
                </Option>
                <Option value={14}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  42s
                </Option>
                <Option value={16}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  48s
                </Option>
                <Option value={18}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  54s
                </Option>
                <Option value={20}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  1m
                </Option>
              </Select>
            </div>

            {tab === "1" ? (
              <div className="create-button-con">
                <button
                  onClick={() => handleSendClick(reimagine)}
                  className="button-21"
                >
                  Imagine
                  <div className="hoverEffect">
                    <div></div>
                  </div>
                </button>
              </div>
            ) : (
              <div className="create-button-con">
                <button
                  onClick={() => handleSendClick2(reimagine)}
                  className="button-21"
                >
                  Imagine
                  <div className="hoverEffect">
                    <div></div>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        visible={editVideo !== undefined}
        onCancel={() => {
          setEditVideo();
          setClip();
        }}
        width={600}
        className="no-buttons-modal "
      >
        {editVideo ? (
          <div>
            <div>
              <b>{editVideo.query}</b>
            </div>

            <div className="video-container radius-video">
              <ReactPlayer
                controls
                url={editVideo.segments && editVideo.segments[0]}
                playing
              />
            </div>

            {clip ? (
              <div>
                <div className="video-container radius-video">
                  <ReactPlayer
                    controls
                    url={clip.file_url ? clip.file_url : clip.file_ur}
                    playing
                  />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setClip();
                    }}
                  >
                    Cancel
                  </Button>
                  {clip && clip.file_url ? (
                    <Button
                      onClick={() => {
                        updateValue(
                          { file_url: clip.file_url },
                          editVideo?._id
                        );
                        setClip();
                        setEditVideo({
                          ...editVideo,
                          file_url: clip.file_url,
                          isCliped: true,
                        });
                      }}
                      type="primary"
                    >
                      Save Clip
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        updateValue(
                          { file_url: clip?.file_ur },
                          editVideo?._id
                        );
                        setClip();
                        setEditVideo();
                      }}
                      type="primary"
                    >
                      Save Clip
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <Slider
                  tooltip={{
                    placement: "bottom",
                    formatter,
                  }}
                  range
                  value={[startT, endT]}
                  defaultValue={[startT, endT]}
                  onChange={(v) => {
                    setStartT(v[0]);
                    setEndT(v[1]);
                  }}
                />
                {editLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Button
                      type="primary"
                      onClick={() => {
                        setEditLoading(true);
                        saveClip();
                      }}
                    >
                      Clip
                    </Button>
                    <Button
                      style={{ margin: "0px 10px" }}
                      type="primary"
                      onClick={() => {
                        setEditLoading(true);
                        saveSlow();
                      }}
                    >
                      Slow
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        setEditLoading(true);
                        saveSpeed();
                      }}
                    >
                      Speed
                    </Button>
                  </div>
                )}
              </div>
            )}
            <div></div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
