import React, { useContext, useState } from "react";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Spin,
  Popconfirm,
} from "antd";
import SearchContext from "../../../../../Context/Search/SearchContext";
import { FaEye } from "react-icons/fa";
const { Option } = Select;

export default function ConceptsTop({}) {
  const { label, setlabel } = useContext(SearchContext);
  const [loadingDone, setLoadingDone] = useState(false);
  const [imagesView, setImagesView] = useState();

  const changeDone = (rows) => {
    const activeV = { ...label.activeVideo };
    let all;
    for (let i = 0; i < rows.length; i++) {
      const obj = activeV.concept_index.filter(
        (f) => f.concept_name === rows[i].concept_name
      )[0];
      obj.indexed = true;
      all = activeV.concept_index.map((r) =>
        r.concept_name === obj.name ? obj : r
      );
      activeV.concept_index = all;
    }

    updateFiled({ concept_index: all }, activeV._id, "done", activeV);
  };

  const updateFiled = (obj, id, type, activeV) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoadingDone(false);
        if (data.isSuccess) {
          if (type === "done") {
            message.success(`5 Labels Marked True Success`);
            setlabel({
              ...label,
              activeVideo: activeV,
            });
          } else if (type === "complete") {
            message.success(`Whole Video Complete `);

            setlabel({
              ...label,
              activeVideo: undefined,
              images: undefined,
            });
          }
        } else {
          message.error("something went wrong");
        }
      });
  };

  return (
    <div>
      <Affix offsetTop={0}>
        <div className="con-bx-to-c">
          <div>
            {loadingDone ? (
              <Spin />
            ) : (
              <Popconfirm
                title={
                  <div>
                    <h6>Complete all Lables?</h6>
                    <h6>Complete all pages?</h6>
                  </div>
                }
                onConfirm={() => {
                  setLoadingDone(true);
                  changeDone(
                    label.activeVideo.concept_index
                      .filter((f) => f.indexed === false)
                      .slice(0, 5)
                  );
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" type="primary">
                  Done
                </Button>
              </Popconfirm>
            )}
          </div>
          {label.activeVideo.concept_index &&
            label.activeVideo.concept_index
              .filter((f) => f.indexed === false)
              .slice(0, 5)
              .map((lb, lbi) => {
                return (
                  <Card card={lbi} className="selec-card-tc">
                    <div className="space-between">
                      <FaEye className="eye-icn-c" />
                    </div>
                    <h6>{lb.concept_name}</h6>{" "}
                    {lb.saved_images.slice(0, 1).map((im, imi) => {
                      return (
                        <img
                          onClick={() => setImagesView(lb)}
                          className="img-con-cs"
                          al={imi}
                          src={im}
                          alt=""
                        />
                      );
                    })}
                  </Card>
                );
              })}
        </div>
      </Affix>
      <Modal
        className="no-h-bo-b-modal top-20h-m"
        visible={imagesView !== undefined}
        onCancel={() => setImagesView()}
        width={1200}
      >
        {imagesView ? (
          <div className="th-imgs-thum">
            <h5>{imagesView.concept_name} </h5>
            <Row>
              {imagesView.saved_images &&
                imagesView.saved_images.map((th, thi) => {
                  return (
                    <Col
                      key={thi}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 24 }}
                    >
                      <img src={th} alt="" />;
                    </Col>
                  );
                })}
            </Row>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
