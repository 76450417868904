import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  AutoComplete,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import GreenTick from "../../components/GreenTick";
import SearchContext from "../../Context/Search/SearchContext";
import ReactPlayer from "react-player";
import AddNew from "./component/AddNew";
const { Option } = Select;
export default function Mamba() {
  const { user, organization, setOrganization } = useContext(SearchContext);

  const [callAPI, setCallAPI] = useState(1);
  const [content, setcontent] = useState();
  const [totalPages, setTotalPages] = useState();
  const [caption, setCaption] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);
  const [search, setsearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [highlights, sethighlights] = useState();
  const [dDelete, displayDelete] = useState();

  const [category, setcategory] = useState("");
  const [action, setAction] = useState("");
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);

  const [status, setStatus] = useState("caption_pending");
  const [clip, setClip] = useState();
  const [movement, setMovement] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [pageNoS, setPageNoS] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [isPages, setIsPages] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [editVideo, setEditVideo] = useState();



 
  useEffect(() => {
    if (content === undefined ) {
      setLoadeing(true);
      getcontent();
    }
  }, [pageNo, callAPI]);

  const getcontent = (id) => {
 
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/content?page=${pageNo}&limit=6&sort_by=${-1}&category=${category}&search=${search}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoadeing(false);
        if (data && data.isSuccess) {
          setcontent(data.contentList);
          setTotalPages(data.totalPages);
          setIsPages(true);

 
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = content.map((m) => (m._id === row._id ? row : m));
    setcontent(all);
    setIsChangeG(true);
  };
  const deleteVideo = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`https://embed.axv.ai/api/content/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isDeleted) {
          let all = content.filter((f) => f._id !== id);
          setcontent(all);
          message.success("Deleted");
        } else {
          notification.error({
            message: "Data Not Deleted  Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };
  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/content/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isPatched) {
          let all = content.map((m) =>
            m._id === response.patchedData._id ? response.patchedData : m
          );
          setcontent(all);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          
        }
      });
  };

  const columns = [
    {
      title: "Mapping Type",
      dataIndex: "mappingType",
      key: "mappingType",
      width: 400,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="Undefined"
          style={{ width: 120 }} // Set the desired width in pixels
          value={text ? text : "Undefined"}
          onChange={(value) => {
            changeValue(row, "mappingType", value);
            updateValue({ mappingType: value }, row._id);
          }}
        >
          <Option value={"equivalent"}>Equivalent</Option>
          <Option value={"explicit"}>Explicit</Option>
        </Select>
      ),
    },
    {
      title: "Input",
      dataIndex: "input",
      key: "input",
      width: 150,
      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="blue"
          onTagChange={(v) => {
            changeValue(row, "input", v);
            updateValue({ input: v }, row._id, true);
          }}
        />
      ),
    },
    {
      title: "Synonyms",
      dataIndex: "synonyms",
      key: "synonyms",
      width: 150,
      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="black"
          onTagChange={(v) => {
            changeValue(row, "synonyms", v);
            updateValue({ synonyms: v }, row._id, true);
          }}
        />
      ),
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 400,
      render: (text, row) => (
        <Select
          bordered={false}
          placeholder="Undefined"
          style={{ width: 120 }} // Set the desired width in pixels
          value={text ? text : "Undefined"}
          onChange={(value) => {
            changeValue(row, "category", value);
            updateValue({ category: value }, row._id);
          }}
        >
          <Option value={"pending"}>pending</Option>
          <Option value={"complete"}>complete</Option>
          <Option value={"progress"}>progress</Option>
        </Select>
      ),
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];

  
  return (
    <div style={{ width: "100%" }}>
      <div className="table-top-nav">
        <AddNew content={content} setcontent={setcontent} />
        <span>
          <Input
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
              setcontent();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            placeholder="Search"
            style={{ width: "200px", marginLeft: "10px", borderRadius: "20px" }}
            allowClear
          />

          <Select
            placeholder="category"
            onChange={(v) => {
              setcategory(v);
              setcontent();
              setPageNo(1);
              setCallAPI(callAPI + 1);
            }}
            value={category}
            style={{ width: "160px", marginLeft: "10px" }}
          >
        <Option value={""}>All Categroy</Option>
        <Option value={"pending"}>pending</Option>
          <Option value={"complete"}>complete</Option>
          <Option value={"progress"}>progress</Option>
          </Select>
 
        </span>
      </div>

      <div style={{ width: "100%", overflowX: "auto" }}>
        {loadeing ? <Spin /> : null}
        {content ? (
          isPages ? (
            <Table
              pagination={{
                pageSize: 15,
                current: pageNo,
                total: totalPages * 15,
                onChange: (v) => {
                  setcontent();
                  setPageNo(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={content}
            />
          ) : (
            <Table
              pagination={{
                pageSize: 20,
                current: pageNoS,
                total: 20 * 20,
                onChange: (v) => {
                  setcontent();
                  setPageNoS(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={content}
            />
          )
        ) : null}
      </div>


      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Content</h5>

        <div className="bx-form-brand">
          <h6 onClick={() => console.log(dDelete)}>
            Are you sure you want to delete?
          </h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteVideo(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
