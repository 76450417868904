import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  message,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import AddNew from "./AddNew";
import { MdClose } from "react-icons/md";
const { Option } = Select;
export default function Action({ row }) {
  const [show, setShow] = useState();
  const [loading, setLoading] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [vectorList, setVectorList] = useState();
  const [addVideo, setAddVideo] = useState(false);
  const getrolePlay = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/vector?page=${pageNo}&limit=15&sort_by=${-1}&roleplay_id=${row._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setVectorList(data.vectorList);
          setTotalPages(data.totalPages);
        }

        setLoading(false);
      });
  };

  const deleteFile = (id) => {
    const filtereted = vectorList.filter((t) => t._id !== id);
    setVectorList(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/vector/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = vectorList.map((m) => (m._id === row._id ? row : m));
    setVectorList(allRow);
  };
  const saveChanges = (row, id, save) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/vector/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Updated");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  return (
    <div>
      <Button
        onClick={() => {
          getrolePlay();
          setLoading(true);
          setShow(true);
        }}
        size="small"
        type="primary"
      >
        Action
      </Button>
      <Modal
        width={1000}
        visible={show !== undefined}
        onCancel={() => {
          setShow();
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        <h6>{row.website}</h6>
        {show &&
          vectorList &&
          vectorList.map((l, li) => {
            return (
              <div className="vector-list-m" style={{ padding: "10px" }}>
                <Card key={li}>
                  <Row>
                    <Col span={8}>
                      <div className="video-container radius-video">
                        <ReactPlayer url={l.file_url} className="" controls />
                      </div>
                    </Col>
                    <Col span={16}>
                      <div className="role-play-molda-c">
                        <div className="close-min-c">
                          <Popconfirm
                            title="Are you sure to mark complete this task?"
                            onConfirm={() => deleteFile(l._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <MdClose />
                          </Popconfirm>
                        </div>

                        <h5>
                          {" "}
                          <Input.TextArea
                            value={l.comment}
                            placeholder="Add Comment"
                            onChange={(e) => {
                              changeField("comment", e.target.value, l);
                            }}
                            onBlur={() =>
                              saveChanges({ comment: l.comment }, l._id)
                            }
                            className="hidden-textarea"
                          />
                        </h5>
                        <div className="role-play-m1">
                          <Select
                            bordered={false}
                            placeholder="status"
                            style={{ width: 120 }} // Set the desired width in pixels
                            value={l.status}
                            onChange={(value) => {
                              changeField("status", value, l);

                              saveChanges({ status: value }, l._id, true);
                            }}
                          >
                            <Option value={"active"}>Complete</Option>
                            <Option value={"pending"}>Progress</Option>
                            <Option value={"error"}>Error</Option>
                          </Select>

                          <b>
                            {moment(l.date).format("MMMM Do YYYY, h:mm:ss a")}
                          </b>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            );
          })}
        {addVideo ? (
          <div>
            <AddNew
              setAddVideo={setAddVideo}
              vectorList={vectorList}
              setVectorList={setVectorList}
              row={row}
            />
          </div>
        ) : (
          <div className="flex-end">
            <Button onClick={() => setAddVideo(true)} type="primary">
              Add
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}
