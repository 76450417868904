import { Button, Input, InputNumber, message, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import SearchContext from "../../../Context/Search/SearchContext";
const { Option } = Select;
export default function ColorGrade() {
  const { object, setobject } = useContext(SearchContext);
  const [Description, setDescription] = useState([]);
  const [isChangeG, setIsChangeG] = useState(false);
  const [callGeneral, setcallGeneral] = useState("");
  const [general, setgeneral] = useState();

  useEffect(() => {
    displayDescription(object.activeVideo);
  }, []);

  useEffect(() => {
    getgeneral();
  }, [callGeneral]);

  const getgeneral = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/insight?page=1&limit=15&search=${callGeneral}&category=visual`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setgeneral(data.insightList);
        }
      });
  };

  const displayDescription = (row) => {
    const g = row.color_grade;
    for (let i = 0; i < g.length; i++) {
      g[i].id = i;
    }
    setDescription(g);
  };
  const changeDescription = (row, key, val) => {
    row[key] = val;
    const all = Description.map((m) => (m.id === row.id ? row : m));
    setDescription(all);
    setIsChangeG(true);
  };

  const deleteDescription = (id) => {
    const all = Description.filter((f) => f.id !== id);
    setDescription(all);
    setIsChangeG(true);
  };

  const updateVd = (obj, id) => {
    setIsChangeG(false);
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("added");
          setobject({
            ...object,
            activeVideo: data.segmentData,
          });
        } else {
          message.error("something went wrong");
        }
      });
  };
  return (
    <div>
      {" "}
      {Description &&
        Description.map((b, bi) => {
          return (
            <table key={bi} className="table-bucket-aws table-b-guide-cm">
              <tbody>
                <tr>
                  <td>
                    <b>StartTime:</b>
                  </td>
                  <td>
                    <div className="close-butcke-bx">
                      <MdClose
                        onClick={() => deleteDescription(b.id)}
                        className="close-bx-d-in hover"
                      />
                    </div>

                    <Input
                      onChange={(e) =>
                        changeDescription(b, "startTime", e.target.value.trim())
                      }
                      value={b.startTime}
                      placeholder="startTime"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>endTime:</b>
                  </td>
                  <td>
                    <Input
                      onChange={(e) =>
                        changeDescription(b, "endTime", e.target.value.trim())
                      }
                      value={b.endTime}
                      placeholder="endTime"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Color:</b>
                  </td>
                  <td>
                    <Input
                      onChange={(e) =>
                        changeDescription(b, "color_match", e.target.value)
                      }
                      value={b.color_match}
                      placeholder="Color Match"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Label:</b>
                  </td>
                  <td>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={b.label}
                      onChange={(e) => changeDescription(b, "label", e)}
                      onSearch={(e) => setcallGeneral(e)}
                      placeholder="Select Label"
                    >
                      {general &&
                        general.map((lb, lbi) => {
                          return (
                            <Option value={lb.value} key={lbi}>
                              <div className="lbl-drp-c">{lb.value} </div>
                            </Option>
                          );
                        })}
                    </Select>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
      <div className="flex-end">
        <AiOutlinePlus
          onClick={() =>
            setDescription([
              ...Description,
              {
                startTime: "",
                endTime: "",
                label: "",
                color_match: "",

                id: Description.length
                  ? Description[Description.length - 1].id + 1
                  : 0,
              },
            ])
          }
          className="icn-side-c"
        />
      </div>
      <div>
        {isChangeG ? (
          <Button
            size="small"
            type="primary"
            onClick={() => {
              updateVd({ color_grade: Description }, object.activeVideo._id);
            }}
          >
            Update
          </Button>
        ) : null}
      </div>
    </div>
  );
}
