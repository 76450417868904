import React from "react";

import AllElements from "../AllElements";

export default function Table({ props }) {
  return (
    <table  {...props}>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <AllElements props={props} />
    </table>
  );
}
