import { Col, Row, Progress, Tag, Pagination } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Card, Container, ListGroup } from "react-bootstrap";
import {
  SettingFilled,
  StarOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Icons from "../../components/Icons";
import AddConcept from "./components/AddConcept";
import "./Concepts.css";
import { FaTable, FaTrash, FaChartArea } from "react-icons/fa";
import ConceptTable from "./components/ConceptTable";
import { BiCard, BiChart } from "react-icons/bi";
import SearchContext from "../../Context/Search/SearchContext";

export default function Concepts() {
  const navigate = useNavigate();
  const { concepts, setConcepts, moments, setMoments } =
    useContext(SearchContext);
  const [isCard, setIsCard] = useState();
  const [callAPI, setCallAPI] = useState(1);
  const [selectedCard, setSelectedCard] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [date, setDate] = useState({
    start: moment(new Date()).subtract(10, "days").format("MM/DD/YYYY"),
    end: moment(new Date()).format("MM/DD/YYYY"),
  });

  useEffect(() => {
    if (concepts.data === undefined) {
      getConcept();
    }
  }, [pageNo]);

  const getConcept = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/concept?page=${pageNo}&limit=15&concept_type=template`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setConcepts({
            ...concepts,
            data: data.conceptList,
            totalPages: data.totalPages,
          });
        }
        console.log(data);
      });
  };

  const changeActive = (c) => {
    if (c === isCard) {
      setIsCard();
    } else {
      setIsCard(c);
    }
  };

  const editData = (key, value, row) => {
    row[key] = value;
    const changedRows = concepts.data.map((m) => (m._id === row._id ? row : m));
    setConcepts({ ...concepts, data: changedRows });
  };

  const saveEditConcept = (row, key) => {
    // const obj = {
    //   [key]: row[key],
    //   business_created: new Date(),
    // };
    const editRow = { ...row };
    const id = row._id;
    delete editRow._id;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(row),
    };
    fetch(`https://int.display.video/api/concept/update/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
        }
      })
      .catch((err) => {});
  };

  const deleteConcept = (row) => {
    const deleted = concepts.data.filter((m) => m._id !== row._id);
    setConcepts({ ...concepts, data: deleted });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(`https://int.display.video/api/concept/delete/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };
  const updateConcepts = (row) => {
    const all = concepts.data;
    all.push(row);
    setConcepts({ ...concepts, data: all });
  };

  return (
    <div className="bg-dashboard">
      <Container>
        <div>
          <Row>
            <Col lg={{ span: 24 }}>
              <div className="cards-top-drp">
                <b className="add-ttl-vc">
                  Templates <AddConcept updateConcepts={updateConcepts} />
                </b>
                <div>
                  {concepts.gridView ? (
                    <FaTable
                      className={concepts.gridView ? "active-tbl" : ""}
                      onClick={() =>
                        setConcepts({
                          ...concepts,
                          gridView: !concepts.gridView,
                        })
                      }
                    />
                  ) : (
                    <BiCard
                      className={!concepts.gridView ? "active-tbl" : ""}
                      onClick={() =>
                        setConcepts({
                          ...concepts,
                          gridView: !concepts.gridView,
                        })
                      }
                    />
                  )}
                  {/* <PlusSquareOutlined /> */}
                  <SearchOutlined />
                  <StarOutlined />
                </div>
              </div>

              {concepts.gridView ? (
                <Row>
                  {concepts.data &&
                    concepts.data.map((ct, cti) => {
                      return (
                        <Col
                          key={cti}
                          // onClick={() => changeActive(ct._id)}
                          // className={ct._id === isCard ? "" : ""}
                          className="cl-tmp-crd"
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          sm={{ span: 24 }}
                        >
                          <div className="flex-cntn">
                            <h6>{ct.name}</h6>{" "}
                            <Tag
                              onClick={() => deleteConcept(ct)}
                              color="#ab1c1c"
                            >
                              Delete
                            </Tag>
                          </div>
                          <div className="col-main-cn">
                            {ct.saved_images && ct.saved_images.length ? (
                              <div className="imgs-cn-tmp">
                                <div>
                                  <img
                                    className="mn-img-tmp"
                                    src={ct.saved_images[0]}
                                    alt=""
                                  />
                                </div>
                                {ct.saved_images.slice(1).map((sm, smi) => {
                                  return (
                                    <img
                                      key={smi}
                                      className="mn-img-tmp2"
                                      src={sm}
                                      alt=""
                                    />
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              ) : (
                <ConceptTable
                  current={pageNo}
                  setPageNo={(v) => {
                    setConcepts({ ...concepts, data: undefined });
                    setPageNo(v);
                  }}
                />
              )}
            </Col>
          </Row>
          <div className="page-vid-c">
            {concepts.totalPages && concepts.gridView ? (
              <Pagination
                pageSize={15}
                current={pageNo}
                size="small"
                total={concepts.totalPages * 15}
                onChange={(v) => {
                  setConcepts({ ...concepts, data: undefined });
                  setPageNo(v);
                }}
              />
            ) : null}
          </div>
        </div>
      </Container>{" "}
    </div>
  );
}
