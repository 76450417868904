import { message, Table } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import { FaTrash } from "react-icons/fa";
export default function ChannelTable() {
  const { channels, setChannels } = useContext(SearchContext);

  const columns = [
    {
      title: "Business Name",
      dataIndex: "business_name",
      key: "business_name",
      render: (text, row) => (
        <input
          onBlur={() => saveEditChannels(row, "business_name")}
          onChange={(e) => editData("business_name", e.target.value, row)}
          value={text}
          type="text"
          className="form-control"
          aria-label="Default"
          placeholder="Headline"
        />
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, row) => (
        <input
          onBlur={() => saveEditChannels(row, "location")}
          onChange={(e) => editData("location", e.target.value, row)}
          value={text}
          type="text"
          className="form-control"
          aria-label="Default"
          placeholder="Headline"
        />
      ),
    },
    {
      title: "Creator Names",
      dataIndex: "creator_names",
      key: "creator_names",
      render: (text, row) => (
        <input
          onBlur={() => saveEditChannels(row, "creator_names")}
          onChange={(e) => editData("creator_names", e.target.value, row)}
          value={text}
          type="text"
          className="form-control"
          aria-label="Default"
          placeholder="Headline"
        />
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, row) => (
        <input
          onBlur={() => saveEditChannels(row, "category")}
          onChange={(e) => editData("category", e.target.value, row)}
          value={text}
          type="text"
          className="form-control"
          aria-label="Default"
          placeholder="Headline"
        />
      ),
    },
    {
      title: "Business Created",
      dataIndex: "business_created",
      key: "business_created",
      render: (text, row) => <span>{moment(text).format("MMMM Do YYYY")}</span>,
    },
    {
      title: "Most Recent Content",
      dataIndex: "most_recent_content",
      key: "most_recent_content",
      render: (text, row) => <span>{moment(text).format("MMMM Do YYYY")}</span>,
    },
    {
      title: "Related Channels",
      dataIndex: "related_channels",
      key: "related_channels",
      render: (text, row) => (
        <input
          onBlur={() => saveEditChannels(row, "related_channels")}
          onChange={(e) => editData("related_channels", e.target.value, row)}
          value={text}
          type="text"
          className="form-control"
          aria-label="Default"
          placeholder="Headline"
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (text, row) => <FaTrash onClick={() => deleteChannels(row)} />,
    },
  ];

  const editData = (key, value, row) => {
    row[key] = value;
    const changedRows = channels.map((m) => (m._id === row._id ? row : m));
    setChannels(changedRows);
  };

  const deleteChannels = (row) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(`https://int.display.video/api/channel/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        window.location.reload();
      });
  };

  const saveEditChannels = (row, key) => {
    // const obj = {
    //   [key]: row[key],
    //   business_created: new Date(),
    // };
    const editRow = { ...row };
    const id = row._id;
    delete editRow._id;
    delete editRow.createdAt;
    delete editRow.updatedAt;
    delete editRow.__v;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(editRow),
    };
    fetch(`https://int.display.video/api/channel/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };

  return (
    <Table className="channle-table" columns={columns} dataSource={channels} />
  );
}
