import React from "react";

export default function P({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <p {...props}>{props.text}</p>
    </>
  );
}
