import { message, Progress, Table } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import { FaTrash } from "react-icons/fa";
export default function ConceptTable() {
  const { concept, setConcept } = useContext(SearchContext);

  const columns = [
    {
      title: "Images",
      dataIndex: "data_v",
      key: "data_v",
      render: (text, row) => (
        <div>
          {text.map((im, i) => {
            return <img key={i} className="concetp-t-img" src={im} alt="" />;
          })}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <input
          onBlur={() => saveEditConcept(row, "name")}
          onChange={(e) => editData("name", e.target.value, row)}
          value={text}
          type="text"
          className="form-control"
          aria-label="Default"
          placeholder="Name"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Progress",
      dataIndex: "index_progress",
      key: "index_progress",
      render: (text, row) => <Progress strokeLinecap="butt" percent={text} />,
    },
    {
      title: "Last Indexed",
      dataIndex: "last_indexed",
      key: "last_indexed",
      render: (text, row) => <span>{moment(text).format("MMMM Do YYYY")}</span>,
    },
    {
      title: "creatted_by",
      dataIndex: "creatted_by",
      key: "creatted_by",
      render: (text, row) => <span>{moment(text).format("MMMM Do YYYY")}</span>,
    },
    {
      title: "user_id",
      dataIndex: "user_id",
      key: "user_id",
      render: (text, row) => (
        <input
          onBlur={() => saveEditConcept(row, "user_id")}
          onChange={(e) => editData("user_id", e.target.value, row)}
          value={text}
          type="text"
          className="form-control"
          aria-label="Default"
          placeholder="user_id"
        />
      ),
    },
    {
      title: "index_id",
      dataIndex: "index_id",
      key: "index_id",
      render: (text, row) => (
        <input
          onBlur={() => saveEditConcept(row, "index_id")}
          onChange={(e) => editData("index_id", e.target.value, row)}
          value={text}
          type="text"
          className="form-control"
          aria-label="Default"
          placeholder="index_id"
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (text, row) => <FaTrash onClick={() => deleteConcept(row)} />,
    },
  ];

  const editData = (key, value, row) => {
    row[key] = value;
    const changedRows = concept.map((m) => (m._id === row._id ? row : m));
    setConcept(changedRows);
  };

  const deleteConcept = (row) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(`https://int.display.video/api/concept/delete/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        window.location.reload();
      });
  };

  const saveEditConcept = (row, key) => {
    // const obj = {
    //   [key]: row[key],
    //   business_created: new Date(),
    // };
    const editRow = { ...row };
    const id = row._id;
    delete editRow._id;
    delete editRow.createdAt;
    delete editRow.updatedAt;
    delete editRow.__v;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(row),
    };
    fetch(`https://int.display.video/api/concept/update/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };

  return (
    <Table className="channle-table" columns={columns} dataSource={concept} />
  );
}
