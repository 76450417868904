import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import SearchContext from "../../Context/Search/SearchContext";
import GreenTick from "../../components/GreenTick";
import moment from "moment";
const { Option } = Select;
export default function Product() {
  const { allEmp, user } = useContext(SearchContext);

  const [Emp, setEmp] = useState("");
  const [product, setproduct] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [loadeingAI, setLoadeingAI] = useState(true);
  const [dAdd, setdAdd] = useState();
  const [feedback, setFeedback] = useState();
  const [feedbackID, setFeedbackId] = useState();
  const [categroy, setCategory] = useState("");
  const [section, setsection] = useState("");
  const [search, setsearch] = useState("");

  useEffect(() => {
    if (product === undefined) {
      setLoadeing(true);
      getproduct();
    }
  }, [pageNo, categroy, section, search]);

  const getproduct = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/product?page=${pageNo}&limit=15&sort_by=${-1}&section=${section}&category=${categroy}&search=${search}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setproduct(data.productList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };
  const Saveproduct = (row) => {
    row.created_by = user.user_id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/product`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Row added in first page");
          const all = [...product];
          all.unshift(data.data);

          setproduct(all);
        } else {
          message.error(data.msgError);
        }
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      closeFile();
      setIsChange(false);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/product/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            const allRow = product.map((m) =>
              m._id === id ? data.productData : m
            );
            setproduct(allRow);

            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = product.filter((t) => t._id !== id);
    setproduct(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/product/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = product.map((m) => (m._id === row._id ? row : m));
    setproduct(allRow);
  };

  const changeFile = (row, key, val) => {
    console.log(row, key, val);

    row[key] = val;
    console.log(row, key, val);
    setFeedback(row);
    setIsChangeG(true);
  };

  const deleteFile = (i) => {
    const all = [...feedback];
    all.splice(i, 1);
    setFeedback(all);
    setIsChangeG(true);
  };

  const displayFile = (row) => {
    const g = row.feedback;

    setFeedbackId(row._id);
    setFeedback(g);
  };
  const closeFile = () => {
    setFeedback();
    setFeedbackId();
    setIsChangeG(false);
  };

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="name"
          onChange={(e) => {
            changeField("name", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ name: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "features",
      dataIndex: "features",
      key: "features",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text[0]}
          placeholder="features"
          onChange={(e) => {
            changeField("features", [e.target.value], row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ features: text }, row._id)}
          className="hidden-textarea"
        />
      ),
      //  {

      //   <TagsGroup
      //     color="blue"
      //     tags={text ? text : []}
      //     onTagChange={(v) => {
      //       changeField("features", v, row);
      //       saveChanges({ features: v }, row._id, true);
      //     }}
      //   />;
      // },
    },
    {
      title: "author",
      dataIndex: "author",
      key: "author",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="author"
          onChange={(e) => {
            changeField("author", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ author: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "date",
      dataIndex: "date_of_release",
      key: "date_of_release",
      width: 100,
      render: (text, row) => text,
    },
    {
      title: "category",
      dataIndex: "category",
      key: "category",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="category"
          onChange={(e) => {
            changeField("category", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ category: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "Feedback",
      dataIndex: "",
      key: "01",
      render: (text, row) => (
        <BButton
          onClick={() => {
            displayFile(row);
          }}
          type="primary"
          size="small"
        >
          F
        </BButton>
      ),
    },
    {
      title: "file_url",
      dataIndex: "file_url",
      key: "file_url",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="file_url"
          onChange={(e) => {
            changeField(
              "file_url",
              e.target.value.indexOf("/ref=") === -1
                ? e.target.value
                : e.target.value.slice(0, e.target.value.indexOf("/ref=")),
              row
            );
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ file_url: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "review_summary",
      dataIndex: "review_summary",
      key: "review_summary",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="review_summary"
          onChange={(e) => {
            changeField("review_summary", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ review_summary: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "AI",
      dataIndex: "",
      key: "03",
      render: (text, row) =>
        loadeingAI === row._id ? (
          <Spin />
        ) : (
          <BButton
            onClick={() => {
              setLoadeingAI(row._id);
              AIPrompt(row._id);
            }}
            type="primary"
            size="small"
          >
            AI
          </BButton>
        ),
    },
    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];

  const AIPrompt = (id) => {
    const obj = {};
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/product/prompt/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoadeingAI(false);
        if (data && data.isSuccess) {
          const allRow = product.map((m) => (m._id === id ? data.data : m));
          setproduct(allRow);
        } else {
          message.error("SOmething went wrong");
        }
      });
  };

  return (
    <div>
      <div className="table-top-nav">
        <Button size="sm" onClick={() => setdAdd({ name: "" })}>
          <BsPlus />
          Add
        </Button>
        <div>
          {/* <Input
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
              setproduct();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Search Value "
          />
          <Input
            value={section}
            onChange={(e) => {
              setsection(e.target.value);
              setproduct();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="section "
          />
          <Input
            value={categroy}
            onChange={(e) => {
              setCategory(e.target.value);
              setproduct();
              setPageNo(1);
            }}
            className="search-d-at"
            placeholder="Category "
          /> */}
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {product ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setproduct();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={product}
        />
      ) : null}
      <Modal
        visible={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Name:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="Value"
                      value={dAdd.name}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, name: e.target.value.slice(0, 75) })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-end">
              <Button
                onClick={() => {
                  Saveproduct(dAdd);
                  setdAdd();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Product</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        width={520}
        visible={feedback !== undefined}
        onCancel={() => {
          closeFile();
        }}
        className="no-f-no-h brand-modal top-20-x"
      >
        <h5 className="create-btn-h">Value</h5>

        <div className="bx-form-brand">
          {feedback &&
            feedback.map((b, bi) => {
              return (
                <table key={bi} className="table-bucket-aws table-b-guide-cm">
                  <tbody>
                    <tr>
                      <td>
                        <b>Feedback:</b>
                      </td>
                      <td>
                        <div className="close-butcke-bx">
                          <MdClose
                            onClick={() => deleteFile(bi)}
                            className="close-bx-d-in hover"
                          />
                        </div>
                        <Input.TextArea
                          autoSize={{ maxRows: 4, minRows: 2 }}
                          onChange={(e) =>
                            changeFile([...feedback], bi, e.target.value)
                          }
                          value={b}
                          placeholder="Title"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          <div className="flex-end">
            <AiOutlinePlus
              onClick={() => setFeedback([...feedback, ""])}
              className="icn-side-c"
            />
          </div>
          <div>
            {isChangeG ? (
              <Button
                size="sm"
                onClick={() =>
                  saveChanges({ feedback: feedback }, feedbackID, true)
                }
              >
                Update
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
