import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Tag,
  message,
  Tabs,
  Spin,
  Modal,
  Input,
  Button,
  Pagination,
} from "antd";
import Thumbnails from "./components/Thumbnails";
import "./label.css";
import { buildQueries } from "@testing-library/react";
import DoneVideo from "./components/DoneVideo";
import moment from "moment";
import { TimeCorrection } from "../../components/GlobalFunctions";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
export default function Label() {
  const navigate = useNavigate();

  const { label, setlabel, setActive, user, setreview, review } =
    useContext(SearchContext);
  useEffect(() => {
    setActive(window.location.pathname);
  }, []);
  const [callApi, setCallApi] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [emp, setEmp] = useState("");

  useEffect(() => {
    if (label[label.activeTab] === undefined) {
      getVideoTask();
    }
  }, [callApi]);

  const getVideoTask = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/video_segment?page=${
        label.pageNo4
      }&limit=15&frame_status=${label.activeTab}&hide_file_url=${true}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        console.log(data.segmentList);
        if (data && data.isSuccess) {
          // let nd = [];
          // nd.push(data.segmentData);
          setlabel({
            ...label,
            [label.activeTab]: data.segmentList,
            totalPages4: data.totalPages,
          });
        }
      });
  };
  const updateFiled = (obj, id, row) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.isSuccess) {
          setreview({
            ...review,
            doneVideo2: data.segmentData,
            activeKey: "Speaker",
          });
          navigate(`/task/${row._id}`);
        } else {
          message.error("something went wrong");
        }
      });
  };

  const updateLabelDuration = (id) => {
    const obj = { headclip_id: id };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/video_segment/label_duration/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {});
  };

  const updateTranscript = (id) => {
    const obj = { headclip_id: id };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/video_segment/update_transcript/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {});
  };

  const startClick = (lb) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    const api = `https://int.display.video/api/video_segment?_id=${lb._id}`;
    fetch(api, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess && data.segmentList.length) {
          if (data.segmentList[0].frame_status === "complete") {
            updateTranscript(lb._id);
            updateLabelDuration(lb._id)
            startTimer(lb);
          } else {
            setLoading(false);
            message.warning("Someone Already Started");
          }
        }
      });
  };
  const startTimer = (row) => {
    const obj = {
      sheet_duration: "progress",
      video_name: row.video_name,
      model_id: user.user_id,
      video_id: row._id,
      user_id: row.user_id,
      startTime: new Date(),
      video_duration: row.video_duration,
      sheet_type: "phase_1",
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(`https://int.display.video/api/timesheet`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isCreated) {
          updateFiled(
            {
              frame_status: "phase_a",
              model_start: new Date(),
            },
            row._id,
            row
          );
        }
      });
  };

  return (
    <div className="main-bx-lbl">
      <div>
        <Tabs
          onChange={(v) => {
            setlabel({
              ...label,
              [label.activeTab]: undefined,
              totalPages: undefined,
              totalPages4: undefined,
              activeTab: v,
              pageNo: 1,
              pageNo4: 1,
            });
            setLoading2(true);
            setCallApi(callApi + 1);
          }}
          activeKey={label.activeTab}
        >
          <TabPane tab="Pending" key="complete">
            {loading2 ? (
              <Spin />
            ) : label[label.activeTab] &&
              label[label.activeTab].filter(
                (f) => f.frame_status === label.activeTab
              ).length === 0 ? (
              <h1>No Video</h1>
            ) : null}
            <Row>
              {label[label.activeTab] &&
                label[label.activeTab]
                  .filter((f) => f.frame_status === label.activeTab)
                  .map((lb, lbi) => {
                    return (
                      <Col key={lbi} md={{ span: 6 }}>
                        <div className="lbl-c-col">
                          <Card>
                            <h6>{lb.video_name}</h6>
                            <div className="bx-2-date-tm">
                              <b>
                                Created At:
                                {lb.createdAt ? (
                                  <i>
                                    {moment(lb.createdAt).format(
                                      "MMMM Do YYYY h:mm:ss"
                                    )}
                                  </i>
                                ) : null}
                              </b>
                            </div>
                            <div className="start-btn-c">
                              {loading === lb._id ? (
                                <Spin />
                              ) : (
                                <Tag
                                  onClick={() => {
                                    setLoading(lb._id);
                                    startClick(lb);
                                  }}
                                  color="rgb(24, 24, 145)"
                                >
                                  Start
                                </Tag>
                              )}
                            </div>
                          </Card>
                        </div>
                      </Col>
                    );
                  })}
            </Row>
          </TabPane>
        </Tabs>
        <div className="page-vid-c">
          {label.totalPages4 ? (
            <Pagination
              pageSize={15}
              current={label.pageNo4}
              size="small"
              total={label.totalPages4 * 15}
              onChange={(v) => {
                setlabel({
                  ...label,
                  [label.activeTab]: undefined,
                  pageNo4: v,
                });
                setCallApi(callApi + 1);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
