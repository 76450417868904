import MambaFilter from "./components/MambaFilter";
import "./shopping.css";

export default function Shopping() {
  return (
    <div>
      <MambaFilter />
    </div>
  );
}
