/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Layout, Menu } from "antd";

import Header from "./Header";

const LayoutP = ({ ActivePage }) => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header />
      <Layout className="site-layout">
        <div style={{ overflowY: "auto" }} className="site-layout-background">
          {ActivePage}
        </div>
      </Layout>
    </Layout>
  );
};

export default LayoutP;
