import { Progress, Table } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../../Context/Search/SearchContext";


export default function DisplayInTable() {
  const { videoTasks, setVideoTask } = useContext(SearchContext);
  const columns = [
    {
      title: "Name",
      dataIndex: "video_name",
      key: "video_name",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Progress",
      dataIndex: "index_progress",
      key: "index_progress",
      render: (text, row) => (
        <Progress className="progress-m-vc" percent={text} />
      ),
    },
  ];

  return (
    <div>
      <div className="vd-tbl-grd">
        <Table columns={columns} dataSource={videoTasks.videos} />
      </div>
    </div>
  );
}
