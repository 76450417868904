import { Button, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import ChannelTable from "./components/ChannelTable";
import "./channels.css";
import { FaPlus } from "react-icons/fa";
import AddChannel from "./components/AddChannel";

export default function Channels() {
  const { channels, setChannels } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(0);

  useEffect(() => {
    if (channels === undefined) {
      getChannels();
    }
  }, [callApi]);

  const getChannels = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(`https://int.display.video/api/channel?page=1&limit=15`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.channelList) {
          setChannels(data.channelList);
        }
        console.log(data);
      });
  };

  return (
    <div>
      <div>
        <AddChannel />
      </div>
      {channels ? <ChannelTable /> : null}
    </div>
  );
}
