/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable dot-notation */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prefer-stateless-function */
import { Container } from "react-bootstrap";
import {
  Col,
  Card,
  Row,
  Drawer,
  Button,
  Modal,
  Tag,
  Input,
  Select,
  InputNumber,
  Checkbox,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import React, { Component } from "react";
import moment from "moment";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";

const { Option } = Select;
export default class AddConcept extends Component {
  state = {
    importFileList: [],
    fileList: [],
    fileTags: [],
    update: 0,
    step: 1,
    newConcept: {
      player_number: "",
      exact_design: false,
      concept_type: "",
      name: "",
      status: "",
      created_by: "John Doe",
      // data_t:  []
      // data_v: []
      favorite: false,
      index_id: "1",
      index_progress: 10,
      color_code: "#FF0000",
      personalize: "",
    },
  };

  closeModal = () => {
    this.setState({
      displayAdd: false,
      importFileList: [],
      fileList: [],
      fileTags: [],
      step: 1,
      newConcept: {
        player_number: "",
        exact_design: false,
        concept_type: "",
        name: "",
        status: "",
        created_by: "John Doe",
        // data_t:  []
        // data_v: []
        favorite: false,
        index_id: "1",
        index_progress: 10,
        color_code: "#FF0000",
        personalize: "",
      },
    });
  };

  saveFile = () => {
    const { importFileList, fileTags, update, newConcept } = this.state;
    let id = uuidv4();
    const formData = new FormData();
    formData.append("exact_design", newConcept.exact_design);
    formData.append("concept_type", newConcept.concept_type);
    formData.append("player_number", newConcept.player_number);
    formData.append("name", newConcept.name);
    formData.append("personalize", newConcept.personalize);
    formData.append("color_code", newConcept.color_code);
    formData.append("status", newConcept.status);
    formData.append(
      "meta_name",
      newConcept.name.trim().split(" ").join("_") + "_" + id
    );

    for (let i = 0; i < importFileList.length; i++) {
      formData.append("images", importFileList[i]);
    }

    this.closeModal();

    const requestOptions = {
      method: "POST",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: formData,
    };
    fetch(`https://int.display.video/api/concept/add`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.name) {
          this.props.updateConcepts(response);
        }
      });
  };

  changeVal = (key, value) => {
    const { newConcept } = this.state;
    newConcept[key] = value;
    this.setState({ newConcept });
  };

  render() {
    const {
      files,
      step,
      personT,
      step1c,
      selected,
      newConcept,
      displayAdd,
      fileTags,
    } = this.state;
    const props = {
      name: "file",
      multiple: true,
      listType: "picture-card",
      fileList: this.state.fileList,
      accept: ".jpg , .jpeg , .png , .tiff , .webp",
      onChange: (info) => {
        const fileList = [...info.fileList];
        if (fileList.length < this.state.fileList.length) {
          // for delete image condition
          const importFileList = [];
          const imported = this.state.importFileList;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.state.importFileList.length; i++) {
            if (imported[i].uid !== info.file.uid) {
              importFileList.push(imported[i]);
            }
          }
          this.setState({
            importFileList,
          });
        }
        this.setState({
          fileList,
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          importFileList: [...state.importFileList, file],
        }));

        return false;
      },
    };
    return (
      <div>
        <Container>
          <div className="space-lk-t">
            <div className="add-more-field">
              <Button
                type="primary"
                size="small"
                onClick={() => this.setState({ displayAdd: true })}
              >
                Upload
              </Button>
            </div>
          </div>
        </Container>

        <Modal
          width={600}
          visible={displayAdd}
          onCancel={this.closeModal}
          className="no-f-no-h top-20-x"
        >
          {step === 1 ? (
            <div className="step1-con-c">
              <h3>Concepts</h3>
              <Row>
                <Col span={12}>
                  <Card
                    className={`${
                      newConcept.concept_type === "template" ? "actives1" : ""
                    }`}
                    onClick={() => {
                      this.setState({ step: 2 });
                      this.changeVal("concept_type", "template");
                    }}
                  >
                    Template
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className={`${
                      newConcept.concept_type === "category" ? "actives1" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        step: 2,
                        player_number: "",
                      });
                      this.changeVal("concept_type", "category");
                    }}
                  >
                    Category
                  </Card>
                </Col>

               
              </Row>
            </div>
          ) : null}
          {step === 2 ? (
            <div>
              <h4>{newConcept.concept_type} details</h4>
              <Dragger className="drp-imgs" {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <div>Drag n Drop or Click Here</div>
              </Dragger>
              <div className="tag-grou-lake-c">
                <table>
                  {newConcept.concept_type === "Person" ? (
                    <tr>
                      <td>
                        <h6>Sports player</h6>
                      </td>
                      <td>
                        <Input
                          size="large"
                          placeholder="Sports player"
                          value={newConcept.player_number}
                          onChange={(e) =>
                            this.changeVal("player_number", e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>
                      <h6>Name</h6>
                    </td>
                    <td>
                      <Input
                        size="large"
                        placeholder="Name"
                        value={newConcept.name}
                        onChange={(e) => this.changeVal("name", e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Personalize</h6>
                    </td>
                    <td>
                      <Input
                        size="large"
                        placeholder="Personalize"
                        value={newConcept.personalize}
                        onChange={(e) => this.changeVal("personalize", e.target.value)}
                      />
                    </td>
                  </tr>
                  
                  <tr>
                    <td>
                      <h6>Status</h6>
                    </td>
                    <td>
                      <Select
                        defaultValue=""
                        style={{ width: 120 }}
                        onChange={(e) => this.changeVal("status", e)}
                      >
                        <Option disabled value="">
                          Select Status
                        </Option>
                        <Option value="active">Active</Option>
                        <Option value="pause">Pause</Option>
                        <Option value="stop">Stop</Option>
                      </Select>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h6>Color</h6>
                    </td>
                    <td>
                      <input
                        onChange={(e) =>
                          this.changeVal("color_code", e.target.value)
                        }
                        type="color"
                        id="color"
                        name="color"
                        value={newConcept.color_code}
                        style={{ marginLeft: "0px" }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h6>Exact Design</h6>
                    </td>
                    <td>
                      <Checkbox
                        onChange={(e) =>
                          this.changeVal("exact_design", e.target.checked)
                        }
                      />
                    </td>
                  </tr>
                </table>
              </div>

              <div className="flex-end">
                <Button type="primary" onClick={this.saveFile}>
                  Save
                </Button>
              </div>
            </div>
          ) : null}
        </Modal>
      </div>
    );
  }
}
