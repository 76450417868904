import React, { useContext, useState } from "react";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Spin,
  Popconfirm,
} from "antd";
import SearchContext from "../../../Context/Search/SearchContext";
import { FaEye } from "react-icons/fa";
const { Option } = Select;

export default function LabelSIdebar({ setViewThumbnails }) {
  const { label, setlabel } = useContext(SearchContext);
  const [loadingDone, setLoadingDone] = useState(false);

  const changeDone = (name, row) => {
    const obj = row.filter((f) => f.concept_name === name)[0];
    obj.indexed = true;

    const all = row.map((r) => (r.concept_name === obj.name ? obj : r));
    const activeV = { ...label.activeVideo };
    activeV.concept_index = all;
    const allRows = label[label.activeTab].map((l) =>
      l._id === activeV._id ? activeV : l
    );

    updateFiled({ concept_index: all }, activeV._id, "done", activeV, allRows);
  };

  const updateFiled = (obj, id, type, activeV, allRows) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoadingDone(false);
        if (data.isSuccess) {
          if (type === "done") {
            message.success(
              `${label.selectedConcept.concept_name} Marked True Success`
            );
            setlabel({
              ...label,
              activeVideo: activeV,
              [label.activeTab]: allRows,
              selectedConcept: undefined,
            });
          } else if (type === "complete") {
            message.success(`Whole Video Complete `);
            const all = label[label.activeTab].map((r) =>
              r._id === label.activeVideo._id
                ? {
                    ...label.activeVideo,
                    frame_status: "indexed",
                    model_end: new Date(),
                  }
                : r
            );

            setlabel({
              ...label,
              activeVideo: undefined,
              [label.activeTab]: all,
              selectedConcept: undefined,
              images: undefined,
            });
          }
        } else {
          message.error("something went wrong");
        }
      });
  };

  return (
    <div>
      <div>
        <h6>Actions</h6>
        <div className="btns-bx-lbkl">
          <Button
            size="small"
            type={label.activeBTN === "Concepts" ? "primary" : "default"}
            onClick={() => setlabel({ ...label, activeBTN: "Concepts" })}
          >
            Concepts
          </Button>
          <Button
            size="small"
            type={label.activeBTN === "Angle" ? "primary" : "default"}
            onClick={() => setlabel({ ...label, activeBTN: "Angle" })}
          >
            Angle
          </Button>
          <Button
            size="small"
            type={label.activeBTN === "Vision View" ? "primary" : "default"}
            onClick={() => setlabel({ ...label, activeBTN: "Vision View" })}
          >
            Vision View
          </Button>
        </div>
        {label.activeVideo.concept_index &&
        label.activeVideo.concept_index.filter((f) => f.indexed === false)
          .length ? (
          <Select
            style={{ width: "100%" }}
            value={
              label.selectedConcept ? label.selectedConcept.concept_name : ""
            }
            onChange={(v) =>
              setlabel({
                ...label,
                selectedConcept: label.activeVideo.concept_index.filter(
                  (f) => f.concept_name === v
                )[0],
              })
            }
          >
            <Option disabled value="">
              <div>Select Concept </div>
            </Option>
            {label.activeVideo.concept_index &&
              label.activeVideo.concept_index
                .filter((f) => f.indexed === false)
                .map((lb, lbi) => {
                  return (
                    <Option value={lb.concept_name} key={lbi}>
                      <div className="lbl-drp-c">{lb.concept_name} </div>
                    </Option>
                  );
                })}
          </Select>
        ) : loadingDone ? (
          <Spin />
        ) : (
          <Button
            onClick={() => {
              updateFiled(
                { frame_status: "indexed", model_end: new Date() },
                label.activeVideo._id,
                "complete"
              );
            }}
            type="primary"
          >
            Complete Video ?
          </Button>
        )}
      </div>
      {label.selectedConcept ? (
        <Card className="selec-card-tc">
          <div className="space-between">
            <div>
              {loadingDone ? (
                <Spin />
              ) : (
                <Popconfirm
                  title="Are you sure you complete all pages?"
                  onConfirm={() => {
                    setLoadingDone(true);
                    changeDone(label.selectedConcept.concept_name, [
                      ...label.activeVideo.concept_index,
                    ]);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small" type="primary">
                    Done
                  </Button>
                </Popconfirm>
              )}
            </div>
          </div>
          <h6>{label.selectedConcept.concept_name}</h6>{" "}
          {label.selectedConcept.saved_images.map((im, imi) => {
            return (
              <img
                onClick={() => setViewThumbnails(label.selectedConcept)}
                className="img-con-cs"
                al={imi}
                src={im}
                alt=""
              />
            );
          })}
        </Card>
      ) : null}
    </div>
  );
}
