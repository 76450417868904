import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/Search/SearchContext";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Affix,
  message,
  Tag,
  Select,
  Popconfirm,
  Spin,
  Input,
} from "antd";
import { CloseOutlined, TagFilled } from "@ant-design/icons";
import ReactPlayer from "react-player";
import { FaEdit, FaUser } from "react-icons/fa";
import { TimeCorrection } from "../../../components/GlobalFunctions";
const { Option } = Select;
const { TextArea } = Input;
export default function SpeakerImages() {
  const { speaker, setspeaker, user } = useContext(SearchContext);
  const playerRef = React.useRef();
  const [callApi, setCallApi] = useState(0);
  const [viewThumbnails, setViewThumbnails] = useState();
  const [viewFile, setViewFile] = useState();
  const [loading, setLoading] = useState();
  const [activeName, setActiveName] = useState("");
  const [callTranscript, setCallTranscript] = useState(0);
  const [SegmentNames, setSegmentNames] = useState();
  const [segmentList, setSegmentList] = useState();
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [bulkName, setBulkName] = useState();
  const [ooneName, setOneName] = useState();
  const [searchLabel, setSearchLabel] = useState("");
  const [bulkLoader, setBulkLoader] = useState();
  const [oneLoader, setOneLoader] = useState();

  useEffect(() => {
    const vt = speaker.activeVideo;
    adjustTranscript(vt.transcript);
  }, [callTranscript]);

  const adjustTranscript = (transcript) => {
    const speakerLabels = transcript.speaker_labels
      ? transcript.speaker_labels
      : {};
    const items = transcript.items ? transcript.items : [];
    const segments = speakerLabels.segments ? speakerLabels.segments : [];

    let listNames = [];
    for (let i = 0; i < segments.length; i++) {
      const segItem = segments[i].items ? segments[i].items : [];
      for (let j = 0; j < segItem.length; j++) {
        const iRow = segItem[j];
        const filtered = items.filter(
          (tm) => tm.start_time === iRow.start_time
        );
        if (filtered.length) {
          iRow.alternatives = filtered[0].alternatives;
          segItem[j] = iRow;
        }
      }
      listNames = listNames.filter((f) => f !== segItem[0].speaker_label);

      listNames.push(segItem[0].speaker_label);
      segments[i].items = segItem;
    }
    setSegmentList(segments);
    setSegmentNames(listNames);
  };

  const videoTimeAdjust = (time) => {
    time = time.toString();
    time = parseInt(time);
    playerRef.current.seekTo(time, "seconds");
  };

  const updateFiled = (obj, id, type) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/video_segment/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          if (type === "indexed") {
            updateTimeSheet(speaker.activeVideo);
          }
        } else {
          message.error("something went wrong");
        }
      });
  };

  const updateTimeSheet = (video) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/timesheet?model_id=${user.user_id}&sheet_duration=progress&sheet_type=speaker&video_id=${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data &&
          data.isSuccess &&
          data.timesheetList &&
          data.timesheetList.length
        ) {
          updateTID(data.timesheetList[0]._id);
        }
      });
  };

  const updateTID = (id) => {
    const obj = {
      sheet_duration: "indexed",
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://int.display.video/api/timesheet/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          message.success(`Whole Video Complete `);
          const all = speaker[speaker.activeTab].map((r) =>
            r._id === speaker.activeVideo._id
              ? {
                  ...speaker.activeVideo,
                  speaker_ai: "indexed",
                }
              : r
          );

          setLoading(false);
          setspeaker({
            ...speaker,
            activeVideo: undefined,
            [speaker.activeTab]: all,
            images: undefined,
            pageNo: 1,
            pageNo4: 1,
          });

          message.success(`Whole Video Complete `);
        } else {
          setLoading(false);
          message.error("something went wrong");
        }
      });
  };

  const updatespeakerBulk = (oldName, newName) => {
    setBulkName();
    setspeaker({
      ...speaker,
      selectedTrns: undefined,
    });
    const video = speaker.activeVideo;

    const obj = {
      old_value: oldName,
      new_value: newName,
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/video_segment/speaker_label/${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          liveUpdateBulk(oldName, newName);
        } else {
          message.error("something went wrong");
        }
        // console.log(data, "video_segment , update");
      });
  };

  const liveUpdateBulk = (oldName, newName) => {
    const video = speaker.activeVideo;
    const transcript = video.transcript;
    if (transcript.speaker_labels) {
      const speaker = transcript.speaker_labels;
      for (let j = 0; j < speaker.segments.length; j++) {
        if (speaker.segments[j].speaker_label === oldName) {
          speaker.segments[j].speaker_label = newName;
        }
      }
      transcript.speaker_labels = speaker;
    }

    video.transcript = transcript;
    const all = speaker[speaker.activeTab].map((v) =>
      v._id === video._id ? video : v
    );
    const index = SegmentNames.indexOf(oldName); // 👉️  0

    SegmentNames.splice(index, 1, newName);
    setSegmentNames(SegmentNames);
    setspeaker({ ...speaker, [speaker.activeTab]: all });
    setBulkLoader();
  };

  const updateSingle = (oldName, newName, time, lbl) => {
    console.log(lbl);

    setOneName();

    const video = speaker.activeVideo;

    const obj = {
      old_value: {
        start_time: lbl.start_time,
        end_time: lbl.end_time,
        speaker_label: lbl.speaker_label,
      },
      new_value: {
        start_time: lbl.start_time,
        end_time: lbl.end_time,
        speaker_label: speaker.selectedTrns,
      },
    };
    setspeaker({
      ...speaker,
      selectedTrns: undefined,
    });
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://int.display.video/api/video_segment/speaker_label_segment/${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          changeSingleName(oldName, newName, time, lbl);
        } else {
          message.error("something went wrong");
        }
        // console.log(data, "video_segment , update");
      });
  };

  const changeSingleName = (oldName, newName, time, lbl) => {
    const video = speaker.activeVideo;
    const transcript = video.transcript;
    const speaker_labels = transcript.speaker_labels;
    const segments = speaker_labels.segments;
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].start_time === time) {
        segments[i].speaker_label = newName;
      }
    }
    speaker_labels.segments = segments;
    transcript.speaker_labels = speaker_labels;
    video.transcript = transcript;
    const all = speaker[speaker.activeTab].map((v) =>
      v._id === video._id ? video : v
    );
    setspeaker({ ...speaker, [speaker.activeTab]: all, activeVideo: video });
    setOneLoader();
  };

  return (
    <div>
      <div>
        <Row>
          <Col md={{ span: 24 }}>
            <div className="sel-s-c">
              <h5>
                {speaker.activeVideo.video_name}{" "}
                <CloseOutlined
                  className="eye-icn-c close-icn"
                  onClick={() =>
                    setspeaker({
                      ...speaker,
                      activeVideo: undefined,
                      images: undefined,
                      pageNo: 1,
                      pageNo4: 1,
                    })
                  }
                />
              </h5>
              {loading ? (
                <Spin />
              ) : (
                <Popconfirm
                  title={
                    <div>
                      <h6>Complete all Images?</h6>
                      <h6>Complete all pages?</h6>
                    </div>
                  }
                  onConfirm={() => {
                    setLoading(true);
                    updateFiled(
                      { speaker_ai: "indexed" },
                      speaker.activeVideo._id,
                      "indexed"
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">Press Button to Complete Video</Button>
                </Popconfirm>
              )}
            </div>

            <div>
              {speaker.images && speaker.images.length === 0 ? (
                <h1>No Images Can Press Done</h1>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 14 }} lg={{ span: 14 }}>
            <div className="vt-vid-c">
              <ReactPlayer
                onProgress={(e) => {
                  setPlayedSeconds(parseInt(e.playedSeconds));
                }}
                controls
                url={speaker.activeVideo.file_url}
                ref={playerRef}
                playing
              />
              <div className="sav-ch-btn">
                <a href={speaker.activeVideo.file_url} download>
                  Dwonload
                </a>
              </div>
            </div>
            <div>
              <div className="label-h-in-c">
                <h5>Labels</h5>
                <Input
                  placeholder="Search Label"
                  onChange={(e) => setSearchLabel(e.target.value)}
                />
              </div>
              <div className="con-vd-c">
                {speaker.activeVideo.concept_index &&
                  speaker.activeVideo.concept_index
                    .filter((f) =>
                      f.concept_name
                        .toLowerCase()
                        .includes(searchLabel.toLowerCase())
                    )
                    .map((vt, vti) => {
                      return (
                        <div key={vti} className="select-cn-cc">
                          <div>
                            {vt.saved_images && vt.saved_images.length ? (
                              <img
                                alt=""
                                className="img-thum-con"
                                src={vt.saved_images[0]}
                                onClick={() => setViewThumbnails(vt)}
                              />
                            ) : null}
                          </div>
                          <div
                            key={vti}
                            onClick={() =>
                              setspeaker({
                                ...speaker,
                                selectedTrns: vt.concept_name,
                              })
                            }
                            className={`name-bx-field-t3 ${
                              speaker.selectedTrns === vt.concept_name
                                ? " active-icn-f"
                                : ""
                            }`}
                          >
                            {vt.concept_name}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </Col>
          <Col md={{ span: 9, offset: 1 }} lg={{ span: 9, offset: 1 }}>
            <div className="lbls-name-tg">
              {SegmentNames &&
                SegmentNames.map((sl, sli) => {
                  return (
                    <div key={sli}>
                      {bulkLoader === sl ? (
                        <Spin />
                      ) : (
                        <>
                          <Tag
                            color={activeName === sl ? "#161d84" : "default"}
                            onClick={() => setActiveName(sl)}
                          >
                            {sl.replace("spk_", "Speaker ")}
                          </Tag>
                          <FaEdit
                            onClick={() => {
                              setspeaker({
                                ...speaker,
                                selectedTrns: undefined,
                              });
                              setBulkName(sl);
                            }}
                            className="editbx-cas"
                          />
                        </>
                      )}
                    </div>
                  );
                })}
              {activeName !== "" ? (
                <Tag onClick={() => setActiveName("")}>All</Tag>
              ) : null}
            </div>
            <div className="trn-bsmx">
              {segmentList &&
                segmentList
                  .filter((f) => f.speaker_label.includes(activeName))
                  .map((sl, sli) => {
                    return (
                      <Card className="crd-bx-trns" key={sli}>
                        <div className="bx-nm-crd">
                          {oneLoader === sl.start_time ? (
                            <Spin />
                          ) : (
                            <h5>
                              {sl.speaker_label.replace("spk_", "Speaker ")}
                              <FaEdit
                                onClick={() => {
                                  setspeaker({
                                    ...speaker,
                                    selectedTrns: undefined,
                                  });
                                  setOneName(sl);
                                }}
                                className="edit-fa-sp"
                              />
                            </h5>
                          )}
                          <span>
                            <i>From:</i>
                            {TimeCorrection(parseInt(Number(sl.start_time)))}
                          </span>
                        </div>
                        <p>
                          {sl.items.map((t, ti) => {
                            return (
                              <span
                                className={`txt-spn-slct ${
                                  playedSeconds ===
                                  parseInt(Number(t.start_time))
                                    ? " active-cls-p"
                                    : ""
                                } ${t.search ? " serch-highlight" : ""}`}
                                onClick={() => videoTimeAdjust(t.start_time)}
                                key={ti}
                              >
                                {t.alternatives[0].content}{" "}
                              </span>
                            );
                          })}
                        </p>
                        {/* <div className="trn-card-cim">
                                  <img src={Icons.scissors} alt="" />
                                  <img src={Icons.highlighter} alt="" />
                                  <img src={Icons.bubbleChat} alt="" />
                                </div> */}
                      </Card>
                    );
                  })}
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        className="no-h-bo-b-modal "
        visible={viewThumbnails !== undefined}
        onCancel={() => setViewThumbnails()}
        width={700}
      >
        {viewThumbnails ? (
          <div className="th-imgs-thum">
            <h5>{viewThumbnails.concept_name} images</h5>
            {viewThumbnails.saved_images &&
              viewThumbnails.saved_images.map((th, thi) => {
                return <img key={thi} src={th} alt="" />;
              })}
          </div>
        ) : null}
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={viewFile !== undefined}
        onCancel={() => setViewFile()}
        width={700}
      >
        <div className="view-f-img">
          <img src={viewFile} alt="" />;
        </div>
      </Modal>

      <Modal
        className="no-h-bo-b-modal "
        visible={bulkName !== undefined}
        onCancel={() => setBulkName()}
        width={700}
      >
        <div className="label-h-in-c">
          <h5>
            Replace <Tag>{bulkName}</Tag> with <Tag>{speaker.selectedTrns}</Tag>
          </h5>
          <Input
            placeholder="Search Label"
            onChange={(e) => setSearchLabel(e.target.value)}
          />
        </div>
        <div className="con-vd-c">
          {speaker.activeVideo.concept_index &&
            speaker.activeVideo.concept_index
              .filter((f) =>
                f.concept_name.toLowerCase().includes(searchLabel.toLowerCase())
              )
              .map((vt, vti) => {
                return (
                  <div key={vti} className="select-cn-cc">
                    <div>
                      {vt.saved_images && vt.saved_images.length ? (
                        <img
                          alt=""
                          className="img-thum-con"
                          src={vt.saved_images[0]}
                          onClick={() => setViewThumbnails(vt)}
                        />
                      ) : null}
                    </div>
                    <div
                      key={vti}
                      onClick={() =>
                        setspeaker({
                          ...speaker,
                          selectedTrns: vt.concept_name,
                        })
                      }
                      className={`name-bx-field-t3 ${
                        speaker.selectedTrns === vt.concept_name
                          ? " active-icn-f"
                          : ""
                      }`}
                    >
                      {vt.concept_name}
                    </div>
                  </div>
                );
              })}
          <div className="select-cn-cc unknown-dvc">
            <div className="img-sc-w">
              <FaUser />
            </div>
            <div
              onClick={() =>
                setspeaker({
                  ...speaker,
                  selectedTrns: "Unknown",
                })
              }
              className={`name-bx-field-t3 ${
                speaker.selectedTrns === "Unknown" ? " active-icn-f" : ""
              }`}
            >
              Unknown
            </div>
          </div>
        </div>
        <Button
          className="btnc-s"
          type="primary"
          onClick={() => {
            updatespeakerBulk(bulkName, speaker.selectedTrns);
            setBulkLoader(bulkName);
          }}
        >
          Save and Replace
        </Button>
      </Modal>
      <Modal
        className="no-h-bo-b-modal "
        visible={ooneName ? true : false}
        onCancel={() => setOneName()}
        width={700}
      >
        {ooneName ? (
          <div>
            <div className="label-h-in-c">
              <h6>
                Replace <Tag>{ooneName.speaker_label}</Tag> with{" "}
                <Tag>{speaker.selectedTrns}</Tag> at{" "}
                {TimeCorrection(parseInt(Number(ooneName.start_time)))}
              </h6>
              <Input
                placeholder="Search Label"
                onChange={(e) => setSearchLabel(e.target.value)}
              />
            </div>
            <div className="con-vd-c">
              {speaker.activeVideo.concept_index &&
                speaker.activeVideo.concept_index
                  .filter((f) =>
                    f.concept_name
                      .toLowerCase()
                      .includes(searchLabel.toLowerCase())
                  )
                  .map((vt, vti) => {
                    return (
                      <div key={vti} className="select-cn-cc">
                        <div>
                          {vt.saved_images && vt.saved_images.length ? (
                            <img
                              alt=""
                              className="img-thum-con"
                              src={vt.saved_images[0]}
                              onClick={() => setViewThumbnails(vt)}
                            />
                          ) : null}
                        </div>
                        <div
                          key={vti}
                          onClick={() =>
                            setspeaker({
                              ...speaker,
                              selectedTrns: vt.concept_name,
                            })
                          }
                          className={`name-bx-field-t3 ${
                            speaker.selectedTrns === vt.concept_name
                              ? " active-icn-f"
                              : ""
                          }`}
                        >
                          {vt.concept_name}
                        </div>
                      </div>
                    );
                  })}
              <div className="select-cn-cc unknown-dvc">
                <div className="img-sc-w">
                  <FaUser />
                </div>
                <div
                  onClick={() =>
                    setspeaker({
                      ...speaker,
                      selectedTrns: "unknown",
                    })
                  }
                  className={`name-bx-field-t3 ${
                    speaker.selectedTrns === "unknown" ? " active-icn-f" : ""
                  }`}
                >
                  Unknown
                </div>
              </div>
            </div>
            <Button
              className="btnc-s"
              type="primary"
              onClick={() => {
                updateSingle(
                  ooneName.speaker_label,
                  speaker.selectedTrns,
                  ooneName.start_time,
                  ooneName
                );
                setOneLoader(ooneName.start_time);
              }}
            >
              Save and Replace
            </Button>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
