import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import Div from "./elements/Div";
import "./schema.css";
import AllElements from "./AllElements";
export default function Schema() {
  let [code, setCode] = useState();
  let [code2, setCode2] = useState();
  let [value, setValue] = useState();
  let [test, setTest] = useState();

  useEffect(() => {
    if (code) {
      const parsedData = removeQuotesFromKeys(JSON.parse(code));

      setCode2(parsedData);
    }
  }, [code]);
  function removeQuotesFromKeys(json) {
    return JSON.parse(json);
  }

  return (
    <div className="row my-contain-editor">
      <div className="col-6">
        <div>
          <b>AI Code</b>
          <Input.TextArea
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        </div>
        <div>
          <b>JSON Code</b>
          <Input.TextArea
            value={JSON.stringify(code2, null, 2)}
            onChange={(e) => {
              setCode2(JSON.parse(e.target.value));
            }}
            autoSize={{ minRows: 3, maxRows: 20 }}
          />
          <div className="flex-end">
            <Button
              onClick={() => {
                setValue(code2);
              }}
              type="primary"
            >
              Build
            </Button>
          </div>
        </div>
      </div>
      <div className="col-6">
        |{" "}
        {test
          ? test.map((v, vi) => {
              return (
                <div
                  className={v.className ? v.className : ""}
                  id={v.id}
                  key={vi}
                >
                  <AllElements props={v} />
                </div>
              );
            })
          : null}
        {value
          ? value.map((v, vi) => {
              return (
                <div
                  className={v.className ? v.className : ""}
                  id={v.id}
                  key={vi}
                >
                  <AllElements props={v} />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
