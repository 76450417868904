import React from "react";
import { Typography } from "antd";
const { Paragraph } = Typography;
export default function ParagraphElm({ props }) {
  return (
    <>
      {props.keyframes ? <style>{props.keyframes}</style> : null}
      <Paragraph {...props}>{props.text}</Paragraph>
    </>
  );
}
