import React from "react";
import "./style.css"
export default function BarChartLoader() {
  return (
    <div className="bc-loading">
      <div className="bc-loading-1"></div>
      <div className="bc-loading-2"></div>
      <div className="bc-loading-3"></div>
      <div className="bc-loading-4"></div>
    </div>
  );
}
