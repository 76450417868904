import React, { useState, useEffect } from "react";
import moment from "moment";

function CountdownTimer({ date }) {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // Calculate time remaining
  function calculateTimeRemaining() {
    const targetDate = moment(date);
    const now = moment();
    const duration = moment.duration(targetDate.diff(now));
    return duration;
  }

  useEffect(() => {
    const timerID = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(timerID);
  }, [date]);

  return (
    <div
      style={{
        color:
          (timeRemaining.seconds() && timeRemaining.seconds() < 0) ||
          (timeRemaining.minutes() && timeRemaining.minutes() < 0)
            ? "red"
            : "green",
      }}
      className="time-counter-r"
    >
      {timeRemaining.days()}d {timeRemaining.hours()}h {timeRemaining.minutes()}
      m {timeRemaining.seconds()}s
    </div>
  );
}

export default CountdownTimer;
