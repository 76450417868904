import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Collapse,
  Slider,
  notification,
  AutoComplete,
  Popconfirm,
  Button,
} from "antd";
import React, { useContext } from "react";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";

import ReactPlayer from "react-player";

const { Option } = Select;
export default function CaptionsSearch({
  captionS,
  setCaptionS,
  setCaptionAct,
  getSearch,
  sceneData
}) {
  const [isChange, setIsChange] = useState(false);

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = captionS.map((m) => (m._id === row._id ? row : m));
    setCaptionS(all);
  };

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/folder/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isPatched) {
          let all = captionS.map((m) =>
            m._id === response.patchedData._id ? response.patchedData : m
          );
          setCaptionS(all);
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };

  const columns = [
    {
      title: "Video",
      dataIndex: "segments",
      key: "segments",
      width: 400,
      render: (text, row) =>
        text && text.length ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => setCaptionAct(row)}
              size="small"
              type="primary"
            >
              <FaPlay />
            </Button>
          </div>
        ) : null,
    },
    {
      title: "Caption",
      dataIndex: "caption",
      key: "caption",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="caption"
          onChange={(e) => {
            changeValue(row, "caption", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ caption: text }, row._id);
            }
          }}
          autoSize={{ minRows: 2, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="actions"
          onChange={(e) => {
            changeValue(row, "action", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ action: text }, row._id);
            }
          }}
          autoSize={{ minRows: 1, maxRows: 8 }}
          className="hidden-textarea"
          style={{ minWidth: "150px" }}
        />
      ),
    },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      width: 30,
      render: (text, row) => (
        <div className="green-btn-box-cs">
          <BButton
            onClick={(value) => {
              changeValue(row, "standard", "layer_1");
              updateValue({ standard: "layer_1" }, row._id);
            }}
            type={text === "layer_1" ? "primary" : "default"}
            style={{ marginBottom: "10px" }}
            size="small"
          >
            layer 1
          </BButton>

          <BButton
            onClick={(value) => {
              changeValue(row, "standard", "pending");
              updateValue({ standard: "pending" }, row._id);
            }}
            type={text === "pending" ? "primary" : "default"}
            color="danger"
            size="small"
          >
            Not layer 1
          </BButton>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "02",
      key: "02",
      width: 30,
      render: (text, row) => (
        <Popconfirm
          title="Delete the task"
          description="Are you sure to delete this task?"
          onConfirm={() => deleteVideo(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Tag className="hover" color="rgb(185, 40, 40)">
            D
          </Tag>
        </Popconfirm>
      ),
    },
  ];

  const deleteVideo = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`https://embed.axv.ai/api/folder/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isDeleted) {
          let all = captionS.filter((f) => f._id !== id);
          setCaptionS(all);
          message.success("Deleted");
        } else {
          notification.error({
            message: "Data Not Deleted  Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };
  return (
    <div style={{ width: "100%" }}>
      {captionS ? (
        <div style={{ width: "100%", overflowX: "auto" }}>
          <div className="search-box-scene-data">
            <Input
              placeholder="Caption Search"
              onChange={(e) => {
                setCaptionS([]);
                if (e.target.value.trim().length) {
                  
                  getSearch({ captions: e.target.value });
                }else{
                  getSearch(sceneData.scene_data[0]);
                }
                
              }}
            />
          </div>
          <Table
            // pagination={{
            //   pageSize: 15,
            //   current: pageNo,
            //   total: totalPages * 15,
            //   onChange: (v) => {
            //     setfolderVideo();
            //     setPageNo(v);
            //   },
            //   size: "small",
            //   showSizeChanger: false,
            // }}
            columns={columns}
            dataSource={captionS}
          />
        </div>
      ) : null}
    </div>
  );
}

