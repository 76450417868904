import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Row,
  Col,
  Pagination,
  Select,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import SearchContext from "../../Context/Search/SearchContext";
const { Option } = Select;

export default function ImagesVerify() {
  const { user } = useContext(SearchContext);

  const [Images, setImages] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [cStatus, setCstatus] = useState("verified");
  const [mediaType, setmediaType] = useState("object");
  const [callApi, setCallApi] = useState(1);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);

  useEffect(() => {
    if (Images === undefined) {
      setLoadeing(true);
      getImages();
    }
  }, [pageNo, cStatus, callApi]);

  const getImages = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/image/internal?page=${pageNo}&limit=15&sort_by=${-1}&status=${cStatus}&media_type=${mediaType}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setImages(data.imageList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const deleteInvite = (id) => {
    const filtereted = Images.filter((t) => t._id !== id);
    setImages(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };
  // onClick={() =>
  //   saveChanges({ description: Description }, DescriptionId, true)
  // }
  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/image/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };
  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = Images.map((m) => (m._id === row._id ? row : m));
    setImages(allRow);
  };
  return (
    <div>
      <div className="connap-filter-c space-between">
        <Select
          placeholder="Status"
          onChange={(v) => {
            setCstatus(v);
            setImages();
            setPageNo(1);
          }}
          value={cStatus}
        >
          <Option value="complete">complete</Option>
          <Option value="verified">verified</Option>
          <Option value="failed">failed</Option>
        </Select>
        <Select
          placeholder="Media Type"
          style={{ width: 150 }} // Set the desired width in pixels
          value={mediaType}
          onChange={(value) => {
            setmediaType(value);
            setImages();
            setPageNo(1);
            setCallApi(callApi + 1);
          }}
        >
          <Option value={"object"}>Character</Option>
          <Option value={"environment"}>Environment</Option>
        </Select>
      </div>
      {loadeing ? <Spin /> : null}

      <Row>
        {Images &&
          Images.map((im, imi) => {
            return (
              <Col key={imi} lg={{ span: 6 }} md={{ span: 8 }}>
                <div className="img-bsx-rev">
                  <span>{im._id}</span>
                  <img src={im.file_url} alt="" />
                  <Input.TextArea
                    onChange={(e) => {
                      changeField("context", e.target.value, im);
                      setIsChange(true);
                    }}
                    onBlur={() => saveChanges({ context: im.context }, im._id)}
                    value={im.context}
                    autoSize={{ minRows: 2, maxRows: 4 }}
                  />
                  <Input.TextArea
                    onChange={(e) => {
                      changeField("predict", e.target.value, im);
                      setIsChange(true);
                    }}
                    onBlur={() => saveChanges({ predict: im.predict }, im._id)}
                    value={im.predict}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                  />

                  <Select
                    placeholder="Status"
                    onChange={(v) => {
                      changeField("status", v, im);
                      saveChanges({ status: v }, im._id, true);
                    }}
                    value={im.status}
                  >
                    <Option value="complete">complete</Option>
                    <Option value="verified">verified</Option>
                    <Option value="failed">failed</Option>
                  </Select>
                  {/* <div>
                    {im.status !== "verified" ? (
                      <BButton
                        onClick={() => {
                          changeField("status", "verified", im);
                          saveChanges({ status: "verified" }, im._id, true);
                        }}
                        type="primary"
                        size="small"
                      >
                        Verify
                      </BButton>
                    ) : null}
                  </div> */}
                </div>
              </Col>
            );
          })}
      </Row>
      <div className="page-vid-c">
        {totalPages ? (
          <Pagination
            pageSize={15}
            current={pageNo}
            size="small"
            total={totalPages * 15}
            onChange={(v) => {
              setImages();
              setPageNo(v);
            }}
          />
        ) : null}
      </div>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Images</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
