import { AutoComplete, Button, Form, Input, Select } from "antd";
import React, { useContext, useState } from "react";
import LoaderDots from "../../../components/LoaderDots";
import SearchContext from "../../../Context/Search/SearchContext";
import playIconblue from "../../../images/play-buttonb.png";
import MambaFilterTable from "./MambaFilterTable";
const { Option } = Select;
export default function MambaFilter() {
  const { mambaFilter, setMambaFilter } = useContext(SearchContext);
  const [search, setSearch] = useState("");

  const [loader, setLoader] = useState(false);
  const [resultNotFound, setResultNotFound] = useState(false);

  const getMambaFilter = (txt) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(
      `https://api.fussy.ai/internal/mamba/filter?page=${mambaFilter.page}&limit=${mambaFilter.limit}&link=${mambaFilter.link}&channel=${mambaFilter.channel}&date=${mambaFilter.date}&pipeline=${mambaFilter.pipeline}&category=${mambaFilter.category}&platform=${mambaFilter.platform}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);

        console.log(data, "mamba/filter");
        if (data && data.isSuccess) {
          setMambaFilter({
            ...mambaFilter,
            data: data.mambaList,
            notFound: false,
          });
        } else {
          setMambaFilter({ ...mambaFilter, data: undefined, notFound: true });
        }
      });
  };
  return (
    <div>
      <div className="filter-bx-main">
        <Input
          value={mambaFilter.link}
          onChange={(e) => setMambaFilter({ ...mambaFilter, link: e.target.value })}
          placeholder="Link"
        />
        <Input
          value={mambaFilter.channel}
          onChange={(e) => setMambaFilter({ ...mambaFilter, channel: e.target.value })}
          placeholder="Channel"
        />

        <Input
          value={mambaFilter.category}
          onChange={(e) => setMambaFilter({ ...mambaFilter, category: e.target.value })}
          placeholder="category"
        />
        <Input
          value={mambaFilter.platform}
          onChange={(e) => setMambaFilter({ ...mambaFilter, platform: e.target.value })}
          placeholder="platform"
        />
        <Select
          placeholder="pipeline"
          value={mambaFilter.pipeline}
          onChange={(val) => setMambaFilter({ ...mambaFilter, pipeline: val })}
        >
          <Option disabled value="">Pipeline</Option>
          <Option value="quene">Quene</Option>
          <Option value="official_timestamp">Official Timestamp</Option>
          <Option value="removal">Removal Q</Option>
          <Option value="processing">Processing</Option>
          <Option value="pending">Pending</Option>
          <Option value="complete">Complete</Option>
          <Option value="review">Review </Option>
          <Option value="indexed"> Indexed </Option>
        </Select>

        {loader ? (
          <span className={`loader-input-c `}>
            <LoaderDots />
          </span>
        ) : (
          <Button
            onClick={() => {
              getMambaFilter(search);
              setMambaFilter({ ...mambaFilter, data: undefined });
              setLoader(true);
            }}
            type="primary"
          >
            Get
          </Button>
        )}
      </div>
      {mambaFilter.data ? <MambaFilterTable /> : null}
    </div>
  );
}
