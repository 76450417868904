import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
} from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import TagsGroup from "../../components/TagsGroup";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import GreenTick from "../../components/GreenTick";

export default function Topic() {
  const [callAPI, setCallAPI] = useState(1);
  const [topic, settopic] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [dAdd, setdAdd] = useState();
  const [guide, setGuide] = useState();
  const [guideId, setGuideId] = useState();

  useEffect(() => {
    if (topic === undefined) {
      setLoadeing(true);
      gettopic();
    }
  }, [pageNo]);

  const gettopic = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/topic?page=${pageNo}&limit=15&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          settopic(data.topicList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };
  const Savetopic = (row) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/topic`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          const list = [...topic];
          list.unshift(data.data);
          settopic(list);
        }
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/topic/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/topic/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const filtereted = topic.filter((t) => t._id !== id);
          settopic(filtereted);
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = topic.map((m) => (m._id === row._id ? row : m));
    settopic(allRow);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="category"
          onChange={(e) => {
            changeField("category", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ category: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Personalize Id",
      dataIndex: "personalize_id",
      key: "personalize_id",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Personalize Id"
          onChange={(e) => {
            changeField("personalize_id", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ personalize_id: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Topic",
      dataIndex: "topic",
      key: "topic",

      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="topic"
          autoSize={{
            minRows: 1,
            maxRows: 3,
          }}
          onChange={(e) => {
            changeField("topic", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ topic: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "related_words",
      dataIndex: "related_words",
      key: "related_words",

      render: (text, row) => (
        <TagsGroup
          tags={text}
          onTagChange={(v) => {
            changeField("related_words", v, row);
            saveChanges({ related_words: v }, row._id, true);
          }}
        />
      ),
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            Delete
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div>
      <Button
        size="sm"
        onClick={() =>
          setdAdd({
            category: "",
            topic: "",
            personalize_id: "",
            related_words: [],
          })
        }
      >
        <BsPlus />
        Add
      </Button>
      {loadeing ? <Spin /> : null}
      {topic ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              settopic();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={topic}
        />
      ) : null}
      <Modal
        visible={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Category:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="Category"
                      value={dAdd.category}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, category: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Personalize id:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="Personalize id"
                      value={dAdd.personalize_id}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, personalize_id: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}>Topic:</b>
                  </td>
                  <td>
                    <Input.TextArea
                      className="bx-fld-10t"
                      placeholder="topic"
                      value={dAdd.topic}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, topic: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}>Related Words:</b>
                  </td>
                  <td>
                    <TagsGroup
                      tags={dAdd.related_words}
                      onTagChange={(v) => {
                        setdAdd({ ...dAdd, related_words: v });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-end">
              <Button
                onClick={() => {
                  Savetopic(dAdd);
                  setdAdd();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={400}
        visible={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Delete Invitation</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteInvite(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
