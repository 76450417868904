import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { TimeCorrection } from "../../components/GlobalFunctions";

export default function DisplayTranscript({ video }) {
  const [SegmentNames, setSegmentNames] = useState();
  const [segmentList, setSegmentList] = useState();

  useEffect(() => {
    if (SegmentNames === undefined) {
      getTranscript(window.location.pathname.slice(7));
    }
  }, []);

  const getTranscript = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    fetch(
      `https://int.display.video/api/search_video/transcription-full/${video._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          adjustTranscript(data.data.transcript);
        }
      });
  };

  const adjustTranscript = (transcript) => {
    const speakerLabels = transcript.speaker_labels
      ? transcript.speaker_labels
      : {};
    const items = transcript.items ? transcript.items : [];
    const segments = speakerLabels.segments ? speakerLabels.segments : [];

    let listNames = [];
    for (let i = 0; i < segments.length; i++) {
      const segItem = segments[i].items ? segments[i].items : [];
      for (let j = 0; j < segItem.length; j++) {
        const iRow = segItem[j];
        const filtered = items.filter(
          (tm) => tm.start_time === iRow.start_time
        );
        if (filtered.length) {
          iRow.alternatives = filtered[0].alternatives;
          segItem[j] = iRow;
        }
      }
      listNames = listNames.filter((f) => f !== segItem[0].speaker_label);

      listNames.push(segItem[0].speaker_label);
      segments[i].items = segItem;
    }
    searchTranscript(segments);
    setSegmentNames(listNames);
  };

  const searchTranscript = (segments) => {
    const searched = [];
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].items && segments[i].items && segments[i].items.length) {
        const items = segments[i].items;
        let find = false;
        for (let j = 0; j < items.length; j++) {
          if (items[j].alternatives[0].content.toLowerCase().includes("")) {
            find = true;
            if ("".length) {
              items[j].search = true;
            }
          }
        }
        segments[i].items = items;
        if (find) {
          searched.push(segments[i]);
        }
      }
    }

    // setSegmentList(searched);
    adjust1SecGap(searched);
  };
  const adjust1SecGap = (searched) => {
    let data = searched;
    for (let i = 1; i < data.length; i++) {
      const diff = Number(data[i].start_time) - Number(data[i - 1].start_time);
      if (data[i - 1].speaker_label === data[i].speaker_label && diff < 2) {
        const item1 = data[i - 1].items;
        const item2 = data[i].items;
        const both = item1.concat(item2);
        data[i].items = both;
        data[i].start_time = data[i - 1].start_time;
        const all = data.filter((f) => f.end_time !== data[i - 1].end_time);

        data = all;
      }
    }
    setSegmentList(data);
  };
  return (
    <div>
      <div className="trn-bsmx">
        <h5>Helping Text</h5>
        {segmentList &&
          segmentList.map((sl, sli) => {
            return (
              <Card className="crd-bx-trns" key={sli}>
                <div className="bx-nm-crd">
                  <h5>{sl.speaker_label.replace("spk_", "Speaker ")}</h5>
                  <span>
                    <i>From:</i>
                    {TimeCorrection(parseInt(Number(sl.start_time)))}
                  </span>
                </div>
                <p>
                  {sl.items.map((t, ti) => {
                    return (
                      <span className={`txt-spn-slct `} key={ti}>
                        {t.alternatives[0].content}{" "}
                      </span>
                    );
                  })}
                </p>
              </Card>
            );
          })}
      </div>
    </div>
  );
}
