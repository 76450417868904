import { DatePicker, Input, message, Modal } from "antd";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import SearchContext from "../../../Context/Search/SearchContext";

export default function AddPlot() {
  const { allEmp, user } = useContext(SearchContext);

  const [dAdd, setdAdd] = useState();

  const Savegeneral = (row) => {
    row.user_id = "6368c6013e74e9fa6ba788b6";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/video_segment/file_url`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          message.success("Row added in first page");
          // const list = [...general];
          // list.unshift(data.data);
          // list.pop();
          // setgeneral(list);
        } else {
          message.error(data.msgError);
        }
      });
  };

  return (
    <div>
      {" "}
      <Button
        size="sm"
        onClick={() =>
          setdAdd({
            tags: ["media", "pending"],
            frame_status: "indexed",
            video_name: "",
            file_url: "",
            description: "",
            media: "",
            date: "",
          })
        }
      >
        <BsPlus />
        Add
      </Button>
      <Modal
        visible={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-f-no-h brand-modal"
      >
        <h5 className="create-btn-h">Add </h5>
        {dAdd ? (
          <div className="bx-form-brand no-radius-input">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Video Name:</b>
                  </td>
                  <td>
                    <Input.TextArea
                      autoSize={{ maxRows: 2, minRows: 1 }}
                      className="bx-fld-10t"
                      placeholder="video name"
                      value={dAdd.video_name}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, video_name: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> File Url:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="file url"
                      value={dAdd.file_url}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, file_url: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Description:</b>
                  </td>
                  <td>
                    <Input.TextArea
                      autoSize={{ maxRows: 3, minRows: 1 }}
                      className="bx-fld-10t"
                      placeholder="description"
                      value={dAdd.description}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, description: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b style={{ marginRight: "10px" }}> Media:</b>
                  </td>
                  <td>
                    <Input
                      className="bx-fld-10t"
                      placeholder="media"
                      value={dAdd.media}
                      onChange={(e) =>
                        setdAdd({ ...dAdd, media: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6>Date</h6>
                  </td>
                  <td>
                    <DatePicker
                      value={dAdd.date}
                      onChange={(e) => setdAdd({ ...dAdd, date: e })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-end">
              <Button
                onClick={() => {
                  Savegeneral(dAdd);
                  setdAdd();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
