import React from "react";

import AllElements from "../AllElements";

export default function Tr({ props }) {
  return (
    <tr {...props}>
      <AllElements props={props} />
    </tr>
  );
}
