import { Alert, Button, Col, Input, message, Row, Spin } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
// import CanvasDraw from "react-canvas-draw";
let myInterval;
export default function DrawImages() {
  const canvasRef = useRef(null);
  const [color, setColor] = useState("white");
  const [drawing, setDrawing] = useState(false);

  const { images5, setImages5, user } = useContext(SearchContext);
  const [loadeing, setLoadeing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [callAPI, setCallAPI] = useState(1);
  const [total, setTotal] = useState(1);
  const [isGet, setIsGet] = useState(0);
  const [sheet, setSHeet] = useState({});
  const [imgD, setImgD] = useState("");

  useEffect(() => {
    if (isGet !== 0) {
      const canvas = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext("2d");

        context.lineWidth = 60;
        context.lineCap = "round";
        context.strokeStyle = color;
        context.fillStyle = "black"; // Set the canvas background color to black
        context.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with black
      }
    }
  }, [color, isGet]);

  const startDrawing = (event) => {
    setDrawing(true);
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const { offsetX, offsetY } = event.nativeEvent;
    context.beginPath();
    context.moveTo(offsetX, offsetY);
  };
  const draw = (event) => {
    if (!drawing) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const { offsetX, offsetY } = event.nativeEvent;
    context.lineTo(offsetX, offsetY);
    context.stroke();
  };
  const endDrawing = (v) => {
    setDrawing(false);
    const canvasss = canvasRef.current;
    const dataUrl = canvasss.toDataURL("image/png");
    setImgD(dataUrl);
  };
  const handleColorChange = (newColor) => {
    setColor(newColor);
  };

  useEffect(() => {
    if (images5.data === undefined) {
      setLoadeing(true);
      getImages();
    }
  }, [callAPI]);

  useEffect(() => {
    if (total === 0) {
      myInterval = setInterval(() => {
        setLoadeing(true);
        getImages();
      }, 5000);
    }
  }, [total]);

  const getImages = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/list?page=${
        images5.pageNo
      }&limit=15&sort_by=${-1}&status=pending`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.listList) {
          setImages5({
            ...images5,
            data: data.listList,
            totalPages: data.totalPages,
          });

          if (data.listList.length) {
            var msg = new SpeechSynthesisUtterance();
            const name = user ? user.name : "Sir";
            msg.text = `Hi ${name} You Got Some Work To Do`;
            window.speechSynthesis.speak(msg);
            setTotal(1);
            clearInterval(myInterval);
          } else {
            setTotal(0);
          }
        }
       
        setLoadeing(false);
      });
  };
  const startClick = (lb) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };
    const api = `https://embed.axv.ai/api/list/${lb._id}`;
    fetch(api, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.listData) {
          if (data.listData.status === "pending") {
            beginProcess({ status: "progress" }, data.listData._id);
            setImages5({
              ...images5,
              activeData: data.listData,
            });
           setIsGet(isGet + 1)
          } else {
            const filtered = images5.data.filter(
              (f) => f._id !== data.listData._id
            );
            message.warning("Someone Alreday Started");
            setImages5({
              ...images5,
              data: filtered,
            });
            if (filtered.length) {
              clearInterval(myInterval);
            } else {
              setTotal(0);
            }
          }
        } else {
          message.warning("something went wrong");
        }
      });
  };

  const startTimer = (row) => {
    const obj = {
      model_id: user.user_id,
      context: row.context,
      // predict: row.predict,
      url: row.url,
      startTime: new Date(),
      sheet_duration: "start",
      video_id: row._id,
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
    };

    fetch(`https://embed.axv.ai/api/timesheet`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isCreated) {
          setSHeet({ ...sheet, id: data.timesheet_payload?._id });
        }
      });
  };
  const updateTID = (id, row) => {
    const obj = {
      sheet_duration: "end",
      endTime: new Date(),
      context: row.context,
    };
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/timesheet/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
        }
      });
  };

  const beginProcess = (obj, id, complete) => {
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/list/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setLoadingC(false);
        if (data && data.isSuccess) {
          if (complete) {
            const filtered = images5.data.filter(
              (f) => f._id !== data.listData._id
            );
            setImages5({
              ...images5,
              activeData: undefined,
              data: filtered,
            });
            if (filtered.length) {
              clearInterval(myInterval);
            } else {
              setTotal(0);
            }
            message.success("Successfully added move to next");
          } else {
            message.success("Work Faster");
          }
        } else {
          setImages5({
            ...images5,
            activeData: undefined,
          });
          message.error("something went wrong");
        }
      });
  };

  const getData = (v) => {
    const val = v.getDataURL("image/png");
    setImgD(val);
  };

  return (
    <div className="images-pages-s">
      <Row>
        <Col md={{ span: 10 }} lg={{ span: 10 }}>
          <div>
            {images5.data && images5.data.length === 0 ? (
              <div className="no-heigh-div-c">
                <Alert
                  message="No"
                  description="no non no nononon on ono n on on on on ononono nonono"
                  type="info"
                  showIcon
                  className="brand-alert"
                />
              </div>
            ) : null}
            {images5.data &&
              images5.data.slice(0, 1).map((im, imi) => {
                return (
                  <div className="imgs-bx-cs img-less-w" key={imi}>
                    {images5.activeData ? (
                      <div>
                        <canvas
                         
                          ref={canvasRef}
                          width={1024}
                          height={1024}
                          onMouseDown={startDrawing}
                          onMouseMove={draw}
                          onMouseUp={endDrawing}
                          onMouseLeave={endDrawing}
                        />
                      </div>
                    ) : (
                      <img src={im.file_url} alt="" />
                    )}
                    {images5.activeData ? null : loading === im._id ? (
                      <Spin />
                    ) : (
                      <Button
                        onClick={() => {
                          setLoading(im._id);
                          startClick(im);
                        }}
                        type="primary"
                      >
                        Start
                      </Button>
                    )}
                    {images5.activeData ? (
                      <div>
                        {loadingC ? (
                          <Spin />
                        ) : (
                          <Button
                            onClick={() => {
                              setLoadingC(true);

                              beginProcess(
                                {
                                  core_image: imgD,
                                },
                                images5.activeData._id,
                                false
                              );
                            }}
                            type="primary"
                          >
                            Post
                          </Button>
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </Col>
        <Col md={{ span: 14 }} lg={{ span: 14 }}>
          {images5.activeData ? (
            <div className="images5-right-des-s">
              <h6>{images5.activeData.instructions}</h6>
              <div className="txtbx-c ">
                    <div className="imgs-bx-cs img-less-w">
                      <img src={images5.activeData.file_url} alt="" />
                    </div>
                <div className="button-scol-v-col">
                  <div className="comple-btn-dc">
                    {loadingC ? (
                      <Spin />
                    ) : (
                      <Button
                        onClick={() => {
                          setLoadingC(true);
                          beginProcess(
                            {
                              status: "complete",
                            },
                            images5.activeData._id,
                            true
                          );
                        }}
                        type="primary"
                      >
                        Complete
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="no-heigh-div-c">
              <Alert
                message="No"
                description="no non no nononon on ono n on on on on ononono nonono"
                type="info"
                showIcon
                className="brand-alert"
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
