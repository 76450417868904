import { Button } from "antd";
import React, { useState } from "react";
import MambaFilter from "./components/MambaFilter";
import TimeStamp from "./components/Timestamp";
import "./Search.css";

export default function Search() {
  const [tab, setTab] = useState(true);
  return (
    <div>
      <Button onClick={() => setTab(!tab)}>{!tab ? "Search" : "Filter"}</Button>
      {tab ? <TimeStamp /> : <MambaFilter />}
    </div>
  );
}
